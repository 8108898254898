import React, { RefObject, useEffect, useMemo, useState } from 'react'
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, View } from 'react-native'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { R } from 'lib/utils'
import { KeyboardAccessoryFieldProps } from '../../reKeyboardAccessory'
import { Input } from './TextInput'

interface SearchInputProps extends KeyboardAccessoryFieldProps {
    ref?: RefObject<NativeTextInput>,
    disabled?: boolean,
    inputProps?: NativeTextInputProps,
    isPassword?: boolean,
    placeholder?: string,
    rightIcon?: JSX.Element,
    leftIcon?: JSX.Element,
    onFocus?: VoidFunction,
    onBlur?: VoidFunction,
    debounce?: number,
    onQuery?(query: string): void, // for real-time query
    onDebouncedQuery?(query: string): void // for debounce query
}

export const SearchInput = React.forwardRef<NativeTextInput, SearchInputProps>((
    {
        onBlur,
        disabled,
        placeholder,
        inputProps = {},
        leftIcon,
        rightIcon,
        onFocus,
        keyboardAccessory,
        isPassword,
        debounce = 500,
        onQuery,
        onDebouncedQuery
    },
    ref
) => {
    const { styles, theme } = useStyles(stylesheet)
    const [controlledValue, setControlledValue] = useState('')
    const inputStream$ = useMemo(() => onDebouncedQuery && new Subject(), [onDebouncedQuery])

    useEffect(() => {
        const subscribe = inputStream$?.pipe(
            debounceTime(debounce)
        ).subscribe(value => {
            R.ifDefined(onDebouncedQuery, fn => fn(value))
        })

        return () => {
            subscribe?.unsubscribe()
        }
    }, [onDebouncedQuery, inputStream$, debounce])

    return (
        <View
            style={styles.container}
            ref={keyboardAccessory?.fieldRef}
        >
            <Input
                ref={ref || undefined}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                isPassword={isPassword}
                inputProps={{
                    value: controlledValue,
                    placeholder,
                    placeholderTextColor: theme.components.input.typography.placeholder,
                    editable: !disabled,
                    ...inputProps,
                    onChangeText: query => {
                        setControlledValue(query)
                        inputStream$?.next(query)
                        R.ifDefined(onQuery, onQuery => onQuery(query))
                    },
                    onFocus: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(onFocus, R.call)
                        R.ifDefined(textInputProps.onFocus, onFocus => onFocus(event))
                        R.ifDefined(keyboardAccessory?.events?.onFocus, R.call)
                    },
                    onBlur: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(textInputProps.onBlur, onBlur => onBlur(event))
                        R.ifDefined(onBlur, onBlur => onBlur(event))
                        R.ifDefined(keyboardAccessory?.events?.onBlur(), R.call)
                    }
                }}
            />
        </View>
    )
})

const stylesheet = createStyles(() => ({
    container: {
        width: '100%'
    }
}))
