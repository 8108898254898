import React from 'react'
import { useScannedItemsAtom } from 'lib/atoms'
import { ConfirmModal } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { ScreenNames, StackProps } from 'lib/routing'

type ResumeScanModalScreenProps = {
    navigation: StackProps<ScreenNames>
}

export const ResumeScanModalScreen: React.FunctionComponent<ResumeScanModalScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const [, setScannedItems] = useScannedItemsAtom()

    const onGoBack = () => {
        setScannedItems([])
        navigation.goBack()
    }

    return (
        <ConfirmModal
            isVisible
            title={T.screens.resumeScanModal.title}
            message={T.screens.resumeScanModal.message}
            confirmText={T.common.ok}
            onClose={onGoBack}
            onConfirm={() => navigation.replace(ScreenNames.Scanner)}
        />
    )
}
