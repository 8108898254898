import React from 'react'
import { Text, TextStyle } from 'react-native'
import { useStyles } from 'lib/styles'
import { TextChildren } from 'lib/types'

type BaseTextProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    children?: TextChildren
}

export const BaseText: React.FunctionComponent<BaseTextProps> = ({
    children,
    style = {},
    numberOfLines,
    onPress,
    forceColor
}) => {
    const { theme } = useStyles()

    return (
        <Text
            onPress={onPress}
            allowFontScaling={false}
            numberOfLines={numberOfLines}
            ellipsizeMode={numberOfLines ? 'tail' : undefined}
            style={{
                ...style,
                color: forceColor || style.color || theme.colors.almostBlack
            }}
            children={children}
        />
    )
}
