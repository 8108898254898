import React from 'react'
import { View } from 'react-native'
import { createStyles, useStyles } from 'lib/styles'
import { Typography } from 'lib/components'

type ErrorMessageProps = {
    text?: string
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ text }) => {
    const { styles, theme } = useStyles(stylesheet)

    if (!text) {
        return (
            <View style={styles.container} />
        )
    }

    return (
        <View style={styles.container}>
            <View style={styles.text}>
                <Typography.Error forceColor={theme.components.input.typography.error}>
                    {text}
                </Typography.Error>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    text: {
        marginLeft: theme.utils.gap(1),
        flexShrink: 1
    }
}))
