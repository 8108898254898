import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Share: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor={color}
        >
            <Path
                d="M45.3 12.3867C45.3 10.9509 47.0754 10.2179 48.0835 11.3C48.5669 11.8188 48.5941 12.5992 48.1825 13.147V18.8732C48.1825 22.4219 51.1725 25.3895 54.9794 25.3895H59.4803C60.0349 24.9943 60.8094 25.0376 61.3169 25.5278C61.8357 26.0289 61.9312 26.768 61.6516 27.3558C61.391 27.9036 60.8325 28.2721 60.1957 28.2721H54.9794C49.6727 28.2721 45.3 24.1045 45.3 18.8732V12.3867Z"
            />
            <Path
                d="M40.1248 32.5013C40.9208 32.5013 41.5661 33.1466 41.5661 33.9425V52.3864C41.5661 53.1824 40.9208 53.8277 40.1248 53.8277C39.3288 53.8277 38.6836 53.1824 38.6836 52.3864V33.9425C38.6836 33.1466 39.3288 32.5013 40.1248 32.5013Z"
            />
            <Path
                d="M41.144 34.6047C40.5811 34.0418 39.6686 34.0418 39.1057 34.6047L32.7604 40.95C32.1975 41.5128 31.285 41.5128 30.7221 40.95C30.1593 40.3871 30.1593 39.4746 30.7221 38.9117L37.0675 32.5664C38.756 30.8779 41.4937 30.8779 43.1822 32.5664L49.5275 38.9117C50.0904 39.4746 50.0904 40.3871 49.5275 40.95C48.9647 41.5128 48.0521 41.5128 47.4893 40.95L41.144 34.6047Z"
            />
            <Path
                d="M17 17.3C17 13.2683 20.2683 10 24.3 10H47.407C48.3447 10 49.2381 10.3989 49.8641 11.0971L61.7571 24.3624C62.2998 24.9678 62.6 25.7522 62.6 26.5653V61.8C62.6 65.8317 59.3317 69.1 55.3 69.1H24.3C20.2683 69.1 17 65.8317 17 61.8V17.3ZM24.3 12.6C21.7043 12.6 19.6 14.7043 19.6 17.3V61.8C19.6 64.3957 21.7043 66.5 24.3 66.5H55.3C57.8957 66.5 60 64.3957 60 61.8V26.5653C60 26.3928 59.9363 26.2264 59.8212 26.098L47.9282 12.8327C47.7954 12.6846 47.6059 12.6 47.407 12.6H24.3Z"
            />
        </Icon>
    )
}
