import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'

type LeftMenuIconProps = {
    screenName: ScreenNames,
    isActive?: boolean
}

export const LeftMenuIcon: React.FunctionComponent<LeftMenuIconProps> = ({
    screenName,
    isActive
}) => {
    const { styles, theme } = useStyles(stylesheet)

    switch (screenName) {
        case ScreenNames.ManageUsers:
            return (
                <View style={styles.manageUsers}>
                    <Icons.Users
                        forceColor={isActive ? theme.colors.pink : theme.colors.white}
                        size={26}
                    />
                </View>
            )
        case ScreenNames.ManageStores:
            return (
                <Icons.Store
                    forceColor={isActive ? theme.colors.pink : theme.colors.white}
                    size={22}
                />
            )
        case ScreenNames.Files:
            return (
                <Icons.Files
                    forceColor={isActive ? theme.colors.pink : theme.colors.white}
                    size={24}
                />
            )
        case ScreenNames.Search:
            return (
                <Icons.Search
                    forceColor={isActive ? theme.colors.pink : theme.colors.white}
                    size={24}
                />
            )
        default:
            return (
                <React.Fragment />
            )
    }
}

const stylesheet = createStyles(() => ({
    manageUsers: {
        position: 'relative',
        top: -2
    }
}))
