import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const ArrowDown: React.FunctionComponent<IconProps> = ({ strokeWidth, ...props }) => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="m 13 29 l 28 28 l 28 -28"
                stroke={color}
                strokeWidth={strokeWidth || 3}
                strokeLinecap="round"
            />
        </Icon>
    )
}
