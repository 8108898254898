import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Category: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 33.8616 46.05 H 9.3546 a 1.9404 1.9404 90 0 0 -1.9404 1.9404 v 24.4692 c 0 1.071 0.8694 1.9404 1.9404 1.9404 h 24.507 a 1.9404 1.9404 90 0 0 1.9446 -1.9404 v -24.4692 a 1.9404 1.9404 90 0 0 -1.9446 -1.9404 Z M 72.4554 46.05 h -24.5028 a 1.9404 1.9404 90 0 0 -1.9446 1.9404 v 24.4692 c 0 1.071 0.8694 1.9404 1.9446 1.9404 h 24.5028 a 1.9404 1.9404 90 0 0 1.9446 -1.9404 v -24.4692 a 1.9404 1.9404 90 0 0 -1.9446 -1.9404 Z M 33.6474 7.2 H 9.1446 A 1.9404 1.9404 90 0 0 7.2 9.1404 v 24.4692 c 0 1.071 0.8694 1.9404 1.9446 1.9404 h 24.5028 a 1.9404 1.9404 90 0 0 1.9446 -1.9404 V 9.1404 A 1.9404 1.9404 90 0 0 33.6474 7.2 Z M 72.4554 7.2 h -24.5028 a 1.9404 1.9404 90 0 0 -1.9446 1.9404 v 24.4692 c 0 1.071 0.8694 1.9404 1.9446 1.9404 h 24.5028 A 1.9404 1.9404 90 0 0 74.4 33.6096 V 9.1404 A 1.9404 1.9404 90 0 0 72.4554 7.2 Z"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
