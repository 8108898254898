import React from 'react'
import { ViewStyle } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles, useStyles } from 'lib/styles'
import { DropdownOption } from 'lib/types'
import { FormComponents, Grid } from 'lib/components'
import { isWeb } from 'lib/common'
import { DropdownMultiplePickerContent } from './DropdownMultiplePickerContent'

interface DropdownMultiplePickerProps<T> extends Field<Array<DropdownOption<T>>> {
    items: Array<DropdownOption<T>>,
    isLoading?: boolean,
    hasSearch?: boolean,
    searchPlaceholder?: string,
    disabled?: boolean,
    style?: ViewStyle,
    modalTitle?: string,
    emptyMessage: string,
    leftIcon?: JSX.Element,
    onToggle?: VoidFunction,
    onFetchNextPage?: VoidFunction,
    isFetchingNextPage?: boolean,
    handleQuery?(query: string): void,
    renderLabel(item: DropdownOption<T>): JSX.Element
}

export const DropdownMultiplePicker = <T,>({
    isLoading,
    hasSearch,
    searchPlaceholder,
    renderLabel,
    placeholder,
    errorMessage,
    emptyMessage,
    style,
    leftIcon,
    modalTitle,
    onToggle,
    ...pickerProps
}: DropdownMultiplePickerProps<T>) => {
    const { styles } = useStyles(stylesheet)
    const [firstValue] = pickerProps.value
    const extraItems = pickerProps.value?.length > 1 ? `(+${pickerProps.value?.length - 1})` : ``

    return (
        <Grid.Gap
            style={{
                ...styles.container,
                ...style
            }}
        >
            {isWeb ? (
                <FormComponents.DropdownMultiplePicker
                    items={pickerProps.items}
                    disabled={isLoading || pickerProps.disabled}
                    onToggle={onToggle}
                    isLoading={isLoading}
                    value={pickerProps.value}
                    label={pickerProps.label}
                    onChangeValue={pickerProps.onChangeValue}
                    currentText={firstValue ? `${(firstValue.label)} ${extraItems}` : ''}
                    renderLabel={renderLabel}
                    emptyMessage={emptyMessage}
                    leftIcon={leftIcon}
                    placeholder={placeholder}
                />
            ) : (
                <FormComponents.DropdownModal
                    label={pickerProps.label}
                    disabled={isLoading || pickerProps.disabled}
                    onToggle={onToggle}
                    isLoading={isLoading}
                    currentText={firstValue ? `${firstValue.label} ${extraItems}` : ''}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    leftIcon={leftIcon}
                    modalContent={onModalClose => (
                        <DropdownMultiplePickerContent<T>
                            title={modalTitle}
                            hasSearch={hasSearch}
                            emptyMessage={emptyMessage}
                            searchPlaceholder={searchPlaceholder}
                            renderLabel={renderLabel}
                            onClose={onModalClose}
                            disabled={isLoading || pickerProps.disabled}
                            {...pickerProps}
                        />
                    )}
                />
            )}
        </Grid.Gap>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        width: '100%'
    }
}))
