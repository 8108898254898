import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Title: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 26 20 v 55.092 c 0 0.66 0.748 1.092 1.412 0.828 l 14.764 -5.776 a 1.148 1.148 90 0 1 0.84 0 l 13.544 5.712 c 0.676 0.28 1.44 -0.16 1.44 -0.816 V 4.908 c 0 -0.504 -0.456 -0.912 -1.012 -0.912 H 26"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
