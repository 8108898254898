import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const User: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 38.486 37.96 c 9.354 0 16.936 -7.6 16.936 -16.98 C 55.42 11.602 47.84 4 38.486 4 c -9.352 0 -16.934 7.602 -16.934 16.98 c 0 9.38 7.58 16.98 16.934 16.98 Z M 45.364 75.988 H 8.688 a 1.698 1.698 90 0 1 -1.684 -1.834 c 0.694 -9.152 6.436 -29.85 21.824 -29.85 c 4.352 2.83 14.864 2.83 19.27 0 c 16.798 -0.014 23.65 19.004 24.38 29.838 c 0.06 1.006 -0.704 1.858 -1.682 1.858 h -11.744"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
