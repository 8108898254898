import { Input } from './TextInput'
import { ErrorMessage } from './ErrorMessage'
import { DropdownModal } from './DropdownModal'
import { DropdownModalTile } from './DropdownModalTile'
import { Dropdown } from './Dropdown'
import { Switch } from './Switch'
import { QuantityPicker } from './QuantityPicker'
import { Checkbox } from './Checkbox'
import { DropdownMultiplePicker } from './DropdownMultiplePicker'

export { SearchInput } from './SearchInput'
export const FormComponents = {
    Input,
    ErrorMessage,
    DropdownModal,
    DropdownModalTile,
    Dropdown,
    Switch,
    Checkbox,
    QuantityPicker,
    DropdownMultiplePicker
}
