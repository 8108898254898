import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { ResetPasswordFormFields } from './forms'

export const useResetPasswordForm = () => {
    const T = useTranslations()

    const password = useField<string>({
        key: ResetPasswordFormFields.Password,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.password.label,
        placeholder: T.forms.common.password.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.password.validation.isValid,
                validate: regexes.isValidPassword
            },
            {
                errorMessage: T.forms.common.password.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const confirmPassword = useField<string>({
        key: ResetPasswordFormFields.ConfirmPassword,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.confirmPassword.label,
        placeholder: T.forms.common.confirmPassword.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.confirmPassword.validation.doesNotMatch,
                validate: value => value === password.value
            }
        ]
    })

    return {
        password,
        confirmPassword
    }
}
