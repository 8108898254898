import React from 'react'
import { View } from 'react-native'
import { AppLayout, Grid, NavigationHeader, ReKeyboardAccessory } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { isNative, isWeb } from 'lib/common'
import { createStyles, useStyles } from 'lib/styles'
import { ItemSetup } from '../components'

type ItemSetupScreenProps = {
    route: NavigationParams<ScreenNames.ItemSetup | ScreenNames.ItemEdit>
}

export const ItemSetupScreen: React.FunctionComponent<ItemSetupScreenProps> = ({ route }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <AppLayout>
            <Grid.Background
                hasPaddingHorizontal={isWeb}
                hasPaddingVertical={isWeb}
            >
                <NavigationHeader hasMarginHorizontal/>
                {isNative ? (
                    <ReKeyboardAccessory numberOfInputs={5}>
                        {keyboardProps => {
                            const [
                                bookCodeProps,
                                titleProps,
                                authorProps,
                                categoryProps,
                                skuProps,
                                quantityProps
                            ] = keyboardProps

                            return (
                                <ItemSetup
                                    storeUUID={route.params?.storeUUID}
                                    bookCodeProps={bookCodeProps}
                                    titleProps={titleProps}
                                    authorProps={authorProps}
                                    categoryProps={categoryProps}
                                    skuProps={skuProps}
                                    quantityProps={quantityProps}
                                />
                            )
                        }}
                    </ReKeyboardAccessory>
                ) : (
                    <View style={styles.container}>
                        <ItemSetup storeUUID={route.params?.storeUUID}/>
                    </View>
                )}
            </Grid.Background>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    }
}))
