import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const Plus: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                stroke={color}
                strokeWidth={6}
                strokeMiterlimit={10}
                strokeLinecap="round"
                d="M 40.5 3 v 72 M 76.5 39 h -72"
            />
        </Icon>
    )
}
