import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, RefreshControl, View, useWindowDimensions } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { useIsWithinBreakpoints, useTranslations, useToast, usePagination } from 'lib/hooks'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { ListTile } from 'features/user'
import { useUserAtom } from 'lib/atoms'
import { isNative, isWeb } from 'lib/common'
import {
    Button,
    Grid,
    SearchInput,
    Typography,
    Table,
    ConfirmModal
} from 'lib/components'
import { Store, UserType } from 'lib/models'
import { ScreenNames } from 'lib/routing'
import { R, inputProps } from 'lib/utils'
import { Action, TableAction, TooltipPlacement } from 'lib/types'
import { useDeleteStore, useGetStores, useGetStoresInfinity } from '../actions'
import { manageStoresTableHeaders } from '../mocks'
import { getManageStoresTableData } from '../utils'

type ManageStoresScreenProps = {
    query: string,
    setQuery(query: string): void
}

export const ManageStores : React.FunctionComponent<ManageStoresScreenProps> = ({
    query,
    setQuery
}) => {
    const { actions: { showErrorToast } } = useToast()
    const [totalStoresCount, setTotalStoresCount] = useState(0)
    const [user] = useUserAtom()
    const isMobileWidth = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const isSmallerView = useWindowDimensions().width < 1240
    const isOwner = user.userType === UserType.Admin
    const isOwnerOrManager = user.userType === UserType.Admin || user.userType === UserType.Manager
    const T = useTranslations()
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const { styles, theme } = useStyles(stylesheet)
    const { mutate: deleteStore, isLoading: isDeleting } = useDeleteStore()
    const [storeToDeleteUUID, setStoreToDeleteUUID] = useState('')
    const pagination = usePagination(totalStoresCount)
    const isTableFetchingDisabled = isNative || isMobileWidth
    const isInfinityScrollFetchingDisabled = !isMobileWidth
    const {
        data: infinityScrollData,
        fetchNextPage,
        hasNextPage,
        isFetching: isInfinityScrollFetching,
        refetch: refetchInfinityScroll
    } = useGetStoresInfinity(query, isInfinityScrollFetchingDisabled)
    const {
        isFetching,
        data: tableData,
        refetch: refetchTableData
    } = useGetStores(query, pagination?.state.offset, pagination?.state.limit, isTableFetchingDisabled)
    const isLoading = isDeleting || isFetching
    const stores = tableData?.data.stores || []
    const infinityScrollStores = infinityScrollData?.pages.flatMap(page => page.data.stores) || []

    useEffect(() => {
        if (tableData?.data.totalCount) {
            setTotalStoresCount(tableData.data.totalCount)
        }
    }, [tableData])

    const onDeleteStore = () =>  deleteStore(
        {
            storeUUID: storeToDeleteUUID
        },
        {
            onSuccess: () => {
                setStoreToDeleteUUID('')

                if (isNative) {
                    refetchInfinityScroll()
                }

                if (!isNative) {
                    refetchTableData()
                }
            },
            onError: () => showErrorToast(T.alerts.oops)
        }
    )

    const tableActions = [
        {
            havePermissions: true,
            key: TableAction.Enter,
            label: T.components.table.actions.viewItems,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Files
                    size={24}
                    forceColor={theme.colors.white}
                />
            ),
            action: store => navigation.navigate(ScreenNames.StoreItems, {
                store,
                storeUUID: store.storeUUID
            })
        },
        {
            havePermissions: isOwnerOrManager,
            key: TableAction.Edit,
            label: T.components.table.actions.edit,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Pencil
                    size={28}
                    forceColor={theme.colors.white}
                />
            ),
            action: params => navigation.navigate(ScreenNames.StoreSetup, { ...params })
        },
        {
            havePermissions: isOwner,
            key: TableAction.Remove,
            label: T.components.table.actions.remove,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Bin
                    size={24}
                    forceColor={theme.colors.white}
                />
            ),
            action: params => setStoreToDeleteUUID(params.storeUUID)
        }
    ] satisfies Array<Action<Store>>

    return (
        <View style={styles.container}>
            <View style={styles.actions}>
                <View style={styles.searchInputWrapper}>
                    <SearchInput
                        placeholder={T.screens.manageStores.searchPlaceholder}
                        onDebouncedQuery={setQuery}
                        inputProps={inputProps.name}
                        rightIcon={(
                            <Icons.Search size={22}/>
                        )}
                    />
                </View>
                {isWeb && isOwnerOrManager && (
                    <Button
                        customStyle={styles.addStoreButtonWeb}
                        isLoading={isDeleting}
                        text={T.screens.manageStores.addStoreButton}
                        onPress={() => navigation.navigate(ScreenNames.StoreSetup)}
                    />
                )}
            </View>
            {isWeb && (
                <Table
                    query={query}
                    columns={manageStoresTableHeaders(isSmallerView)}
                    data={getManageStoresTableData(stores ?? [])}
                    isLoading={isLoading}
                    style={styles.table}
                    pagination={pagination}
                    actions={tableActions.filter(action => action.havePermissions)}
                    cellStyle={styles.tableCell}
                    rowStyle={styles.tableRow}
                />
            )}
            {isMobileWidth && (
                <FlatList
                    data={infinityScrollStores}
                    style={{
                        ...styles.list,
                        ...conditionalStyle(isWeb, styles.disableListPadding)
                    }}
                    keyExtractor={storeItem => storeItem.storeUUID}
                    renderItem={({ item }) => (
                        <ListTile
                            title={item.storeName}
                            primaryText={`${item.city}, ${item.state}`}
                            secondaryText={item.streetAddress}
                            onPress={() => {
                                navigation.navigate(ScreenNames.StoreItems, {
                                    store: item,
                                    storeUUID: item.storeUUID
                                })
                            }}
                            onEditPress={isOwnerOrManager
                                ? () => navigation.navigate(ScreenNames.StoreSetup, { ...item })
                                : undefined
                            }
                            onDeletePress={isOwner
                                ? () => setStoreToDeleteUUID(item.storeUUID)
                                : undefined
                            }
                        />
                    )}
                    onEndReached={() => {
                        if (hasNextPage && !isInfinityScrollFetching) {
                            fetchNextPage()
                        }
                    }}
                    ListFooterComponent={(isInfinityScrollFetching && infinityScrollStores?.length > 10) ? (
                        <ActivityIndicator />
                    ) : undefined}
                    refreshControl={(
                        <RefreshControl
                            refreshing={isInfinityScrollFetching && isFocused}
                            onRefresh={isInfinityScrollFetching
                                ? R.T
                                : refetchInfinityScroll
                            }
                            colors={[theme.ui.primary]}
                            tintColor={theme.ui.primary}
                            progressViewOffset={theme.gap}
                        />
                    )}
                    showsVerticalScrollIndicator={false}
                    ListEmptyComponent={!isInfinityScrollFetching ? (
                        <View style={styles.noStoresMessage}>
                            <Typography.Subheading>
                                {query.length !== 0
                                    ? T.screens.manageStores.noStoresFoundMessage
                                    : T.screens.manageStores.noStoresMessage
                                }
                            </Typography.Subheading>
                        </View>
                    ) : undefined}
                />
            )}
            {isMobileWidth && (
                <View style={conditionalStyle(isWeb, styles.listWrapper)}>
                    {isOwnerOrManager && (
                        <Grid.Gap gapTop={4}>
                            <Button
                                text={T.screens.manageStores.addStoreButton}
                                onPress={() => navigation.navigate(ScreenNames.StoreSetup)}
                                customStyle={{
                                    ...styles.addStoreButton,
                                    ...conditionalStyle(isNative, styles.addStoreButtonNative)
                                }}
                            />
                        </Grid.Gap>
                    )}
                </View>
            )}
            <ConfirmModal
                isVisible={Boolean(storeToDeleteUUID)}
                message={T.screens.manageStores.areYouSureToDelete}
                onClose={() => setStoreToDeleteUUID('')}
                onConfirm={onDeleteStore}
                isLoading={isDeleting}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        width: '100%'
    },
    keyboardAvoidingWrapper: {
        alignItems: 'center',
        width: '100%',
        flex: 1
    },
    header: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        paddingBottom: theme.gap * 2
    },
    noStoresMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actions: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.gap * 2
    },
    addStoreButtonWeb: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        width: 220
    },
    listWrapper: {
        width: '100%'
    },
    searchWrapperContainer: {
        alignItems: 'center',
        flexGrow: 0
    },
    searchWrapper: {
        width: '100%',
        flexGrow: 0
    },
    searchInputWrapper: {
        width: {
            xs: '100%',
            sm: 328
        }
    },
    listContainer: {
        paddingBottom: theme.utils.gap(8)
    },
    list: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        flex: 1
    },
    emptyList: {
        flex: 1,
        justifyContent: 'center'
    },
    deleteModal: {
        backgroundColor: theme.ui.background
    },
    noButtonShadow: {
        bottom: theme.utils.gap(8)
    },
    addStoreButton: {
        display: {
            xs: 'flex',
            sm: 'none'
        }
    },
    disableListPadding: {
        paddingBottom: 0,
        paddingTop: 0
    },
    table: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        marginTop: theme.gap
    },
    tableCell: {
        lineHeight: 68
    },
    addStoreButtonNative: {
        marginBottom: theme.gap * 4
    },
    tableRow: {
        width: {
            ':w[, 1100]': 'calc(100% + 120px)'
        }
    }
}))
