import React from 'react'
import { useColorScheme } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import * as SplashScreen from 'expo-splash-screen'
import { QueryClientProvider } from '@tanstack/react-query'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { UnistylesTheme } from 'react-native-unistyles'
import { queryClient } from 'lib/api'
import { AppRouter } from 'lib/routing'
import { KeyboardProvider, Toast } from 'lib/components'
import { R } from 'lib/utils'
import { useCustomFonts, useQueryFocusManager } from 'lib/hooks'
import { createStyles, darkTheme, lightTheme, useStyles } from 'lib/styles'
import { ApperanceTheme } from 'lib/types'
import { isWeb } from 'lib/common'

SplashScreen
    .preventAutoHideAsync()
    .catch(R.T)

export const App: React.FunctionComponent = () => {
    const colorScheme = useColorScheme()
    const theme = (colorScheme === ApperanceTheme.Dark || isWeb)
        ? darkTheme
        : lightTheme
    const { styles } = useStyles(stylesheet)
    const { fontsLoaded, onLayoutRootView } = useCustomFonts()

    useQueryFocusManager()

    if (!fontsLoaded) {
        return null
    }

    return (
        <QueryClientProvider client={queryClient}>
            <UnistylesTheme theme={theme}>
                <GestureHandlerRootView
                    onLayout={onLayoutRootView}
                    style={styles.container}
                >
                    <KeyboardProvider>
                        <AppRouter />
                    </KeyboardProvider>
                    <StatusBar
                        style="auto"
                        backgroundColor={theme.ui.background}
                    />
                    <Toast />
                </GestureHandlerRootView>
            </UnistylesTheme>
        </QueryClientProvider>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flex: 1
    }
}))
