import React from 'react'
import { G, Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const State: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 23.5864 23.4492 c 2.268 -19.548 29.052 -17.352 29.952 0 c 0.144 10.512 -7.2 19.08 -14.976 27.54 c -7.848 -7.704 -16.344 -18.648 -14.976 -27.54 Z"
                />
                <Path
                    d="M 38.494 31.9452 c 3.564 0 6.444 -2.952 6.444 -6.588 c 0 -3.636 -2.88 -6.588 -6.444 -6.588 s -6.444 2.952 -6.444 6.588 c 0 3.636 2.88 6.588 6.444 6.588 Z"
                />
                <Path
                    d="M 42.49 47.9652 h 22.32 c 0.324 0 0.648 0.18 0.864 0.504 l 10.62 14.868 c 0.72 1.008 0.18 2.628 -0.864 2.628 H 5.806 c -1.044 0 -1.584 -1.584 -0.9 -2.592 l 9.972 -14.868 c 0.216 -0.324 0.576 -0.54 0.9 -0.54 h 19.116 M 29.89 47.2452 l -2.988 8.892 M 10.018 56.9652 h 42.3 M 19.594 56.1732 l -4.68 9.828 M 49.186 47.9652 l 6.84 18 M 69.3784 55.1652 h -18.036                    "
                />
            </G>
        </Icon>
    )
}
