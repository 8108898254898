import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const Envelope: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 62.4262 67.4162 a 13.0688 13.0688 90 0 1 -2.25 0.1912 H 14.8575 c -6.8625 0 -12.45 -5.55 -12.45 -12.375 V 24.7675 c 0 -6.825 5.5875 -12.375 12.45 -12.375 h 45.3263 c 6.8625 0 12.4462 5.55 12.4462 12.375 v 30.495" />
                <Path
                    d="m 11.1937 21.0363 l 19.3462 19.65 a 9.7387 9.7387 90 0 0 13.8562 0 l 19.3425 -19.65"
                />
            </G>
        </Icon>
    )
}
