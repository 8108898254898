import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const ArrowBack: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 71 40 H 7 m 0 0 l 32 -28 M 7 40 l 32 28"
                stroke={color}
                strokeWidth={4}
                strokeLinecap="round"
            />
        </Icon>
    )
}
