import { authUtils, logoutFromGoogle } from 'features/auth'
import { AuthProvider } from 'lib/models'
import { mmkvStorage } from '../mmkv'
import { useAuthProviderAtom, useResetAtoms } from '../atoms'

export const useCleanApp = () => {
    const { resetAtoms } = useResetAtoms()
    const [authProvider] = useAuthProviderAtom()

    return {
        cleanPersonalData: () => {
            authUtils.clearAuthTokens()

            // todo sign out from social providers
            if (authProvider === AuthProvider.Google) {
                logoutFromGoogle()
            }

            resetAtoms()
            mmkvStorage.deleteAll()
        }
    }
}
