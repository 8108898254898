import React, { useEffect, useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { queryClient, QueryKey } from 'lib/api'
import { isNative, isWeb } from 'lib/common'
import { ReKeyboardAccessory, AppLayout, Grid, NavigationHeader } from 'lib/components'
import { useBackHandler, useToast, useTranslations, useGoBack } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { Store } from 'lib/models'
import { useAddNewUser, useEditUser, useGetUserStores } from '../actions'
import { AddEditUserFields, useAddEditUserForm } from '../forms'
import { AddNewUserRequest, EditUserRequest } from '../types'
import { AddEditUser } from '../components'

type AddEditUserScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.EditUser | ScreenNames.AddUser>
}

export const AddEditUserScreen: React.FunctionComponent<AddEditUserScreenProps> = ({ navigation, route }) => {
    const showSecurityGroup = false // todo
    const T = useTranslations()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { actions: { showSuccessToast, showErrorToast } } = useToast()
    const [storeQuery, setStoreQuery] = useState('')
    const { mutate: addNewUser, isLoading: isAddingNewUser } = useAddNewUser()
    const { mutate: editUser, isLoading: isEditingUser } = useEditUser()
    const [userStores, setUserStores] = useState<Array<Store>>([])
    const { goBack } = useGoBack(ScreenNames.ManageUsers)
    const { mutate: getUserStores, isLoading: isLoadingUserStores } = useGetUserStores()
    const isLoading = isAddingNewUser || isEditingUser || isLoadingUserStores
    const { form, submit, resetForm, setFieldInitialValue, setFieldValue } = useForm(useAddEditUserForm(route.params, true, false), {
        onSuccess: ({ email, stores, password, fullName, securityGroup }) => {
            // securityGroup will be used in the future
            if (route.params) {
                const editedUser: EditUserRequest = {
                    userUUID: route.params.userUUID,
                    email,
                    fullName: fullName || undefined,
                    password: password || undefined,
                    userType: securityGroup,
                    storesUUID: stores.length
                        ? stores.map(store => store.key)
                        : undefined
                }

                return editUser(editedUser, {
                    onSuccess: () => {
                        isNative
                            ? queryClient.invalidateQueries([QueryKey.InfiniteUsers])
                            : queryClient.invalidateQueries([QueryKey.Users])

                        resetForm()
                        showSuccessToast(T.screens.addEditUser.toast.editSuccess)
                        goBack()
                    },
                    onError: error => showErrorToast(error.data?.message || T.alerts.oops)
                })
            }

            const newUser: AddNewUserRequest = {
                email,
                password,
                fullName,
                userType: securityGroup,
                storesUUID: stores.length
                    ? stores.map(store => store.key)
                    : undefined
            }

            addNewUser(newUser, {
                onSuccess: () => {
                    isNative
                        ? queryClient.invalidateQueries([QueryKey.InfiniteUsers])
                        : queryClient.invalidateQueries([QueryKey.Users])

                    resetForm()
                    showSuccessToast(T.screens.addEditUser.toast.addSuccess)
                    goBack()
                },
                onError: error => showErrorToast(error.data?.message || T.alerts.oops)
            })
        }
    })

    const fetchUserStores = () => {
        if (route.params?.userUUID) {
            getUserStores({ userUUID: route.params.userUUID }, {
                onSuccess: response => {
                    const user = response.data.users.find(user => user.userUUID === route.params?.userUUID)

                    if (user) {
                        setUserStores(user.userStores)
                    }
                }
            })
        }
    }

    useEffect(() => {
        fetchUserStores()
    }, [route.params?.userUUID])

    useEffect(() => {
        if (userStores?.length) {
            setFieldValue(AddEditUserFields.Stores, userStores.map(store => ({
                key: store.storeUUID,
                label: store.storeName,
                value: store
            })) ?? [])
        }
    }, [userStores])

    useEffect(() => {
        setFieldInitialValue(AddEditUserFields.Email, route.params?.email ?? '')
        setFieldInitialValue(AddEditUserFields.FullName, route.params?.fullName ?? '')
        setFieldInitialValue(AddEditUserFields.SecurityGroup, route.params?.type)
    }, [route.params])

    useBackHandler(() => isLoading)

    const onClear = () => {
        setUserStores([])
        resetForm()
    }

    return (
        <AppLayout>
            <Grid.Background
                hasPaddingHorizontal={isWeb}
                hasPaddingVertical={isWeb}
            >
                <NavigationHeader hasMarginHorizontal />
                {isNative ? (
                    <ReKeyboardAccessory
                        numberOfInputs={3}
                        isModalOpen={isModalOpen}
                    >
                        {keyboardProps => {
                            const [
                                fullNameProps,
                                passwordProps,
                                emailProps
                            ] = keyboardProps

                            return (
                                <AddEditUser
                                    onToggle={() => setIsModalOpen(prevState => !prevState)}
                                    form={form}
                                    submit={submit}
                                    isLoading={isLoading}
                                    showSecurityGroup={showSecurityGroup}
                                    storeQuery={storeQuery}
                                    setStoreQuery={setStoreQuery}
                                    fullNameProps={fullNameProps}
                                    passwordProps={passwordProps}
                                    emailProps={emailProps}
                                    route={route}
                                    onClear={onClear}
                                />
                            )
                        }}
                    </ReKeyboardAccessory>
                ) : (
                    <AddEditUser
                        onToggle={() => setIsModalOpen(prevState => !prevState)}
                        form={form}
                        submit={submit}
                        isLoading={isLoading}
                        showSecurityGroup={showSecurityGroup}
                        storeQuery={storeQuery}
                        setStoreQuery={setStoreQuery}
                        route={route}
                        onClear={onClear}
                    />
                )}
            </Grid.Background>
        </AppLayout>
    )
}
