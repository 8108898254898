import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const EyeCrossed: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M 8.2312 9.56 a 3.2724 3.2724 90 0 1 4.6284 0 l 46.722 46.7208 a 3.252 3.252 90 0 1 0.4992 0.4992 l 11.688 11.688 a 3.2724 3.2724 90 0 1 -4.6284 4.6284 L 57.04 62.9936 A 33.7728 33.7728 90 0 1 40 67.5092 c -14.6616 0 -26.4852 -8.6628 -35.5692 -24.558 a 3.2736 3.2736 90 0 1 0 -3.2484 a 58.5312 58.5312 90 0 1 13.08 -16.2408 l -9.276 -9.276 a 3.2724 3.2724 90 0 1 -0.0036 -4.626 Z m 13.9284 18.5544 a 49.482 49.482 90 0 0 -11.0904 13.2132 C 19.24 54.8456 28.9144 60.9632 40 60.9632 A 27.1956 27.1956 90 0 0 52.2016 58.16 l -7.9668 -7.9668 a 9.8196 9.8196 90 0 1 -13.098 -13.1004 Z m 15.3396 15.3408 l 0.3732 0.372 q -0.1008 -0.0852 -0.1944 -0.18 t -0.1788 -0.192 Z M 32.2696 22.76 a 27.7092 27.7092 90 0 1 7.7184 -1.068 H 40 c 11.0856 0 20.76 6.12 28.9308 19.6356 a 56.8548 56.8548 90 0 1 -6.7992 9.1944 a 3.2724 3.2724 90 0 0 4.8072 4.44 a 65.5428 65.5428 90 0 0 8.6292 -12.012 a 3.2736 3.2736 90 0 0 0 -3.2484 C 66.4864 23.8112 54.6652 15.1472 40 15.146 a 34.254 34.254 90 0 0 -9.5364 1.32 a 3.2724 3.2724 90 0 0 1.8 6.2928 Z"
        />
    </Icon>
)
