import React, { useEffect } from 'react'
import { View } from 'react-native'
import {
    Canvas,
    LinearGradient,
    Rect,
    useSharedValueEffect,
    useValue,
    vec
} from '@shopify/react-native-skia'
import { Easing, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'
import { createStyles, useStyles } from 'lib/styles'
import { useGetScannerDimensions } from '../hooks'

export const ScannerAnimated: React.FunctionComponent = () => {
    const isScannerShadowVisible = false // todo to be determined
    const { styles, theme } = useStyles(stylesheet)
    const { scannerWidth, scannerHeight } = useGetScannerDimensions()
    const animatedScanProgress = useSharedValue(1)
    const scanHeight = useValue(scannerHeight)
    const scanPositionY = useValue(scannerHeight)
    const gradientStart = useValue(vec(scannerHeight / 2, 0))

    useSharedValueEffect(() => {
        scanHeight.current = animatedScanProgress.value * scannerHeight
        scanPositionY.current = (1 - animatedScanProgress.value) * scannerHeight
        gradientStart.current = vec(
            scannerWidth / 2,
            -animatedScanProgress.value * scannerHeight
        )
    }, animatedScanProgress)

    useEffect(() => {
        animatedScanProgress.value = withRepeat(
            withTiming(
                0,
                {
                    duration: 2000,
                    easing: Easing.linear
                }),
            -1,
            true
        )
    }, [])

    return (
        <View
            style={{
                ...styles.scanner,
                width: scannerWidth,
                height: scannerHeight
            }}
        >
            <Canvas
                style={{
                    width: scannerWidth,
                    height: scannerHeight
                }}
            >
                <Rect
                    x={0}
                    y={scanPositionY}
                    width={scannerWidth}
                    height={2}
                    color={theme.ui.primary}
                />
                {isScannerShadowVisible && (
                    <Rect
                        x={0}
                        y={scanPositionY}
                        width={scannerWidth}
                        height={scannerHeight}
                    >
                        <LinearGradient
                            start={gradientStart}
                            end={vec(scannerWidth / 2, scannerHeight)}
                            colors={[
                                theme.colors.mixTransparent(theme.ui.primary, 0.5),
                                theme.colors.mixTransparent(theme.ui.primary, 0)
                            ]}
                        />
                    </Rect>
                )}
            </Canvas>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    scanner: {
        borderRadius: 12,
        overflow: 'hidden'
    }
}))
