export enum AdminSetupFormFields {
    FullName = 'fullName',
    Email = 'email',
    Password = 'password'
}

export type AdminSetupFormShape = {
    [AdminSetupFormFields.FullName]: string,
    [AdminSetupFormFields.Email]: string,
    [AdminSetupFormFields.Password]: string
}
