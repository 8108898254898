import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Checkmark: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M29.922 61.079a3.116 3.116 0 0 1-2.207-.912L10.919 43.428A3.126 3.126 0 1 1 15.332 39l14.59 14.54 34.745-34.628a3.126 3.126 0 0 1 4.413 4.428L32.129 60.168a3.116 3.116 0 0 1-2.207.911Z"
        />
    </Icon>
)
