import { useState } from 'react'
import { Alert } from 'react-native'
import { Languages } from 'lib/locale'
import { APP_CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'
import { AppleID, AuthFlowError } from 'lib/types'
import { getAppleAppBundleIdentifier } from 'lib/utils'
import { useSignInWithAppleWeb } from '../actions'

declare global {
    export interface Window {
        AppleID: AppleID
    }
}

export const getAppleInstance = () => {
    if (!window.AppleID) {
        throw AuthFlowError.ServerError
    }

    return window.AppleID
}

export const initAppleSDK = (id: string, language: string) => new Promise<void>((resolve, reject) => {
    const doesScriptExist = Boolean(document.getElementById(id))

    if (doesScriptExist) {
        return resolve()
    }

    if (!language.includes('_')) {
        return reject()
    }

    const scriptTag = document.createElement('script')

    scriptTag.id = id
    scriptTag.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${language}/appleid.auth.js`
    scriptTag.onload = () => {
        const apple = getAppleInstance()

        apple.auth.init({
            scope: 'name email',
            clientId: getAppleAppBundleIdentifier(),
            redirectURI: window.location.origin,
            usePopup: true
        })

        resolve()
    }
    scriptTag.onerror = () => reject()

    const body = document.querySelector('body')

    if (!body) {
        return reject()
    }

    body.append(scriptTag)
})

export const useAppleAuth = () => {
    const T = useTranslations()
    const [isLoading, setIsLoading] = useState(false)
    const { mutate: signInWithApple, isLoading: isMutating } = useSignInWithAppleWeb()

    const authorizeWithApple = async () => {
        setIsLoading(true)

        initAppleSDK(APP_CONFIG.KEYS.APPLE_SCRIPT_ID, Languages.en_US)
            .then(() => getAppleInstance())
            .then(async apple => {
                const data = await apple.auth.signIn()
                const { user, authorization } = data

                signInWithApple(
                    {
                        authorizationCode: authorization.code,
                        fullName: user
                            ? `${user.name.firstName} ${user.name.lastName}`
                            : ''
                    },
                    {
                        onError: () => {
                            setIsLoading(false)
                            Alert.alert(T.alerts.oops, T.components.authMethods.errorApple)
                        }
                    }
                )
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    return {
        isLoading: isLoading || isMutating,
        authorizeWithApple
    }
}

