import React, { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons } from 'assets'
import { Button, FullScreenLoader, Grid, SquareTile, Typography, Logo } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { authActions } from 'features/auth'
import { R } from 'lib/utils'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { useScanConfigAtom, useScannedItemsAtom, useUserAtom } from 'lib/atoms'
import { UserType } from 'lib/models'
import { useGetGreeting } from '../hooks'

type HomeScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.Home>
}

export const HomeScreen: React.FunctionComponent<HomeScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [scannedItems] = useScannedItemsAtom()
    const { bottom } = useSafeAreaInsets()
    const [scanConfig] = useScanConfigAtom()
    const isOwnerOrManager = user.userType === UserType.Admin || user.userType === UserType.Manager
    const { mutate: logout, isLoading } = authActions.useLogout()
    const greeting = useGetGreeting()

    useEffect(() => {
        if (scannedItems.length && scanConfig?.store) {
            navigation.navigate(ScreenNames.ResumeScan)
        }
    }, [])

    return (
        <Grid.Background>
            {R.hasKeys(user) && (
                <ScrollView
                    scrollEnabled
                    contentContainerStyle={styles.container}
                    showsVerticalScrollIndicator={false}
                >
                    <Logo/>
                    <Grid.Gap gapBottom={2}/>
                    <Typography.Subheading>
                        {greeting}
                    </Typography.Subheading>
                    <Typography.Heading numberOfLines={1}>
                        {user.fullName}
                    </Typography.Heading>
                    <View style={styles.tiles}>
                        <Grid.Gap style={styles.row}>
                            <SquareTile
                                disabled={isLoading}
                                label={T.titles.inventory}
                                icon={(
                                    <Icons.Barcode
                                        size={50}
                                        forceColor={theme.components.icon.color}
                                    />
                                )}
                                onPress={() => navigation.navigate(ScreenNames.ChooseScanStore)}
                            />
                            <SquareTile
                                disabled={isLoading}
                                label={T.titles.manageStores}
                                icon={(
                                    <Icons.Store
                                        size={50}
                                        forceColor={theme.components.icon.color}
                                    />
                                )}
                                onPress={() => navigation.navigate(ScreenNames.ManageStores)}
                            />
                        </Grid.Gap>
                        <Grid.Gap style={styles.row}>
                            <SquareTile
                                disabled={isLoading}
                                label={T.titles.files}
                                icon={(
                                    <Icons.Files
                                        size={50}
                                        forceColor={theme.components.icon.color}
                                    />
                                )}
                                onPress={() => navigation.navigate(ScreenNames.Files)}
                            />
                            <SquareTile
                                disabled={isLoading}
                                label={T.titles.account}
                                icon={(
                                    <Icons.User
                                        size={50}
                                        forceColor={theme.components.icon.color}
                                    />
                                )}
                                onPress={() => navigation.navigate(ScreenNames.EditSelf)}
                            />
                        </Grid.Gap>
                        <Grid.Gap style={styles.row}>
                            {isOwnerOrManager && (
                                <SquareTile
                                    disabled={isLoading}
                                    label={T.titles.manageUsers}
                                    icon={(
                                        <Icons.Users
                                            size={60}
                                            forceColor={theme.components.icon.color}
                                        />
                                    )}
                                    onPress={() => navigation.navigate(ScreenNames.ManageUsers)}
                                />
                            )}
                            <SquareTile
                                disabled={isLoading}
                                label={T.titles.search}
                                icon={(
                                    <Icons.Search
                                        size={60}
                                        forceColor={theme.components.icon.color}
                                    />
                                )}
                                onPress={() => navigation.navigate(ScreenNames.Search)}
                            />
                        </Grid.Gap>
                        <Grid.Gap gapTop={1} />
                    </View>
                    <View style={styles.row}>
                        <View style={styles.signOutContainer(bottom)}>
                            <Button
                                isPlain
                                isLoading={isLoading}
                                text={T.screens.home.signOut}
                                onPress={logout}
                                customStyleText={styles.signOutButtonText}
                            />
                        </View>
                    </View>
                </ScrollView>
            )}
            <FullScreenLoader
                isActive={!R.hasKeys(user)}
                color={theme.colors.pink}
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center'
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 12
    },
    spacer: {
        flex: 1
    },
    signOutButtonText: {
        fontWeight: '400'
    },
    tiles: {
        marginTop: theme.gap * 3,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap * 3
    },
    signOutContainer: (bottomInset: number) => ({
        flex: 1,
        paddingHorizontal: 12,
        paddingBottom: bottomInset + (theme.gap * 2)
    })
}))
