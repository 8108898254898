import React from 'react'
import { Modal, View, ViewStyle } from 'react-native'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { Touchable, Typography } from 'lib/components'
import { Measurements } from 'lib/common'
import { Icons } from 'assets'

type CustomModalProps = {
    disabledIconClose?: boolean,
    onClose: VoidFunction,
    isModalVisible: boolean,
    title?: string,
    subtitle?: string,
    isLoading?: boolean,
    containerStyle?: ViewStyle,
    buttonContainerStyle?: ViewStyle,
    renderButton(): JSX.Element,
    renderImage?(): JSX.Element
}

export const CustomModal: React.FunctionComponent<CustomModalProps> = ({
    disabledIconClose,
    onClose,
    title,
    subtitle,
    renderImage,
    containerStyle,
    buttonContainerStyle,
    renderButton,
    isModalVisible
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Modal
            transparent
            animationType="fade"
            visible={isModalVisible}
            statusBarTranslucent
            onRequestClose={onClose}
        >
            <Touchable
                activeOpacity={1}
                style={styles.background}
            >
                <View
                    style={{
                        ...styles.container,
                        ...containerStyle
                    }}
                >
                    <Touchable
                        disabled={disabledIconClose}
                        style={styles.closeIcon}
                        onPress={onClose}
                    >
                        <Icons.Close
                            size={20}
                            forceColor={theme.components.icon.primary}
                        />
                    </Touchable>
                    {renderImage && (
                        renderImage()
                    )}
                    {Boolean(title) && (
                        <Typography.Subheading style={styles.text}>
                            {title}
                        </Typography.Subheading>
                    )}
                    {Boolean(subtitle) && (
                        <Typography.Regular style={styles.text}>
                            {subtitle}
                        </Typography.Regular>
                    )}
                    <View
                        style={{
                            ...styles.buttons,
                            ...buttonContainerStyle
                        }}
                    >
                        {renderButton()}
                    </View>
                </View>
            </Touchable>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: Measurements.WindowHeight,
        width: Measurements.WindowWidth,
        backgroundColor: theme.components.modal.whiteBackdrop.backgroundColor
    },
    container: {
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.3, 5),
        width: '90%',
        padding: theme.gap * 2,
        borderRadius: theme.gap * 2,
        paddingVertical: theme.gap * 6,
        alignItems: 'center'
    },
    buttons: {
        width: '100%',
        marginTop: theme.gap * 2,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    text: {
        textAlign: 'center',
        marginTop: theme.gap * 2,
        marginBottom: theme.gap * 4
    },
    closeIcon: {
        top: 20,
        right: 20,
        position: 'absolute'
    }
}))
