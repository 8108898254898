import React, { useEffect, useState } from 'react'
import { Alert } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { isAndroid } from 'lib/common'
import { Adapter, BlurModal, Button, Grid, Typography } from 'lib/components'
import { useBackHandler, useTimer, useTranslations, useAppState } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { inputProps } from 'lib/utils'
import { useForgotPassword } from '../actions'
import { useForgotPasswordForm } from '../forms'

type ForgotPasswordScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ForgotPassword>
}

export const ForgotPasswordScreen: React.FunctionComponent<ForgotPasswordScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [email, setEmail] = useState('')
    const { timer, startTimer, isTimerInProgress } = useTimer()
    const { mutate: sendResetPassword, isLoading } = useForgotPassword()
    const { isInBackground } = useAppState()
    const { form, submit } = useForm(useForgotPasswordForm(), {
        onSuccess: ({ email }) => sendResetPassword(
            {
                email,
                userType: route.params.userType
            },
            {
                onSuccess: (response, request) => {
                    startTimer()
                    setEmail(request.email)
                },
                onError: () => Alert.alert(T.common.error, T.common.errorMessage)
            }
        )
    })

    useBackHandler(() => isLoading)

    useEffect(() => {
        if (isInBackground) {
            navigation.goBack()
        }
    }, [isInBackground])

    return (
        <BlurModal
            isVisible
            onClose={navigation.goBack}
            enableKeyboardAvoiding={!isAndroid}
        >
            <Typography.Heading>
                {T.screens.forgotPassword.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={1}/>
            {isTimerInProgress ? (
                <Typography.Subheading style={styles.text}>
                    {`${T.screens.forgotPassword.emailMessage.prefix}\n`}
                    <Typography.Subheading forceColor={theme.ui.primary}>
                        {email}
                    </Typography.Subheading>
                    {`. ${T.screens.forgotPassword.emailMessage.suffix}`}
                </Typography.Subheading>
            ) : (
                <Typography.Subheading style={styles.text}>
                    {T.screens.forgotPassword.message}
                </Typography.Subheading>
            )}
            <Grid.Gap gapBottom={5}/>
            {!isTimerInProgress && (
                <Adapter.TextInput
                    {...form.email}
                    inputProps={inputProps.email}
                    leftIcon={(
                        <Icons.Envelope size={22}/>
                    )}
                />
            )}
            <Grid.Gap gapBottom={3}/>
            {isTimerInProgress ? (
                <Grid.Gap gapBottom={3}>
                    <Typography.Subheading style={styles.text}>
                        {`${T.screens.forgotPassword.timerMessage.prefix} ${timer} ${T.screens.forgotPassword.timerMessage.suffix}`}
                    </Typography.Subheading>
                </Grid.Gap>
            ) : (
                <Button
                    disabled={isTimerInProgress}
                    isLoading={isLoading}
                    text={T.screens.forgotPassword.button}
                    onPress={submit}
                />
            )}
        </BlurModal>
    )
}

const stylesheet = createStyles(() => ({
    text: {
        textAlign: 'center'
    }
}))
