import React from 'react'
import { BlurModal, Button, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'

type SignUpConfirmScreenProps = {
    navigation: StackProps<ScreenNames>
}

export const SignUpConfirmScreen: React.FunctionComponent<SignUpConfirmScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <BlurModal
            isVisible
            onClose={navigation.goBack}
            style={styles.container}
        >
            <Typography.Regular>
                {T.screens.signUp.onSuccess.title}
            </Typography.Regular>
            <Typography.Subheading>
                {T.screens.signUp.onSuccess.message}
            </Typography.Subheading>
            <Button
                text={T.common.ok}
                onPress={() => navigation.pop(2)}
            />
        </BlurModal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap * 3
    }
}))
