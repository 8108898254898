export enum Breakpoint {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export const breakpoints = {
    [Breakpoint.XS]: 0,
    [Breakpoint.SM]: 990,
    [Breakpoint.MD]: 991,
    [Breakpoint.LG]: 992,
    [Breakpoint.XL]: 1200
}
