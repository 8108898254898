import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const City: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 12.5 55.5 V 38.5355 C 12.5 38.2345 12.71 38 12.9795 38 h 9.541 C 22.79 38 23 38.2345 23 38.5355 v 7.133 M 23 41.5 V 14.151 C 23 13.787 23.273 13.5 23.6195 13.5 h 16.261 c 0.35 0 0.6195 0.287 0.6195 0.651 V 55.5 M 40.5 27.5 h 13.3525 c 0.364 0 0.6475 0.2695 0.6475 0.616 V 55.5 M 54.5 41.5 h 16.6845 c 0.448 0 0.8155 0.14 0.8155 0.308 V 55.5 M 5.5 66 h 70"
                stroke={color}
                strokeWidth={6}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
