import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigation } from '@react-navigation/native'
import { useFetcher } from 'lib/api'
import { useAuthProviderAtom, useIsAuthorizedAtom, useSearchFilters, useWelcomeAnimation } from 'lib/atoms'
import { useCleanApp } from 'lib/hooks'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { AuthProvider } from 'lib/models'
import {
    ForgotPasswordRequest,
    AppleLoginRequest,
    GoogleLoginRequest,
    LoginRequest,
    LoginResponse,
    MicrosoftLoginRequest,
    RegisterRequest,
    RegisterResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
    VerifyEmailResponse,
    TokenRequest
} from './types'
import { saveAccessToken, saveRefreshToken } from './utils'

export const useRegister = () => {
    const fetcher = useFetcher<RegisterResponse>(HttpMethod.POST, '/auth/register')

    return useMutation<Response<RegisterResponse>, ErrorResponse, RegisterRequest>(userData => fetcher(userData))
}

export const useSignInWithEmail = (onSuccess?: (email: string, password: string) => void) => {
    const fetcher = useFetcher<LoginResponse>(HttpMethod.POST, '/auth/login')
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setAuthProvider] = useAuthProviderAtom()

    return useMutation<Response<LoginResponse>, ErrorResponse, LoginRequest>(userData => fetcher(userData), {
        onSuccess: ({ data, headers }, variables) => {
            const { refreshToken, accessToken } = data

            setAuthProvider(AuthProvider.Email)
            saveAccessToken(accessToken)
            saveRefreshToken(refreshToken)
            setAuthorized(true)

            if (onSuccess) {
                onSuccess(variables.email, variables.password)
            }
        }
    })
}

export const useSignInWithApple = () => {
    const fetcher = useFetcher<LoginResponse>(HttpMethod.POST, '/auth/apple-login')
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setAuthProvider] = useAuthProviderAtom()

    return useMutation<Response<LoginResponse>, ErrorResponse, AppleLoginRequest>(userData => fetcher(userData), {
        onSuccess: ({ data }) => {
            const { refreshToken, accessToken } = data

            setAuthProvider(AuthProvider.Apple)
            saveAccessToken(accessToken)
            saveRefreshToken(refreshToken)
            setAuthorized(true)
        }
    })
}

export const useSignInWithAppleWeb = () => {
    const fetcher = useFetcher<LoginResponse>(HttpMethod.POST, '/auth/apple-login-web')
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setAuthProvider] = useAuthProviderAtom()

    return useMutation<Response<LoginResponse>, ErrorResponse, AppleLoginRequest>(userData => fetcher(userData), {
        onSuccess: ({ data }) => {
            const { refreshToken, accessToken } = data

            setAuthProvider(AuthProvider.Apple)
            saveAccessToken(accessToken)
            saveRefreshToken(refreshToken)
            setAuthorized(true)
        }
    })
}

export const useSignInWithGoogle = () => {
    const fetcher = useFetcher<LoginResponse>(HttpMethod.POST, '/auth/google-login')
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setAuthProvider] = useAuthProviderAtom()

    return useMutation<Response<LoginResponse>, ErrorResponse, GoogleLoginRequest>(userData => fetcher(userData), {
        onSuccess: ({ data }) => {
            const { refreshToken, accessToken } = data

            setAuthProvider(AuthProvider.Google)
            saveAccessToken(accessToken)
            saveRefreshToken(refreshToken)
            setAuthorized(true)
        }
    })
}

export const useSignInWithMicrosoft = () => {
    const fetcher = useFetcher<LoginResponse>(HttpMethod.POST, '/auth/microsoft-login')
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setAuthProvider] = useAuthProviderAtom()

    return useMutation<Response<LoginResponse>, ErrorResponse, MicrosoftLoginRequest>(userData => fetcher(userData), {
        onSuccess: ({ data }) => {
            const { refreshToken, accessToken } = data

            setAuthProvider(AuthProvider.Microsoft)
            saveAccessToken(accessToken)
            saveRefreshToken(refreshToken)
            setAuthorized(true)
        }
    })
}

export const useLogout = () => {
    const queryClient = useQueryClient()
    const fetcher = useFetcher<void>(HttpMethod.POST, '/auth/logout')
    const { cleanPersonalData } = useCleanApp()
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setWelcomeAnimation] = useWelcomeAnimation()
    const [, setFilters] = useSearchFilters()
    const navigation = useNavigation()

    const cleanAuth = () => {
        setAuthorized(false)
        cleanPersonalData()
        setWelcomeAnimation(false)
        setFilters([])
        queryClient.clear()
        navigation.navigate(ScreenNames.Welcome)
    }

    return useMutation<Response<void>, ErrorResponse, void>(() => fetcher(), {
        onSuccess: cleanAuth,
        onError: cleanAuth
    })
}

export const useLogoutWithoutRedirection = () => {
    const queryClient = useQueryClient()
    const fetcher = useFetcher<void>(HttpMethod.POST, '/auth/logout')
    const { cleanPersonalData } = useCleanApp()
    const [, setAuthorized] = useIsAuthorizedAtom()
    const [, setWelcomeAnimation] = useWelcomeAnimation()
    const [, setFilters] = useSearchFilters()

    const cleanAuth = () => {
        setAuthorized(false)
        cleanPersonalData()
        setWelcomeAnimation(false)
        setFilters([])
        queryClient.clear()
    }

    return useMutation<Response<void>, ErrorResponse, void>(() => fetcher(), {
        onSuccess: cleanAuth,
        onError: cleanAuth
    })
}

export const useForgotPassword = () => {
    const fetcher = useFetcher<void>(HttpMethod.POST, '/auth/forgot-password')

    return useMutation<Response<void>, ErrorResponse, ForgotPasswordRequest>(userData => fetcher(userData))
}

export const useResetPassword = () => {
    const fetcher = useFetcher<ResetPasswordResponse>(HttpMethod.POST, '/auth/reset-password')

    return useMutation<Response<ResetPasswordResponse>, ErrorResponse, ResetPasswordRequest>(userData => fetcher(userData))
}

export const useVerifyEmail = () => {
    const fetcher = useFetcher<VerifyEmailResponse>(HttpMethod.POST, '/auth/confirm-register')

    return useMutation<Response<VerifyEmailResponse>, ErrorResponse, TokenRequest>(userData => fetcher(userData))
}

export const useConfirmEmail = () => {
    const fetcher = useFetcher<VerifyEmailResponse>(HttpMethod.POST, '/user/confirm-email')

    return useMutation<Response<VerifyEmailResponse>, ErrorResponse, TokenRequest>(userData => fetcher(userData))
}
