import { ImageStyle, TextStyle, TransformsStyle, ViewStyle } from 'react-native'
import { CSSProperties } from 'react'
import { Breakpoint } from './breakpoints'

export type ScreenSize = {
    width: number,
    height: number
}

export enum ImageResizeMode {
    Cover = 'cover'
}

export type CreateStylesFactory<T, Theme> = (theme: Theme) => T

type StyleProperty<T, B extends string> = {
    [key in keyof T]?: {
        [innerKey in B]?: T[key]
    } | {
        [innerKey in `:w${string}` | `:h${string}`]?: T[key]
    } | T[key]
}

export type CustomNamedStyles<T, B extends string> = {
    [P in keyof T]:
    | ViewStyle
    | TextStyle
    | ImageStyle
    | TransformsStyle
    | CSSProperties
    | StyleProperty<ViewStyle, B>
    | StyleProperty<ImageStyle, B>
    | StyleProperty<TextStyle, B>
    | (
        (...args: Array<never>) => ViewStyle | TextStyle | ImageStyle | TransformsStyle | CSSProperties | StyleProperty<ViewStyle, B> | StyleProperty<ImageStyle, B> | StyleProperty<TextStyle, B>
    )
}

export type ExtractBreakpoints<T> = T extends Partial<Record<Breakpoint, infer V>>
    ? V
    : T extends (...args: infer A) => infer R
        ? (...args: A) => ExtractBreakpoints<R>
        : {
            [K in keyof T]: T[K] extends (...args: infer A) => infer R
                ? (...args: A) => ExtractBreakpoints<R>
                : T[K] extends object
                    ? ExtractBreakpoints<T[K]>
                    : T[K]
        }

export type RemoveKeysWithPrefix<T> = T extends (...args: infer X) => infer R
    ? (...args: Parameters<T>) => RemoveKeysWithPrefix<R>
    : T extends object
        ? T extends Record<string, infer V>
            ? { [K in keyof T as K extends `:w${string}` | `:h${string}` ? Breakpoint : K]: RemoveKeysWithPrefix<T[K]> }
            : { [K in keyof T]: RemoveKeysWithPrefix<T[K]> }
        : T

