import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Padlock: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path d="M 23.94 28 h 36.12 C 65.548 28 70 32.672 70 38.44 v 17.92 C 70 67.2 61.616 76 51.296 76 H 32.704 C 22.384 76 14 67.2 14 56.36 V 38.44 C 14 32.668 18.452 28 23.94 28 v 0 Z" />
                <Path d="M 50.224 45.108 c 0 -4.028 -3.196 -7.316 -7.176 -7.316 c -3.984 0 -7.18 3.26 -7.18 7.32 c 0 2.84 1.6 5.268 3.92 6.48 v 9.068 c 0 1.836 1.456 3.32 3.26 3.32 a 3.28 3.28 90 0 0 3.252 -3.32 v -9.064 c 2.328 -1.216 3.924 -3.64 3.924 -6.488 v 0 Z M 62 16 c 2.476 3.088 4 7.08 4 12 M 18.012 28 C 17.384 6.26 39.892 -0.88 54 7.272" />
            </G>
        </Icon>
    )
}
