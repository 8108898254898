export enum ApperanceTheme {
    Dark = 'dark',
    Light = 'light'
}

export enum AppStateStatus {
    Unknown = 'unknown',
    Inactive = 'inactive',
    Background = 'background',
    Active = 'active'
}
