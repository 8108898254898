import React, { useState } from 'react'
import { View } from 'react-native'
import { useIsWithinBreakpoints } from 'lib/hooks'
import { Breakpoint, createStyles, useStyles } from 'lib/styles'
import { isWeb } from 'lib/common'
import { Touchable } from 'lib/components'
import { LeftMenu } from './LeftMenu'
import { TopBar } from './TopBar'
import { Title } from './Title'
import { WelcomeAnimation } from './WelcomeAnimation'
import { IsFetching } from './IsFetching'

type WebLayoutProps = {
    children: React.ReactNode
}

export const AppLayout: React.FunctionComponent<WebLayoutProps> = ({ children }) => {
    const isHigherScreenWidth = useIsWithinBreakpoints(Breakpoint.SM, Breakpoint.XL)
    const [isOpen, setIsOpen] = useState(false)
    const { styles } = useStyles(stylesheet)

    const toggleMenu = () => setIsOpen(prevValue => !prevValue)

    return (
        <View style={styles.container}>
            {isWeb && (
                <WelcomeAnimation/>
            )}
            {isOpen && !isHigherScreenWidth && (
                <Touchable
                    onPress={toggleMenu}
                    style={styles.closeLeftMenu}
                />
            )}
            {isWeb && isOpen && (
                <LeftMenu onPress={toggleMenu} />
            )}
            {isWeb && !isOpen && isHigherScreenWidth && (
                <LeftMenu onPress={toggleMenu} />
            )}
            <View style={styles.content}>
                <TopBar/>
                {isWeb && (
                    <Title onBurgerMenuPress={toggleMenu} />
                )}
                <IsFetching />
                <React.Fragment>
                    {children}
                </React.Fragment>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
    },
    closeLeftMenu: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        cursor: 'default'
    },
    leftMenuCollapsed: {
        padding: 0
    },
    content: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    screens: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap
    }
}))
