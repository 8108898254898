import React from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { useScannedItemsAtom } from 'lib/atoms'
import { masks, Measurements } from 'lib/common'
import { Adapter, Button, GoBack, Grid, ReKeyboardAccessory, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { inputProps } from 'lib/utils'
import { useNewItemForm } from '../forms'

type NewItemScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.NewItem>
}

export const NewItemScreen: React.FunctionComponent<NewItemScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [, setScannedItems] = useScannedItemsAtom()
    const { form, submit } = useForm(useNewItemForm(route.params.bookCode), {
        onSuccess: formData => {
            setScannedItems(prevState => {
                const otherItems = prevState.filter(item => item.bookCode !== formData.bookCode)

                return otherItems.concat({
                    ...formData,
                    quantity: Number(formData.quantity)
                })
            })

            navigation.goBack()
        }
    })

    return (
        <Grid.Background style={styles.background}>
            <ReKeyboardAccessory
                numberOfInputs={5}
                contentContainerStyle={styles.form}
            >
                {formProps => {
                    const [
                        titleProps,
                        authorProps,
                        categoryProps,
                        skuProps,
                        quantityProps
                    ] = formProps

                    return (
                        <React.Fragment>
                            <GoBack/>
                            <Typography.Heading>
                                {T.screens.newItem.title}
                            </Typography.Heading>
                            <Grid.Gap gapBottom={3}/>
                            <Adapter.TextInput
                                {...form.bookCode}
                                disabled
                                leftIcon={(
                                    <Icons.Sku
                                        size={24}
                                        forceColor={theme.components.typography.primary.color}
                                    />
                                )}
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...titleProps}
                                {...form.title}
                                leftIcon={(
                                    <Icons.Title size={24}/>
                                )}
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...authorProps}
                                {...form.author}
                                inputProps={inputProps.author}
                                leftIcon={(
                                    <Icons.Author size={24}/>
                                )}
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput // todo optional: select category from list
                                {...categoryProps}
                                {...form.category}
                                leftIcon={(
                                    <Icons.Category size={24}/>
                                )}
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...skuProps}
                                {...form.sku}
                                mask={masks.getNumericMask()}
                                inputProps={{
                                    keyboardType: 'numeric'
                                }}
                                leftIcon={(
                                    <Icons.Sku size={24}/>
                                )}
                            />
                            <View style={styles.spacer}/>
                            <Grid.Gap
                                gapTop={2}
                                gapBottom={5}
                            >
                                <Adapter.QuantityPicker
                                    {...form.quantity}
                                    {...quantityProps}
                                />
                            </Grid.Gap>
                            <Button
                                text={T.common.save}
                                onPress={submit}
                            />
                            <Grid.Gap gapBottom={3}/>
                            <Button
                                noBackground
                                text={T.common.cancel}
                                onPress={navigation.goBack}
                            />
                        </React.Fragment>
                    )
                }}
            </ReKeyboardAccessory>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    background: {
        paddingTop: 0
    },
    form: {
        width: Measurements.WindowWidth,
        paddingHorizontal: theme.gap * 3,
        paddingBottom: theme.gap * 4,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Number(Measurements.StatusBarHeight) + theme.gap * 3
    },
    spacer: {
        flex: 1
    },
    bottomTiles: {
        width: '100%',
        justifyContent: 'flex-end',
        paddingBottom: theme.utils.gap(8)
    }
}))
