import { useEffect, useRef, useState } from 'react'

export const useTimer = (limitInSeconds: number = 59) => {
    const intervalId = useRef<NodeJS.Timeout>()
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        if (intervalId.current && timer === -1) {
            clearInterval(intervalId.current)
            intervalId.current = undefined
        }

        if (!intervalId.current && timer === limitInSeconds) {
            intervalId.current = setInterval(() => {
                if (timer > -1) {
                    setTimer(prevState => prevState - 1)
                }
            }, 1000)
        }
    }, [timer, limitInSeconds])

    useEffect(() => () => {
        if (intervalId.current) {
            clearInterval(intervalId.current)
        }
    }, [intervalId])

    return {
        timer,
        isTimerInProgress: timer > 0,
        startTimer: () => setTimer(limitInSeconds),
        resetTimer: () => setTimer(-1)
    }
}
