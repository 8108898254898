import React, { useEffect, useState } from 'react'
import { View, useWindowDimensions } from 'react-native'
import { Icons } from 'assets'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { SearchInput, Button, Table, ConfirmModal, TableNative, Typography, Grid } from 'lib/components'
import { useToast, useTranslations, usePagination, useIsWithinBreakpoints, useFocusRefetch, useGoBack } from 'lib/hooks'
import { Breakpoint, createStyles, useStyles } from 'lib/styles'
import { ScreenNames, StoreFilesParams } from 'lib/routing'
import { Action, TableAction, TableItemKey, TooltipPlacement } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { useCurrentStoreAtom } from 'lib/atoms'
import { useGetStoreItems, useDeleteStoreItem, useGetSingleStore, useGetStoreItemsInfinity } from '../actions'
import { storeFilesTableHeaders } from '../mocks'
import { getStoreItems } from '../utils'
import { StoreItemsTableItem } from '../types'

export const StoreFiles: React.FunctionComponent = () => {
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const isSmallerView = useWindowDimensions().width < 1100
    const T = useTranslations()
    const { actions: { showSuccessToast } } = useToast()
    const [query, setQuery] = useState('')
    const [deleteUUID, setDeleteUUID] = useState('')
    const { actions: { showErrorToast } } = useToast()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { goBack } = useGoBack(ScreenNames.ManageStores)
    const { mutate: getStore, data: currentStore } = useGetSingleStore()
    const { params } = useRoute<RouteProp<Record<string, StoreFilesParams>>>()
    const [,setCurrenStore] = useCurrentStoreAtom()
    const isInfinityScrollFetchingDisabled = !isMobileView
    const {
        data: infinityScrollData,
        fetchNextPage,
        hasNextPage,
        isFetching: isInfinityScrollFetching,
        refetch: refetchInfinityScroll
    } = useGetStoreItemsInfinity(params.storeUUID, undefined, query, isInfinityScrollFetchingDisabled)
    const {
        mutate: getItems,
        data: items,
        isLoading,
        isError
    } = useGetStoreItems()
    const {
        mutate: deleteItem,
        isLoading: isDeleting
    } = useDeleteStoreItem({
        onSuccess: () => {
            showSuccessToast(T.screens.storeItems.itemDeleted)
            setDeleteUUID('')
            fetchItems()
        }
    })
    const pagination = usePagination(items?.data.totalCount || 0)
    const storeItems = getStoreItems(items?.data.storeItems || []).map(item => ({
        ...item,
        itemUUID: undefined
    }))
    const infinityScrollStoreItems = infinityScrollData?.pages.flatMap(page => page.data.storeItems) || []

    const fetchItems = () => getItems({
        ...{
            storeUUID: params.storeUUID,
            limit: pagination.state.limit,
            offset: pagination.state.offset
        },
        ...(query && { query })
    })

    useEffect(() => {
        getStore(params.storeUUID)
    }, [params.storeUUID])

    useEffect(() => {
        const [store] = currentStore?.data.stores || []

        setCurrenStore(store)
    }, [currentStore])

    useEffect(() => {
        if (!params?.storeUUID) {
            showErrorToast(T.alerts.oops)

            return goBack()
        }

        if (!isLoading) {
            fetchItems()
        }
    }, [query, params, pagination.state.offset, pagination.state.limit])

    const tableActions = [
        {
            key: TableAction.Edit,
            label: T.components.table.actions.edit,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Pencil
                    size={24}
                    forceColor={theme.colors.white}
                />
            ),
            action: row => navigation.navigate(ScreenNames.ItemEdit, {
                storeUUID: params.storeUUID,
                itemUUID: row.storeItemUUID || row.itemUUID || ''
            })
        },
        {
            key: TableAction.Remove,
            label: T.components.table.actions.remove,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Bin
                    size={24}
                    forceColor={theme.colors.white}
                />
            ),
            action: row => setDeleteUUID(row.storeItemUUID || row.itemUUID || '')
        }
    ] satisfies Array<Action<StoreItemsTableItem>>

    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            pagination.actions.clearPaginationState()
        })

        return unsubscribe
    }, [navigation])

    useFocusRefetch(fetchItems)

    return (
        <Grid.Background
            hasPaddingHorizontal
            hasPaddingVertical
        >
            <View style={styles.actions}>
                <View style={styles.searchInputWrapper}>
                    <SearchInput
                        placeholder={T.screens.storeItems.searchPlaceholder}
                        onDebouncedQuery={setQuery}
                        rightIcon={(
                            <Icons.Search size={22}/>
                        )}
                    />
                </View>
                {!isMobileView && (
                    <Button
                        customStyle={styles.addItemButton}
                        disabled={isLoading}
                        text={T.screens.storeItems.addItem}
                        onPress={() => navigation.navigate(ScreenNames.ItemSetup, {
                            storeUUID: params.storeUUID
                        })}
                    />
                )}
            </View>
            {isMobileView ? (
                <TableNative
                    data={infinityScrollStoreItems.map(item => ({
                        itemUUID: item.storeItemUUID || '',
                        bookCode: (
                            <View style={styles.bookCode}>
                                <Typography.Regular numberOfLines={1}>
                                    {item.bookCode}
                                </Typography.Regular>
                                <Typography.Regular numberOfLines={1}>
                                    {T.components.table.headers.sku}: {item.sku ?? ''}
                                </Typography.Regular>
                            </View>
                        ),
                        quantity: item.quantity
                    }))}
                    columns={[
                        {
                            key: TableItemKey.BookCode,
                            title: `${T.components.table.headers.bookCode} & ${T.components.table.headers.sku}`,
                            size: 180
                        },
                        {
                            key: TableItemKey.Quantity,
                            title: T.components.table.headers.quantity
                        }
                    ]}
                    style={styles.tableNative}
                    actions={tableActions}
                    onEndReached={() => {
                        if (hasNextPage && !isInfinityScrollFetching) {
                            fetchNextPage()
                        }
                    }}
                    onRefetch={refetchInfinityScroll}
                    isError={isError}
                    isLoading={isInfinityScrollFetching}
                />
            ) : (
                <Table
                    columns={storeFilesTableHeaders(isSmallerView)}
                    data={storeItems}
                    actions={tableActions}
                    style={styles.table}
                    isLoading={isLoading}
                    pagination={pagination}
                    cellStyle={styles.tableCell}
                />
            )}
            {isMobileView && (
                <Button
                    disabled={isLoading}
                    text={T.screens.storeItems.addItem}
                    onPress={() => navigation.navigate(ScreenNames.ItemSetup, {
                        storeUUID: params.storeUUID
                    })}
                />
            )}
            <ConfirmModal
                isVisible={Boolean(deleteUUID)}
                message={T.screens.storeItems.itemDeleteLabel}
                onClose={() => setDeleteUUID('')}
                onConfirm={() => deleteItem({
                    itemUUIDs: [deleteUUID],
                    storeUUID: params.storeUUID,
                    scanDate: dateHelpers.nowUnix()
                })}
                isLoading={isDeleting}
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    actions: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.gap * 2
    },
    searchInputWrapper: {
        width: {
            xs: '100%',
            sm: 328
        }
    },
    addItemButton: {
        width: 220
    },
    table: {
        marginTop: theme.gap,
        minWidth: 562
    },
    tableCell: {
        lineHeight: 24
    },
    tableNative: {
        paddingBottom: theme.gap * 2
    },
    bookCode: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap / 2
    }
}))
