import { UserType } from 'lib/models'
import { ScreenNames } from './screens'

export const getInitialRoute = (userType: UserType) => {
    if (userType === UserType.Admin) {
        return ScreenNames.ManageUsers
    }

    return ScreenNames.ManageStores
}
