import { breakpoints } from './breakpoints'
import { colors, hexToRGBA } from './colors'
import { isSmallPhone } from '../common'

const GAP = isSmallPhone ? 6 : 8
const LEFT_MENU_WIDTH = 318
const LEFT_MENU_PADDING = 24
const TOP_BAR_HEIGHT = 128
const LEFT_MENU_ITEM_HEIGHT = 56
const CONTENT_PADDING_WEB = 56
const CONTENT_PADDING_MOBILE = 24

const commonStyles = {
    colors,
    gap: GAP,
    leftMenuWidth: LEFT_MENU_WIDTH,
    leftMenuPadding: LEFT_MENU_PADDING,
    topBarHeight: TOP_BAR_HEIGHT,
    leftMenuItemHeight: LEFT_MENU_ITEM_HEIGHT,
    contentPaddingWeb: CONTENT_PADDING_WEB,
    contentPaddingMobile: CONTENT_PADDING_MOBILE,
    breakpoints,
    utils: {
        createShadow: (
            offsetY = 0,
            opacity = 0.25,
            radius = 18,
            elevation = 18,
            color = colors.black
        ) => ({
            shadowOffset: {
                width: 0,
                height: offsetY
            },
            shadowOpacity: opacity,
            shadowRadius: radius,
            elevation,
            shadowColor: color
        }),
        gap: (factor: number) => factor * GAP
    },
    icon: {
        success: colors.green,
        selected: colors.lightGray,
        unselected: colors.lighterGray,
        error: colors.red
    },
    typography: {
        heading: {
            fontWeight: '300'
        },
        subheading: {
            fontWeight: '300'
        },
        title: {
            fontWeight: '400'
        },
        regular: {
            fontWeight: '400'
        },
        label: {
            fontWeight: '400'
        },
        logo: {
            fontWeight: '700'
        },
        error: {
            fontWeight: '400'
        },
        subText: {
            fontWeight: '300'
        }
    } as const
}

export const darkTheme = {
    ...commonStyles,
    ui: {
        primary: colors.pink,
        accent: colors.lighterGray,
        background: colors.almostBlack,
        foreground: colors.darkerGray,
        transparent: colors.transparent
    },
    components: {
        indicator: {
            color: colors.pink
        },
        icon: {
            primary: colors.white,
            color: colors.pink
        },
        input: {
            height: GAP * 7,
            borderRadius: GAP * 1.5,
            borderWidth: 0,
            paddingVertical: GAP * 2,
            paddingHorizontal: GAP * 3,
            backgroundColor: colors.darkerGray,
            borderColor: colors.lighterGray,
            error: {
                borderColor: colors.red
            },
            disabled: {
                backgroundColor: colors.darkerGray
            },
            typography: {
                placeholder: colors.darkGray,
                text: colors.white,
                error: colors.red
            },
            icon: {
                regular: colors.pink,
                error: colors.red
            }
        },
        button: {
            filled: {
                backgroundColor: colors.pink,
                borderWidth: 0,
                typography: {
                    color: colors.almostWhite
                }
            },
            outline: {
                backgroundColor: colors.transparent,
                borderWidth: 1,
                borderColor: hexToRGBA(colors.lighterGray, 0.5),
                typography: {
                    color: colors.white
                }
            },
            plain: {
                backgroundColor: colors.darkGray,
                borderWith: 0,
                typography: {
                    color: colors.white
                }
            },
            padding: GAP,
            height: GAP * 7,
            borderRadius: GAP * 1.5,
            disabled: {
                backgroundColor: colors.lightGray,
                text: colors.almostWhite
            }
        },
        modal: {
            backdrop: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            whiteBackdrop: {
                backgroundColor: 'rgba(255, 255, 255, 0.6)'
            },
            content: {
                backgroundColor: colors.white,
                borderRadius: GAP * 1.5
            }
        },
        typography: {
            primary: {
                color: colors.white
            },
            secondary: {
                color: colors.lighterGray
            }
        }
    }
}

export const lightTheme = {
    ...commonStyles,
    ui: {
        primary: colors.pink,
        accent: colors.lighterGray,
        background: colors.almostWhite,
        foreground: colors.white,
        transparent: colors.transparent
    },
    components: {
        indicator: {
            color: colors.black
        },
        icon: {
            primary: colors.almostBlack,
            color: colors.darkGray
        },
        input: {
            height: GAP * 7,
            borderRadius: GAP * 1.5,
            borderWidth: 0,
            paddingVertical: GAP * 2,
            paddingHorizontal: GAP * 3,
            backgroundColor: colors.white,
            borderColor: colors.darkerGray,
            error: {
                borderColor: colors.red
            },
            disabled: {
                backgroundColor: colors.lighterGray
            },
            typography: {
                placeholder: colors.lighterGray,
                text: colors.almostBlack,
                error: colors.red
            },
            icon: {
                regular: colors.almostBlack,
                error: colors.red
            }
        },
        button: {
            filled: {
                backgroundColor: colors.pink,
                borderWidth: 0,
                typography: {
                    color: colors.almostWhite
                }
            },
            outline: {
                backgroundColor: colors.transparent,
                borderWidth: 1,
                borderColor: colors.lighterGray,
                typography: {
                    color: colors.almostBlack
                }
            },
            plain: {
                backgroundColor: colors.white,
                borderWith: 0,
                typography: {
                    color: colors.lighterGray
                }
            },
            padding: GAP,
            height: GAP * 7,
            borderRadius: GAP * 1.5,
            disabled: {
                backgroundColor: colors.lightGray,
                text: colors.darkerGray
            }
        },
        modal: {
            backdrop: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            whiteBackdrop: {
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
            },
            content: {
                backgroundColor: colors.white,
                borderRadius: GAP * 1.5
            }
        },
        typography: {
            primary: {
                color: colors.almostBlack
            },
            secondary: {
                color: colors.lighterGray
            }
        }
    }
}
