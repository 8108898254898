import { KeyValuePair } from './common'

export enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete'
}

export enum ErrorCodes {
    Unauthorized = 401,
    BadRequest = 400,
    Forbidden = 403,
    NotFound = 404,
    TooManyRequests = 429
}

export type Response<T> = {
    data: T,
    status: number,
    statusText: string,
    headers: KeyValuePair,
    config: KeyValuePair,
    response?: Response<T>
}

export type ErrorResponse = { // typescript, es5 Error
    name: string;
    message: string;
    stack?: string;
    data?: Data
}

type Data = {
    message: string,
    code: number
}

export enum Limit {
    Default = 20,
    Extended = 50,
    Maximum = 100
}

export type InfiniteQueryParams = {
    query?: string,
    limit?: Limit
}
