import React, { useState } from 'react'
import { ItemDetailTile } from 'features/item'
import { useScannedItemsAtom } from 'lib/atoms'
import { BlurModal, Typography, Grid, FormComponents, Button } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { R } from 'lib/utils'

type QuantityModalScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.QuantityModal>
}

export const QuantityModalScreen: React.FunctionComponent<QuantityModalScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const storeItem = route.params.storeItem
    const [, setScannedItems] = useScannedItemsAtom()
    const [quantity, setQuantity] = useState(R.isNil(storeItem?.quantity) ? '1' : storeItem.quantity?.toFixed())

    const onSubmit = () => {
        setScannedItems(prevState => {
            const otherItems = prevState.filter(item => item.bookCode !== storeItem.bookCode)

            return otherItems.concat({
                ...storeItem,
                quantity: Number(quantity)
            })
        })

        navigation.goBack()
    }

    return (
        <BlurModal
            isVisible
            onClose={navigation.goBack}
        >
            <Typography.Heading>
                {T.screens.quantityModal.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={4}/>
            <ItemDetailTile
                style={styles.bookCode}
                label={T.screens.itemDetails.fields.bookCode}
                value={storeItem.bookCode}
            />
            <Grid.Gap gapBottom={4}/>
            <FormComponents.QuantityPicker
                value={quantity}
                onChangeValue={setQuantity}
            />
            <Grid.Gap gapBottom={4}/>
            <Button
                text={T.common.confirm}
                onPress={onSubmit}
            />
        </BlurModal>
    )
}

const stylesheet = createStyles(() => ({
    bookCode: {
        borderBottomWidth: 0 // override
    }
}))
