import React, { useState } from 'react'
import { AppLayout, NavigationHeader, Grid } from 'lib/components'
import { ManageStores } from '../components'

export const ManageStoresScreen: React.FunctionComponent = () => {
    const [query, setQuery] = useState('')

    return (
        <AppLayout>
            <Grid.Background
                hasPaddingHorizontal
                hasPaddingVertical
            >
                <NavigationHeader/>
                <ManageStores
                    query={query}
                    setQuery={setQuery}
                />
            </Grid.Background>
        </AppLayout>
    )
}
