import { Store, StoreItem } from 'lib/models'
import { StoreColumn } from './components'

export const getManageStoresTableData = (stores: Array<Store>) => stores.map(store => ({
    store: (
        <StoreColumn
            name={store.storeName}
            state={store.state}
            city={store.city}
            street={store.streetAddress}
        />
    ),
    storeName: store.storeName,
    country: store.country,
    city: store.city,
    streetAddress: store.streetAddress,
    zipCode: store.zipCode,
    storeUUID: store.storeUUID,
    state: store.state,
    imStoreId: store.imStoreId,
    totalUsersCount: store.totalUsersCount,
    totalStoreItemsCount: store.totalStoreItemsCount,
    scanDate: store.scanDate,
    syncDate: store.syncDate
}))

export const getStoreItems = (items: Array<StoreItem>) => items.map(item => ({
    storeItemUUID: item.storeItemUUID,
    sku: item.sku,
    quantity: item.quantity,
    bookCode: item.bookCode
}))
