import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Files: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 60.4 69.6836 C 60.4 73.724 57.1338 77 53.106 77 h -4.956 M 37.125 77 H 18.407 C 14.536 77 11.4 73.8318 11.4 69.9258 V 15.792 c 0 -3.906 3.1374 -7.0742 7.0056 -7.0742"
                />
                <Path
                    d="M 63.2056 68.4642 H 25.7584 c -3.8696 0 -7.0084 -3.1108 -7.0084 -6.9468 V 8.3482 C 18.75 4.5122 21.8888 1.4 25.7584 1.4 h 29.0164 s 0.119 0.0266 0.1722 0.0784 L 70.1468 18.368 s 0.0532 0.0924 0.0532 0.1456 v 43.0052 c 0 3.836 -3.1388 6.9468 -7.0084 6.9468 h 0.014 Z"
                />
                <Path
                    d="M 53.386 2.6838 c -0.126 -0.1358 -0.336 -0.0406 -0.336 0.1218 V 10.388 c 0 5.1324 4.3036 9.303 9.6306 9.303 h 6.097 c 0.168 0 0.266 -0.1904 0.14 -0.3122"
                />
            </G>
        </Icon>
    )
}
