import React from 'react'
import { Path, Circle } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Search: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Circle
                cx={30}
                cy={30}
                r={26}
                stroke={color}
                strokeWidth={3}
            />
            <Path
                d="m 54 54 l 18 18"
                stroke={color}
                strokeWidth={3}
                strokeLinecap="round"
            />
        </Icon>
    )
}
