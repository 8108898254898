import { useState } from 'react'
import { isWeb } from 'lib/common'

const INITIAL_MOUSE_POSITION = { x: 0, y: 0 }

export const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState(INITIAL_MOUSE_POSITION)

    const getMousePosition = event => {
        if (isWeb) {
            const nativeEvent = event.nativeEvent as MouseEvent
            const { x, y } = nativeEvent

            setMousePosition({ x, y })
        }
    }

    const resetMousePosition = () => {
        setMousePosition(INITIAL_MOUSE_POSITION)
    }

    return {
        actions: {
            getMousePosition,
            resetMousePosition
        },
        state: {
            mousePosition
        }
    }
}
