import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Breakpoint, conditionalStyle, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { Typography, Touchable, Adapter, Button, FormComponents } from 'lib/components'
import { useSearchFilters, useSearchStores } from 'lib/atoms'
import { SearchFilter } from 'lib/types'
import { R } from 'lib/utils'
import { Store } from 'lib/models'
import { searchFilters } from '../mocks'
import { Input } from '../types'

type FiltersListWebProps = {
    stores: Array<Store>,
    onClose: VoidFunction,
    onSave(filters: Array<SearchFilter>, stores: Array<Store>): void
}

export const FiltersListWeb: React.FunctionComponent<FiltersListWebProps> = ({
    onClose,
    onSave,
    stores
}) => {
    const T = useTranslations()
    const [containerHeight, setContainerHeight] = useState(0)
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const [selectedFilters] = useSearchFilters()
    const [searchStores] = useSearchStores()
    const [temporaryFilters, setTemporaryFilters] = useState<Array<SearchFilter>>(selectedFilters)
    const { styles, theme } = useStyles(stylesheet)
    const selectedFiltersKeys = temporaryFilters.map(filter => filter.id)
    const [inputs, setInputs] = useState<Array<Input>>(selectedFilters.map(filter => ({
        filter: filter.id,
        value: filter.value || ''
    })))
    const dropdownStores = stores.map(store => ({
        key: store.storeUUID,
        label: store.storeName,
        value: store
    }))
    const [temporaryStores, setTemporaryStores] = useState(searchStores.map(store => ({
        key: store.storeUUID,
        label: store.storeName,
        value: store
    })))

    const clearInputs = () => {
        const clearState = selectedFilters.map(filter => ({
            filter: filter.id,
            value: ''
        }))

        setInputs(clearState)
    }

    const updateTemporaryFilters = (filter: SearchFilter) => {
        const filterExist = temporaryFilters.find(temporaryFilter => temporaryFilter.id === filter.id)

        if (filterExist) {
            const newFilterValue = {
                ...filterExist,
                value: filter.value
            }
            const filtersWithoutFiltered = temporaryFilters.filter(temporaryFilter => temporaryFilter.id !== filter.id)

            return setTemporaryFilters([...filtersWithoutFiltered, newFilterValue])
        }

        setTemporaryFilters(prevValue => [...prevValue, filter])
    }

    const removeTemporaryFilter = (filterID: string) => {
        const filteredTemporaryFilters = temporaryFilters.filter(includedFilter => includedFilter.id !== filterID)

        setTemporaryFilters(filteredTemporaryFilters)
    }

    const onClear = () => {
        setTemporaryFilters([])
        clearInputs()
    }

    return (
        <View
            style={{
                ...styles.container,
                ...conditionalStyle(isMobileView, styles.mobileViewContainer)
            }}
            onLayout={event => setContainerHeight(event.nativeEvent.layout.height)}
        >
            <Touchable
                onPress={onClose}
                style={styles.closeBackground}
            />
            <View style={styles.header}>
                <Typography.Subheading style={styles.text}>
                    {T.screens.search.selectFilters}
                </Typography.Subheading>
                <Touchable onPress={onClear}>
                    <Typography.SubText
                        bold
                        forceColor={theme.ui.primary}
                    >
                        {T.screens.search.clearAll}
                    </Typography.SubText>
                </Touchable>
            </View>
            <View style={styles.labels}>
                <Typography.Subheading style={styles.text}>
                    {T.screens.search.selectStore}
                </Typography.Subheading>
                <FormComponents.DropdownMultiplePicker
                    inputStyle={styles.selectStoreInput}
                    maxDropdownHeight={containerHeight - 146}
                    currentText={temporaryStores.length > 0 ? temporaryStores.map(store => store.label).join(', ') : T.screens.search.selectStore}
                    items={dropdownStores}
                    value={temporaryStores}
                    placeholder={T.screens.search.selectStore}
                    emptyMessage={T.screens.search.noStoresSelected}
                    errorMessage={R.isEmpty(temporaryStores)
                        ? T.screens.search.noStoresSelected
                        : undefined
                    }
                    onChangeValue={setTemporaryStores}
                    renderLabel={item => (
                        <React.Fragment>
                            <Typography.Title>
                                {item.value.storeName}
                            </Typography.Title>
                            <Typography.Error>
                                {`${item.value.city}${item.value.country ? ', ' : ''}${item.value.country || ''}`}
                            </Typography.Error>
                            <Typography.Label>
                                {item.value.streetAddress}
                            </Typography.Label>
                        </React.Fragment>
                    )}
                />
                <Typography.Subheading style={styles.text}>
                    {T.screens.search.additionalFilters}
                </Typography.Subheading>
            </View>
            <View style={styles.filters}>
                {searchFilters.map((filter, index) => {
                    const isActive = selectedFiltersKeys.includes(filter.id)
                    const inputValue = inputs.find(input => input.filter === filter.id)?.value

                    const updateInputValue = (value: string) => {
                        const inputExist = inputs.find(input => input.filter === filter.id)

                        if (inputExist) {
                            const newInputValue = {
                                ...inputExist,
                                value
                            }
                            const inputsWithoutFiltered = inputs.filter(input => input.filter !== filter.id)

                            return setInputs([...inputsWithoutFiltered, newInputValue])
                        }

                        setInputs(prevValue => [...prevValue, { filter: filter.id, value }])
                    }

                    useEffect(() => {
                        if (!isActive || inputValue === '') {
                            updateInputValue('')

                            return removeTemporaryFilter(filter.id)
                        }

                        if (inputValue) {
                            return updateTemporaryFilters({
                                ...filter,
                                value: inputValue
                            })
                        }
                    }, [inputValue])

                    const toggle = () => {
                        if (isActive) {
                            removeTemporaryFilter(filter.id)

                            return updateInputValue('')
                        }

                        updateTemporaryFilters({
                            id: filter.id,
                            value: ''
                        })
                    }

                    return (
                        <View
                            key={filter.id}
                            style={{
                                ...styles.filter,
                                ...conditionalStyle((searchFilters.length - 1 === index), styles.removeBorderBottom)
                            }}
                        >
                            <Touchable
                                onPress={toggle}
                                style={{
                                    ...styles.checkBox,
                                    ...conditionalStyle(isActive, styles.removeCheckboxBorder),
                                    ...conditionalStyle(isActive, styles.activeFilter)
                                }}
                            >
                                {isActive && (
                                    <Icons.Checkmark
                                        size={22}
                                        forceColor={theme.colors.white}
                                    />
                                )}
                            </Touchable>
                            <View style={styles.label}>
                                {isActive ? (
                                    <Adapter.TextInput
                                        inputProps={{
                                            onChangeText: value => value === ''
                                                ? updateInputValue('')
                                                : updateInputValue(value),
                                            value: inputValue,
                                            placeholder: filter.title,
                                            style: { paddingLeft: 0, paddingRight: 80 }
                                        }}
                                        label={filter.title}
                                        style={styles.input}
                                        labelStyle={styles.inputLabel}
                                    />
                                ) : (
                                    <Typography.Subheading style={styles.text}>
                                        {filter.title}
                                    </Typography.Subheading>
                                )}
                            </View>
                        </View>
                    )
                })}
            </View>
            <Button
                text={T.common.save}
                onPress={() => {
                    const filters = temporaryFilters.filter(filter => filter.value !== '')
                    const stores = temporaryStores.map(store => store.value)

                    if (!R.isEmpty(stores)) {
                        onSave(filters, stores)
                    }
                }}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'absolute',
        borderRadius: 12,
        backgroundColor: theme.ui.foreground,
        padding: theme.gap * 3,
        top: theme.gap * 8,
        width: 328,
        ...theme.utils.createShadow()
    },
    mobileViewContainer: {
        right: 0
    },
    closeBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'default'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingVertical: theme.gap * 2
    },
    checkBox: {
        width: 32,
        height: 32,
        borderRadius: 4,
        display: 'flex',
        backgroundColor: theme.ui.foreground,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: theme.colors.lightGray
    },
    removeCheckboxBorder: {
        borderWidth: 0
    },
    activeFilter: {
        backgroundColor: theme.colors.pink
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.gap * 2,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.darkGray
    },
    label: {
        height: 56,
        display: 'flex',
        justifyContent: 'center'
    },
    removeBorderBottom: {
        borderBottomWidth: 0
    },
    text: {
        color: theme.components.input.typography.text,
        fontWeight: '400',
        zIndex: -1
    },
    input: {
        flexShrink: 1,
        paddingLeft: 0
    },
    inputLabel: {
        left: 0
    },
    labels: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap,
        paddingVertical: theme.gap,
        zIndex: 999
    },
    selectStoreInput: {
        backgroundColor: hexToRGBA(theme.ui.background, 0.5)
    }
}))
