import { MMKVKey, mmkvStorage } from 'lib/mmkv'
import { GoogleOneTapResponse } from 'lib/types'
import { getGoogleWebClientId } from 'lib/utils'

export const getAccessToken = () => mmkvStorage.getString(MMKVKey.AccessToken)
export const getRefreshToken = () => mmkvStorage.getString(MMKVKey.RefreshToken)
export const saveAccessToken = (accessToken: string) => mmkvStorage.setString(MMKVKey.AccessToken, accessToken)
export const saveRefreshToken = (refreshToken: string) => mmkvStorage.setString(MMKVKey.RefreshToken, refreshToken)
export const clearAuthTokens = () => mmkvStorage.delete(MMKVKey.AccessToken, MMKVKey.RefreshToken)

export const initGoogleSDK = (id: string, onCallback: (token: string) => void) => new Promise<void>((resolve, reject) => {
    const doesScriptExist = Boolean(document.getElementById(id))

    if (doesScriptExist) {
        return resolve()
    }

    const scriptTag = document.createElement('script')

    scriptTag.id = id
    scriptTag.src = `https://accounts.google.com/gsi/client`

    const body = document.querySelector('body')

    if (!body) {
        return reject()
    }

    body.append(scriptTag)

    scriptTag.onerror = () => reject()
    scriptTag.onload = () => {
        window.google.accounts.id.initialize({
            // eslint-disable-next-line camelcase
            client_id: getGoogleWebClientId(),
            callback: (response: GoogleOneTapResponse) => onCallback(response.credential)
        })

        resolve()
    }
})
