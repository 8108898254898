import { useState } from 'react'

const availableLimits = [10, 20, 30]

export const usePagination = (elements: number) => {
    const [limit, setLimit] = useState(availableLimits[availableLimits.length - 1])
    const [currentPage, setCurrentPage] = useState(1)
    const offset = Math.abs(currentPage - 1) * limit
    const totalPages = Math.ceil(elements / limit)

    const setPage = (page: number) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page)
        }
    }

    const updateLimit = (newLimit: number) => {
        setCurrentPage(1)
        setLimit(newLimit)
    }

    const clear = () => {
        setCurrentPage(1)
        setLimit(availableLimits[availableLimits.length - 1])
    }

    return {
        state: {
            availableLimits,
            currentPage,
            totalPages,
            limit,
            offset
        },
        actions: {
            setPage,
            setLimit: updateLimit,
            clearPaginationState: clear
        }
    }
}
