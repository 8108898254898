import React, { useState } from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withSequence, withTiming } from 'react-native-reanimated'
import * as Clipboard from 'expo-clipboard'
import { Touchable, Typography } from 'lib/components'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useMousePosition, useTranslations } from 'lib/hooks'

const INITIAL_SCALE = 1
const MAX_SCALE = 1.02

type CopyToClipboardProps = {
    children: React.ReactNode,
    copyValue: string
}

export const CopyToClipboard: React.FunctionComponent<CopyToClipboardProps> = ({
    children,
    copyValue
}) => {
    const T = useTranslations()
    const { state: { mousePosition }, actions: { getMousePosition } } = useMousePosition()
    const { styles } = useStyles(stylesheet)
    const [isLabelVisible, setIsLabelVisible] = useState(false)
    const scale = useSharedValue(INITIAL_SCALE)
    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ scale: scale.value }]
    }))

    const startAnimation = () => {
        Clipboard.setStringAsync(copyValue)
        setIsLabelVisible(true)

        if (scale.value === INITIAL_SCALE) {
            scale.value = withSequence(
                withTiming(MAX_SCALE, { duration: 150 }),
                withTiming(INITIAL_SCALE, { duration: 150 }, () => setIsLabelVisible(false))
            )
        }
    }

    return (
        <Touchable
            onPress={event => {
                getMousePosition(event)
                startAnimation()
            }}
        >
            {isLabelVisible && (
                <Animated.View
                    style={{
                        ...styles.label,
                        ...conditionalStyle(
                            (Boolean(mousePosition.x) && Boolean(mousePosition.y)),
                            {
                                top: mousePosition.y - 32,
                                left: mousePosition.x,
                                zIndex: 999
                            }
                        )
                    }}
                >
                    <Typography.Subheading>
                        {T.components.table.copied}
                    </Typography.Subheading>
                </Animated.View>
            )}
            <Animated.View style={animatedStyle}>
                {children}
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    label: {
        position: 'fixed',
        backgroundColor: theme.ui.foreground,
        padding: theme.gap,
        borderRadius: theme.gap,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
