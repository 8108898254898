import { Filter, SearchFilterKey } from './types'

export const searchFilters = [
    {
        id: SearchFilterKey.StoreDescription,
        title: 'Store Description'
    },
    {
        id: SearchFilterKey.ProductNumber,
        title: 'Product Number'
    },
    {
        id: SearchFilterKey.BindingDescription,
        title: 'Binding Description'
    },
    {
        id: SearchFilterKey.AuthorShortName,
        title: 'Author Short Name'
    },
    {
        id: SearchFilterKey.AuthorFirstName,
        title: 'Author First Name'
    },
    {
        id: SearchFilterKey.AuthorLastName,
        title: 'Author Last Name'
    }
] satisfies Array<Filter>
