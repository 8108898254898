import React from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Adapter, Button, GoBack, Grid, Typography, ReKeyboardAccessory } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { inputProps, R } from 'lib/utils'
import { useAdminSetupForm } from '../forms'

export const AdminSetupScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { form, submit } = useForm(useAdminSetupForm(), {
        onSuccess: R.T,
        onError: R.T
    })

    return (
        <Grid.Background hasPaddingHorizontal>
            <GoBack/>
            <Typography.Heading>
                {T.screens.adminSetup.title}
            </Typography.Heading>
            <ReKeyboardAccessory
                numberOfInputs={3}
                contentContainerStyle={styles.form}
            >
                {keyboardProps => {
                    const [
                        fullNameProps,
                        passwordProps,
                        emailProps
                    ] = keyboardProps

                    return (
                        <React.Fragment>
                            <Grid.Gap gapBottom={7}/>
                            <Adapter.TextInput
                                {...form.fullName}
                                {...fullNameProps}
                                inputProps={inputProps.name}
                                leftIcon={(
                                    <Icons.User size={22}/>
                                )}
                                rightIcon={(
                                    <Icons.Pencil size={24}/>
                                )}
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...form.password}
                                {...passwordProps}
                                inputProps={inputProps.newPassword}
                                leftIcon={(
                                    <Icons.Padlock size={20}/>
                                )}
                                isPassword
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...form.email}
                                {...emailProps}
                                inputProps={inputProps.email}
                                leftIcon={(
                                    <Icons.Envelope size={22}/>
                                )}
                                rightIcon={(
                                    <Icons.Pencil size={24}/>
                                )}
                            />
                            <View style={styles.spacer}/>
                            <Button
                                width={Measurements.WindowWidth - (theme.gap * 6)}
                                text={T.common.save}
                                onPress={submit}
                            />
                            <Grid.Gap gapBottom={8}/>
                        </React.Fragment>
                    )
                }}
            </ReKeyboardAccessory>
        </Grid.Background>
    )
}

const stylesheet = createStyles(() => ({
    form: {
        flex: 1
    },
    spacer: {
        flex: 1
    }
}))
