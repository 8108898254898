import { useCallback } from 'react'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

export const useCustomFonts = () => {
    // todo register fonts here
    const [fontsLoaded] = useFonts({
        HouschkaRoundedBold: require('assets/fonts/HouschkaRoundedBold.otf')
    })

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync()
        }
    }, [fontsLoaded])

    return {
        fontsLoaded,
        onLayoutRootView
    }
}
