import React, { useEffect, useState } from 'react'
import { Alert, SectionList } from 'react-native'
import { Measurements } from 'lib/common'
import { Button, ConfirmModal, GoBack, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { ScanHistoryEntry, Store } from 'lib/models'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { R } from 'lib/utils'
import { FileScanTile, FileStoreTile } from '../components'
import { useFiles } from '../hooks'

type FilesScreenProps = {
    navigation: NavigationProps<ScreenNames>
}

type ListSection = {
    store: Store,
    data: Array<ScanHistoryEntry>
}

type ItemToDelete = {
    createdAt: number,
    storeUUID: string
}

export const FilesScreen: React.FunctionComponent<FilesScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { readSavedScans, deleteScan } = useFiles()
    const [savedScans, setSavedScans] = useState<Array<ListSection>>([])
    const [itemToDelete, setItemToDelete] = useState<Nullable<ItemToDelete>>(null)

    const readScans = () => {
        readSavedScans()
            .then(rawSavedScans => {
                const groupedSavedScans = rawSavedScans
                    .filter(savedScan => savedScan?.scans?.length)
                    .map(savedScan => ({
                        store: savedScan?.store,
                        data: savedScan.scans.sort((scan1, scan2) => scan1.createdAt > scan2.createdAt ? -1 : 1)
                    }))

                setSavedScans(groupedSavedScans || [])
            })
            .catch(R.T)
    }

    useEffect(() => {
        const removeListener = navigation.addListener('focus', () => {
            readScans()
        })

        return removeListener
    }, [])

    return (
        <Grid.Background
            style={styles.background}
            hasPaddingHorizontal
        >
            <GoBack/>
            <Typography.Heading>
                {T.screens.files.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={5}/>
            <SectionList
                contentContainerStyle={{
                    ...styles.list,
                    ...(!savedScans.length ? styles.emptyList : {})
                }}
                sections={savedScans}
                keyExtractor={item => `item-${item.createdAt}`}
                renderItem={({ section, item: { createdAt, items, isSaved } }) => (
                    <FileScanTile
                        isSaved={isSaved}
                        onDeletePress={() => setItemToDelete({
                            storeUUID: section.store.storeUUID,
                            createdAt
                        })}
                        createdAt={createdAt}
                        onPress={() => navigation.navigate(ScreenNames.ScanDetails, {
                            items,
                            store: section?.store,
                            createdAt,
                            isSaved
                        })}
                    />
                )}
                renderSectionHeader={({ section: { store } }) => (
                    <FileStoreTile store={store}/>
                )}
                ListEmptyComponent={() => (
                    <React.Fragment>
                        <Typography.Subheading style={styles.text}>
                            {T.screens.files.listEmpty.message}
                        </Typography.Subheading>
                        <Grid.Gap gapBottom={5}/>
                        <Button
                            width={Measurements.WindowWidth - theme.utils.gap(6)}
                            text={T.screens.files.listEmpty.button}
                            onPress={() => navigation.navigate(ScreenNames.ChooseScanStore)}
                        />
                    </React.Fragment>
                )}
            />
            <Grid.Gap gapBottom={8}/>
            <ConfirmModal
                isVisible={Boolean(itemToDelete)}
                message={T.screens.files.confirmDelete}
                onClose={() => setItemToDelete(null)}
                onConfirm={() => deleteScan(itemToDelete?.storeUUID, itemToDelete?.createdAt)
                    .then(() => {
                        setItemToDelete(null)
                        readScans()
                    })
                    .catch(() => Alert.alert(T.alerts.oops, T.screens.files.errorDelete))
                }
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    list: {
        paddingBottom: theme.utils.gap(8)
    },
    emptyList: {
        flex: 1,
        justifyContent: 'center'
    },
    section: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(3),
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1)
    },
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(3)
    },
    background: {
        alignItems: 'center'
    }
}))
