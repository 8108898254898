import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles, useStyles } from 'lib/styles'
import { useUserAtom, useCurrentStoreAtom } from 'lib/atoms'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'
import { regexes } from 'lib/utils'
import { Typography, Touchable, FullScreenLoader } from 'lib/components'

export const TopBar: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const { name } = useRoute()
    const [currentStore] = useCurrentStoreAtom()
    const isStoreItemsScreen = name === ScreenNames.StoreItems

    return (
        <View style={styles.container}>
            <View style={styles.route}>
                <Typography.Heading>
                    {isStoreItemsScreen
                        ? currentStore?.storeName ?? (
                            <View style={styles.storeLoading}>
                                <FullScreenLoader isActive />
                            </View>
                        )
                        : T.titles[regexes.firstLetterLowercase(name)]
                    }
                </Typography.Heading>
            </View>
            <View style={styles.userContainer}>
                <View style={styles.userDetails}>
                    <Typography.Regular>
                        {user?.fullName}
                    </Typography.Regular>
                    <Typography.SubText>
                        {user?.userType}
                    </Typography.SubText>
                </View>
                <Touchable
                    onPress={() => navigation.navigate(ScreenNames.EditSelf)}
                    style={styles.rectangle}
                >
                    <Icons.User
                        forceColor={theme.colors.pink}
                        size={32}
                    />
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 24,
        width: '100%',
        backgroundColor: theme.colors.almostBlack,
        height: theme.topBarHeight,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.darkGray,
        gap: theme.gap * 2
    },
    routeFirstPart: {
        color: theme.colors.darkGray
    },
    route: {
        display: 'flex',
        flexDirection: 'row'
    },
    userContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    },
    userDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: theme.gap,
        position: 'relative',
        top: 6
    },
    rectangle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
        borderRadius: 14,
        borderWidth: 1,
        borderType: 'solid',
        borderColor: theme.colors.pink
    },
    storeLoading: {
        position: 'relative',
        bottom: theme.gap,
        marginLeft: theme.gap * 2
    }
}))
