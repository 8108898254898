import React, { useState } from 'react'
import { Alert } from 'react-native'
import { useFiles } from 'features/files'
import { Icons } from 'assets'
import { itemActions } from 'features/item'
import { queryClient, QueryKey } from 'lib/api'
import { useScanConfigAtom, useScannedItemsAtom, useStoresAtom } from 'lib/atoms'
import { Measurements } from 'lib/common'
import {
    Button,
    ConfirmModal,
    FadeShadowWrapper,
    FullScreenLoader,
    GoBack,
    Grid,
    TableNative,
    Touchable,
    Typography
} from 'lib/components'
import { useBackHandler, useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { dateHelpers, R } from 'lib/utils'
import { TableItemKey } from 'lib/types'

type ScanDetailsScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ScanDetails>
}
export const ScanDetailsScreen: React.FunctionComponent<ScanDetailsScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalVisible, setModalVisibility] = useState(false)
    const [, setStores] = useStoresAtom()
    const [, setScannedItems] = useScannedItemsAtom()
    const [, setScanConfig] = useScanConfigAtom()
    const { saveToCSVFile, markScanAsShared } = useFiles()
    const { mutate: postScannedItems, isLoading } = itemActions.usePostScannedItems()

    const onSaveToFile = () => {
        saveToCSVFile(route.params)
            .catch(() => Alert.alert(T.alerts.oops, T.screens.finishScanning.errorSharing))
    }

    const onSaveToDatabaseScan = () => {
        postScannedItems(
            {
                items: route.params.items.map(scannedItem => ({
                    ...scannedItem,
                    storeItemUUID: scannedItem?.storeItemUUID
                })),
                storeUUID: route.params.store.storeUUID,
                scanDate: route.params.createdAt
            },
            {
                onSuccess: () => {
                    markScanAsShared(route.params.store.storeUUID, route.params.createdAt)
                        .catch(R.T)
                    setStores(prevState => ({
                        ...prevState,
                        stores: prevState.data.map(store => store.storeUUID === route.params.store.storeUUID
                            ? {
                                ...route.params.store,
                                syncDate: dateHelpers.nowUnix()
                            }
                            : store
                        )
                    }))
                    queryClient.invalidateQueries([QueryKey.Stores, {}])
                        .catch(R.T)
                    navigation.navigate(ScreenNames.CustomModal, {
                        title: T.screens.finishScanning.onSaveSuccess.title,
                        message: T.screens.finishScanning.onSaveSuccess.message,
                        onClose: () => navigation.pop(2)
                    })
                },
                onError: () => Alert.alert(T.alerts.oops, T.screens.finishScanning.errorSaving)
            }
        )
    }

    const onStartScan = (isFastScan: boolean) => {
        setScanConfig(() => ({
            isFastScan,
            store: route.params.store
        }))
        setScannedItems(route.params.items)
        setModalVisibility(false)
        navigation.navigate(ScreenNames.ScannedItems, {
            showScannerButton: true,
            store: route.params.store
        })
    }

    useBackHandler(() => isLoading)

    return (
        <Grid.Background
            style={styles.background}
            hasPaddingHorizontal
        >
            <GoBack/>
            <Typography.Heading>
                {T.screens.scanDetails.title}
            </Typography.Heading>
            <Touchable
                style={styles.edit}
                onPress={() => setModalVisibility(true)}
            >
                <Icons.Pencil
                    size={32}
                    forceColor={theme.ui.primary}
                />
            </Touchable>
            <Touchable
                style={styles.saveToFile}
                onPress={onSaveToFile}
            >
                <Icons.Share
                    size={32}
                    forceColor={theme.ui.primary}
                />
            </Touchable>
            <Grid.Gap gapBottom={5}/>
            <FadeShadowWrapper
                containerStyle={styles.fadeShadowWrapper}
                showTopShadow={false}
                showBottomShadow={route.params?.items?.length > 0}
            >
                <TableNative
                    columns={[
                        {
                            key: TableItemKey.BookCode,
                            title: T.components.table.headers.bookCode,
                            size: 170
                        },
                        {
                            key: TableItemKey.Quantity,
                            title: T.components.table.headers.quantity
                        }
                    ]}
                    data={route.params?.items?.map(item => ({
                        bookCode: item.bookCode,
                        quantity: item.quantity
                    }))}
                />
            </FadeShadowWrapper>
            <Grid.Gap gapBottom={4}/>
            <Button
                disabled={route.params?.isSaved}
                width={Measurements.WindowWidth - theme.utils.gap(6)}
                text={T.screens.scanDetails.button.saveToDatabase}
                onPress={onSaveToDatabaseScan}
            />
            <Grid.Gap gapBottom={8}/>
            <FullScreenLoader isActive={isLoading}/>
            <ConfirmModal
                isVisible={isModalVisible}
                title={T.components.fastScanModal.title}
                message={T.components.fastScanModal.message}
                confirmText={T.common.ok}
                cancelText={T.components.fastScanModal.decline}
                onClose={() => setModalVisibility(false)}
                onCancel={() => onStartScan(false)}
                onConfirm={() => onStartScan(true)}
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    edit: {
        position: 'absolute',
        right: theme.utils.gap(3),
        top: Number(Measurements.StatusBarHeight) + theme.utils.gap(3)
    },
    saveToFile: {
        position: 'absolute',
        right: theme.utils.gap(9),
        top: Number(Measurements.StatusBarHeight) + theme.utils.gap(3)
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingBottom: theme.utils.gap(1.5),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.mixTransparent(theme.ui.accent, 0.5)
    },
    listContainer: {
        paddingBottom: theme.utils.gap(4)
    },
    text: {
        textAlign: 'center'
    },
    fadeShadowWrapper: {
        width: '100%'
    },
    background: {
        alignItems: 'center'
    }
}))
