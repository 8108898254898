import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Sku: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 29.242 29.4 h -6.084 c -0.3096 0 -0.558 0.234 -0.558 0.5256 v 24.1488 c 0 0.288 0.252 0.5256 0.558 0.5256 h 6.084 c 0.3096 0 0.558 -0.234 0.558 -0.5256 V 29.9256 c 0 -0.288 -0.252 -0.5256 -0.558 -0.5256 Z M 58.042 29.4 h -6.084 c -0.3096 0 -0.558 0.234 -0.558 0.5256 v 24.1488 c 0 0.288 0.252 0.5256 0.558 0.5256 h 6.084 c 0.3096 0 0.558 -0.234 0.558 -0.5256 V 29.9256 c 0 -0.288 -0.252 -0.5256 -0.558 -0.5256 Z M 43.0804 22.2 h -4.9608 c -0.6192 0 -1.1196 0.3708 -1.1196 0.828 v 37.944 c 0 0.4572 0.504 0.828 1.1196 0.828 h 4.9608 c 0.6192 0 1.1196 -0.3708 1.1196 -0.828 V 23.028 c 0 -0.4572 -0.504 -0.828 -1.1196 -0.828 Z M 12.412 9.6 H 6.3928 C 5.3992 9.6 4.6 10.4532 4.6 11.508 v 57.384 c 0 1.0548 0.7992 1.908 1.7892 1.908 h 7.038 M 68.3632 9.6 h 6.0264 c 0.99 0 1.7892 0.8532 1.7892 1.908 v 57.384 c 0 1.0548 -0.7992 1.908 -1.7892 1.908 H 67.348"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
