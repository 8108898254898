import React from 'react'

type KeyboardProviderProps = {
    children: React.ReactNode
}

export const KeyboardProvider: React.FunctionComponent<KeyboardProviderProps> = ({ children }) => (
    <React.Fragment>
        {children}
    </React.Fragment>
)
