import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const ArrowLeft: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M47.5932 24.2756C48.5739 23.3037 48.5809 21.7208 47.609 20.7401C46.637 19.7595 45.0541 19.7524 44.0735 20.7244L38.091 26.6537C35.8378 28.8868 33.9962 30.712 32.6902 32.3382C31.3321 34.0293 30.349 35.7457 30.0864 37.8061C29.9712 38.7095 29.9712 39.6238 30.0864 40.5272C30.349 42.5876 31.3321 44.3041 32.6902 45.9951C33.9962 47.6214 35.8378 49.4465 38.091 51.6796L44.0735 57.609C45.0541 58.5809 46.637 58.5739 47.609 57.5932C48.5809 56.6125 48.5739 55.0296 47.5932 54.0577L41.7166 48.2333C39.3317 45.8695 37.6956 44.2427 36.5886 42.8643C35.5133 41.5253 35.1444 40.6653 35.0462 39.895C34.9846 39.4114 34.9846 38.922 35.0462 38.4383C35.1444 37.6681 35.5133 36.808 36.5886 35.469C37.6956 34.0906 39.3317 32.4638 41.7166 30.1L47.5932 24.2756Z"
        />
    </Icon>
)
