import React from 'react'
import { G, Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Country: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 36.026 24.0372 c 1.62 -13.59 20.8548 -12.0852 21.51 0 c 0.1008 7.326 -5.148 13.284 -10.746 19.1628 c -5.6376 -5.364 -11.7504 -12.978 -10.7424 -19.1628 h -0.0216 Z"
                />
                <Path
                    d="M 46.736 28.8 a 3.6 3.6 90 1 0 0 -7.2 a 3.6 3.6 90 0 0 0 7.2 Z"
                />
                <Path
                    d="M 12.4712 9.1944 c -0.2268 -1.1916 -1.332 -2.5956 2.4552 -2.7936 c 3.7836 -0.1944 11.5704 0.396 13.7808 0 c 2.214 -0.3924 17.784 -2.988 19.116 -2.79 c 1.332 0.1944 4.8924 2.3832 8.676 2.988 c 3.7872 0.6012 11.3472 4.176 11.3472 5.1732 c 0 0.9972 1.1052 3.9852 0 7.578 c -1.1052 3.5892 -0.4392 19.3284 -1.1052 19.7352 c -0.666 0.4068 -2.4552 6.7752 -5.3316 9.3708 c -2.88 2.592 -7.7868 6.228 -8.226 10.1844 c -0.4392 3.9528 -0.666 9.7308 -3.7872 10.9404 c -3.1176 1.206 -6.948 3.5748 -9.144 4.284 c -0.8208 0.2556 -2.6532 0.558 -4.6224 0.846 c -3.15 0.4356 -5.634 -2.6568 -4.5576 -5.688 c 0.9684 -2.736 1.8756 -5.3424 1.8756 -5.616 c 0 -0.6012 0.666 -5.3568 -2.4516 -7.164 c -3.1212 -1.8108 -5.3316 -4.1976 -3.5604 -7.7868 c 1.7712 -3.5928 -2.2248 -12.1644 -3.3336 -13.7484 c -0.8172 -1.1772 -7.1784 -9.2952 -10.4796 -13.5 a 7.2432 7.2432 90 0 1 -1.422 -5.6448 c 0.4212 -2.4912 0.9072 -5.6592 0.7704 -6.372 Z"
                />
            </G>
        </Icon>
    )
}
