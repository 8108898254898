import { ColumnConfig } from 'lib/types'

export const manageStoresTableHeaders = (isSmallerView?: boolean) => [
    {
        key: 'store',
        size: 260,
        title: 'Store'
    },
    {
        key: 'totalUsersCount',
        size: isSmallerView ? 120 : 170,
        title: 'Users'
    },
    {
        key: 'totalStoreItemsCount',
        size: isSmallerView ? 120 : 170,
        title: 'Items'
    }
] satisfies Array<ColumnConfig>

export const storeFilesTableHeaders = (isSmallerView?: boolean) => [
    {
        key: 'bookCode',
        size: isSmallerView ? 120 : 240,
        title: 'Book Code'
    },
    {
        key: 'sku',
        size: isSmallerView ? 120 : 240,
        title: 'SKU'
    },
    {
        key: 'quantity',
        title: 'Quantity'
    }
] satisfies Array<ColumnConfig>
