import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { inputProps } from 'lib/utils'
import { useGoBack, useTranslations } from 'lib/hooks'
import { Adapter, Button, Grid, KeyboardAccessoryFieldProps } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { Country } from 'lib/models'
import { isNative, isWeb, masks } from 'lib/common'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { StoreSetupShape } from '../forms'

type StoreSetupProps = {
    isLoading?: boolean,
    form: StoreSetupShape,
    storeNameProps?: KeyboardAccessoryFieldProps,
    imStoreIdProps?: KeyboardAccessoryFieldProps,
    streetAddressProps?: KeyboardAccessoryFieldProps,
    cityProps?: KeyboardAccessoryFieldProps,
    zipCodeProps?: KeyboardAccessoryFieldProps,
    isFetchingCountries?: boolean,
    countries?: Array<Country>,
    submit: VoidFunction,
    onCountryChange: VoidFunction
}

export const StoreSetup: React.FunctionComponent<StoreSetupProps> = ({
    isLoading,
    form,
    storeNameProps,
    imStoreIdProps,
    streetAddressProps,
    cityProps,
    zipCodeProps,
    countries,
    isFetchingCountries,
    submit,
    onCountryChange
}) => {
    const T = useTranslations()
    const { goBack } = useGoBack(ScreenNames.ManageStores)
    const { styles } = useStyles(stylesheet)

    return (
        <View style={conditionalStyle(isNative,styles.container)}>
            <View style={styles.formRow}>
                <Adapter.TextInput
                    disabled={isLoading}
                    {...form.storeName}
                    {...storeNameProps}
                    style={styles.textInput}
                    inputProps={{
                        autoCapitalize: isNative ? 'words' : 'none',
                        textContentType: 'organizationName'
                    }}
                    leftIcon={(
                        <Icons.Store size={22}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={22}/>
                    )}
                />
                <Adapter.TextInput
                    disabled={isLoading}
                    {...form.imStoreId}
                    {...imStoreIdProps}
                    mask={[/[1-9]/, ...masks.getNumericMask(255)]}
                    style={styles.textInput}
                    inputProps={{
                        ...inputProps.numberInput
                    }}
                    leftIcon={(
                        <Icons.Storefront size={22}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={22}/>
                    )}
                />
            </View>
            <View style={styles.formRow}>
                <Adapter.TextInput
                    disabled={isLoading}
                    {...form.streetAddress}
                    {...streetAddressProps}
                    style={styles.textInput}
                    inputProps={{
                        autoComplete: 'street-address',
                        textContentType: 'fullStreetAddress',
                        dataDetectorTypes: 'address',
                        autoCapitalize: isNative ? 'words' : 'none'
                    }}
                    leftIcon={(
                        <Icons.HouseBorder size={22}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={22}/>
                    )}
                />
                <Adapter.TextInput
                    disabled={isLoading}
                    {...form.city}
                    {...cityProps}
                    style={styles.textInput}
                    inputProps={{
                        autoComplete: 'postal-address-locality',
                        textContentType: 'addressCity',
                        autoCapitalize: isNative ? 'words' : 'none'
                    }}
                    leftIcon={(
                        <Icons.City size={22}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={22}/>
                    )}
                />
            </View>
            <Adapter.Dropdown
                isLoading={isFetchingCountries}
                style={{
                    ...styles.textInput,
                    ...styles.countryIndex,
                    ...styles.dropdown
                }}
                disabled={isLoading}
                {...form.country}
                items={countries || []}
                onChange={onCountryChange}
                getItemLabel={item => item?.countryName ?? ''}
                getItemKey={item => item?.countryUUID ?? ''}
                leftIcon={(
                    <Icons.Country size={24}/>
                )}
            />
            <Adapter.Dropdown
                isLoading={isFetchingCountries}
                disabled={isLoading}
                search={isWeb}
                style={{
                    ...styles.textInput,
                    ...styles.stateIndex,
                    ...styles.dropdown
                }}
                {...form.state}
                disableClearButton
                items={form.country.value?.states || []}
                getItemLabel={item => item?.stateName ?? ''}
                getItemKey={item => item?.stateUUID ?? ''}
                leftIcon={(
                    <Icons.State size={24}/>
                )}
            />
            <View
                style={{
                    ...styles.formRow,
                    ...styles.textInputWrapper
                }}
            >
                <Adapter.TextInput
                    disabled={isLoading}
                    {...form.zipCode}
                    {...zipCodeProps}
                    inputProps={{
                        autoComplete: 'postal-code',
                        textContentType: 'postalCode'
                    }}
                    leftIcon={(
                        <Icons.Mailbox size={24}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={22}/>
                    )}
                />
            </View>
            <Grid.Gap gapBottom={2}/>
            <View style={{
                ...styles.formRow,
                ...conditionalStyle(isWeb, styles.reverseButtons)
            }}>
                <Button
                    isLoading={isLoading}
                    text={T.common.save}
                    customStyle={styles.textInput}
                    onPress={submit}
                />
                <Grid.Gap gapBottom={2}/>
                <Button
                    disabled={isLoading}
                    noBackground
                    customStyle={styles.textInput}
                    text={T.common.cancel}
                    onPress={goBack}
                />
            </View>
            {isNative && (
                <Grid.Gap gapBottom={8}/>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: {
            xs: theme.contentPaddingMobile
        },
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: {
            xs: 0,
            sm: theme.gap * 2
        }
    },
    textInput: {
        flexShrink: 1
    },
    countryIndex: {
        zIndex: 999
    },
    stateIndex: {
        zIndex: 998
    },
    textInputWrapper: {
        zIndex: 0
    },
    dropdown: {
        width: '100%'
    },
    reverseButtons: {
        flexDirection: 'row-reverse'
    }
}))
