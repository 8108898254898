import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
    ConfirmEmailChangeScreen,
    ForgotPasswordScreen,
    ResetPasswordScreen,
    SignInScreen,
    SignUpScreen,
    VerifyEmailScreen,
    WelcomeScreen,
    SetupPasswordScreen,
    SignUpConfirmScreen
} from 'features/auth'
import { CustomModalScreen } from 'features/home'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from './screens'
import { AuthStackParams } from './stackParams'

const Stack = createNativeStackNavigator<AuthStackParams>()

export const AuthStack = () => {
    const T = useTranslations()

    return (
        <Stack.Navigator
            initialRouteName={ScreenNames.Welcome}
            screenOptions={{
                headerShown: false
            }}
        >
            <Stack.Screen
                name={ScreenNames.Welcome}
                component={WelcomeScreen}
                options={{
                    title: T.titles.welcome
                }}
            />
            <Stack.Screen
                name={ScreenNames.SignIn}
                component={SignInScreen}
                options={{
                    title: T.titles.signIn
                }}
            />
            <Stack.Screen
                name={ScreenNames.SignUp}
                component={SignUpScreen}
                options={{
                    title: T.titles.signUp
                }}
            />
            <Stack.Screen
                name={ScreenNames.ResetPassword}
                component={ResetPasswordScreen}
                options={{
                    title: T.titles.resetPassword
                }}
            />
            <Stack.Screen
                name={ScreenNames.VerifyEmail}
                component={VerifyEmailScreen}
                options={{
                    title: T.titles.verifyEmail
                }}
            />
            <Stack.Screen name={ScreenNames.ConfirmEmailChange} component={ConfirmEmailChangeScreen}/>
            <Stack.Screen name={ScreenNames.SetupPassword} component={SetupPasswordScreen}/>
            <Stack.Group
                screenOptions={{
                    presentation: 'transparentModal',
                    animation: 'fade'
                }}
            >
                <Stack.Screen
                    name={ScreenNames.ForgotPassword}
                    component={ForgotPasswordScreen}
                    options={{
                        title: T.titles.forgotPassword
                    }}
                />
                <Stack.Screen
                    name={ScreenNames.SignUpConfirm}
                    component={SignUpConfirmScreen}
                />
                <Stack.Screen name={ScreenNames.CustomModal} component={CustomModalScreen}/>
            </Stack.Group>
        </Stack.Navigator>
    )
}
