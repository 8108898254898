import React, { useState, useEffect } from 'react'
import { View, FlatList, RefreshControl } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { Icons } from 'assets'
import { storeActions } from 'features/store'
import { useTranslations, useIsWithinBreakpoints } from 'lib/hooks'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { AppLayout, SearchInput, Typography, GoBack, ListErrorComponent } from 'lib/components'
import { isIOS, isNative, isWeb, Measurements } from 'lib/common'
import { R } from 'lib/utils'
import { useSearchFilters, useSearchItemsAtom, useSearchStores } from 'lib/atoms'
import { SearchItems, Filter, Item } from '../components'
import { useGetItems } from '../actions'
import { SearchFilterKey } from '../types'

export const SearchScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const [searchItems, setSearchItems] = useSearchItemsAtom()
    const [searchFilters] = useSearchFilters()
    const [searchStores, setSearchStores] = useSearchStores()
    const isFocused = useIsFocused()
    const isMobileResolution = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const [searchQuery, setSearchQuery] = useState('')
    const { styles, theme } = useStyles(stylesheet)
    const {
        mutate: getItems,
        isLoading: getItemsLoading,
        isError,
        error,
        reset
    } = useGetItems()
    const {
        isFetching: isStoresFetching,
        refetch: getStores,
        data: storesResponse
    } = storeActions.useGetStores()
    const notFoundFromAPI = error?.data?.code === 400
    const isLoading = getItemsLoading || isStoresFetching
    const storesWithImStoreId = storesResponse?.data.stores.filter(store => store.imStoreId) || []

    const fetchItems = () => getItems(
        {
            searchFilters: [
                ...searchFilters.map(filter => ({
                    key: filter.id,
                    value: filter.value
                })),
                ...searchQuery ? [{
                    key: SearchFilterKey.SKU,
                    value: searchQuery
                }] : []
            ],
            imStoreId: searchStores.map(store => Number(store.imStoreId))
        },
        {
            onSuccess: response => {
                const items = response.data.map(item => ({
                    SKU: item.SKU,
                    title: item.Prod_Description,
                    authorFirstName: item.Author_FirstName,
                    authorLastName: item.Author_LastName,
                    publisher: item.BKP_Pub_Name,
                    departament: item.PD_DeptCat_Description,
                    store: item.PD_Store_Description,
                    inStock: item.PA_OnHand,
                    price: Number(item.PA_Retail).toFixed(2),
                    isbn: item.Prod_Number
                }))

                setSearchItems(items)
            },
            onError: () => setSearchItems([])
        }
    )

    useEffect(() => {
        getStores()
    }, [])

    useEffect(() => {
        if (!isLoading && searchStores) {
            fetchItems()
        }
    }, [searchQuery, searchFilters, searchStores])

    const shouldRenderFlatList = R.cond([
        [R.always(isNative), R.T],
        [R.always(isWeb && isMobileResolution), R.T],
        [R.T, R.always(false)]
    ])

    useEffect(() => {
        if (R.isEmpty(searchStores)) {
            setSearchStores(storesWithImStoreId || [])
        }
    }, [storesResponse])

    return (
        <AppLayout>
            <View style={styles.container}>
                {isNative && (
                    <React.Fragment>
                        <GoBack disabled={isLoading} />
                        <View
                            style={{
                                ...styles.header,
                                ...conditionalStyle(isNative, { marginTop: isIOS ? Measurements.StatusBarHeight : 0 })
                            }}
                        >
                            <Typography.Heading>
                                {T.titles.search}
                            </Typography.Heading>
                        </View>
                    </React.Fragment>
                )}
                <View style={styles.actions}>
                    <View style={styles.input}>
                        <SearchInput
                            placeholder={T.screens.search.searchPlaceholder}
                            onDebouncedQuery={setSearchQuery}
                            rightIcon={(
                                <Icons.Search size={22}/>
                            )}
                        />
                    </View>
                    <Filter
                        onReset={reset}
                        stores={storesWithImStoreId|| []}
                        isFetching={isStoresFetching}
                    />
                </View>
                {isWeb && (
                    <React.Fragment>
                        <SearchItems
                            items={searchItems.slice(0, 50)}
                            emptyFilters={R.isEmpty(searchFilters)}
                        />
                    </React.Fragment>
                )}
                {shouldRenderFlatList() && (
                    <FlatList
                        data={searchItems}
                        showsVerticalScrollIndicator={false}
                        keyExtractor={item => item.SKU}
                        contentContainerStyle={styles.flatList}
                        renderItem={item => (
                            <Item
                                key={item.index}
                                item={item.item}
                            />
                        )}
                        refreshControl={(
                            <RefreshControl
                                refreshing={isLoading && isFocused}
                                onRefresh={isLoading
                                    ? R.T
                                    : fetchItems
                                }
                                colors={[theme.ui.primary]}
                                tintColor={theme.ui.primary}
                                progressViewOffset={40}
                                style={conditionalStyle(isLoading, styles.refresh)}
                            />
                        )}
                        ListEmptyComponent={() => (isError && !notFoundFromAPI) ? (
                            <ListErrorComponent
                                onRefetch={fetchItems}
                                isLoading={isLoading}
                            />
                        ) : (
                            <Typography.Subheading style={styles.text}>
                                {(searchQuery || notFoundFromAPI)
                                    ? T.screens.search.noMatching
                                    : T.screens.search.noItems
                                }
                            </Typography.Subheading>
                        )}
                    />
                )}
            </View>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        padding: {
            xs: theme.contentPaddingMobile,
            sm: theme.contentPaddingWeb
        },
        backgroundColor: theme.ui.background
    },
    input: {
        width: {
            xs: 'auto',
            sm: 328
        },
        flexGrow: {
            xs: 1,
            sm: 0
        }
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.gap * 3,
        zIndex: 1
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: theme.gap * 3,
        display: {
            xs: 'flex',
            sm: 'none'
        }
    },
    flatList: {
        gap: theme.gap * 2,
        display: {
            xs: 'flex',
            sm: 'none'
        }
    },
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.gap * 3
    },
    refresh: {
        paddingBottom: theme.gap * 4
    },
    noStoresText: {
        textAlign: 'center',
        paddingHorizontal: theme.gap * 3,
        color: theme.colors.red
    }
}))
