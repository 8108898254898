import { isWeb } from 'lib/common'
import { UserType } from 'lib/models'

export enum RootStack {
    Root = 'Root'
}

export enum StackNavigatorKey {
    NativeStack = 'nativeStack'
}

export enum ScreenNames {
    Home = 'HomeScreen',
    SignIn = 'SignIn',
    Welcome = 'Welcome',
    SignUp = 'SignUp',
    ResetPassword = 'ResetPassword',
    AdminSetup = 'AdminSetup',
    ManageUsers = 'ManageUsers',
    ManageStores = 'ManageStores',
    EditUser = 'EditUser',
    AddUser = 'AddUser',
    StoreSetup = 'StoreSetup',
    ChooseScanStore = 'ChooseScanStore',
    Scanner = 'Scanner',
    ItemDetails = 'ItemDetails',
    NewItem = 'NewItem',
    ItemAddedModal = 'ItemAddedModal',
    ScannedItems = 'ScannedItems',
    EditItemModal = 'EditItemModal',
    FinishScanning = 'FinishScanning',
    ForgotPassword = 'ForgotPassword',
    Files = 'files',
    ResumeScan = 'resumeScan',
    ScanDetails = 'scanDetails',
    EditSelf = 'EditSelf',
    CustomModal = 'CustomModal',
    VerifyEmail = 'VerifyEmail',
    ConfirmEmailChange = 'ConfirmEmailChange',
    StoreItems = 'StoreItems',
    DatabaseItems = 'DatabaseItems',
    QuantityModal = 'QuantityModal',
    SetupPassword = 'SetupPassword',
    Search = 'Search',
    SearchItemDetails = 'SearchItemDetails',
    ItemSetup = 'ItemSetup',
    ItemEdit = 'ItemEdit',
    SearchFilters = 'SearchFilters',
    SignUpConfirm = 'SignUpConfirm'
}

export const ScreensConfig = {
    ...(isWeb ? {
        [ScreenNames.Home]: {
            path: '/'
        }
    } : {}),
    [ScreenNames.SignIn]: {
        path: '/login'
    },
    [ScreenNames.Welcome]: {
        path: '/welcome'
    },
    [ScreenNames.SignUp]: {
        path: '/register'
    },
    [ScreenNames.ResetPassword]: {
        path: '/reset-password'
    },
    [ScreenNames.AdminSetup]: {
        path: '/admin-setup'
    },
    [ScreenNames.ManageUsers]: {
        path: '/user/manage'
    },
    [ScreenNames.ManageStores]: {
        path: '/stores/manage'
    },
    [ScreenNames.EditUser]: {
        path: '/user/update'
    },
    [ScreenNames.AddUser]: {
        path: '/user/add'
    },
    [ScreenNames.StoreSetup]: {
        path: '/stores/update'
    },
    [ScreenNames.ChooseScanStore]: {
        path: '/select-store'
    },
    [ScreenNames.Scanner]: {
        path: '/scanner'
    },
    [ScreenNames.ItemDetails]: {
        path: '/item/details'
    },
    [ScreenNames.NewItem]: {
        path: '/item/create'
    },
    [ScreenNames.ItemAddedModal]: {
        path: '/item/add'
    },
    [ScreenNames.ScannedItems]: {
        path: '/scanned'
    },
    [ScreenNames.EditItemModal]: {
        path: '/item/edit'
    },
    [ScreenNames.FinishScanning]: {
        path: '/scanner/finish'
    },
    [ScreenNames.ForgotPassword]: {
        path: '/forgot-password'
    },
    [ScreenNames.Files]: {
        path: '/files'
    },
    [ScreenNames.ResumeScan]: {
        path: '/resume-scan'
    },
    [ScreenNames.ScanDetails]: {
        path: '/scan-details'
    },
    [ScreenNames.EditSelf]: {
        path: '/account/edit'
    },
    [ScreenNames.CustomModal]: {
        path: '/custom-modal'
    },
    [ScreenNames.VerifyEmail]: {
        path: '/verify-email'
    },
    [ScreenNames.ConfirmEmailChange]: {
        path: '/email-confirm'
    },
    [ScreenNames.DatabaseItems]: {
        path: '/database-items'
    },
    [ScreenNames.QuantityModal]: {
        path: '/quantity'
    },
    [ScreenNames.SetupPassword]: {
        path: '/setup-password'
    },
    [ScreenNames.Search]: {
        path: '/search'
    },
    [ScreenNames.SearchItemDetails]: {
        path: '/search/details'
    },
    [ScreenNames.StoreItems]: {
        path: '/stores/items'
    },
    [ScreenNames.ItemSetup]: {
        path: '/stores/items/add'
    },
    [ScreenNames.ItemEdit]: {
        path: '/stores/items/edit'
    },
    [ScreenNames.SearchFilters]: {
        path: '/search/filters'
    },
    [ScreenNames.SignUpConfirm]: {
        path: '/register/confirm'
    }
}

export const webScreens = [
    {
        rootPath: 'search',
        screenName: ScreenNames.Search,
        access: [UserType.Admin, UserType.Manager, UserType.Staff]
    },
    {
        rootPath: 'user',
        screenName: ScreenNames.ManageUsers,
        access: [UserType.Admin, UserType.Manager]
    },
    {
        rootPath: 'store',
        screenName: ScreenNames.ManageStores,
        access: [UserType.Admin, UserType.Manager, UserType.Staff]
    }
]
