import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { SignUpWithEmailFormFields } from './forms'

export const useSignUpWithEmailForm = () => {
    const T = useTranslations()

    const fullName = useField<string>({
        key: SignUpWithEmailFormFields.FullName,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.fullName.label,
        placeholder: T.forms.common.fullName.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.fullName.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.common.fullName.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const email = useField<string>({
        key: SignUpWithEmailFormFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.email.label,
        placeholder: T.forms.common.email.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.email.validation.isValid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.forms.common.email.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ],
        liveParser: value => value.toLowerCase()
    })

    const password = useField<string>({
        key: SignUpWithEmailFormFields.Password,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.password.label,
        placeholder: T.forms.common.password.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.password.validation.isValid,
                validate: regexes.isValidPassword
            },
            {
                errorMessage: T.forms.common.password.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const confirmPassword = useField<string>({
        key: SignUpWithEmailFormFields.ConfirmPassword,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.confirmPassword.label,
        placeholder: T.forms.common.confirmPassword.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.confirmPassword.validation.doesNotMatch,
                validate: value => value === password.value
            }
        ]
    })

    const termsAndConditions = useField<boolean>({
        key: SignUpWithEmailFormFields.TermsAndConditions,
        initialValue: false,
        isRequired: true,
        validateOnBlur: false,
        validationRules: [
            {
                errorMessage: T.forms.common.termsAndConditions.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        fullName,
        email,
        password,
        confirmPassword,
        termsAndConditions
    }
}
