import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Grid, Touchable, Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { dateHelpers } from 'lib/utils'

type FileScanTileProps = {
    createdAt: number,
    isSaved: boolean,
    onPress: VoidFunction,
    onDeletePress: VoidFunction
}

export const FileScanTile: React.FunctionComponent<FileScanTileProps> = ({
    createdAt,
    isSaved,
    onPress,
    onDeletePress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onPress}
            style={styles.container}
        >
            <View>
                <Typography.Subheading numberOfLines={1}>
                    {dateHelpers.toUnixScanDate(createdAt)}
                </Typography.Subheading>
                <Typography.Label numberOfLines={1}>
                    {dateHelpers.toDistanceToNowUnix(createdAt)}
                </Typography.Label>
            </View>
            {isSaved && (
                <Grid.Gap gapLeft={2}>
                    <Icons.Checkmark size={24}/>
                </Grid.Gap>
            )}
            <View style={styles.spacer}/>
            <Touchable onPress={onDeletePress}>
                <Icons.Bin
                    size={24}
                    forceColor={theme.icon.error}
                />
            </Touchable>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.ui.foreground,
        borderRadius: theme.components.button.borderRadius,
        width: Measurements.WindowWidth - theme.utils.gap(6),
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(3),
        paddingVertical: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1)
    },
    spacer: {
        flex: 1
    }
}))
