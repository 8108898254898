import { Dimensions, StatusBar } from 'react-native'
import { DeviceInfo } from '../utils'
import { isIOS } from './platform'

const HEADER_HEIGHT = 62
const STATUSBAR_HEIGHT = isIOS
    ? DeviceInfo.get().hasNotch ? 44 : 25
    : StatusBar.currentHeight as number

const WINDOW_WIDTH = Dimensions.get('window').width
const WINDOW_HEIGHT = Dimensions.get('window').height

export enum Measurements {
    HeaderHeight = HEADER_HEIGHT,
    StatusBarHeight = STATUSBAR_HEIGHT,
    HeaderWithStatusBarHeight = HEADER_HEIGHT + STATUSBAR_HEIGHT,
    WindowWidth = WINDOW_WIDTH,
    WindowHeight = WINDOW_HEIGHT
}
