import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Adapter, GoBack, Touchable, Typography, FormComponents } from 'lib/components'
import { useGoBack, useTranslations } from 'lib/hooks'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { SearchFilter } from 'lib/types'
import { useSearchFilters, useSearchStores } from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { Store } from 'lib/models'
import { R } from 'lib/utils'
import { searchFilters } from '../mocks'
import { Input } from '../types'
import { FiltersListKeyboardAccessory } from './FiltersListKeyboardAccessory'

type FiltersListProps = {
    stores: Array<Store>,
    onBack: VoidFunction,
    onUpdate(filters: Array<SearchFilter>, stores: Array<Store>): void
}

export const FiltersList: React.FunctionComponent<FiltersListProps> = ({
    onBack,
    onUpdate,
    stores
}) => {
    const [activeInputs, setActiveInputs] = useState<Array<number>>([])
    const [selectedFilters] = useSearchFilters()
    const [searchStores] = useSearchStores()
    const [temporaryFilters, setTemporaryFilters] = useState<Array<SearchFilter>>(selectedFilters)
    const T = useTranslations()
    const selectedFiltersKeys = temporaryFilters.map(filter => filter.id)
    const { styles, theme } = useStyles(stylesheet)
    const { goBack } = useGoBack(ScreenNames.Search)
    const filtersLength = searchFilters.length
    const [inputs, setInputs] = useState<Array<Input>>(selectedFilters.map(filter => ({
        filter: filter.id,
        value: filter.value || ''
    })))
    const dropdownStores = stores.map(store => ({
        key: store.storeUUID,
        label: store.storeName,
        value: store
    }))
    const [temporaryStores, setTemporaryStores] = useState(searchStores.map(store => ({
        key: store.storeUUID,
        label: store.storeName,
        value: store
    })))

    const clearInputs = () => {
        const clearState = selectedFilters.map(filter => ({
            filter: filter.id,
            value: ''
        }))

        setInputs(clearState)
    }

    const updateTemporaryFilters = (filter: SearchFilter) => {
        const filterExist = temporaryFilters.find(temporaryFilter => temporaryFilter.id === filter.id)

        if (filterExist) {
            const newFilterValue = {
                ...filterExist,
                value: filter.value
            }
            const filtersWithoutFiltered = temporaryFilters.filter(temporaryFilter => temporaryFilter.id !== filter.id)

            return setTemporaryFilters([...filtersWithoutFiltered, newFilterValue])
        }

        setTemporaryFilters(prevValue => [...prevValue, filter])
    }

    const removeTemporaryFilter = (filterID: string) => {
        const filteredTemporaryFilters = temporaryFilters.filter(includedFilter => includedFilter.id !== filterID)

        setTemporaryFilters(filteredTemporaryFilters)
    }

    const onClear = () => {
        setTemporaryFilters([])
        clearInputs()
    }

    return (
        <React.Fragment>
            <GoBack onPress={onBack}/>
            <View style={styles.keyboardStyle}>
                <View style={styles.header}>
                    {/* Empty view to align content */}
                    <View/>
                    <Typography.Heading style={styles.title}>
                        {T.screens.search.selectFilters}
                    </Typography.Heading>
                    <Touchable
                        onPress={() => {
                            const filters = temporaryFilters.filter(filter => filter.value !== '')
                            const stores = temporaryStores.map(store => store.value)

                            if (R.isEmpty(temporaryStores)) {
                                return
                            }

                            onUpdate(filters, stores)
                            goBack()
                        }}
                    >
                        <Typography.SubText
                            bold
                            forceColor={theme.ui.primary}
                        >
                            {T.common.save}
                        </Typography.SubText>
                    </Touchable>
                </View>
            </View>
            <Typography.Regular style={styles.label}>
                {T.screens.search.selectStore}
            </Typography.Regular>
            <View style={{ paddingHorizontal: theme.gap * 3 }}>
                <FormComponents.DropdownModal
                    label={T.screens.search.selectStore}
                    currentText={temporaryStores.map(store => store.label).join(', ') || T.components.filters.noStoresSelected}
                    errorMessage={temporaryStores.length === 0
                        ? T.components.filters.selectStore
                        : undefined
                    }
                    modalContent={onModalClose => (
                        <Adapter.DropdownMultiplePickerContent
                            label={T.screens.search.selectStore}
                            emptyMessage={T.components.filters.userHasNoStores}
                            onClose={onModalClose}
                            selectAllToggle
                            value={temporaryStores}
                            items={dropdownStores}
                            renderLabel={item => (
                                <React.Fragment>
                                    <Typography.Title>
                                        {item.value.storeName}
                                    </Typography.Title>
                                    <Typography.Error>
                                        {`${item.value.city}${item.value.country ? ', ' : ''}${item.value.country || ''}`}
                                    </Typography.Error>
                                    <Typography.Label>
                                        {item.value.streetAddress}
                                    </Typography.Label>
                                </React.Fragment>
                            )}
                            onChangeValue={setTemporaryStores}
                        />
                    )}
                />
            </View>
            <Typography.Regular style={styles.label}>
                {T.screens.search.additionalFilters}
            </Typography.Regular>
            <FiltersListKeyboardAccessory
                numberOfInputs={filtersLength}
                activeInputs={activeInputs}
                contentContainerStyle={styles.filtersContent}
            >
                {keyboardProps => (
                    <React.Fragment>
                        {searchFilters.map((filter, index) => {
                            const isActive = selectedFiltersKeys.includes(filter.id)
                            const inputValue = inputs.find(input => input.filter === filter.id)?.value

                            const updateInputValue = (value: string) => {
                                const inputExist = inputs.find(input => input.filter === filter.id)

                                if (inputExist) {
                                    const newInputValue = {
                                        ...inputExist,
                                        value
                                    }
                                    const inputsWithoutFiltered = inputs.filter(input => input.filter !== filter.id)

                                    return setInputs([...inputsWithoutFiltered, newInputValue])
                                }

                                setInputs(prevValue => [...prevValue, { filter: filter.id, value }])
                            }

                            useEffect(() => {
                                if (!isActive || inputValue === '') {
                                    updateInputValue('')

                                    return removeTemporaryFilter(filter.id)
                                }

                                if (inputValue) {
                                    return updateTemporaryFilters({
                                        ...filter,
                                        value: inputValue
                                    })
                                }
                            }, [inputValue])

                            useEffect(() => {
                                const isInputActive = activeInputs.includes(index)

                                if (isActive && !isInputActive) {
                                    return setActiveInputs(prevValue => [...prevValue, index])
                                }

                                if (!isActive) {
                                    return setActiveInputs(prevValue => prevValue.filter(prevIndex => prevIndex !== index))
                                }
                            }, [isActive])

                            const toggle = () => {
                                if (isActive) {
                                    removeTemporaryFilter(filter.id)

                                    return updateInputValue('')
                                }

                                updateTemporaryFilters({
                                    id: filter.id,
                                    value: ''
                                })
                            }

                            return (
                                <React.Fragment key={filter.id}>
                                    <Touchable
                                        onPress={toggle}
                                        style={{
                                            ...styles.singleFilter,
                                            ...conditionalStyle((searchFilters.length - 1 === index || isActive), styles.removeBorderBottom)
                                        }}
                                    >
                                        <View
                                            style={{
                                                ...styles.checkBox,
                                                ...conditionalStyle(isActive, styles.activeFilter)
                                            }}
                                        >
                                            {isActive && (
                                                <Icons.Checkmark
                                                    size={22}
                                                    forceColor={theme.colors.white}
                                                />
                                            )}
                                        </View>
                                        <Typography.SubText style={styles.text}>
                                            {filter.title}
                                        </Typography.SubText>
                                    </Touchable>
                                    {isActive && (
                                        <Adapter.TextInput
                                            inputProps={{
                                                onChangeText: value => value === ''
                                                    ? updateInputValue('')
                                                    : updateInputValue(value),
                                                value: inputValue
                                            }}
                                            {...keyboardProps[index]}
                                        />
                                    )}
                                    {index === filtersLength - 1 && (
                                        <View style={styles.clearAll}>
                                            <Touchable onPress={() => onClear()}>
                                                <Typography.SubText
                                                    bold
                                                    forceColor={theme.ui.primary}
                                                >
                                                    {T.screens.search.clearAll}
                                                </Typography.SubText>
                                            </Touchable>
                                        </View>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                )}
            </FiltersListKeyboardAccessory>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    singleFilter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.gap * 2,
        paddingBottom: theme.gap * 2,
        borderBottomWidth: 1,
        paddingTop: theme.gap,
        borderBottomColor: theme.ui.foreground
    },
    checkBox: {
        width: 32,
        height: 32,
        borderRadius: 4,
        display: 'flex',
        backgroundColor: theme.ui.foreground,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeFilter: {
        backgroundColor: theme.colors.pink
    },
    filtersContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: theme.gap
    },
    removeBorderBottom: {
        borderBottomWidth: 0
    },
    filtersContent: {
        paddingHorizontal: theme.gap * 3,
        paddingBottom: theme.gap * 3
    },
    text: {
        color: theme.components.input.typography.text
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.gap * 3,
        display: {
            xs: 'flex',
            sm: 'none'
        }
    },
    goBack: {
        top: 0,
        left: 0
    },
    clearAll: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.gap * 2
    },
    title: {
        marginLeft: theme.gap * 3
    },
    save: {
        position: 'relative',
        top: 2
    },
    keyboardStyle: {
        paddingHorizontal: theme.gap * 3
    },
    label: {
        fontSize: 18,
        fontWeight: '300',
        paddingHorizontal: theme.gap * 3,
        marginVertical: theme.gap * 2
    }
}))
