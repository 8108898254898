import React from 'react'
import { Alert, View } from 'react-native'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Button, GoBack, Grid, Typography } from 'lib/components'
import { useBackHandler, useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { useVerifyEmail } from '../actions'

type VerifyEmailScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.VerifyEmail>,
}

export const VerifyEmailScreen: React.FunctionComponent<VerifyEmailScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { mutate: verifyEmail, isLoading } = useVerifyEmail()
    const onVerifyEmail = () => verifyEmail(
        {
            token: route.params.token
        },
        {
            onSuccess: () => navigation.navigate(ScreenNames.CustomModal, {
                title: T.common.success,
                message: T.screens.verifyEmail.onSuccessMessage,
                onClose: () => navigation.pop(2)
            }),
            onError: () => Alert.alert(T.alerts.oops, T.screens.verifyEmail.onErrorMessage)
        }
    )

    useBackHandler(() => isLoading)

    return (
        <Grid.Background hasPaddingHorizontal>
            <GoBack/>
            <Icons.Logo size={72}/>
            <Grid.Gap gapBottom={2}/>
            <Typography.Heading style={styles.text}>
                {T.screens.verifyEmail.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={2}/>
            <Typography.Subheading style={styles.text}>
                {T.screens.verifyEmail.message}
            </Typography.Subheading>
            <Grid.Gap gapBottom={5}/>
            <View style={styles.spacer}/>
            <View style={styles.container}>
                <Button
                    width={Measurements.WindowWidth - theme.utils.gap(6)}
                    isLoading={isLoading}
                    text={T.screens.verifyEmail.button}
                    onPress={onVerifyEmail}
                />
                <Grid.Gap
                    gapTop={4}
                    gapBottom={4}
                >
                    <View style={styles.separator}/>
                </Grid.Gap>
                <Typography.Subheading style={styles.text}>
                    {T.screens.verifyEmail.footerMessage}
                </Typography.Subheading>
            </View>
            <Grid.Gap gapBottom={8}/>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.gap * 2
    },
    spacer: {
        flex: 1
    },
    container: {
        flex: 1,
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    separator: {
        height: 1,
        width: Math.max(100, Measurements.WindowWidth - theme.utils.gap(16)),
        backgroundColor: theme.colors.mixTransparent(theme.ui.accent, 0.5)
    }
}))
