import React from 'react'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type BurgerMenuButtonProps = {
    onPress: VoidFunction
}

export const BurgerMenuButton: React.FunctionComponent<BurgerMenuButtonProps> = ({ onPress }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onPress}
            style={styles.container}
        >
            <Icons.BurgerMenu
                forceColor={theme.colors.white}
                size={24}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        position: 'absolute',
        left: theme.gap * 2,
        top: theme.gap * 2,
        cursor: 'pointer'
    }
}))
