import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useTranslations } from 'lib/hooks'
import { Typography, Touchable } from 'lib/components'
import { conditionalStyle, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { PaginationActions, PaginationState, PaginationSide } from 'lib/types'
import { getBothSidePages } from './utils'
import { SelectLimit } from './SelectLimit'

type PaginationProps = {
    paginationState: PaginationState,
    paginationActions: PaginationActions
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({
    paginationActions,
    paginationState
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    const getPages = () => {
        const currentPage = paginationState.currentPage
        const totalPages = paginationState.totalPages

        return [
            ...getBothSidePages(PaginationSide.Left, currentPage, totalPages),
            currentPage,
            ...getBothSidePages(PaginationSide.Right, currentPage, totalPages)
        ]
    }

    return (
        <View style={styles.container}>
            {paginationState.currentPage && (
                <React.Fragment>
                    <View style={styles.selectItemsPerPage}>
                        <Typography.Subheading>
                            {T.components.table.itemsPerPage}
                        </Typography.Subheading>
                        <SelectLimit
                            onLimitChange={paginationActions.setLimit}
                            currentLimit={paginationState.limit}
                            limits={paginationState.availableLimits}
                        />
                    </View>
                    <View style={styles.selectPage}>
                        <Touchable
                            style={styles.pageChangeIcon}
                            onPress={() => paginationActions.setPage(paginationState.currentPage - 1)}
                        >
                            <Icons.ArrowLeft
                                size={24}
                                forceColor={theme.colors.white}
                            />
                        </Touchable>
                        <View style={styles.pages}>
                            {getPages().map(page => (
                                <Typography.Regular
                                    key={page}
                                    onPress={() => paginationActions.setPage(page)}
                                    style={{
                                        ...styles.page,
                                        ...conditionalStyle(page === paginationState.currentPage, styles.activePageColor)
                                    }}
                                >
                                    {page}
                                </Typography.Regular>
                            ))}
                        </View>
                        {paginationState.currentPage <= paginationState.totalPages - 3 && (
                            <View style={styles.lastPage}>
                                <Typography.Regular style={styles.page}>
                                    ...
                                </Typography.Regular>
                                <Typography.Regular
                                    onPress={() => paginationActions.setPage(paginationState.totalPages)}
                                    style={styles.page}
                                >
                                    {paginationState.totalPages}
                                </Typography.Regular>
                            </View>
                        )}
                        <Touchable
                            style={styles.pageChangeIcon}
                            onPress={() => paginationActions.setPage(paginationState.currentPage + 1)}
                        >
                            <Icons.ArrowRight
                                size={24}
                                forceColor={theme.colors.white}
                            />
                        </Touchable>
                    </View>
                </React.Fragment>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.gap * 2
    },
    selectItemsPerPage: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2,
        alignItems: 'center'
    },
    selectPage: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.gap * 3
    },
    pageChangeIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: 50,
        backgroundColor: hexToRGBA(theme.colors.darkGray, 0.5)
    },
    activePageColor: {
        color: theme.colors.pink
    },
    page: {
        cursor: 'pointer',
        userSelect: 'none',
        fontSize: 22
    },
    pages: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    },
    lastPage: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    }
}))
