import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'

export const useGetGreeting = () => {
    const T = useTranslations()
    const hour = dateHelpers.getHourNow()

    if (hour >= 18 || hour < 5) {
        return `${T.screens.home.title.prefix} ${T.screens.home.title.evening}`
    }

    if (hour >= 12 && hour < 18) {
        return `${T.screens.home.title.prefix} ${T.screens.home.title.afternoon}`
    }

    return `${T.screens.home.title.prefix} ${T.screens.home.title.morning}`
}
