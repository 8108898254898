export const APP_CONFIG = {
    KEYS: {
        STORAGE: 'imrchnt_storage',
        APPLE_SCRIPT_ID: 'apple_sdk'
    },
    API: {
        REQUEST_TIMEOUT: 10000
    },
    URLS: {
        MICROSOFT_REDIRECT_URL: 'org.im.bookstore://org.im.bookstore/ios/callback',
        MICROSOFT_AUTH: 'https://login.microsoftonline.com/common/v2.0'
    }
}
