import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Cog: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 76.2 26.8 a 3.352 3.352 90 0 1 -0.944 3.66 L 68.76 36.32 a 28.12 28.12 90 0 1 0 7.56 l 6.5 5.864 a 3.376 3.376 90 0 1 0.944 3.66 a 37.72 37.72 90 0 1 -2.352 5.108 l -0.704 1.204 a 39.148 39.148 90 0 1 -3.32 4.66 a 3.44 3.44 90 0 1 -3.68 1.012 l -8.356 -2.648 a 32.904 32.904 90 0 1 -6.6 3.796 l -1.88 8.5 a 3.56 3.56 90 0 1 -2.728 2.648 a 39.496 39.496 90 0 1 -12.756 0 a 3.604 3.604 90 0 1 -2.724 -2.648 l -1.88 -8.5 a 29.556 29.556 90 0 1 -6.6 -3.796 l -8.36 2.648 a 3.456 3.456 90 0 1 -3.672 -1.012 a 39.064 39.064 90 0 1 -3.32 -4.66 l -0.704 -1.208 a 36.872 36.872 90 0 1 -2.368 -5.104 a 3.356 3.356 90 0 1 0.956 -3.66 l 6.488 -5.86 a 28.672 28.672 90 0 1 0 -7.564 L 5.152 30.46 A 3.372 3.372 90 0 1 4.2 26.8 c 0.656 -1.76 1.448 -3.468 2.364 -5.108 l 0.704 -1.2 c 0.988 -1.632 2.1 -3.188 3.32 -4.656 a 3.412 3.412 90 0 1 3.68 -1.016 l 8.348 2.644 a 28.92 28.92 90 0 1 6.6 -3.788 l 1.88 -8.5 a 3.368 3.368 90 0 1 2.732 -2.652 a 39.176 39.176 90 0 1 12.752 0 a 3.416 3.416 90 0 1 2.732 2.652 l 1.876 8.5 a 32.12 32.12 90 0 1 6.6 3.788 l 8.36 -2.64 a 3.44 3.44 90 0 1 3.676 1.012 c 1.22 1.468 2.332 3.024 3.32 4.652 l 0.704 1.204 c 0.912 1.64 1.7 3.348 2.352 5.104 Z m -36 25.212 a 12.144 12.144 90 0 0 6.72 -1.984 a 12 12 90 0 0 4.476 -5.348 a 11.888 11.888 90 0 0 -2.548 -13.064 a 12.164 12.164 90 0 0 -13.14 -2.668 a 12.052 12.052 90 0 0 -5.44 4.38 a 11.908 11.908 90 0 0 -1.192 11.24 a 11.932 11.932 90 0 0 6.504 6.52 c 1.464 0.608 3.04 0.92 4.624 0.924 Z"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
            />
        </Icon>
    )
}
