export const hexToRGBA = (hex: string, opacity: number) => hex
    .replace('#', '')
    .split(/(?=(?:..)*$)/)
    .map(x => parseInt(x, 16))
    .filter(num => !isNaN(num))
    .reduce((acc, color) => `${acc}${color},`, 'rgba(')
    .concat(`${opacity})`)

export const colors = {
    pink: '#E77C76',
    white: '#FFFFFF',
    almostWhite: '#F5F5F5',
    gray: '#555555',
    lighterGray: '#A0A0A0',
    lightGray: '#777777',
    darkGray: '#4F4F4F',
    littleMoreDarkerGray: '#232323',
    darkerGray: '#2D2D2D',
    almostBlack: '#1F1F1F',
    black: '#000000',
    transparent: 'transparent',
    green: '#07A35D',
    yellow: '#FFF6D2',
    orange: '#DD8510',
    red: '#E03C31',
    mixTransparent: hexToRGBA
}
