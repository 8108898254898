import React from 'react'
import { View } from 'react-native'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { SearchItem } from 'lib/models'
import { R } from 'lib/utils'
import { Typography } from 'lib/components'
import { Item } from './Item'

type SearchItemsProps = {
    items: Array<SearchItem>,
    emptyFilters?: boolean
}

export const SearchItems: React.FunctionComponent<SearchItemsProps> = ({
    items,
    emptyFilters
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.noResults}>
                {R.isEmpty(items) && (
                    <Typography.Regular forceColor={theme.colors.darkGray}>
                        {emptyFilters
                            ? T.components.table.noResults
                            : T.components.table.noMatchingResults
                        }
                    </Typography.Regular>
                )}
            </View>
            <View style={styles.items}>
                {items.map((item, index)=> (
                    <Item
                        key={index}
                        item={item}
                    />
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.gap,
        marginBottom: theme.gap * 3,
        display: {
            xs: 'none',
            sm: 'flex'
        },
        flexDirection: 'column',
        gap: theme.gap * 3,
        flex: 1,
        justifyContent: 'space-between',
        overflow: 'scroll',
        position: 'relative'
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: theme.gap * 3,
        minWidth: 756
    },
    noResults: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    }
}))
