import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import Animated, { FadeIn } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Grid, Touchable, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'

type UserTileProps = {
    title: string,
    primaryText?: string,
    secondaryText: string,
    isLoading?: boolean,
    onPress?: VoidFunction,
    onEditPress?: VoidFunction,
    onDeletePress?: VoidFunction
}

export const UserTile: React.FunctionComponent<UserTileProps> = ({
    title,
    primaryText,
    secondaryText,
    isLoading,
    onPress,
    onEditPress,
    onDeletePress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const hitSlops = {
        hitSlopTop: 10,
        hitSlopRight: 10,
        hitSlopBottom: 10,
        hitSlopLeft: 10
    }

    return (
        <Touchable
            onPress={onPress}
            style={styles.container}
        >
            <View style={styles.text}>
                <Typography.Subheading
                    numberOfLines={1}
                    style={styles.userName}
                >
                    {title}
                </Typography.Subheading>
                <Typography.Regular
                    numberOfLines={1}
                    style={styles.location}
                >
                    {primaryText ?? T.screens.manageUsers.storeNotAsigned}
                </Typography.Regular>
                <Typography.Regular
                    numberOfLines={1}
                    style={styles.email}
                >
                    {secondaryText}
                </Typography.Regular>
            </View>
            <Animated.View entering={FadeIn}>
                {isLoading ? (
                    <ActivityIndicator/>
                ) : (
                    <View style={styles.row}>
                        {onEditPress && (
                            <Touchable
                                disabled={isLoading}
                                onPress={onEditPress}
                                {...hitSlops}
                            >
                                <Icons.Pencil
                                    size={24}
                                    forceColor={theme.components.icon.primary}
                                />
                            </Touchable>
                        )}
                        {(onEditPress && onDeletePress) && (
                            <Grid.Gap gapRight={3}/>
                        )}
                        {onDeletePress && (
                            <Touchable
                                disabled={isLoading}
                                onPress={onDeletePress}
                                {...hitSlops}
                            >
                                <Icons.Bin
                                    size={24}
                                    forceColor={theme.components.icon.primary}
                                />
                            </Touchable>
                        )}
                    </View>
                )}
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(1.5),
        backgroundColor: theme.ui.foreground,
        borderRadius: theme.components.button.borderRadius
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    text: {
        // window_width - paddings - icons
        maxWidth: Measurements.WindowWidth - theme.utils.gap(6) - 100
    },
    userName: {
        lineHeight: 26,
        color: theme.components.typography.primary.color
    },
    location: {
        fontSize: 12,
        lineHeight: 22,
        fontWeight: '400',
        color: theme.ui.primary
    },
    email: {
        fontSize: 12,
        lineHeight: 22,
        fontWeight: '300',
        color: theme.components.typography.secondary.color
    }
}))
