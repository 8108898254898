import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { NewItemFormFields } from '../types'

export const useItemSetupForm = () => {
    const T = useTranslations()

    const bookCode = useField<string>({
        key: NewItemFormFields.BookCode,
        initialValue: '',
        label: T.forms.newItem.bookCode.label,
        placeholder: T.forms.newItem.bookCode.placeholder,
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.forms.newItem.bookCode.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const title = useField<string>({
        key: NewItemFormFields.Title,
        initialValue: '',
        label: T.forms.newItem.title.label,
        placeholder: T.forms.newItem.title.placeholder,
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.forms.newItem.title.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.newItem.title.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const author = useField<string>({
        key: NewItemFormFields.Author,
        initialValue: '',
        label: T.forms.newItem.author.label,
        placeholder: T.forms.newItem.author.placeholder,
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.forms.newItem.author.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.newItem.author.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const category = useField<string>({
        key: NewItemFormFields.Category,
        initialValue: '',
        label: T.forms.newItem.category.label,
        placeholder: T.forms.newItem.category.placeholder,
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.forms.newItem.category.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.newItem.category.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const sku = useField<string>({
        key: NewItemFormFields.SKU,
        initialValue: '',
        label: T.forms.newItem.sku.label,
        placeholder: T.forms.newItem.sku.placeholder,
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.forms.newItem.sku.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.newItem.sku.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const quantity = useField<string>({
        key: NewItemFormFields.Quantity,
        initialValue: '1',
        label: T.forms.newItem.quantity.label,
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.forms.newItem.quantity.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        bookCode,
        title,
        author,
        category,
        sku,
        quantity
    }
}
