import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Barcode: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 34.3802 16.6912 h -4.2112 c -0.5208 0 -0.9436 0.4522 -0.9436 1.0122 v 46.578 c 0 0.56 0.4228 1.0136 0.9436 1.0136 h 4.2112 c 0.5208 0 0.9422 -0.4536 0.9422 -1.0136 V 17.7034 c 0 -0.56 -0.4214 -1.0122 -0.9422 -1.0122 Z M 23.4056 16.6912 H 13.096 c -0.5208 0 -0.9422 0.4522 -0.9422 1.0122 v 46.578 c 0 0.56 0.4214 1.0136 0.9422 1.0136 h 10.3082 c 0.5208 0 0.9422 -0.4536 0.9422 -1.0136 V 17.7034 c 0 -0.56 -0.4214 -1.0122 -0.9422 -1.0122 Z M 44.1298 16.6912 h -4.2 c -0.525 0 -0.9492 0.4522 -0.9492 1.0122 v 46.578 c 0 0.56 0.4242 1.0136 0.9478 1.0136 h 4.2 c 0.525 0 0.9492 -0.4536 0.9492 -1.0136 V 17.7034 c 0 -0.56 -0.4242 -1.0122 -0.9478 -1.0122 Z M 58.7668 16.6912 h -7.868 c -0.5222 0 -0.9436 0.4522 -0.9436 1.0122 v 46.578 c 0 0.56 0.4214 1.0136 0.9436 1.0136 h 7.868 c 0.5208 0 0.9422 -0.4536 0.9422 -1.0136 V 17.7034 c 0 -0.56 -0.4214 -1.0122 -0.9422 -1.0122 Z M 67.3964 16.6912 h -1.96 c -0.469 0 -0.8498 0.4522 -0.8498 1.0122 v 46.578 c 0 0.56 0.3808 1.0136 0.8498 1.0136 h 1.96 c 0.469 0 0.8484 -0.4536 0.8484 -1.0136 V 17.7034 c 0 -0.56 -0.3794 -1.0122 -0.8484 -1.0122 Z M 11.038 9.4 H 4.3782 C 3.282 9.4 2.4 10.2974 2.4 11.4104 v 60.3778 C 2.4 72.904 3.282 73.8 4.3782 73.8 h 7.777 M 69.362 9.4 h 6.6598 C 77.118 9.4 78 10.2974 78 11.4104 v 60.3792 c 0 1.113 -0.882 2.0104 -1.9782 2.0104 h -7.777"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
