import React from 'react'
import { View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { isNative } from 'lib/common'
import { Typography } from 'lib/components'
import { regexes } from 'lib/utils'
import { GoBack } from './GoBack'

type NavigationHeaderProps = {
    hasMarginHorizontal?: boolean
}

export const NavigationHeader: React.FunctionComponent<NavigationHeaderProps> = ({ hasMarginHorizontal }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const route = useRoute()

    if (!isNative) {
        return null
    }

    return (
        <View
            style={{
                ...styles.container,
                ...conditionalStyle(Boolean(hasMarginHorizontal), styles.paddingHorizontal)
            }}
        >
            <GoBack backgroundStyle={styles.goBack} />
            <Typography.Heading>
                {T.titles[regexes.firstLetterLowercase(route.name)]}
            </Typography.Heading>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        marginBottom: theme.gap * 3,
        position: 'relative'
    },
    goBack: {
        top: 0,
        left: 0
    },
    paddingHorizontal: {
        marginHorizontal: theme.contentPaddingMobile
    }
}))
