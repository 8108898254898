import React from 'react'
import { KeyboardAvoidingView, View } from 'react-native'
import { gestureHandlerRootHOC, GestureDetector, GestureType } from 'react-native-gesture-handler'
import { Measurements } from 'lib/common'
import { createStyles, useStyles } from 'lib/styles'
import { Children, VoidFunction } from 'lib/types'
import { Touchable } from '../Touchable'

type ModalContentComponentProps = {
    draggable: boolean,
    dragDown: GestureType,
    children: Children,
    onBackgroundPress: VoidFunction
}

export const ModalContent = gestureHandlerRootHOC<ModalContentComponentProps>(({
    children,
    draggable,
    dragDown,
    onBackgroundPress
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <Touchable
                onPress={onBackgroundPress}
                style={styles.touchableContainer}
            />
            <KeyboardAvoidingView
                behavior="padding"
                style={styles.contentContainer}
                onStartShouldSetResponder={() => true} // required (on Android) for scrollview inside
            >
                {draggable && (
                    <GestureDetector gesture={dragDown}>
                        <View style={styles.handlerContainer}>
                            <View style={styles.handler} />
                        </View>
                    </GestureDetector>
                )}
                {children}
            </KeyboardAvoidingView>
        </React.Fragment>
    )
})

const stylesheet = createStyles(theme => ({
    touchableContainer: {
        flex: 1
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'flex-end'
    },
    handlerContainer: {
        height: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.ui.background,
        borderTopLeftRadius: theme.utils.gap(2),
        borderTopRightRadius: theme.utils.gap(2)
    },
    handler: {
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.ui.foreground,
        width: Measurements.WindowWidth / 3
    }
}))
