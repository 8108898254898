import React, { useEffect } from 'react'
import Animated, { useSharedValue, useAnimatedStyle, withTiming, withDelay } from 'react-native-reanimated'
import { Icons } from 'assets'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { AppName } from 'lib/components'
import { useWelcomeAnimation } from 'lib/atoms'

export const WelcomeAnimation: React.FunctionComponent = () => {
    const opacity = useSharedValue(1)
    const scale = useSharedValue(1)
    const { styles } = useStyles(stylesheet)
    const [seenWelcomeAnimation, setWelcomeAnimation] = useWelcomeAnimation()
    const animatedContainerStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
        transform: [{
            scale: scale.value
        }]
    }))

    useEffect(() => {
        if (!seenWelcomeAnimation) {
            scale.value = withDelay(
                1000,
                withTiming(2.5, { duration: 400 })
            )
            opacity.value = withDelay(
                1000,
                withTiming(0, { duration: 400 }, () => setWelcomeAnimation(true))
            )
        }
    }, [])

    return (
        <Animated.View
            style={[
                styles.container,
                animatedContainerStyle,
                conditionalStyle(seenWelcomeAnimation, styles.disable)
            ]}
        >
            <Icons.Logo size={128} />
            <AppName style={styles.text} />
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        inset: 0,
        backgroundColor: theme.ui.background,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.gap * 2
    },
    disable: {
        display: 'none'
    },
    text: {
        fontSize: 64
    }
}))
