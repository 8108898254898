import { TextInputProps, View, ViewStyle } from 'react-native'
import Animated, { Easing, FadeInDown, withTiming } from 'react-native-reanimated'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import React, { useEffect, useMemo, useState } from 'react'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { KeyValuePair } from 'lib/types'
import { R } from 'lib/utils'
import { Icons } from 'assets'
import { Measurements, isAndroid, isWeb } from 'lib/common'
import { Touchable, Typography } from 'lib/components'

type InputProps = {
    label?: string,
    errorMessage?: string,
    hasValue: boolean,
    clearDisabled?: boolean,
    labelStyle?: ViewStyle,
    inputProps: TextInputProps,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    onDebounceEnd?(inputValue: string): void
}

export const useTextInput = ({
    inputProps,
    errorMessage,
    hasValue,
    labelStyle,
    clearDisabled,
    onDebounceEnd,
    leftIcon,
    rightIcon,
    label
}: InputProps) => {
    const { styles, theme } = useStyles(stylesheet)
    const [isLabelVisible, setLabelVisibility] = useState(false)
    const [shouldRenderCloseButton, setRenderCloseButton] = useState((inputProps.value && isWeb) ? true : false)
    const inputStream$ = useMemo(() => onDebounceEnd && new Subject(), [onDebounceEnd])
    const isClearVisible = shouldRenderCloseButton && !(inputProps.editable === false) && !clearDisabled
    const multilineStyles = inputProps.multiline
        ? styles.multiline
        : {}
    useEffect(() => {
        const subscribe = inputStream$?.pipe(
            debounceTime(500)
        ).subscribe(value => {
            R.ifDefined(onDebounceEnd, fn => fn(value))
        })

        return () => {
            subscribe?.unsubscribe()
        }
    }, [onDebounceEnd, inputStream$])

    const customExitingAnimation = values => {
        'worklet'
        const animations = {
            originY: withTiming(
                Number(values.currentOriginY) + 24,
                {
                    duration: 300,
                    easing: Easing.out(Easing.cubic)
                }),
            opacity: withTiming(0, { duration: 150 })
        }
        const initialValues = {
            originY: values.currentOriginY,
            opacity: 1
        }

        return {
            initialValues,
            animations
        }
    }

    const getInputColor = R.cond([
        [R.always(!inputProps.editable), R.always(theme.colors.lighterGray)],
        [R.T, R.always(theme.components.input.typography.text)]
    ])

    return {
        styles,
        label,
        errorMessage,
        inputProps: {
            ...inputProps,
            underlineColorAndroid: theme.ui.transparent,
            placeholderTextColor: theme.components.input.typography.placeholder,
            style: {
                ...styles.input,
                ...multilineStyles,
                borderColor: errorMessage
                    ? theme.components.input.error.borderColor
                    : theme.components.input.borderColor,
                color: errorMessage
                    ? theme.components.input.typography.error
                    : getInputColor(),
                backgroundColor: inputProps.editable
                    ? theme.components.input.backgroundColor
                    : theme.ui.foreground,
                paddingRight: (rightIcon ? 50 : 20) + (clearDisabled ? 0 : 20),
                paddingLeft: leftIcon ? 50 : 20,
                paddingTop: label && (isLabelVisible || hasValue) ? (isAndroid ? 18 : 16) : theme.gap * (isAndroid ? 2 : 0),
                paddingBottom: isAndroid ?
                    label && (isLabelVisible || hasValue) ? 14 : theme.gap * 2
                    : undefined,
                ...(inputProps.style as KeyValuePair) || {}
            },
            clearButtonMode: 'never',
            allowFontScaling: false,
            onChangeText: text => {
                inputStream$?.next(text)
                setRenderCloseButton(R.all(
                    !R.isDefined(inputProps.editable) || Boolean(inputProps.editable),
                    text.length > 0
                ))
                setLabelVisibility(text.length > 0)
                R.ifDefined(inputProps.onChangeText, onChangeText => onChangeText(text))
            },
            onSubmitEditing: () => {
                R.ifDefined(inputProps.onSubmitEditing, fn => fn(inputProps.value))
            },
            onFocus: event => {
                if (inputProps.value && inputProps.value.length > 0) {
                    setRenderCloseButton(true)
                }

                R.ifDefined(inputProps.onFocus, onFocus => onFocus(event))
            },
            onBlur: event => {
                R.ifDefined(inputProps.onBlur, onBlur => onBlur(event))

                if (!inputProps.value) {
                    setLabelVisibility(false)
                }
            }
        },
        renderLeftIcon: () => {
            if (leftIcon) {
                return (
                    <View style={styles.leftIconWrapper}>
                        <View style={styles.leftIcon}>
                            {leftIcon}
                        </View>
                    </View>
                )
            }

            return null
        },
        renderRightIcon: () => {
            if (rightIcon) {
                return (
                    <View style={styles.rightIconWrapper(isClearVisible)}>
                        <View style={styles.leftIcon}>
                            {rightIcon}
                        </View>
                    </View>
                )
            }

            return null
        },
        renderClearButton: () => {
            if (isClearVisible) {
                return (
                    <Touchable
                        focusable={false}
                        style={
                            inputProps.multiline
                                ? {
                                    ...styles.clearIcon,
                                    ...styles.multiLineHeight
                                }
                                : {
                                    ...styles.clearIcon
                                }
                        }
                        onPress={() => {
                            setRenderCloseButton(false)
                            inputStream$?.next('')
                            R.ifDefined(inputProps.onChangeText, onChangeText => onChangeText(''))
                        }}
                    >
                        <View style={styles.clearIconInnerView}>
                            <Icons.Close
                                size={7}
                                forceColor={theme.components.input.backgroundColor}
                            />
                        </View>
                    </Touchable>
                )
            }

            return null
        },
        renderLabel: () => label && (isLabelVisible || hasValue)
            ? (
                <Animated.View
                    entering={FadeInDown}
                    exiting={customExitingAnimation}
                    style={{
                        ...styles.label,
                        ...(leftIcon ? styles.labelWithLeftIcon : {}),
                        ...conditionalStyle(Boolean(labelStyle), labelStyle || {})
                    }}
                >
                    <Typography.Label numberOfLines={1}>
                        {label}
                    </Typography.Label>
                </Animated.View>
            )
            : null
    }
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%'
    },
    leftIconWrapper: {
        position: 'absolute',
        left: theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19,
        zIndex: 999
    },
    rightIconWrapper: (withClearButton: boolean) => ({
        position: 'absolute',
        right: withClearButton
            ? theme.utils.gap(4)
            : theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19,
        zIndex: 999
    }),
    leftIcon: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1)
    },
    input: {
        paddingLeft: theme.utils.gap(2),
        height: isAndroid ? undefined : theme.components.input.height,
        width: '100%',
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        borderColor: theme.components.input.borderColor,
        paddingHorizontal: theme.components.input.paddingHorizontal,
        textAlignVertical: 'center'
    },
    clearIcon: {
        position: 'absolute',
        right: 5,
        paddingHorizontal: theme.utils.gap(1),
        height: theme.components.input.height,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 19
    },
    multiLineHeight: {
        height: 170
    },
    multiline: {
        height: 170,
        textAlignVertical: 'top',
        borderRadius: theme.components.input.borderRadius,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(2)
    },
    clearIconInnerView: {
        width: 15,
        height: 15,
        borderRadius: theme.utils.gap(1),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.icon.selected
    },
    viewPasswordIcon: {
        position: 'absolute',
        right: 35,
        top: 3,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    label: {
        position: 'absolute',
        top: theme.gap,
        left: 20,
        width: Measurements.WindowWidth - theme.gap * 8 - 100,
        flex: 1,
        zIndex: 999
    },
    labelWithLeftIcon: {
        left: 50
    }
}))
