import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const ArrowRight: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M31.5735 54.891C30.5928 55.863 30.5858 57.4459 31.5577 58.4265C32.5296 59.4072 34.1125 59.4143 35.0932 58.4423L41.0756 52.513C43.3289 50.2799 45.1705 48.4547 46.4765 46.8285C47.8346 45.1374 48.8177 43.4209 49.0803 41.3606C49.1955 40.4571 49.1955 39.5429 49.0803 38.6395C48.8177 36.5791 47.8346 34.8626 46.4765 33.1715C45.1705 31.5453 43.3289 29.7202 41.0757 27.4871L35.0932 21.5577C34.1125 20.5858 32.5297 20.5928 31.5577 21.5735C30.5858 22.5541 30.5928 24.137 31.5735 25.109L37.45 30.9333C39.835 33.2971 41.471 34.9239 42.578 36.3024C43.6534 37.6414 44.0223 38.5014 44.1204 39.2717C44.1821 39.7553 44.1821 40.2447 44.1204 40.7284C44.0223 41.4986 43.6534 42.3587 42.578 43.6977C41.471 45.0761 39.835 46.7029 37.45 49.0667L31.5735 54.891Z"
        />
    </Icon>
)
