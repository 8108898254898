import { parseISO, fromUnixTime, getHours, format, getUnixTime, formatDistanceToNow } from 'date-fns'
import { is } from './ramda'

type AnyDate = Date | number | string

export const toDateFnsDate = (date: AnyDate) => {
    const formattedDate = is(String, date)
        ? parseISO(date as string)
        : fromUnixTime(date as number)

    return formattedDate as Date
}

export const getHourNow = () => getHours(new Date())
export const toUnixScanDate = (date: number) => format(fromUnixTime(date), 'do LLL yyy')
export const nowUnix = () => getUnixTime(new Date())
export const toDistanceToNowUnix = (date: number) => formatDistanceToNow(fromUnixTime(date), { addSuffix: true })
export const toCSVFileDate = (date: number) => format(fromUnixTime(date), 'dd-LL-yyyy HH:mm:ss')
