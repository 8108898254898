import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Adapter, Button, Grid, Typography } from 'lib/components'
import { isWeb } from 'lib/common'
import { AddEditUserShape } from '../forms'

type EditPasswordModalProps = {
    onPasswordChange: VoidFunction,
    onClose: VoidFunction,
    isLoading?: boolean,
    form: AddEditUserShape
}

export const EditPasswordModal: React.FunctionComponent<EditPasswordModalProps> = ({
    onPasswordChange,
    onClose,
    isLoading,
    form
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <Typography.Heading style={styles.headingText}>
                    {T.screens.adminSetup.changePasswordModal.title}
                </Typography.Heading>
                <Typography.Subheading style={styles.message}>
                    {T.screens.resetPassword.message}
                </Typography.Subheading>
                <Grid.Gap gapBottom={2}/>

                <View style={styles.formRow}>
                    <Adapter.TextInput
                        {...form.password}
                        style={styles.textInput}
                        isPassword
                        leftIcon={(
                            <Icons.User size={24}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={24}/>
                        )}
                    />
                    <Adapter.TextInput
                        {...form.confirmPassword}
                        style={styles.textInput}
                        isPassword
                        leftIcon={(
                            <Icons.Envelope size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={24}/>
                        )}
                    />
                </View>
                <View
                    style={{
                        ...styles.formRow,
                        ...styles.buttonsGap,
                        ...conditionalStyle(isWeb, styles.reverseButtons)
                    }}
                >
                    <Button
                        isLoading={isLoading}
                        text={T.common.save}
                        onPress={onPasswordChange}
                        customStyle={conditionalStyle(isWeb, styles.textInput)}
                    />
                    <Button
                        noBackground
                        disabled={isLoading}
                        text={T.common.cancel}
                        onPress={onClose}
                        customStyle={conditionalStyle(isWeb, styles.textInput)}
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    formRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: {
            xs: 0,
            sm: theme.gap * 3
        }
    },
    textInput: {
        flexShrink: 1
    },
    buttonsGap: {
        gap: theme.gap * 2
    },
    container: {
        width: '100%',
        padding: {
            sm: theme.gap * 4
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.gap * 2,
        minWidth: {
            xs: 0,
            sm: 420
        },
        maxWidth: 564
    },
    headingText: {
        textAlign: 'center'
    },
    message: {
        textAlign: 'center',
        paddingHorizontal: theme.gap * 2
    },
    reverseButtons: {
        flexDirection: 'row-reverse'
    }
}))
