import React from 'react'
import { ActivityIndicator, TextStyle, View, ViewStyle } from 'react-native'
import { Grid } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { Touchable } from './Touchable'
import { Regular } from './typography'
import { Children, VoidFunction } from '../types'

type ButtonProps = {
    isPlain?: boolean,
    disabled?: boolean,
    customStyleText?: TextStyle,
    customStyle?: ViewStyle,
    text: string,
    onPress: VoidFunction,
    noBackground?: boolean,
    width?: number,
    height?: number,
    isLoading?: boolean,
    renderIcon?: (color: string) => Children
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    text,
    disabled,
    onPress,
    noBackground,
    width = '100%' as const,
    height,
    isLoading = false,
    renderIcon,
    isPlain,
    customStyleText,
    customStyle
}) => {
    const getButtonStyles = () => {
        if (noBackground) {
            return {
                buttonStyles: theme.components.button.outline,
                textStyles: theme.components.button.outline.typography
            }
        }

        if (isPlain) {
            return {
                buttonStyles: theme.components.button.plain,
                textStyles: theme.components.button.plain.typography
            }
        }

        return {
            buttonStyles: {
                ...theme.components.button.filled,
                ...disabledStyles
            },
            textStyles: theme.components.button.filled.typography
        }
    }

    const { styles, theme } = useStyles(stylesheet)
    const disabledStyles = disabled
        ? theme.components.button.disabled
        : {}
    const { buttonStyles, textStyles } = getButtonStyles()
    const icon = renderIcon
        ? renderIcon(textStyles.color)
        : null

    return (
        <Touchable
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                ...styles.button,
                ...buttonStyles,
                width,
                height: height ?? theme.components.button.height,
                ...customStyle
            }}
        >
            {isLoading ? (
                <ActivityIndicator
                    size="small"
                    color={isPlain
                        ? theme.components.indicator.color
                        : theme.colors.white
                    }
                />
            ) : (
                <View style={styles.textContainer}>
                    {icon}
                    {Boolean(icon) && (
                        <Grid.Gap gapRight={2}/>
                    )}
                    <View>
                        <Regular
                            style={{
                                ...textStyles,
                                ...styles.text,
                                ...customStyleText
                            }}
                        >
                            {text}
                        </Regular>
                    </View>
                </View>
            )}
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        padding: theme.components.button.padding,
        borderRadius: theme.components.button.borderRadius,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainer: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontSize: 21,
        lineHeight: 24,
        fontWeight: '700',
        userSelect: 'none'
    }
}))
