import React, { useState } from 'react'
import { ActivityIndicator, TextStyle } from 'react-native'
import { Icons } from 'assets'
import { AnimatedModal, FormComponents, Touchable } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { R } from 'lib/utils'

type DropdownModalProps = {
    isLoading?: boolean,
    currentText: string,
    textStyle?: TextStyle,
    errorMessage?: string,
    disabled?: boolean,
    placeholder?: string,
    label?: string,
    leftIcon?: JSX.Element,
    onToggle?: VoidFunction,
    modalContent(onModalClose: VoidFunction): JSX.Element
}

export const DropdownModal: React.FunctionComponent<DropdownModalProps> = ({
    isLoading,
    currentText,
    textStyle,
    errorMessage,
    disabled,
    placeholder,
    label ,
    leftIcon,
    onToggle,
    modalContent
}) => {
    const { styles } = useStyles(stylesheet)
    const [isModalVisible, setModalVisibility] = useState(false)
    const onPress = () => {
        if (!isLoading) {
            R.ifDefined(onToggle, R.call)
            setModalVisibility(true)
        }
    }

    return (
        <React.Fragment>
            <Touchable
                disabled={disabled || isLoading}
                onPress={onPress}
            >
                <FormComponents.Input
                    onPress={onPress}
                    rightIcon={isLoading ? (
                        <ActivityIndicator/>
                    ) : (
                        <Icons.ArrowDown
                            size={24}
                        />
                    )}
                    inputProps={{
                        value: currentText,
                        style: {
                            ...styles.text,
                            ...textStyle
                        },
                        editable: false,
                        placeholder
                    }}
                    label={label}
                    leftIcon={leftIcon}
                    errorMessage={errorMessage}
                />
            </Touchable>
            <AnimatedModal
                draggable
                isVisible={isModalVisible}
                onClose={() => {
                    R.ifDefined(onToggle, R.call)
                    setModalVisibility(false)
                }}
            >
                {({ onModalClose }) => modalContent(onModalClose)}
            </AnimatedModal>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginVertical: theme.utils.gap(1)
    },
    text: {
        opacity: 1,
        backgroundColor: theme.components.input.backgroundColor
    }
}))
