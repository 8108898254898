import Toast from 'react-native-toast-message'
import { useToast } from 'lib/hooks'

export const ToastComponent = () => {
    const { state: { config: toastConfig } } = useToast()

    return (
        <Toast config={toastConfig} />
    )
}
