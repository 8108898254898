import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Author: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 42.9624 49.4528 c 12.6 -12.114 14.4324 -25.9704 9.8028 -45.3528 C 22.698 20.4656 16.9416 44.5352 28.44 53.4848 l -8.6868 17.5644 M 17.1 76.1 h 50.4 M 36.504 37.58 L 27.9 54.5756"
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
