import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, RefreshControl, View, useWindowDimensions } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { isNative, isWeb } from 'lib/common'
import { Button, SearchInput, Typography, Table, ConfirmModal } from 'lib/components'
import { useIsWithinBreakpoints, usePagination, useToast, useTranslations } from 'lib/hooks'
import { QueryKey, queryClient } from 'lib/api'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, conditionalStyle, Breakpoint, useStyles } from 'lib/styles'
import { R, inputProps } from 'lib/utils'
import { Action, TableAction, TooltipPlacement } from 'lib/types'
import { useDeleteUser, useGetUsers, useGetUsersInfinity } from '../actions'
import { UserTile } from '../components'
import { getManageUsersTableData, getLocation } from '../utils'
import { manageUsersTableHeaders } from '../mocks'
import { ManageUsersTableItem } from '../types'

type ManageUsersProps = {
    query?: string,
    setQuery: (query: string) => void
}

export const ManageUsers: React.FunctionComponent<ManageUsersProps> = ({
    query,
    setQuery
}) => {
    const T = useTranslations()
    const isFocused = useIsFocused()
    const { actions: { showErrorToast } } = useToast()
    const [totalUsersCount, setTotalUsersCount] = useState(0)
    const [userToDeleteUUID, setUserToDeleteUUID] = useState('')
    const isMobileWidth = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const isSmallerView = useWindowDimensions().width < 1240
    const { styles, theme } = useStyles(stylesheet)
    const { mutate: deleteUser, isLoading: isDeleting } = useDeleteUser()
    const navigation = useNavigation<NavigationProps<ScreenNames>>()
    const pagination = usePagination(totalUsersCount)
    const isTableFetchingDisabled = isNative || isMobileWidth
    const isInfinityScrollFetchingDisabled = !isMobileWidth
    const {
        data: infinityScrollData,
        fetchNextPage,
        hasNextPage,
        isRefetching,
        isFetching: isInfinityScrollFetching,
        refetch: refetchInfinityScroll
    } = useGetUsersInfinity(query, isInfinityScrollFetchingDisabled)
    const {
        isFetching: isTableDataFetching,
        data: tableData,
        refetch: refetchTableData
    } = useGetUsers(query, pagination?.state.offset, pagination?.state.limit, isTableFetchingDisabled)
    const tabelUsers = tableData?.data.users || []
    const infinityScrollUsers = infinityScrollData?.pages.flatMap(page => page.data.users) || []

    useEffect(() => {
        if (tableData?.data.totalCount) {
            setTotalUsersCount(tableData.data.totalCount)
        }
    }, [tableData])

    const onDeleteUser = () => deleteUser(
        {
            userUUID: userToDeleteUUID
        },
        {
            onSuccess: async () => {
                if (isNative) {
                    await refetchInfinityScroll()
                }

                if (!isNative) {
                    await refetchTableData()
                }

                setUserToDeleteUUID('')

                queryClient.invalidateQueries([QueryKey.Users])
            },
            onError: () => showErrorToast(T.alerts.oops)
        }
    )

    const tableActions = [
        {
            key: TableAction.Edit,
            label: T.components.table.actions.edit,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Pencil
                    size={28}
                    forceColor={theme.colors.white}
                />
            ),
            action: params => navigation.navigate(ScreenNames.EditUser, {
                ...params,
                isActive: true
            })
        },
        {
            key: TableAction.Remove,
            label: T.components.table.actions.remove,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Bin
                    size={24}
                    forceColor={theme.colors.white}
                />
            ),
            action: params => setUserToDeleteUUID(params.userUUID)
        }
    ] satisfies Array<Action<ManageUsersTableItem>>

    return (
        <React.Fragment>
            <View style={styles.actions}>
                <View style={styles.searchInputWrapper}>
                    <SearchInput
                        placeholder={T.screens.manageUsers.searchPlaceholder}
                        inputProps={inputProps.name}
                        onDebouncedQuery={setQuery}
                        rightIcon={(
                            <Icons.Search size={22}/>
                        )}
                    />
                </View>
                {isWeb && (
                    <Button
                        customStyle={styles.addUserButtonWeb}
                        isLoading={isDeleting}
                        text={T.screens.manageUsers.addUserButton}
                        onPress={() => navigation.navigate(ScreenNames.AddUser)}
                    />
                )}
            </View>
            {isWeb && (
                <Table
                    columns={manageUsersTableHeaders(isSmallerView)}
                    data={getManageUsersTableData(tabelUsers)}
                    actions={tableActions}
                    style={styles.table}
                    isLoading={isTableDataFetching || isDeleting}
                    pagination={pagination}
                    cellStyle={styles.tableCell}
                    rowStyle={styles.tableRow}
                />
            )}
            {isMobileWidth && (
                <FlatList
                    data={infinityScrollUsers}
                    style={{
                        ...styles.list,
                        ...conditionalStyle(isWeb, styles.disableListPadding)
                    }}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={userItem => userItem.userUUID}
                    renderItem={({ item }) => {
                        const [firstStore] = item.userStores || []
                        const otherStores = item.userStores?.length > 1
                            ? ` +(${item.userStores?.length - 1})`
                            : ''

                        return (
                            <UserTile
                                title={item.fullName}
                                primaryText={getLocation(firstStore, otherStores)}
                                secondaryText={item.email}
                                onEditPress={() => navigation.navigate(ScreenNames.EditUser, { ...item })}
                                onDeletePress={() => setUserToDeleteUUID(item.userUUID)}
                            />
                        )
                    }}
                    refreshControl={(
                        <RefreshControl
                            refreshing={isInfinityScrollFetching && isFocused}
                            onRefresh={isInfinityScrollFetching
                                ? R.T
                                : () => refetchInfinityScroll()
                            }
                            colors={[theme.ui.primary]}
                            tintColor={theme.ui.primary}
                            progressViewOffset={theme.gap}
                        />
                    )}
                    onEndReached={() => {
                        if (hasNextPage && !isInfinityScrollFetching) {
                            fetchNextPage()
                        }
                    }}
                    ListEmptyComponent={!isInfinityScrollFetching ? (
                        <Typography.Subheading style={styles.text}>
                            {query
                                ? T.screens.manageUsers.noMatchingUsers
                                : T.screens.manageUsers.noUsersMessage
                            }
                        </Typography.Subheading>
                    ) : undefined}
                    ListFooterComponent={(isInfinityScrollFetching && infinityScrollUsers?.length > 10) ? (
                        <ActivityIndicator />
                    ) : undefined}
                />
            )}
            <Button
                isLoading={isDeleting}
                text={T.screens.manageUsers.addUserButton}
                onPress={() => navigation.navigate(ScreenNames.AddUser)}
                customStyle={{
                    ...styles.addUserButtonNative,
                    ...conditionalStyle(isNative, styles.addStoreButtonNative)
                }}
            />
            <ConfirmModal
                isVisible={Boolean(userToDeleteUUID)}
                message={T.screens.manageUsers.areYouSureToDelete}
                onClose={() => setUserToDeleteUUID('')}
                isLoading={isDeleting || isRefetching}
                onConfirm={onDeleteUser}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    searchWrapperContainer: {
        alignItems: 'center',
        flexGrow: 0
    },
    searchWrapper: {
        width: '100%',
        flexGrow: 0
    },
    list: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        flex: 1
    },
    table: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        marginTop: theme.gap
    },
    emptyList: {
        flex: 1,
        justifyContent: 'center'
    },
    listWrapper: {
        width: '100%'
    },
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(3)
    },
    header: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        alignItems: 'center',
        paddingBottom: theme.gap * 2
    },
    actions: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.gap * 2
    },
    searchInputWrapper: {
        width: {
            xs: '100%',
            sm: 328
        }
    },
    addUserButtonWeb: {
        display: {
            xs: 'none',
            sm: 'flex'
        },
        width: 220
    },
    addUserButtonNative: {
        display: {
            xs: 'flex',
            sm: 'none'
        },
        marginTop: theme.gap * 4
    },
    disableListPadding: {
        paddingBottom: 0,
        paddingTop: 0
    },
    tableCell: {
        lineHeight: 24
    },
    addStoreButtonNative: {
        marginBottom: theme.gap * 4
    },
    tableRow: {
        width: {
            ':w[, 1100]': 'calc(100% + 100px)'
        }
    }
}))
