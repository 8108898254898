import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Grid } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { Children } from 'lib/types'
import { useGetScannerDimensions } from '../hooks'

type ScannerBoxProps = {
    children: Children
}

export const ScannerBox: React.FunctionComponent<ScannerBoxProps> = ({ children }) => {
    const { styles } = useStyles(stylesheet)
    const { scannerWidth, scannerHeight } = useGetScannerDimensions()
    // outside of styles, using stylesheet makes undefined out of transform array
    const rotateCorner = (degrees: number) => ({
        transform: [
            {
                rotateZ: `${degrees}deg`
            }
        ]
    })

    return (
        <Grid.Gap
            style={{
                ...styles.box,
                width: scannerWidth + 4, // adding corners border width
                height: scannerHeight + 4
            }}
        >
            <View
                style={{
                    ...styles.topLeft,
                    ...styles.icon
                }}
            >
                <Icons.BoxCorner size={60}/>
            </View>
            <View
                style={{
                    ...styles.topRight,
                    ...styles.icon,
                    ...rotateCorner(90)
                }}
            >
                <Icons.BoxCorner size={60}/>
            </View>
            <View
                style={{
                    ...styles.bottomRight,
                    ...styles.icon,
                    ...rotateCorner(180)
                }}
            >
                <Icons.BoxCorner size={60}/>
            </View>
            <View
                style={{
                    ...styles.bottomLeft,
                    ...styles.icon,
                    ...rotateCorner(270)
                }}
            >
                <Icons.BoxCorner size={60}/>
            </View>
            {children}
        </Grid.Gap>
    )
}

const stylesheet = createStyles(theme => ({
    box: {
        width: Measurements.WindowWidth - theme.utils.gap(6),
        aspectRatio: 1.25,
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        position: 'absolute',
        zIndex: 101
    },
    topLeft: {
        top: 0,
        left: 0
    },
    topRight: {
        top: 0,
        right: 0
    },
    bottomRight: {
        bottom: 0,
        right: 0
    },
    bottomLeft: {
        bottom: 0,
        left: 0
    }
}))
