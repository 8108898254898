import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Store: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 59.709 72 h 6.741 a 5.4698 5.4698 90 0 0 5.453 -5.453 V 34.186 H 8.497 v 32.3498 a 5.4698 5.4698 90 0 0 5.453 5.453 h 28.7 c 0.5978 0 1.0878 -0.4886 1.0878 -1.0878 V 48.3232 c 0 -0.854 0.7 -1.554 1.554 -1.554 h 11.13 c 0.854 0 1.5428 0.6874 1.554 1.5428 l 0.1554 22.589 c 0 0.5992 0.4886 1.078 1.0878 1.078 l 0.49 0.021 Z"
                />
                <Path
                    d="M 76.8002 25.6474 H 3.5998 c -0.6622 0 -1.1998 0.4858 -1.1998 1.085 v 6.37 c 0 0.5978 0.5376 1.0836 1.1998 1.0836 h 73.2004 c 0.6622 0 1.1998 -0.4858 1.1998 -1.085 v -6.3686 c 0 -0.5992 -0.5376 -1.085 -1.1998 -1.085 Z"
                />
                <Path
                    d="M 77.8572 26.2578 L 67.402 11.324 A 2.17 2.17 90 0 0 65.6156 10.4 H 14.8124 c -0.6972 0 -1.358 0.3332 -1.764 0.8904 L 2.4 26.2564 M 33.1958 46.3842 h -11.5976 c -0.5012 0 -0.9072 0.448 -0.9072 0.9982 v 10.2018 c 0 0.5502 0.406 0.9982 0.9072 0.9982 h 11.5976 c 0.5012 0 0.9072 -0.448 0.9072 -0.9982 v -10.2018 c 0 -0.5516 -0.406 -0.9982 -0.9072 -0.9982 Z"
                />
            </G>
        </Icon>
    )
}
