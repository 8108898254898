import { TextStyle, ViewStyle } from 'react-native'
import { Breakpoint, breakpoints } from './breakpoints'

export const conditionalStyle = (condition: boolean, style: ViewStyle & TextStyle) => condition ? style : {}

export const getBreakpointFromScreenWidth = (width: number): Breakpoint => {
    if (width >= breakpoints.xs && width < breakpoints.sm) {
        return Breakpoint.XS
    }

    if (width >= breakpoints.sm && width < breakpoints.md) {
        return Breakpoint.SM
    }

    if (width >= breakpoints.md && width < breakpoints.lg) {
        return Breakpoint.MD
    }

    if (width >= breakpoints.lg && width < breakpoints.xl) {
        return Breakpoint.LG
    }

    return Breakpoint.XL
}
