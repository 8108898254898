import React from 'react'
import { Icons } from 'assets'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useScannedItemsAtom } from 'lib/atoms'
import { isAndroid, masks } from 'lib/common'
import { Adapter, BlurModal, Button, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { useEditItemForm } from '../forms'

type EditItemModalScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.EditItemModal>
}

export const EditItemModalScreen: React.FunctionComponent<EditItemModalScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const [, setScannedItems] = useScannedItemsAtom()
    const { form, submit } = useForm(useEditItemForm(route.params.item), {
        onSuccess: formData => {
            setScannedItems(prevState => prevState.map(scannedItem => scannedItem.bookCode !== route.params.item.bookCode
                ? scannedItem
                : {
                    ...scannedItem,
                    ...formData,
                    quantity: Number(formData.quantity)
                }
            ))
            navigation.goBack()
        }
    })

    return (
        <BlurModal
            isVisible
            onClose={navigation.goBack}
            enableKeyboardAvoiding={!isAndroid}
        >
            <Typography.Heading>
                {T.screens.editItem.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={2}/>
            <Typography.Subheading>
                {T.screens.editItem.message}
            </Typography.Subheading>
            <Grid.Gap gapBottom={3}/>
            <Adapter.TextInput
                {...form.bookCode}
                leftIcon={(
                    <Icons.Sku size={24}/>
                )}
            />
            <Grid.Gap gapBottom={3}/>
            <Adapter.TextInput
                mask={masks.quantity}
                {...form.quantity}
                inputProps={{
                    keyboardType: 'numeric'
                }}
                leftIcon={(
                    <Icons.Quantity size={24}/>
                )}
            />
            <Grid.Gap gapBottom={4}/>
            <Button
                text={T.common.confirm}
                onPress={submit}
            />
            <Grid.Gap gapBottom={3}/>
            <Button
                noBackground
                text={T.common.cancel}
                onPress={navigation.goBack}
            />
        </BlurModal>
    )
}
