import React, { useEffect } from 'react'
import { View } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { useGoBack, useToast, useTranslations } from 'lib/hooks'
import { Adapter, Button, Grid, KeyboardAccessoryFieldProps } from 'lib/components'
import { ScreenNames, ItemEditParams } from 'lib/routing'
import { isNative, isWeb } from 'lib/common'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { QueryKey, queryClient } from 'lib/api'
import { useItemSetupForm } from '../forms'
import { useAddNewItem, useGetStoreItems } from '../actions'
import { NewItemFormFields } from '../types'

type ItemSetupProps = {
    storeUUID: string,
    itemUUID?: string,
    bookCodeProps?: KeyboardAccessoryFieldProps,
    titleProps?: KeyboardAccessoryFieldProps,
    authorProps?: KeyboardAccessoryFieldProps,
    categoryProps?: KeyboardAccessoryFieldProps,
    skuProps?: KeyboardAccessoryFieldProps,
    quantityProps?: KeyboardAccessoryFieldProps
}

export const ItemSetup: React.FunctionComponent<ItemSetupProps> = ({
    bookCodeProps,
    titleProps,
    authorProps,
    categoryProps,
    skuProps,
    quantityProps
}) => {
    const { actions: { showErrorToast, showSuccessToast } } = useToast()
    const { params } = useRoute<RouteProp<Record<string, ItemEditParams>>>()
    const T = useTranslations()
    const { goBack } = useGoBack(ScreenNames.StoreItems, {
        storeUUID: params.storeUUID
    })
    const { styles } = useStyles(stylesheet)
    const {
        mutate: getItemDetails,
        data: itemDetails,
        isLoading: getItemDetailsLoading
    } = useGetStoreItems()
    const { form, submit, setFieldInitialValue } = useForm(useItemSetupForm(), {
        onSuccess: value => addItem({
            storeUUID: params.storeUUID,
            scanDate: dateHelpers.nowUnix(),
            items: [
                {
                    ...{
                        bookCode: value.bookCode,
                        title: value.title,
                        sku: value.sku,
                        author: value.author,
                        quantity: Number(value.quantity),
                        category: value.category
                    },
                    ...params.itemUUID && { storeItemUUID: params.itemUUID }
                }
            ]
        })
    })
    const { mutate: addItem, isLoading: addLoading } = useAddNewItem({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.StoreItems])
            showSuccessToast(params?.itemUUID
                ? T.screens.itemSetup.itemEdited
                : T.screens.itemSetup.itemAdded
            )
            goBack()
        },
        onError: () => {
            showErrorToast(T.alerts.oops)
            goBack()
        }
    })
    const isLoading = getItemDetailsLoading || addLoading

    useEffect(() => {
        if (!params?.storeUUID) {
            showErrorToast(T.alerts.oops)

            return goBack()
        }
    }, [])

    useEffect(() => {
        if (params.itemUUID) {
            getItemDetails({
                storeUUID: params.storeUUID,
                storeItemUUID: params.itemUUID,
                limit: 20,
                offset: 0
            })
        }
    }, [params])

    useEffect(() => {
        const [editItem] = itemDetails?.data.storeItems || []

        if (editItem) {
            setFieldInitialValue(NewItemFormFields.BookCode, editItem.bookCode)
            setFieldInitialValue(NewItemFormFields.Title, editItem.title ?? '')
            setFieldInitialValue(NewItemFormFields.Author, editItem.author ?? '')
            setFieldInitialValue(NewItemFormFields.Category, editItem.category ?? '')
            setFieldInitialValue(NewItemFormFields.SKU, editItem.sku ?? '')
            setFieldInitialValue(NewItemFormFields.Quantity, editItem.quantity.toString())
        }
    }, [itemDetails])

    return (
        <React.Fragment>
            <View
                style={{
                    ...styles.form,
                    ...conditionalStyle(isNative, styles.nativePadding)
                }}
            >
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        disabled={isLoading}
                        {...form.bookCode}
                        {...bookCodeProps}
                        style={styles.textInput}
                        leftIcon={(
                            <Icons.Store size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={22}/>
                        )}
                    />
                    <Adapter.TextInput
                        disabled={isLoading}
                        {...form.title}
                        {...titleProps}
                        style={styles.textInput}
                        leftIcon={(
                            <Icons.Title size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={22}/>
                        )}
                    />
                </View>
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        disabled={isLoading}
                        {...form.author}
                        {...authorProps}
                        style={styles.textInput}
                        leftIcon={(
                            <Icons.Author size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={22}/>
                        )}
                    />
                    <Adapter.TextInput
                        disabled={isLoading}
                        {...form.category}
                        {...categoryProps}
                        style={styles.textInput}
                        leftIcon={(
                            <Icons.Category size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={22}/>
                        )}
                    />
                </View>
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        disabled={isLoading}
                        {...form.sku}
                        {...skuProps}
                        style={styles.textInput}
                        leftIcon={(
                            <Icons.Sku size={22}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={22}/>
                        )}
                    />
                </View>
                <Adapter.QuantityPicker
                    disabled={isLoading}
                    {...form.quantity}
                    {...quantityProps}
                    style={styles.quantityPicker}
                />
                <Grid.Gap gapBottom={2}/>
                <View
                    style={{
                        ...styles.formRow,
                        ...styles.buttons,
                        ...conditionalStyle(isWeb, styles.reverseButtons)
                    }}
                >
                    <Button
                        isLoading={isLoading}
                        text={T.common.save}
                        customStyle={styles.textInput}
                        onPress={submit}
                    />
                    <Button
                        disabled={isLoading}
                        noBackground
                        customStyle={styles.textInput}
                        text={T.common.cancel}
                        onPress={goBack}
                    />
                </View>
                {isNative && (
                    <Grid.Gap gapBottom={8}/>
                )}
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    nativeHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: {
            xs: 0,
            sm: theme.gap * 2
        }
    },
    textInput: {
        flexShrink: 1
    },
    countryIndex: {
        zIndex: 999
    },
    stateIndex: {
        zIndex: 998
    },
    form: {
        borderRadius: 12,
        maxWidth: {
            sm: 728
        },
        width: '100%',
        padding: {
            sm: theme.gap * 3
        },
        backgroundColor: {
            sm: theme.colors.littleMoreDarkerGray
        },
        minWidth: {
            sm: 580
        }
    },
    nativePadding: {
        paddingHorizontal: theme.contentPaddingMobile
    },
    quantityPicker: {
        width: '100%'
    },
    buttons: {
        gap: theme.gap * 2
    },
    reverseButtons: {
        flexDirection: 'row-reverse'
    }
}))
