import React from 'react'
import { TextStyle } from 'react-native'
import { TextChildren, VoidFunction } from 'lib/types'
import { createStyles, useStyles } from 'lib/styles'
import { BaseText } from './BaseText'

type RegularProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    children?: TextChildren,
    bold?: boolean
}

export const Regular: React.FunctionComponent<RegularProps> = ({
    children,
    numberOfLines,
    onPress,
    style = {},
    forceColor,
    bold
}) => {
    const { styles } = useStyles(stylesheet)
    const userStyles = bold
        ? {
            ...styles.bold,
            ...style
        }
        : style

    return (
        <BaseText
            onPress={onPress}
            style={{
                ...styles.text,
                ...userStyles
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.typography.regular,
        ...theme.components.typography.primary,
        fontSize: {
            sm: 24,
            xs: 14
        },
        lineHeight: {
            sm: 24,
            xs: 17
        }
    },
    bold: {
        fontWeight: '600'
    }
}))
