import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { DropdownOption } from 'lib/types'
import { UserType, Store, AddEditUser } from 'lib/models'
import { regexes } from 'lib/utils'
import { AddEditUserFields } from './forms'

export const useAddEditUserForm = (initialUser?: AddEditUser, securityGroupRequired = false, isChangePasswordModal = false) => {
    const T = useTranslations()

    const fullName = useField<string>({
        key: AddEditUserFields.FullName,
        initialValue: initialUser?.fullName || '',
        isRequired: Boolean(initialUser?.fullName) || !initialUser,
        validateOnBlur: false,
        label: T.forms.common.fullName.label,
        placeholder: T.forms.common.fullName.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.fullName.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.common.fullName.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const email = useField<string>({
        key: AddEditUserFields.Email,
        initialValue: initialUser?.email || '',
        isRequired: true,
        validateOnBlur: false,
        label: T.forms.common.email.label,
        placeholder: T.forms.common.email.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.email.validation.isValid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.forms.common.email.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ],
        liveParser: value => value.toLowerCase()
    })

    const password = useField<string>({
        key: AddEditUserFields.Password,
        initialValue: '',
        isRequired: isChangePasswordModal,
        validateOnBlur: false,
        label: T.forms.common.password.label,
        placeholder: T.forms.common.password.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.password.validation.isValid,
                validate: regexes.isValidPassword
            },
            {
                errorMessage: T.forms.common.password.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const confirmPassword = useField<string>({
        key: AddEditUserFields.ConfirmPassword,
        initialValue: '',
        isRequired: isChangePasswordModal && password.value !== '',
        validateOnBlur: false,
        label: T.forms.common.confirmPassword.label,
        placeholder: T.forms.common.confirmPassword.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.confirmPassword.validation.doesNotMatch,
                validate: value => value === password.value
            }
        ]
    })

    const securityGroup = useField<UserType | undefined>({
        key: AddEditUserFields.SecurityGroup,
        initialValue: initialUser?.type,
        isRequired: securityGroupRequired,
        validateOnBlur: securityGroupRequired,
        label: T.forms.editUser.securityGroup.label,
        placeholder: T.forms.editUser.securityGroup.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.editUser.securityGroup.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const stores = useField<Array<DropdownOption<Store>>>({
        key: AddEditUserFields.Stores,
        initialValue: [],
        isRequired: false,
        validateOnBlur: false,
        label: T.forms.editUser.store.label,
        placeholder: T.forms.editUser.store.placeholder
    })

    return {
        fullName,
        email,
        password,
        securityGroup,
        stores,
        confirmPassword
    }
}
