import React, { ReactNode } from 'react'
import { View, ViewStyle } from 'react-native'
import { useTranslations } from 'lib/hooks'
import { createStyles, hexToRGBA, conditionalStyle, useStyles } from 'lib/styles'
import { ColumnConfig, Action as ActionType, Pagination as PaginationType } from 'lib/types'
import { Typography, CopyToClipboard } from 'lib/components'
import { R } from 'lib/utils'
import { Pagination } from './Pagination'
import { Action } from './Action'
import { ElementPreview } from './ElementPreview'

type TableProps<
    T extends ColumnConfig,
    D extends Record<T['key'], ReactNode>
> = {
    query?: string,
    columns: Array<T>,
    actions?: Array<ActionType<D>>,
    data: Array<D>,
    style?: ViewStyle,
    rowStyle?: ViewStyle,
    cellStyle?: ViewStyle,
    pagination?: PaginationType,
    isLoading?: boolean
}

export const Table = <
    T extends ColumnConfig,
    D extends Record<T['key'], ReactNode>
>({
    query,
    columns,
    actions,
    data,
    isLoading,
    rowStyle,
    cellStyle,
    style,
    pagination
}: TableProps<T, D>) => {
    const Translations = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            <View style={styles.tableContainer}>
                <View style={styles.noResults}>
                    {R.isEmpty(data) && !isLoading && (
                        <Typography.Regular forceColor={theme.colors.darkGray}>
                            {query?.length !== 0
                                ? Translations.screens.manageStores.noStoresFoundMessage
                                : Translations.screens.manageStores.noStoresMessage
                            }
                        </Typography.Regular>
                    )}
                </View>
                {!R.isEmpty(columns) && (
                    <View style={styles.header}>
                        {columns.map(({ key, title, size }) => (
                            <Typography.Regular
                                style={{
                                    ...styles.headerCell,
                                    minWidth: size ?? 'auto'
                                }}
                                key={key}
                            >
                                {title}
                            </Typography.Regular>
                        ))}
                    </View>
                )}
                <View style={styles.rows}>
                    {!R.isEmpty(data) && data.map((row, index) => (
                        <View
                            key={index}
                            style={{
                                ...styles.row,
                                ...rowStyle
                            }}
                        >
                            <View style={styles.cells}>
                                {columns.map(({ key, size, previewElement }, columnIndex) => {
                                    const columnType = typeof row[key]

                                    return (
                                        <View
                                            key={key}
                                            style={{
                                                width: size ?? 'auto',
                                                ...styles.cell,
                                                ...conditionalStyle(columnIndex === columns.length - 1, styles.removeCellBorder)
                                            }}
                                        >
                                            {previewElement && (
                                                <ElementPreview element={row[key]}/>
                                            )}
                                            {columnType === 'string' || columnType === 'number' ? (
                                                <CopyToClipboard copyValue={row[key]}>
                                                    <Typography.Subheading
                                                        numberOfLines={1}
                                                        style={{
                                                            ...styles.cellText,
                                                            ...cellStyle
                                                        }}
                                                    >
                                                        {row[key]}
                                                    </Typography.Subheading>
                                                </CopyToClipboard>
                                            ) : (
                                                row[key]
                                            )}
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={styles.actions}>
                                {actions?.map(({ action, key, icon, label, tooltipPlacement }) => (
                                    <Action
                                        onPress={() => action(row)}
                                        tooltipPlacement={tooltipPlacement}
                                        key={key}
                                        icon={icon}
                                        label={label}
                                    />
                                ))}
                            </View>
                        </View>
                    ))}
                </View>
            </View>
            {pagination && (
                <Pagination
                    paginationActions={pagination.actions}
                    paginationState={pagination.state}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        paddingBottom: 1,
        gap: 12,
        position: 'relative'
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 12,
        flex: 1
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: theme.gap * 3
    },
    headerCell: {
        fontSize: 16,
        fontWeight: '300',
        color: theme.colors.lightGray
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 12,
        backgroundColor: hexToRGBA(theme.colors.darkerGray, 0.5),
        gap: theme.gap * 4,
        paddingVertical: theme.gap * 2
    },
    rows: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 12,
        overflow: 'scroll'
    },
    cell: {
        color: theme.colors.white,
        borderRightColor: theme.colors.darkGray,
        borderRightWidth: 1,
        paddingLeft: theme.gap * 3,
        minHeight: theme.gap * 3,
        paddingRight: theme.gap * 3
    },
    cellText: {
        color: theme.colors.white,
        cursor: 'pointer'
    },
    noResults: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    cells: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 4,
        paddingRight: theme.gap * 3
    },
    removeCellBorder: {
        borderRightWidth: 0
    }
}))
