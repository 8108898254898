import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { Icons } from 'assets'
import { isWeb } from 'lib/common'
import { AppName, Grid, SquareTile, Typography, Logo } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { UserType } from 'lib/models'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { BackgroundImage } from '../components'

export const WelcomeScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation<NavigationProps<ScreenNames>>()

    return (
        <Grid.Background
            style={conditionalStyle(isWeb, styles.background)}
            hasPaddingHorizontal
        >
            {isWeb && (
                <BackgroundImage />
            )}
            <View style={styles.container}>
                <Logo/>
                <Grid.Gap gapBottom={1}/>
                <Typography.Heading>
                    {T.screens.welcome.title}
                </Typography.Heading>
                <AppName/>
                <Grid.Gap gapBottom={1}/>
                <Typography.Subheading>
                    {T.screens.welcome.message}
                </Typography.Subheading>
                <Grid.Gap
                    gapTop={7}
                    style={styles.row}
                >
                    <SquareTile
                        customContainerStyle={styles.tileContainerStyle}
                        label={T.screens.welcome.userType.employee}
                        icon={(
                            <Icons.User
                                size={50}
                                forceColor={theme.ui.primary}
                            />
                        )}
                        onPress={() => navigation.navigate(ScreenNames.SignIn, {
                            userType: UserType.Manager
                        })}
                    />
                    <SquareTile
                        customContainerStyle={styles.tileContainerStyle}
                        label={T.screens.welcome.userType.owner}
                        icon={(
                            <Icons.Briefcase
                                size={50}
                                forceColor={theme.ui.primary}
                            />
                        )}
                        onPress={() => navigation.navigate(ScreenNames.SignIn, {
                            userType: UserType.Admin
                        })}
                    />
                </Grid.Gap>
            </View>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '100%',
        maxWidth: 944,
        display: 'flex',
        alignItems: 'center',
        borderRadius: {
            sm: 80
        },
        padding: {
            sm: 80
        },
        backgroundColor: {
            sm: theme.colors.almostBlack
        }
    },
    row: {
        width: {
            xs: 368,
            sm: '100%'
        },
        maxWidth: {
            sm: 368
        },
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tileContainerStyle: {
        paddingHorizontal: {
            sm: 8,
            xs: 12
        }
    }
}))
