import React from 'react'
import { View, ViewStyle } from 'react-native'
import { createStyles, useStyles } from '../styles'
import { Children } from '../types'
import { FadeShadow } from './FadeShadow'

type FadeShadowWrapperProps = {
    showTopShadow?: boolean,
    showBottomShadow?: boolean,
    shadowHeight?: number,
    containerStyle?: ViewStyle,
    children: Children
}

export const FadeShadowWrapper: React.FunctionComponent<FadeShadowWrapperProps> = ({
    showTopShadow = true,
    showBottomShadow = true,
    shadowHeight = 60,
    containerStyle,
    children
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...containerStyle
            }}
        >
            {children}
            <FadeShadow
                isVisible={showTopShadow}
                height={shadowHeight}
                isInverted
                style={{
                    top: 0
                }}
            />
            <FadeShadow
                height={shadowHeight}
                isVisible={showBottomShadow}
                style={{
                    bottom: 0
                }}
            />
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        flex: 1
    }
}))
