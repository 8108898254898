import React from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { signInBackground } from 'assets'
import { ImageResizeMode, createStyles, useStyles } from 'lib/styles'

export const BackgroundImage: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const backgroundOpacity = useSharedValue(0)
    const backgroundImageOpacity = useAnimatedStyle(() => ({
        opacity: backgroundOpacity.value
    }))

    return (
        <Animated.Image
            style={[
                styles.backgroundImage,
                backgroundImageOpacity
            ]}
            source={signInBackground}
            resizeMode={ImageResizeMode.Cover}
            onLoad={() => {
                backgroundOpacity.value = withTiming(1)
            }}
        />
    )
}

const stylesheet = createStyles(() => ({
    backgroundImage: {
        position: 'absolute',
        inset: 0,
        visibility: {
            xs: 'hidden',
            sm: 'visible'
        }
    }
}))
