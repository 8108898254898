import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useMousePosition } from 'lib/hooks'

type ElementPreviewProps = {
    element: React.ReactNode
}

export const ElementPreview: React.FunctionComponent<ElementPreviewProps> = ({ element }) => {
    const {
        actions: {
            getMousePosition,
            resetMousePosition
        },
        state: {
            mousePosition
        }
    } = useMousePosition()
    const [elementWidth, setElementWidth] = useState(0)
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <View
                style={styles.previewElement}
                onMouseMove={event => getMousePosition(event)}
                onMouseLeave={resetMousePosition}
            >
                <Icons.Eye size={18}/>
            </View>
            {mousePosition.x !== 0 && mousePosition.y !== 0 && (
                <View
                    onLayout={event => setElementWidth(event.nativeEvent.layout.width)}
                    style={{
                        ...styles.container,
                        ...conditionalStyle(
                            (Boolean(mousePosition.y) && Boolean(mousePosition.x)),
                            {
                                top: mousePosition.y - 120,
                                left: mousePosition.x - (elementWidth / 2),
                                ...styles.element
                            }
                        )
                    }}
                >
                    {element}
                </View>
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'fixed',
        padding: theme.gap * 2,
        borderRadius: 12,
        backgroundColor: theme.ui.background,
        zIndex: 999
    },
    previewElement: {
        position: 'absolute',
        top: 0,
        right: -12,
        width: 18,
        height: 18,
        zIndex: 999
    },
    element: {
        zIndex: 999
    }
}))
