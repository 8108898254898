import React from 'react'
import { ViewStyle } from 'react-native'
import { isAndroid } from 'lib/common'
import { CustomKeyboardAccessory, KeyboardAccessoryFieldProps, ReKeyboardAccessory } from 'lib/components'

type FiltersListKeyboardAccessoryProps = {
    numberOfInputs: number,
    activeInputs?: Array<number>,
    contentContainerStyle?: ViewStyle,
    children(props: Array<KeyboardAccessoryFieldProps>): React.ReactNode
}

export const FiltersListKeyboardAccessory: React.FunctionComponent<FiltersListKeyboardAccessoryProps> = ({
    numberOfInputs,
    activeInputs,
    children,
    contentContainerStyle
}) => isAndroid ? (
    <CustomKeyboardAccessory
        numberOfInputs={numberOfInputs}
        contentContainerStyle={contentContainerStyle}
    >
        {keyboardProps => children(keyboardProps)}
    </CustomKeyboardAccessory>
) : (
    <ReKeyboardAccessory
        numberOfInputs={numberOfInputs}
        activeInputs={activeInputs}
        contentContainerStyle={contentContainerStyle}
    >
        {keyboardProps => children(keyboardProps)}
    </ReKeyboardAccessory>
)
