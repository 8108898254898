import React, { useEffect } from 'react'
import { View } from 'react-native'
import { ParamListBase, useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { authActions } from 'features/auth'
import { ScreenNames, webScreens } from 'lib/routing'
import { Breakpoint, conditionalStyle, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { useUserAtom } from 'lib/atoms'
import { Typography, Touchable, Button, AppName, FullScreenLoader, Logo } from 'lib/components'
import { LeftMenuIcon } from './LeftMenuIcon'

export type WebMenuScreen = ScreenNames.ManageStores | ScreenNames.ManageUsers | ScreenNames.Files

type LeftMenuProps = {
    onPress: VoidFunction
}

export const LeftMenu: React.FunctionComponent<LeftMenuProps> = ({ onPress }) => {
    const isHigherScreenWidth = useIsWithinBreakpoints(Breakpoint.SM, Breakpoint.XL)
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()
    const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>()
    const { mutate: logout } = authActions.useLogout()
    const route = useRoute()
    const width = useSharedValue(0)
    const padding = useSharedValue(0)
    const smallResolutionAnimation = useAnimatedStyle(() => ({
        width: width.value,
        paddingLeft: padding.value,
        paddingRight: padding.value
    }))

    useEffect(() => {
        width.value = withTiming(theme.leftMenuWidth)
        padding.value = withTiming(theme.leftMenuPadding)
    }, [])

    return (
        <Animated.View
            style={[
                styles.leftMenu,
                conditionalStyle(!isHigherScreenWidth, smallResolutionAnimation),
                conditionalStyle(isHigherScreenWidth, styles.webMenuMode)
            ]}
        >
            <View style={styles.logo}>
                <Logo size={52} />
                <Typography.Subheading style={styles.logoText}>
                    <AppName />
                </Typography.Subheading>
            </View>
            <View style={styles.container}>
                <View style={styles.items}>
                    {user.userType ? (
                        webScreens
                            .filter(screen => screen.access.includes(user.userType))
                            .map(screen => {
                                const isActive = route.name.toLowerCase().includes(screen.rootPath)

                                return (
                                    <Touchable
                                        onPress={() => {
                                            onPress()
                                            navigation.replace(screen.screenName as WebMenuScreen)
                                        }}
                                        key={screen.screenName}
                                        style={{
                                            ...styles.item,
                                            ...conditionalStyle(isActive, styles.activeItem)
                                        }}
                                    >
                                        <LeftMenuIcon
                                            screenName={screen.screenName}
                                            isActive={Boolean(isActive)}
                                        />
                                        <Typography.Regular
                                            style={{
                                                ...styles.itemText,
                                                ...conditionalStyle(isActive, styles.activeItemText)
                                            }}
                                        >
                                            {T.titles[regexes.firstLetterLowercase(screen.screenName)]}
                                        </Typography.Regular>
                                    </Touchable>
                                )
                            })
                    ) : (
                        <FullScreenLoader isActive />
                    )}
                </View>
                <Button
                    isPlain
                    text={T.screens.home.signOut}
                    onPress={logout}
                    customStyle={styles.signOutButton}
                    customStyleText={styles.signOutText}
                />
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItem: 'flex-start',
        width: '100%',
        height: '100%',
        gap: theme.gap * 2
    },
    leftMenu: {
        position: {
            xs: 'absolute',
            sm: 'static'
        },
        borderTopRightRadius: {
            xs: 40,
            sm: 0
        },
        borderBottomRightRadius: {
            xs: 40,
            sm: 0
        },
        width: 0,
        overflow: 'hidden',
        alignItems: 'flex-start',
        backgroundColor: theme.colors.darkerGray,
        height: '100%',
        zIndex: 2,
        paddingVertical: theme.gap * 8,
        ...theme.utils.createShadow()
    },
    webMenuMode: {
        width: theme.leftMenuWidth,
        paddingHorizontal: theme.leftMenuPadding * 2
    },
    itemText: {
        fontSize: 24
    },
    items: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap * 2,
        marginTop: {
            xs: 40,
            sm: 78
        }
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2,
        alignItems: 'center',
        padding: 28,
        borderRadius: 12,
        height: theme.leftMenuItemHeight,
        cursor: 'pointer'
    },
    activeItem: {
        backgroundColor: hexToRGBA(theme.colors.darkGray, 0.5)
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.gap * 2,
        paddingHorizontal: {
            xs: theme.leftMenuPadding,
            sm: 0
        }
    },
    logoText: {
        position: 'relative',
        top: 2,
        userSelect: 'none'
    },
    activeItemText: {
        color: theme.colors.pink
    },
    signOutText: {
        fontSize: 21,
        fontWeight: '400'
    },
    signOutButton: {
        marginBottom: 58
    }
}))
