import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Adapter, Button, Grid, KeyboardAccessoryFieldProps, Typography } from 'lib/components'
import { Measurements, isNative, isWeb } from 'lib/common'
import { inputProps } from 'lib/utils'
import { getInitialRoute } from 'lib/routing'
import { useUserAtom } from 'lib/atoms'
import { AddEditUserShape } from '../forms'

type EditSelfProps = {
    form: AddEditUserShape,
    fullNameProps?: KeyboardAccessoryFieldProps,
    passwordProps?: KeyboardAccessoryFieldProps,
    emailProps?: KeyboardAccessoryFieldProps,
    isEditingUser?: boolean,
    isChangePasswordModal: boolean,
    onSubmit: VoidFunction,
    resetForm: VoidFunction,
    setIsChangePasswordModal(state: boolean): void
}

export const EditSelf: React.FunctionComponent<EditSelfProps> = ({
    form,
    fullNameProps,
    passwordProps,
    emailProps,
    isEditingUser,
    isChangePasswordModal,
    setIsChangePasswordModal,
    onSubmit,
    resetForm
}) => {
    const [user] = useUserAtom()
    const navigation = useNavigation()
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <React.Fragment>
            {isNative && (
                <Grid.Gap gapBottom={5}/>
            )}
            <View style={styles.formRow}>
                <Adapter.TextInput
                    {...form.fullName}
                    {...fullNameProps}
                    style={styles.textInput}
                    inputProps={inputProps.name}
                    leftIcon={(
                        <Icons.User size={24}/>
                    )}
                    rightIcon={(
                        <Icons.Pencil size={24}/>
                    )}
                />
                <Adapter.TextInput
                    {...form.email}
                    disabled
                    {...emailProps}
                    style={styles.textInput}
                    inputProps={inputProps.email}
                    leftIcon={(
                        <Icons.Envelope size={22}/>
                    )}
                />
            </View>
            <Typography.SubText
                style={{
                    ...styles.changePassword,
                    ...conditionalStyle(isWeb, styles.changePasswordWeb)
                }}
                onPress={() => setIsChangePasswordModal(!isChangePasswordModal)}
                forceColor={theme.colors.pink}
            >
                {T.screens.adminSetup.changePassword}
            </Typography.SubText>
            <View style={styles.spacer} />
            <View style={{
                ...styles.formRow,
                ...styles.buttonsGap,
                ...conditionalStyle(isWeb, styles.reverseButtons)
            }}>
                <Button
                    isLoading={isEditingUser}
                    text={T.common.save}
                    onPress={onSubmit}
                    customStyle={conditionalStyle(isWeb, styles.textInput)}
                    width={isNative ? Measurements.WindowWidth - theme.utils.gap(6) : undefined}
                />
                <Button
                    noBackground
                    disabled={isEditingUser}
                    text={T.common.cancel}
                    onPress={isNative
                        ? navigation.goBack
                        : () => navigation.navigate(getInitialRoute(user.userType))
                    }
                    customStyle={conditionalStyle(isWeb, styles.textInput)}
                    width={isNative ? Measurements.WindowWidth - theme.utils.gap(6) : undefined}
                />
            </View>
            {isNative && (
                <Grid.Gap gapBottom={4}/>
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: {
            xs: 0,
            sm: theme.gap * 2
        }
    },
    textInput: {
        flexShrink: 1
    },
    spacer: {
        flex: 1
    },
    buttonsGap: {
        marginTop: theme.gap * 2,
        gap: theme.gap * 2
    },
    changePasswordWeb: {
        marginVertical: theme.gap * 2,
        cursor: 'pointer'
    },
    changePassword: {
        textDecorationLine: 'underline'
    },
    reverseButtons: {
        flexDirection: 'row-reverse'
    }
}))
