/* eslint-disable max-lines */
import { Dictionary } from 'lib/types'

// eslint-disable-next-line
export const en_US: Dictionary = {
    common: {
        goBack: 'Go back',
        myProfile: 'My profile',
        imrchnt: 'imrchnt',
        close: 'Close',
        error: 'Error',
        success: 'Success',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        errorMessage: 'An error has occurred. Please try again or check your internet connection',
        ok: 'Okay',
        confirm: 'Confirm',
        tryAgain: 'Try again',
        yes: 'Yes'
    },
    titles: {
        manageUsers: 'Users',
        account: 'Account',
        manageStores: 'Stores',
        files: 'Files',
        inventory: 'Inventory',
        editUser: 'Edit user',
        storeSetup: 'Store setup',
        editSelf: 'Edit profile',
        addUser: 'Add user',
        search: 'Search',
        searchItemDetails: 'Item details',
        welcome: 'Welcome',
        signIn: 'Sign in',
        signUp: 'Sign up',
        storeItems: 'Store items',
        resetPassword: 'Reset password',
        forgotPassword: 'Forgot password',
        verifyEmail: 'Verify email',
        itemSetup: 'Add item',
        itemEdit: 'Edit item',
        itemDetails: 'Item details'
    },
    alerts: {
        oops: 'Oops!',
        tooManyRequests: {
            title: 'Steady on!',
            message: 'It looks like you are using the application not in accordance with the regulations.\nYour account has been temporarily restricted.'
        },
        playServicesNotAvailable: {
            title: 'Oops!',
            message: 'Seems like the Google Play services are outdated or not available on your device'
        },
        saveAuthCredentials: {
            title: 'Save password?',
            message: 'Do you want to save your password and enable logging in with biometry?' // todo check
        }
    },
    errorBoundary: {
        title: 'Oops - something went wrong!',
        message: 'Whatever happened, it was probably our fault.\nDetailed information about this error has automatically been recorder and we have been notified.',
        submit: 'Reload the app'
    },
    permissions: {
        openSettings: {
            title: 'Oops!',
            message: 'You need to add permission manually',
            button: 'Open settings'
        }
    },
    screens: {
        home: {
            title: {
                prefix: 'Good',
                morning: 'Morning',
                afternoon: 'Afternoon',
                evening: 'Evening'
            },
            signOut: 'Sign out'
        },
        welcome: {
            title: 'welcome to',
            message: 'How would you like to use that app?',
            userType: {
                employee: 'Employee',
                owner: 'Business owner'
            }
        },
        signIn: {
            title: 'welcome to',
            message: 'Please sign in to continue',
            forgotPassword: 'Forgot your password?',
            button: 'Sign in',
            orUse: 'or use',
            noAccount: 'Don\'t have an account?',
            signUp: 'Sign up',
            biometric: 'Sign in with biometrics',
            errorEmail: 'Something went wrong while signing in. Please check your internet connection and try again',
            incorrectCredentials: 'Incorrect email or password'
        },
        signUp: {
            title: 'Sign up',
            message: 'Sign up with one of the following options',
            accountExist: 'Already have an account?',
            signIn: 'Sign in',
            button: 'Sign up',
            onSuccess: {
                title: 'You have signed up successfully',
                message: 'To activate your account, please check your email and follow the included steps.'
            },
            errorSignUp: 'Something went wrong while signing up. Please check your internet connection and try again'
        },
        forgotPassword: {
            title: 'Forgot password',
            message: 'Enter your email address below to receive reset password instruction',
            emailMessage: {
                prefix: 'Reset password link has been sent to',
                suffix: 'Check the email and follow the steps.'
            },
            timerMessage: {
                prefix: 'You can resend reset password link again in:',
                suffix: 'seconds'
            },
            button: 'Send'
        },
        resetPassword: {
            title: 'Reset password',
            message: 'To change the password, enter the new password and confirm it'
        },
        adminSetup: {
            title: 'Admin setup',
            changePassword: 'Do you want to change your password?',
            changePasswordModal: {
                title: 'Change password',
                message: 'To change the password, enter the new password and confirm it'
            },
            removeAccount: {
                header: 'Delete account',
                subText: 'Deletion of your account is permanent and cannot be undone.',
                confirm: 'Are you sure you want to delete your account?'
            }
        },
        manageUsers: {
            title: 'Users',
            searchPlaceholder: 'Enter user or store name',
            addUserButton: 'Add new user',
            areYouSureToDelete: 'Are you sure you want to delete this user?',
            noUsersMessage: 'You haven\'t added any users yet',
            noMatchingUsers: 'There are no users with matching username or store name',
            errorDelete: 'Something went wrong while deleting the user. Please check your internet connection and try again',
            storeNotAsigned: 'Store not assigned'
        },
        manageStores: {
            title: 'Stores',
            searchPlaceholder: 'Enter store name or location',
            addStoreButton: 'Add new store',
            areYouSureToDelete: 'Are you sure you want to delete this store?',
            noStoresMessage: 'No stores found',
            noStoresFoundMessage: 'No matching records found',
            errorDelete: 'Something went wrong while deleting the store. Please check your internet connection and try again'
        },
        addEditUser: {
            titleAddUser: 'Add user',
            titleEditUser: 'Edit user',
            successAdded: 'New user was successfully added',
            successEdited: 'User was successfully edited',
            successEditedWithEmail: 'User was successfully edited. Now you have to confirm your email',
            selectStoreTitle: 'Select stores',
            noStoresMessage: {
                owner: 'You haven\'t added any stores yet',
                user: 'You aren\'t added to any stores yet',
                loading: 'Loading stores...'
            },
            errorEdit: 'Something went wrong while editing the user. Please check your internet connection and try again',
            errorAdd: 'Something went wrong while adding a new user. Please check your internet connection and try again',
            toast: {
                addSuccess: 'New user has been successfully added',
                editSuccess: 'User has been successfully edited'
            }
        },
        storeSetup: {
            title: 'Store setup',
            successAdded: 'New store was successfully added',
            successEdited: 'Store was successfully edited',
            errorEdit: 'Something went wrong while editing the store. Please check your internet connection and try again',
            errorAdd: 'Something went wrong while adding a new store. Please check your internet connection and try again',
            noCountrySelected: 'No country selected',
            toast: {
                addSuccess: 'New store has been successfully added',
                editSuccess: 'Store has been successfully edited'
            }
        },
        chooseScanStore: {
            title: 'Choose store',
            message: 'Select a specific store from the list of stores',
            button: 'Scanner',
            dropdownLabel: 'Store',
            noStoresMessage: {
                owner: 'You haven\'t added any stores yet',
                user: 'You aren\'t added to any stores yet'
            },
            addStoreButton: 'Add new store',
            fastScan: 'Fast Scan',
            fastScanDescription: 'You won\'t be asked for any additional details. The scan will be saved only as the book codes and quantities.'
        },
        scanner: {
            title: 'Scanner',
            instruction: 'Place barcode in the box',
            permissionWarning: 'Disabled camera. \n To scan codes you have to grant access to the camera',
            button: 'Grant access',
            modalTitle: 'Enter your book code',
            bookCodeExample: 'Example: 9788378459989',
            nextButton: 'Next step',
            itemAddedToast: {
                prefix: 'Item saved. Total:',
                suffix: 'item(s)'
            }
        },
        itemDetails: {
            title: 'Item details',
            fields: {
                bookCode: 'Book code',
                title: 'Title',
                author: 'Author',
                category: 'Category',
                sku: 'SKU',
                quantity: 'Pick quantity'
            }
        },
        newItem: {
            title: 'New item'
        },
        itemAdded: {
            message: 'Item has been successfully added to the database',
            button: 'Go back to scanner'
        },
        scannedItems: {
            title: 'Scanned items',
            finishButton: 'Finish scanning',
            scannerButton: 'Scanner',
            bookCode: 'Book code',
            quantity: 'Quantity',
            listEmpty: {
                message: 'The scanned item list is empty',
                button: 'Go back to scanner'
            },
            confirmDelete: 'Are you sure you want to delete this item?'
        },
        editItem: {
            title: 'Edit item',
            message: 'Change the book code or enter a new quantity'
        },
        finishScanning: {
            title: 'Finish scanning',
            button: {
                saveToDatabase: 'Save to database',
                shareFile: 'Share file'
            },
            onSaveSuccess: {
                title: 'Save success',
                message: 'Scanned items has been saved successfully to the database'
            },
            errorSharing: 'Something went wrong while sharing the file. Please check your internet connection and try again',
            errorSaving: 'Something went wrong while saving the file. Please check your internet connection and try again'
        },
        files: {
            title: 'Files',
            shareButton: 'Share',
            newScanButton: 'New scan',
            uniqueItems: 'unique items',
            totalItems: 'total',
            listEmpty: {
                message: 'Oops! It seems like you have no scanned items.',
                button: 'Go to scanner'
            },
            confirmDelete: 'Are you sure you want to delete this file?',
            errorDelete: 'Something went wrong while deleting the file. Please check your internet connection and try again',
            errorSave: 'Something went wrong while saving the scan. Please check your internet connection and try again',
            errorEdit: 'Something went wrong while editing the scan. Please check your internet connection and try again'
        },
        resumeScanModal: {
            title: 'Resume scan?',
            message: 'You have an uncompleted scan.\nDo you wish to resume it?'
        },
        scanDetails: {
            title: 'Items',
            scanOutdated: 'This scan is outdated.\nYou can only share the latest scan.',
            scanAlreadySaved: 'This scan has been saved successfully',
            button: {
                saveToDatabase: 'Save to database',
                shareFile: 'Share file'
            }
        },
        verifyEmail: {
            title: 'Verify your\nemail address',
            message: 'In order to start using your imrchnt account, you need to confirm you email address.',
            button: 'Verify Email Address',
            footerMessage: 'If you did not sign for this account, you can ignore this email and the account will be deleted',
            onSuccessMessage: 'Your email address has been confirmed successfully',
            onErrorMessage: 'Something went wrong while verifying you email address. Please check your internet connection and try again'
        },
        confirmEmail: {
            title: 'Confirm email\naddress change',
            message: 'In order to start using new email address, you need to confirm the change of it.',
            button: 'Confirm Email Address',
            footerMessage: 'If you did not change the email address, you can ignore this email.',
            onSuccessMessage: 'Your email address change has been confirmed successfully',
            onErrorMessage: 'Something went wrong while confirming your email address change. Please check your internet connection and try again'
        },
        storeItems: {
            title: 'Store',
            files: 'Files',
            database: 'Database',
            databaseTile: 'Check database state',
            scanner: 'Scanner',
            scanDate: 'Last scan date:',
            syncDate: 'Last database update:',
            searchPlaceholder: 'Enter item name',
            addItem: 'Add new item',
            itemSetup: 'Item setup',
            itemDeleted: 'Item has been successfully deleted',
            itemDeleteLabel: 'Are you sure you want to delete this item?'
        },
        itemSetup: {
            itemAdded: 'Item has been successfully added',
            itemEdited: 'Item has been successfully edited'
        },
        databaseItems: {
            title: 'Database',
            listEmpty: {
                message: 'Oops! It seems like you have no items in the database.'
            }
        },
        quantityModal: {
            title: 'Quantity',
            bookCode: 'Book code'
        },
        setupPassword: {
            title: 'Set up password',
            message: 'Enter the password and confirm it.'
        },
        search: {
            inStock: 'In stock',
            searchPlaceholder: 'Search by SKU',
            selectFilters: 'Filters',
            additionalFilters: 'Additional filters',
            selectStore: 'Select store',
            clearAll: 'Clear all',
            noMatching: 'No matching items found',
            noItems: 'No items found',
            noStoresSelected: 'No stores selected, please select at least one store'
        },
        searchDetails: {
            title: 'Item details',
            information: {
                bookInformation: 'Book information',
                available: 'Available',
                onPO: 'On P/O',
                mtd: 'MTD',
                ytd: 'YTD',
                isbn: 'ISBN',
                title: 'Title',
                author: 'Author',
                department: 'Department',
                store: 'Store',
                publisher: 'Publisher',
                price: 'Price'
            }
        }
    },
    components: {
        table: {
            noMatchingResults: 'No matching items found',
            noResults: 'No items found',
            itemsPerPage: 'Items per page',
            headers: {
                bookCode: 'Book code',
                sku: 'SKU',
                quantity: 'Quantity'
            },
            copied: 'Copied',
            actions: {
                edit: 'Edit',
                remove: 'Remove',
                viewItems: 'View items'
            }
        },
        newItemModal: {
            message: 'Hmm, it seems like this title doesn\'t exist in our database yet.\nDo you want to add new?',
            button: {
                yes: 'Yes',
                notNow: 'Not now'
            }
        },
        fileSavedModal: {
            title: 'File saved',
            message: 'The file has been saved as'
        },
        biometryAuth: {
            title: 'Authentication needed'
        },
        authMethods: {
            errorGoogle: 'Something went wrong while authorizing with Google. Please check your internet connection and try again',
            errorApple: 'Something went wrong while authorizing with Apple. Please check your internet connection and try again',
            errorMicrosoft: 'Something went wrong while authorizing with Microsoft. Please check your internet connection and try again',
            errorBiometry: 'Something went wrong while authorizing with biometry. Please check your internet connection and try again'
        },
        sharingSuccess: {
            message: 'Scan file has been shared successfully'
        },
        fastScanModal: {
            title: 'Enable fast scan?',
            message: 'You won\'t be asked for any additional details. The scan will be saved only as the book codes and quantities.',
            decline: 'No'
        },
        dropdownModal: {
            selectAll: 'Select all',
            unselectAll: 'Unselect all'
        },
        filters: {
            noStoresSelected: 'No stores selected',
            selectStore: 'Please select at least one store',
            userHasNoStores: 'User has no stores'
        }
    },
    forms: {
        common: {
            email: {
                label: 'Email',
                placeholder: 'Email',
                validation: {
                    isTooLong: 'Email should have less than 255 characters',
                    isValid: 'Email is invalid'
                }
            },
            fullName: {
                label: 'Full name',
                placeholder: 'Full name',
                validation: {
                    isRequired: 'Full name is required',
                    isTooLong: 'Full name should have less than 255 characters'
                }
            },
            password: {
                label: 'Password',
                placeholder: 'Password',
                validation: {
                    isTooLong: 'Password should have less than 255 characters',
                    isValid: 'Password should contain at least 8 characters, one upper-cased letter and one number'
                }
            },
            confirmPassword: {
                label: 'Confirm password',
                placeholder: 'Confirm password',
                validation: {
                    doesNotMatch: 'Confirmed password doesn\'t match the password'
                }
            },
            termsAndConditions: {
                label: {
                    firstPart: 'I agree with ',
                    secondPart: 'terms and conditions ',
                    thirdPart: 'and ',
                    fourthPart: 'privacy policy'
                },
                validation: {
                    isRequired: 'Terms and Conditions are required'
                }
            }
        },
        editUser: {
            securityGroup: {
                label: 'Security group',
                placeholder: 'Security group',
                validation: {
                    isRequired: 'Security group is required'
                }
            },
            store: {
                label: 'Store',
                placeholder: 'Store',
                validation: {
                    isRequired: 'Store is required'
                }
            }
        },
        storeSetup: {
            legalName: {
                label: 'Store legal name',
                placeholder: 'Store legal name',
                validation: {
                    isRequired: 'Legal name is required',
                    isTooLong: 'Legal name should have less than 255 characters'
                }
            },
            imStoreId: {
                label: 'Store iMrchnt ID',
                placeholder: 'Store iMrchnt ID',
                validation: {
                    isInvalid: 'Store iMrchnt ID should be a number bigger than 0'
                }
            },
            street: {
                label: 'Street address',
                placeholder: 'Street address',
                validation: {
                    isRequired: 'Street address is required',
                    isTooLong: 'Street address should have less than 255 characters'
                }
            },
            city: {
                label: 'City',
                placeholder: 'City',
                validation: {
                    isRequired: 'City is required',
                    isTooLong: 'City should have less than 255 characters'
                }
            },
            zipCode: {
                label: 'Zip code',
                placeholder: 'Zip code',
                validation: {
                    isRequired: 'Zip code is required',
                    hasInvalidLength: 'Zip code should be between 5 and 20 characters'
                }
            },
            state: {
                label: 'State',
                placeholder: 'State',
                validation: {
                    isRequired: 'State is required',
                    isTooLong: 'State should have less than 255 characters'
                }
            },
            country: {
                label: 'Country',
                placeholder: 'Country',
                validation: {
                    isRequired: 'Country is required',
                    isTooLong: 'Country should have less than 255 characters'
                }
            }
        },
        bookCode: {
            validation: {
                isRequired: 'Book code is required',
                hasInvalidFormat: 'Invalid book code format'
            }
        },
        newItem: {
            bookCode: {
                label: 'Book code',
                placeholder: 'Book code',
                validation: {
                    isRequired: 'Book code is required'
                }
            },
            title: {
                label: 'Title',
                placeholder: 'Title',
                validation: {
                    isRequired: 'Title is required',
                    isTooLong: 'Title should have less than 255 characters'
                }
            },
            author: {
                label: 'Author',
                placeholder: 'Author',
                validation: {
                    isRequired: 'Author is required',
                    isTooLong: 'Author should have less than 255 characters'
                }
            },
            category: {
                label: 'Category',
                placeholder: 'Category',
                validation: {
                    isRequired: 'Category is required',
                    isTooLong: 'Category should have less than 255 characters'
                }
            },
            sku: {
                label: 'SKU',
                placeholder: 'SKU',
                validation: {
                    isRequired: 'SKU is required',
                    isTooLong: 'SKU should have less than 255 characters'
                }
            },
            quantity: {
                label: 'Pick quantity',
                validation: {
                    isRequired: 'Quantity is required'
                }
            }
        },
        editItem: {
            bookCode: {
                label: 'Book code',
                placeholder: 'Book code'
            },
            quantity: {
                label: 'Quantity',
                placeholder: 'Quantity',
                validation: {
                    isRequired: 'Quantity is required'
                }
            }
        }
    }
}
