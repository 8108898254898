import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const BurgerMenu: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M10 18.3684C10 17.0604 11.0604 16 12.3684 16H67.6316C68.9396 16 70 17.0604 70 18.3684C70 19.6765 68.9396 20.7368 67.6316 20.7368H12.3684C11.0604 20.7368 10 19.6765 10 18.3684Z"
        />
        <Path
            d="M10 62.2145C10 60.9064 11.0604 59.8461 12.3684 59.8461H67.6316C68.9396 59.8461 70 60.9064 70 62.2145C70 63.5225 68.9396 64.5829 67.6316 64.5829H12.3684C11.0604 64.5829 10 63.5225 10 62.2145Z"
        />
        <Path
            d="M10 40.2915C10 38.9835 11.0542 37.9231 12.3547 37.9231H52.6453C53.9458 37.9231 55 38.9835 55 40.2915C55 41.5996 53.9458 42.6599 52.6453 42.6599H12.3547C11.0542 42.6599 10 41.5996 10 40.2915Z"
        />
    </Icon>
)
