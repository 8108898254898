import { Store } from 'lib/models'

export enum UserType {
    Admin = 'Admin',
    Manager = 'Manager',
    Staff = 'Staff'
}

export type BaseUser = {
    userUUID: string,
    fullName: string,
    email: string,
    isActive: boolean,
    type?: UserType,
    userStores: Array<Store>
}

export type AddEditUser = Omit<BaseUser, 'userStores'>

export type UserStore = {
    totalCount: number,
    users: Array<BaseUser>
}
export interface User extends BaseUser {
    userType: UserType
}
