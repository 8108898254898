import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const Pencil: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="m 46.78 12.32 l -24.628 26.068 c -0.932 0.988 -1.832 2.94 -2.012 4.288 l -1.108 9.72 c -0.392 3.512 2.128 5.912 5.608 5.312 l 9.66 -1.652 c 1.352 -0.24 3.24 -1.228 4.172 -2.248 l 24.628 -26.072 c 4.26 -4.5 6.18 -9.628 -0.448 -15.9 c -6.6 -6.208 -11.612 -4.02 -15.872 0.48 Z"
                />
                <Path
                    d="M 42.672 16.668 a 18.38 18.38 90 0 0 16.348 15.448 M 16 67.52 h 54"
                />
            </G>
        </Icon>
    )
}
