import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { GetItemsRequest, GetItemsResponse } from './types'

export const useGetItems = () => {
    const fetcher = useFetcher<GetItemsResponse>(HttpMethod.POST, '/books-api/search')

    return useMutation<Response<GetItemsResponse>, ErrorResponse, GetItemsRequest>(searchItems => fetcher(searchItems))
}
