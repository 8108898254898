import { PaginationSide } from 'lib/types'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getBothSidePages = (side: PaginationSide, currentPage: number, totalPages: number) => Array.from({ length: 2 }).reduce<Array<number>>((acc, _, index) => {
    switch (side) {
        case PaginationSide.Left: {
            const isPageValid = currentPage - (index + 1) >= 1

            if (isPageValid) {
                return [...acc, currentPage - (index + 1)].reverse()
            }

            return acc
        }
        case PaginationSide.Right: {
            const isPageValid = currentPage + (index + 1) <= totalPages

            if (isPageValid) {
                return [...acc, currentPage + (index + 1)]
            }

            return acc
        }
        default:
            return acc
    }
}, [])
