import { SearchItem } from 'lib/models'
import { SearchItemKey } from './types'

export const getSearchItemDetails = (item: SearchItem) => {
    const topItems = [
        {
            key: SearchItemKey.Available,
            value: item.inStock
        },
        {
            key: SearchItemKey.Price,
            value: `$${item.price}`
        }
    ]
    const bottomItems = [
        {
            key: SearchItemKey.ISBN,
            value: item.isbn
        },
        {
            key: SearchItemKey.Title,
            value: item.title
        },
        {
            key: SearchItemKey.Author,
            value: `${item.authorFirstName} ${item.authorLastName}`
        },
        {
            key: SearchItemKey.Publisher,
            value: item.publisher
        },
        {
            key: SearchItemKey.Department,
            value: item.departament || '-'
        },
        {
            key: SearchItemKey.Store,
            value: item.store
        }
    ]

    return {
        topItems,
        bottomItems
    }
}

export const getSearchItemDescription = (item: SearchItem) => ([
    item.SKU,
    item.title,
    `${item.authorFirstName} ${item.authorLastName}`,
    item.publisher,
    item.departament,
    item.store
])
