import React, { useMemo } from 'react'
import { View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons } from 'assets'
import { storeActions } from 'features/store'
import { useFocusRefetch, useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { Typography, Adapter, KeyboardAccessoryFieldProps } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { UserType } from 'lib/models'
import { inputProps, R } from 'lib/utils'
import { isNative, isWeb } from 'lib/common'
import { useUserAtom } from 'lib/atoms'
import { AddEditUserShape } from '../forms'
import { AddEditUserButtons } from './AddEditUserButtons'

type AddEditUserProps = {
    form: AddEditUserShape,
    route: NavigationParams<ScreenNames.EditUser | ScreenNames.AddUser>,
    fullNameProps?: KeyboardAccessoryFieldProps,
    passwordProps?: KeyboardAccessoryFieldProps,
    emailProps?: KeyboardAccessoryFieldProps,
    showSecurityGroup?: boolean,
    storeQuery: string,
    isLoading?: boolean,
    submit: VoidFunction,
    setStoreQuery: (query: string) => void,
    onClear: VoidFunction,
    onToggle: VoidFunction
}

export const AddEditUser: React.FunctionComponent<AddEditUserProps> = ({
    form,
    route,
    isLoading,
    fullNameProps,
    passwordProps,
    emailProps,
    setStoreQuery,
    onClear,
    submit,
    onToggle
}) => {
    const T = useTranslations()
    const { bottom } = useSafeAreaInsets()
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const [userDetails] = useUserAtom()
    const { styles } = useStyles(stylesheet)
    const { name: routeName } = useRoute()
    const {
        isFetching: isFetchingStores,
        refetch: getStores,
        data: response
    } = storeActions.useGetStores()
    const stores = response?.data.stores
    const dropdownStores = useMemo(() => stores?.map(store => ({
        key: store.storeUUID,
        label: store.storeName,
        value: store
    })) || [], [stores])

    useFocusRefetch(() => {
        getStores()
    })

    const getDropdownEmptyMessage = R.cond([
        [R.always(isFetchingStores), R.always(T.screens.addEditUser.noStoresMessage.loading)],
        [R.always(userDetails.userType === UserType.Admin), R.always(T.screens.addEditUser.noStoresMessage.owner)],
        [R.T, R.always(T.screens.addEditUser.noStoresMessage.user)]
    ])

    return (
        <View
            style={{
                ...styles.container,
                ...conditionalStyle(isNative, styles.nativePadding)
            }}
        >
            <View style={conditionalStyle(isWeb, styles.form)}>
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        style={styles.textInput}
                        {...form.fullName}
                        {...fullNameProps}
                        inputProps={inputProps.bypassAutocomplete}
                        leftIcon={(
                            <Icons.User size={24}/>
                        )}
                        rightIcon={(
                            <Icons.Pencil size={24}/>
                        )}
                    />
                    {routeName === ScreenNames.EditUser && (
                        <Adapter.TextInput
                            style={styles.textInput}
                            {...form.password}
                            {...passwordProps}
                            inputProps={inputProps.bypassAutocomplete}
                            leftIcon={(
                                <Icons.Padlock size={20}/>
                            )}
                            isPassword
                        />
                    )}
                </View>
                <View
                    style={{
                        ...styles.formRow,
                        ...styles.securityGroupIndex
                    }}
                >
                    <Adapter.TextInput
                        style={styles.textInput}
                        disabled={routeName === ScreenNames.EditUser}
                        {...form.email}
                        {...emailProps}
                        inputProps={inputProps.email}
                        leftIcon={(
                            <Icons.Envelope size={22}/>
                        )}
                    />
                    <Adapter.Dropdown
                        {...form.securityGroup}
                        style={styles.textInput}
                        items={Object.values({
                            [UserType.Manager]: UserType.Manager,
                            [UserType.Staff]: UserType.Staff
                        })}
                        getItemKey={item => item ?? ''}
                        getItemLabel={item => item ?? ''}
                        leftIcon={(
                            <Icons.Cog size={22}/>
                        )}
                    />
                </View>
                <View
                    style={{
                        ...styles.formRow,
                        ...styles.storeIndex
                    }}
                >
                    <Adapter.DropdownMultiplePicker
                        style={styles.textInput}
                        items={dropdownStores}
                        onToggle={onToggle}
                        {...form.stores}
                        leftIcon={(
                            <Icons.Store size={22}/>
                        )}
                        isLoading={isLoading || isFetchingStores}
                        emptyMessage={getDropdownEmptyMessage()}
                        disabled={R.isEmpty(dropdownStores)}
                        modalTitle={T.screens.addEditUser.selectStoreTitle}
                        handleQuery={setStoreQuery}
                        renderLabel={item => (
                            <React.Fragment>
                                <Typography.Title>
                                    {item.value.storeName}
                                </Typography.Title>
                                <Typography.Error>
                                    {`${item.value.city}${item.value.country ? ', ' : ''}${item.value.country || ''}`}
                                </Typography.Error>
                                <Typography.Label>
                                    {item.value.streetAddress}
                                </Typography.Label>
                            </React.Fragment>
                        )}
                    />
                </View>
                {(isWeb && !isMobileView) && (
                    <AddEditUserButtons
                        submit={submit}
                        onClear={onClear}
                    />
                )}
            </View>
            {(isNative || isMobileView) && (
                <View style={conditionalStyle(isNative, styles.nativeButtonsContainer(bottom))}>
                    <AddEditUserButtons
                        isLoading={isLoading || isFetchingStores}
                        submit={submit}
                        onClear={isNative
                            ? undefined
                            : onClear
                        }
                    />
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexGrow: 1,
        justifyContent: 'space-between'
    },
    nativePadding: {
        paddingHorizontal: theme.contentPaddingMobile
    },
    form: {
        borderRadius: 12,
        maxWidth: {
            sm: 728
        },
        width: '100%',
        padding: {
            sm: 24
        },
        backgroundColor: {
            sm: theme.colors.littleMoreDarkerGray
        },
        minWidth: {
            sm: 580
        }
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: {
            xs: 0,
            sm: theme.gap * 2
        }
    },
    textInput: {
        flexShrink: 1,
        width: '100%'
    },
    securityGroupIndex: {
        zIndex: 999
    },
    storeIndex: {
        zIndex: 998
    },
    nativeButtonsContainer: (paddingBottom: number) => ({
        paddingBottom: theme.gap * 7
    })
}))
