import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Books: React.FunctionComponent<IconProps & { topBookColor: string }> = props => (
    <Icon {...props}>
        <G
            stroke={props.forceColor}
            strokeWidth="0.5"
            strokeMiterlimit="10"
        >
            <Path
                fill="transparent"
                d="M13.3672 56.1189L44.7829 54.2555L70.4138 68.1822L66.6648 76.0087L32.5793 79L8.1387 64.9556C7.90509 64.8281 7.98296 64.5142 8.24995 64.4652L11.1979 63.661L10.6306 64.7398L33.5471 77.5093L37.0402 70.0653L13.9902 57.031L13.2115 56.6093C12.9667 56.472 13.078 56.1189 13.3784 56.1189H13.3672Z"
            />
            <Path d="M13.8567 58.5709L11.2091 63.7885"/>
            <Path d="M70.4138 68.1824C69.8019 68.2805 41.4566 70.4382 41.4566 70.4382"/>
            <Path
                fill={props.topBookColor}
                d="M47.8977 60.1594H39.2095L30.5212 21.3116L39.2095 21.3018L47.8977 60.1594Z"
            />
            <Path
                fill={props.topBookColor}
                d="M64.0839 3.87421L39.1984 21.2336L47.8977 60.1599L72.7833 42.8005L64.0839 3.87421Z"
            />
            <Path
                fill={props.topBookColor}
                d="M30.5212 21.3119L42.3132 0L44.0709 7.82644"
            />
            <Path
                fill={props.topBookColor}
                d="M32.8685 21.3018C33.2134 21.0076 49.6554 0.627434 49.6554 0.627434L51.1239 7.19851"
            />
            <Path
                fill={props.topBookColor}
                d="M35.7164 21.302L57.3536 2.28513L58.5662 7.71853"
            />
        </G>
    </Icon>
)
