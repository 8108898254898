import React from 'react'
import { ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { Measurements, isIOS } from 'lib/common'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { Touchable } from './Touchable'

type GoBackProps = {
    disabled?: boolean,
    noBackground?: boolean,
    color?: string,
    backgroundStyle?: ViewStyle,
    onPress?: VoidFunction,
}

export const GoBack: React.FunctionComponent<GoBackProps> = ({
    disabled,
    noBackground,
    backgroundStyle,
    color,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation<NavigationProps<ScreenNames>>()

    return (
        <Touchable
            disabled={disabled}
            style={{
                ...styles.goBack,
                ...(noBackground ? styles.noBackground : {}),
                ...backgroundStyle
            }}
            onPress={() => {
                onPress?.()
                navigation.goBack()
            }}
            hitSlopBottom={10}
            hitSlopLeft={10}
            hitSlopRight={10}
            hitSlopTop={10}
        >
            <Icons.ArrowBack
                forceColor={noBackground
                    ? color || theme.ui.foreground
                    : theme.components.typography.primary.color
                }
                size={20}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    goBack: {
        position: 'absolute',
        top: isIOS
            ? Number(Measurements.StatusBarHeight) + theme.gap * 2
            : Number(Measurements.StatusBarHeight) / 2,
        left: theme.gap * 3,
        width: 40,
        height: 40,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.ui.foreground,
        zIndex: 101
    },
    noBackground: {
        backgroundColor: theme.colors.transparent
    }
}))
