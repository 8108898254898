import React, { RefObject } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, View, ViewStyle } from 'react-native'
import { Mask } from 'react-native-mask-input'
import { VoidFunction } from 'lib/types'
import { R } from 'lib/utils'
import { createStyles, useStyles } from 'lib/styles'
import { FormComponents } from '../components'
import { KeyboardAccessoryFieldProps } from '../../reKeyboardAccessory'

interface TextInputProps extends KeyboardAccessoryFieldProps, Partial<Field<string>> {
    ref?: RefObject<NativeTextInput>,
    disabled?: boolean,
    inputProps?: NativeTextInputProps,
    isPassword?: boolean,
    clearDisabled?: boolean,
    mask?: Mask,
    rightIcon?: JSX.Element,
    leftIcon?: JSX.Element,
    style?: ViewStyle,
    labelStyle?: ViewStyle,
    onFocus?: VoidFunction
}

export const TextInput = React.forwardRef<NativeTextInput, TextInputProps>((
    {
        style,
        value,
        onBlur,
        disabled,
        placeholder,
        onChangeValue,
        inputProps = {},
        onFocus,
        keyboardAccessory,
        ...formInputProps
    },
    ref
) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
            ref={keyboardAccessory?.fieldRef}
        >
            <FormComponents.Input
                ref={ref || undefined}
                {...formInputProps}
                inputProps={{
                    value,
                    placeholder,
                    placeholderTextColor: theme.components.input.typography.placeholder,
                    editable: !disabled,
                    onChangeText: onChangeValue,
                    ...inputProps,
                    onFocus: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(onFocus, R.call)
                        R.ifDefined(textInputProps.onFocus, onFocus => onFocus(event))
                        R.ifDefined(keyboardAccessory?.events?.onFocus, R.call)
                    },
                    onBlur: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(textInputProps.onBlur, onBlur => onBlur(event))
                        R.ifDefined(onBlur, onBlur => onBlur(event))
                        R.ifDefined(keyboardAccessory?.events?.onBlur(), R.call)
                    }
                }}
            />
        </View>
    )
})

const stylesheet = createStyles(() => ({
    container: {
        width: '100%'
    }
}))
