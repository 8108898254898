import React, { ReactNode } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { View, FlatList, RefreshControl, ViewStyle } from 'react-native'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { Action, ColumnConfig } from 'lib/types'
import { Touchable, Typography, FullScreenLoader } from 'lib/components'
import { isWeb } from 'lib/common'
import { R } from 'lib/utils'

type TableNativeProps<T, D> = {
    columns: Array<T>,
    actions?: Array<Action<D>>,
    data: Array<D>,
    isError?: boolean,
    isLoading?: boolean,
    style?: ViewStyle,
    onRefetch?: VoidFunction,
    onEndReached?: VoidFunction,
    listEmptyComponent?: () => React.ReactElement
}

export const TableNative = <
    T extends ColumnConfig,
    D extends Record<T['key'], ReactNode>
>({
    columns,
    actions,
    data,
    isError,
    isLoading,
    style,
    onRefetch,
    onEndReached,
    listEmptyComponent
}: TableNativeProps<T, D>) => {
    const isFocused = useIsFocused()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            {isWeb && (
                <View style={styles.noResults}>
                    <FullScreenLoader isActive={Boolean(isLoading)} />
                </View>
            )}
            <View style={styles.header}>
                {columns.map(column => (
                    <View
                        key={column.key}
                        style={{
                            ...styles.headerItem,
                            ...conditionalStyle(Boolean(column.size), { width: column.size })
                        }}
                    >
                        <Typography.SubText>
                            {column.title}
                        </Typography.SubText>
                    </View>
                ))}
            </View>
            <FlatList
                style={styles.list}
                contentContainerStyle={styles.flatList}
                data={data || []}
                showsVerticalScrollIndicator={false}
                renderItem={listItem => {
                    const cells = Object.entries(listItem.item) as Array<[T['key'], ReactNode]>
                    const cellsWithHeaders = cells.filter(([key]) => columns.some(column => column.key === key))

                    return (
                        <View style={styles.row}>
                            <View style={styles.cells}>
                                {cellsWithHeaders.map(([key, value], index) => {
                                    const columnSize = columns.find(column => column.key === key)?.size

                                    return (
                                        <View
                                            key={key}
                                            style={{
                                                ...styles.cell,
                                                ...conditionalStyle(index === cellsWithHeaders.length - 1, styles.removeCellBorder),
                                                ...conditionalStyle(Boolean(columnSize), { width: columnSize })
                                            }}
                                        >
                                            {(typeof value === 'string' || typeof value === 'number') ? (
                                                <Typography.Regular numberOfLines={1}>
                                                    {value}
                                                </Typography.Regular>
                                            ) : (
                                                value
                                            )}
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={styles.actions}>
                                {actions?.map(({ action, key, icon }) => (
                                    <Touchable
                                        onPress={() => action(listItem.item)}
                                        key={key}
                                    >
                                        <View>
                                            {icon}
                                        </View>
                                    </Touchable>
                                ))}
                            </View>
                        </View>
                    )
                }}
                ListEmptyComponent={listEmptyComponent}
                onEndReached={onEndReached}
                refreshControl={(
                    <RefreshControl
                        refreshing={Boolean(isLoading) && isFocused}
                        onRefresh={isLoading
                            ? R.T
                            : () => onRefetch?.()
                        }
                        colors={[theme.ui.primary]}
                        tintColor={theme.ui.primary}
                    />
                )}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alighItems: 'flex-start',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.gray
    },
    headerItem: {
        disgplay: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 24,
        padding: theme.gap
    },
    flatList: {
        display: 'flex',
        flexDirection: 'column',
        marginVertical: theme.gap,
        gap: theme.gap
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 12,
        backgroundColor: theme.ui.foreground,
        gap: theme.gap * 4,
        paddingVertical: theme.gap * 2
    },
    rows: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 12,
        overflow: 'scroll'
    },
    cell: {
        color: theme.colors.white,
        borderRightColor: theme.colors.darkGray,
        borderRightWidth: 1,
        paddingHorizontal: 24,
        minHeight: 24,
        display: 'flex',
        justifyContent: 'center'
    },
    cellText: {
        color: theme.colors.white
    },
    cells: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2,
        paddingRight: 24
    },
    removeCellBorder: {
        borderRightWidth: 0
    },
    emptyListText: {
        textAlign: 'center',
        marginTop: theme.gap * 4
    },
    emptyList: {
        flex: 1,
        justifyContent: 'center'
    },
    noResults: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    list: {
        flex: 1
    }
}))
