import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Filter: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M10 23.1784C10 21.9753 10.9343 21 12.0869 21H67.7367C68.8892 21 69.8235 21.9753 69.8235 23.1784C69.8235 24.3815 68.8892 25.3568 67.7367 25.3568H12.0869C10.9343 25.3568 10 24.3815 10 23.1784Z"
        />
        <Path
            d="M17.42 39.5164C17.42 38.3133 18.3543 37.338 19.5068 37.338L60.3167 37.338C61.4692 37.338 62.4035 38.3133 62.4035 39.5164C62.4035 40.7195 61.4692 41.6948 60.3167 41.6948L19.5068 41.6948C18.3543 41.6948 17.42 40.7195 17.42 39.5164Z"
        />
        <Path
            d="M28.5499 55.8544C28.5499 54.6513 29.4842 53.676 30.6368 53.676H49.1867C50.3393 53.676 51.2736 54.6513 51.2736 55.8544C51.2736 57.0575 50.3393 58.0328 49.1867 58.0328H30.6368C29.4842 58.0328 28.5499 57.0575 28.5499 55.8544Z"
        />
    </Icon>
)
