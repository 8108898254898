import React from 'react'
import { AppLayout } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { StoreFiles } from '../components'

type StoreFilesScreenProps = {
    route: NavigationParams<ScreenNames.StoreItems>
}

export const StoreFilesScreen: React.FunctionComponent<StoreFilesScreenProps> = ({ route }) => (
    <AppLayout>
        <StoreFiles
            store={route.params?.store}
            storeUUID={route.params?.storeUUID}
        />
    </AppLayout>
)
