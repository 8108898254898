import React, { RefObject } from 'react'
import { TextInput, TextInput as NativeTextInput, ViewStyle } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { KeyboardAccessoryFieldProps, FormComponents } from 'lib/components'

interface QuantityPickerProps extends Field<string>, KeyboardAccessoryFieldProps {
    disabled?: boolean,
    style?: ViewStyle
}

export const QuantityPicker = React.forwardRef<NativeTextInput, QuantityPickerProps>((
    {
        disabled,
        style,
        ...inputProps
    },
    ref
) => (
    <FormComponents.QuantityPicker
        disabled={disabled}
        textInputRef={ref as RefObject<TextInput>}
        value={inputProps.value}
        style={style}
        onChangeValue={inputProps.onChangeValue}
        adapterProps={inputProps}
    />
))
