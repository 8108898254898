import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Quantity: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="m 40 19.848 l 31.104 -15.78 c 0.424 -0.212 0.896 0.156 0.896 0.696 v 52.092 c 0 0.3 -0.148 0.56 -0.368 0.68 l -31.72 18.384 a 0.54 0.54 90 0 1 -0.56 0 l -30.984 -17.528 c -0.22 -0.12 -0.368 -0.4 -0.368 -0.696 V 4.764 c 0 -0.54 0.472 -0.924 0.896 -0.696 L 39.604 19.64 c 0.236 0.12 0.396 0.4 0.396 0.696 v 33.276"
                stroke={color}
                strokeWidth={3}
                strokeLinecap="round"
            />
        </Icon>
    )
}
