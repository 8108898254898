import React, { useEffect } from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'

type AnimatedArrowProps = {
    isRotated: boolean,
    iconSize?: number
}

export const AnimatedArrow: React.FunctionComponent<AnimatedArrowProps> = ({
    isRotated,
    iconSize = 24
}) => {
    const animatedArrowRotation = useSharedValue(0)
    const animatedArrowStyles = useAnimatedStyle(() => ({
        transform: [{
            rotateZ: `${animatedArrowRotation.value}deg`
        }]
    }))

    const toggleArrow = () => {
        if (isRotated) {
            return animatedArrowRotation.value = withTiming(180)
        }

        animatedArrowRotation.value = withTiming(0)
    }

    useEffect(() => {
        toggleArrow()
    }, [isRotated])

    return (
        <Animated.View style={animatedArrowStyles}>
            <Icons.ArrowDown size={iconSize}/>
        </Animated.View>
    )
}
