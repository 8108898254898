import React from 'react'
import { View } from 'react-native'
import { Typography } from 'lib/components'
import { conditionalStyle, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { Store } from 'lib/models'
import { Nullable } from 'lib/types'

type ChooseStoreTileProps = {
    store: Nullable<Store>,
    isLastItem?: boolean
}

export const ChooseStoreTile: React.FunctionComponent<ChooseStoreTileProps> = ({
    store,
    isLastItem
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.item,
                    ...conditionalStyle(Boolean(isLastItem), styles.removeBottomBorder)
                }}
            >
                <Typography.Subheading style={styles.text}>
                    {store?.storeName}, {store?.country}, {store?.city}
                </Typography.Subheading>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        height: 42,
        paddingHorizontal: theme.gap * 3
    },
    item: {
        borderBottomWidth: 1,
        paddingVertical: 12,
        borderColor: hexToRGBA(theme.colors.lighterGray, 0.1)
    },
    text: {
        color: theme.colors.lighterGray,
        fontSize: 14,
        lineHeight: 16
    },
    removeBottomBorder: {
        borderBottomWidth: 0
    }
}))
