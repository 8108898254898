import React from 'react'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { Touchable } from './Touchable'

export type PopperProps = {
    isOpen: boolean,
    children: React.ReactNode,
    onClose: VoidFunction
}

export const Popper: React.FunctionComponent<PopperProps> = ({
    isOpen,
    children,
    onClose
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <Touchable
                onPress={onClose}
                style={conditionalStyle(isOpen, styles.closeSelectBackground)}
            />
            {isOpen && (
                children
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(() => ({
    closeSelectBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'default'
    }
}))
