try {
    window.localStorage
} catch {
    // eslint-disable-next-line functional/no-let
    let inMemoryStorage = {}

    Object.defineProperty(window, 'localStorage', {
        value: {
            inMemory: true,
            key: (nth: number) => Object
                .keys(inMemoryStorage)
                .at(nth),
            clear: () => {
                inMemoryStorage = {
                    inMemory: true
                }
            },
            removeItem: (key: string) => {
                delete inMemoryStorage[key]
            },
            setItem: (key: string, value: string) => {
                inMemoryStorage[key] = value
            },
            getItem: (key: string) => inMemoryStorage[key]
        }
    })
}

import { MMKV } from 'react-native-mmkv'

export const _mmkv = new MMKV()
