import React, { useEffect } from 'react'
import { ActivityIndicator, View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Grid, Touchable, Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { R } from 'lib/utils'

type SwitchProps = {
    disabled?: boolean,
    isToggled: boolean,
    label?: string,
    labelComponent?: JSX.Element,
    isLoading?: boolean,
    onToggle(value: boolean): void
}

export const Switch: React.FunctionComponent<SwitchProps> = ({
    disabled,
    isToggled,
    label,
    labelComponent,
    isLoading,
    onToggle
}) => {
    const { theme, styles } = useStyles(stylesheet)
    const animatedTranslateX = useSharedValue<number>(isToggled ? 40 : 12)
    const animatedBackgroundColor = useSharedValue(isToggled ? theme.ui.primary : theme.ui.accent)
    const animatedBackgroundStyles = useAnimatedStyle(() => ({
        backgroundColor: animatedBackgroundColor.value
    }))
    const animatedSwitchControlStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: animatedTranslateX.value
            }
        ]
    }))

    useEffect(() => {
        animatedTranslateX.value = withTiming(isToggled ? 40 : 12)
        animatedBackgroundColor.value = withTiming(isToggled ? theme.ui.primary : theme.ui.accent)
    }, [isToggled])

    return (
        <View style={styles.container}>
            <View style={styles.switchContainer}>
                <Touchable
                    onPress={() => disabled
                        ? R.T
                        : onToggle(isToggled)
                    }
                >
                    <Animated.View
                        style={[
                            styles.switch,
                            animatedBackgroundStyles
                        ]}
                    >
                        <Animated.View
                            style={[
                                styles.switchControl,
                                animatedSwitchControlStyles
                            ]}
                        >
                            {Boolean(isLoading) && (
                                <ActivityIndicator/>
                            )}
                        </Animated.View>
                    </Animated.View>
                </Touchable>
            </View>
            {Boolean(label || labelComponent) && (
                <Grid.Gap style={styles.labelWrapper}>
                    {labelComponent || (
                        <Typography.Regular style={styles.label}>
                            {label}
                        </Typography.Regular>
                    )}
                </Grid.Gap>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginVertical: theme.utils.gap(2)
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 15,
        width: 60,
        ...theme.utils.createShadow()
    },
    switch: {
        width: 60,
        height: 30,
        borderRadius: 15
    },
    switchControl: {
        height: 24,
        width: 24,
        borderRadius: 12,
        backgroundColor: theme.colors.white,
        position: 'relative',
        top: 3,
        left: -8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    labelWrapper: {
        flex: 1,
        paddingTop: 6,
        paddingLeft: theme.utils.gap(2)
    },
    label: {
        flex: 1,
        marginRight: theme.utils.gap(2)
    }
}))
