import React from 'react'
import { BlurModal, Button, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { conditionalStyle, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { isWeb } from 'lib/common'

type CustomModalScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.CustomModal>
}

export const CustomModalScreen: React.FunctionComponent<CustomModalScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const {
        title,
        message,
        onClose,
        filledButtonText,
        outlineButtonText,
        onFilledPress,
        onOutlinePress
    } = route.params

    return (
        <BlurModal
            isVisible
            onClose={onClose || navigation.goBack}
            backgroundStyle={conditionalStyle(isWeb, styles.background)}
        >
            {title && (
                <Typography.Heading style={styles.text}>
                    {title}
                </Typography.Heading>
            )}
            <Grid.Gap gapBottom={2}/>
            <Typography.Subheading style={styles.text}>
                {message}
            </Typography.Subheading>
            <Grid.Gap gapBottom={6}/>
            <Button
                text={filledButtonText || T.common.ok}
                onPress={onFilledPress || onClose || navigation.goBack}
            />
            {(outlineButtonText && onOutlinePress) && (
                <React.Fragment>
                    <Grid.Gap gapBottom={3}/>
                    <Button
                        noBackground
                        text={outlineButtonText}
                        onPress={onOutlinePress}
                    />
                </React.Fragment>
            )}
        </BlurModal>
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(3)
    },
    background: {
        backgroundColor: hexToRGBA(theme.ui.background, 0.5)
    }
}))

