import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles, hexToRGBA, conditionalStyle, useStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type SelectLimitProps = {
    currentLimit: number,
    limits: Array<number>,
    onLimitChange: (limit: number) => void
}

export const SelectLimit: React.FunctionComponent<SelectLimitProps> = ({
    currentLimit,
    limits,
    onLimitChange
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const { styles } = useStyles(stylesheet)
    const animatedArrowRotation = useSharedValue(180)
    const animatedArrowStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotateZ: `${animatedArrowRotation.value}deg`
            }
        ]
    }))

    useEffect(() => {
        animatedArrowRotation.value = withTiming(animatedArrowRotation.value ? 0 : 180)
    }, [isOpen])

    return (
        <View style={styles.container}>
            <Touchable
                onPress={() => setIsOpen(false)}
                style={conditionalStyle(isOpen, styles.closeSelectBackground)}
            />
            <Touchable
                onPress={() => setIsOpen(prevValue => !prevValue)}
                style={styles.currentLimit}
            >
                <Typography.Regular style={styles.currentPageText}>
                    {currentLimit}
                </Typography.Regular>
                <View style={styles.arrow}>
                    <Animated.View style={animatedArrowStyles}>
                        <Icons.ArrowDown size={24}/>
                    </Animated.View>
                </View>
            </Touchable>
            {isOpen && (
                <View style={styles.limitsContainer}>
                    {limits.map(limit => (
                        <Touchable
                            onPress={() => {
                                onLimitChange(limit)
                                setIsOpen(false)
                            }}
                            style={styles.limit}
                            key={limit}
                        >
                            <Typography.Regular style={styles.limitText}>
                                {limit}
                            </Typography.Regular>
                        </Touchable>
                    ))}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: 88
    },
    currentLimit: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap / 2,
        borderRadius: 12,
        height: 48,
        padding: 16,
        userSelect: 'none',
        backgroundColor: hexToRGBA(theme.colors.darkGray, 0.5)
    },
    limit: {
        padding: 1
    },
    limitText: {
        userSelect: 'none',
        fontSize: 20
    },
    closeSelectBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'default'
    },
    currentPageText: {
        position: 'relative',
        bottom: 4,
        fontSize: 20
    },
    arrow: {
        position: 'relative',
        bottom: 4,
        left: 6
    },
    limitsContainer: {
        backgroundColor: hexToRGBA(theme.colors.darkGray, 0.5),
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        bottom: 60,
        padding: 16,
        borderRadius: 12,
        gap: theme.gap / 2
    }
}))
