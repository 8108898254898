import { View } from 'react-native'
import Toast, { BaseToast, ToastProps } from 'react-native-toast-message'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useIsWithinBreakpoints } from 'lib/hooks'
import { Measurements, isNative, isAndroid } from 'lib/common'
import { R } from 'lib/utils'

enum ToastType {
    Success = 'success',
    Error = 'error'
}

enum ToastHeader {
    Success = 'Success',
    Error = 'Error'
}

export const useToast = () => {
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)
    const { styles } = useStyles(stylesheets)

    const config = {
        success: (props: ToastProps) => (
            <View
                style={{
                    ...conditionalStyle(isMobileView, styles.mobileToastWrapper),
                    ...conditionalStyle(!isMobileView, styles.webToastWrapper)
                }}
            >
                <BaseToast
                    {...props}
                    style={styles.success}
                    text1Style={styles.text1}
                    text2Style={styles.text2}
                    text2NumberOfLines={3}
                />
            </View>
        ),
        error: (props: ToastProps) => (
            <View
                style={{
                    ...conditionalStyle(isMobileView, styles.mobileToastWrapper),
                    ...conditionalStyle(!isMobileView, styles.webToastWrapper)
                }}
            >
                <BaseToast
                    {...props}
                    style={styles.error}
                    text1Style={styles.text1}
                    text2Style={styles.text2}
                    text2NumberOfLines={3}
                />
            </View>
        )
    }

    const topOffset = R.cond([
        [R.always(isAndroid), R.always(4)],
        [R.always(isNative), R.always(Measurements.StatusBarHeight)],
        [R.T, R.always(undefined)]
    ])()

    const showSuccessToast = (message: string) => Toast.show({
        type: ToastType.Success,
        text1: ToastHeader.Success,
        text2: message,
        visibilityTime: 3000,
        topOffset,
        onPress: () => Toast.hide()
    })

    const showErrorToast = (message: string) => Toast.show({
        type: ToastType.Error,
        text1: ToastHeader.Error,
        text2: message,
        visibilityTime: 3000,
        topOffset,
        onPress: () => Toast.hide()
    })

    return {
        state: {
            config
        },
        actions: {
            showSuccessToast,
            showErrorToast
        }
    }
}

const stylesheets = createStyles(theme => ({
    success: {
        borderLeftColor: theme.colors.pink,
        backgroundColor: theme.ui.foreground,
        width: '100%'
    },
    error: {
        borderLeftColor: theme.colors.red,
        backgroundColor: theme.ui.foreground,
        width: '100%'
    },
    mobileToastWrapper: {
        width: '100%',
        paddingHorizontal: theme.gap * 3,
        ...theme.utils.createShadow()
    },
    webToastWrapper: {
        width: 328,
        position: 'absolute',
        right: theme.gap * 2,
        ...theme.utils.createShadow()
    },
    text1: {
        color: theme.components.input.typography.text
    },
    text2: {
        fontWeight: '400',
        color: theme.components.input.typography.text
    }
}))
