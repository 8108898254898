import { useAtom } from 'jotai'
import { ScanConfig } from 'lib/models'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const defaultState = {
    isFastScan: true
} as ScanConfig

const { atom, resetAtom } = createAtom<ScanConfig>(defaultState, MMKVKey.ScanConfig)

export const useScanConfigAtom = () => useAtom(atom)
export const useResetScanConfigAtom = () => useAtom(resetAtom)
