import { Nullable } from './common'
import { UserType } from '../models'

export enum AuthFlowError {
    CanceledByUser = 'CanceledByUser',
    ServerError = 'ServerError'
}

export type AppleAuthResponse = {
    authorizationCode: string,
    fullName: Nullable<string>
}

export type GoogleAuthResponse = {
    id_token: string
}

export type GoogleOneTapResponse = {
    credential: string
}

export type BiometryInfo = {
    userType: UserType,
    email: string
}
