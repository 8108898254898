import React from 'react'
import { Grid, Typography } from 'lib/components'
import { useTranslations } from '../hooks'
import { createStyles, useStyles } from '../styles'
import { BlurModal } from './BlurModal'
import { Button } from './Button'

type ConfirmModalProps = {
    isVisible: boolean,
    title?: string,
    message: string,
    confirmText?: string,
    cancelText?: string,
    isLoading?: boolean,
    onClose: VoidFunction,
    onCancel?: VoidFunction,
    onConfirm: VoidFunction
}

export const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({
    isVisible,
    title,
    message,
    confirmText,
    cancelText,
    isLoading,
    onClose,
    onCancel,
    onConfirm
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <BlurModal
            isVisible={isVisible}
            onClose={onClose}
            style={styles.container}
        >
            {title && (
                <Typography.Heading>
                    {title}
                </Typography.Heading>
            )}
            <Grid.Gap gapBottom={2}/>
            <Typography.Subheading style={styles.message}>
                {message}
            </Typography.Subheading>
            <Grid.Gap gapBottom={6}/>
            <Button
                isLoading={isLoading}
                text={confirmText || T.common.delete}
                onPress={onConfirm}
            />
            <Grid.Gap gapBottom={3}/>
            <Button
                disabled={isLoading}
                noBackground
                text={cancelText || T.common.cancel}
                onPress={onCancel || onClose}
            />
        </BlurModal>
    )
}

const stylesheet = createStyles(theme => ({
    message: {
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(3)
    },
    container: {
        borderRadius: 12,
        paddingBottom: theme.gap * 4
    }
}))
