export enum NewItemFormFields {
    BookCode = 'bookCode',
    Title = 'title',
    Author = 'author',
    Category = 'category',
    SKU = 'sku',
    Quantity = 'quantity'
}

export enum EditItemFormFields {
    BookCode = 'bookCode',
    Quantity = 'quantity'
}

export type NewItemFormShape = {
    [NewItemFormFields.BookCode]: string,
    [NewItemFormFields.Title]: string,
    [NewItemFormFields.Author]: string,
    [NewItemFormFields.Category]: string,
    [NewItemFormFields.SKU]: string,
    [NewItemFormFields.Quantity]: string
}

export type EditItemFormShape = {
    [EditItemFormFields.BookCode]: string,
    [EditItemFormFields.Quantity]: string
}
