import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { useFloating } from '@floating-ui/react-native'
import { Touchable, Typography } from 'lib/components'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { TooltipPlacement } from 'lib/types'

type ActionProps = {
    label: string,
    tooltipPlacement: TooltipPlacement,
    icon: React.ReactNode,
    onPress: VoidFunction
}

export const Action: React.FunctionComponent<ActionProps> = ({
    label,
    icon,
    tooltipPlacement,
    onPress
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const { styles } = useStyles(stylesheet)
    const {
        refs,
        floatingStyles
    } = useFloating({
        placement: tooltipPlacement,
        sameScrollView: true
    })

    return (
        <View>
            <View
                ref={refs.setFloating}
                style={{
                    ...floatingStyles as ViewStyle,
                    ...styles.tooltip as ViewStyle,
                    ...conditionalStyle(isTooltipVisible, { opacity: 1 }),
                    ...conditionalStyle(!isTooltipVisible, { opacity: 0 })
                }}
            >
                <Typography.SubText style={styles.text}>
                    {label}
                </Typography.SubText>
            </View>
            <Touchable onPress={onPress}>
                <View
                    ref={refs.setReference}
                    onMouseEnter={() => setIsTooltipVisible(true)}
                    onMouseLeave={() => setIsTooltipVisible(false)}
                >
                    {icon}
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    tooltip: {
        backgroundColor: theme.ui.background,
        padding: theme.gap,
        width: 'max-content',
        borderRadius: theme.gap,
        pointerEvents: 'none',
        ...theme.utils.createShadow()
    },
    text: {
        color: theme.colors.white
    }
}))
