import React from 'react'
import { FormComponents } from 'lib/components'
import { CheckboxProps } from 'lib/types'

export const Checkbox = (props: CheckboxProps) => (
    <FormComponents.Checkbox
        form={props.form}
        customLabel={props.customLabel}
        styles={props.styles}
    />
)
