import React from 'react'
import { Button, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { ScannerAnimated, ScannerBox } from '../components'

type ScannerContentProps = {
    hasDisabledCamera: boolean,
    isModalVisible: boolean,
    isCameraEnabled: boolean,
    onOpenSettingsPress: VoidFunction
}

export const ScannerContent: React.FunctionComponent<ScannerContentProps> = ({
    hasDisabledCamera,
    isModalVisible,
    isCameraEnabled,
    onOpenSettingsPress
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <Typography.Subheading style={styles.text}>
                {hasDisabledCamera
                    ? ` ${T.screens.scanner.permissionWarning} `
                    : ` ${T.screens.scanner.instruction} `
                }
            </Typography.Subheading>
            <Grid.Gap gapTop={2}>
                {hasDisabledCamera ? (
                    <Grid.Gap
                        gapTop={4}
                        gapBottom={2}
                    >
                        <Button
                            width={200}
                            noBackground
                            customStyleText={styles.text}
                            customStyle={styles.button}
                            text={T.screens.scanner.button}
                            onPress={onOpenSettingsPress}
                        />
                    </Grid.Gap>
                ) : (
                    <ScannerBox>
                        {!isModalVisible && isCameraEnabled && (
                            <ScannerAnimated/>
                        )}
                    </ScannerBox>
                )}
            </Grid.Gap>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        color: theme.colors.white,
        textAlign: 'center',
        textShadowColor: theme.colors.black,
        textShadowRadius: 1,
        marginHorizontal: theme.utils.gap(3),
        zIndex: 101
    },
    button: {
        borderColor: theme.colors.white
    }
}))
