import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Apple: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M 74.07 62.181 a 42.21 42.21 90 0 1 -4.1715 7.5015 c -2.196 3.132 -3.9915 5.2965 -5.3775 6.498 c -2.1465 1.9755 -4.446 2.988 -6.9075 3.042 c -1.7685 0 -3.9015 -0.4995 -6.3855 -1.521 c -2.4885 -1.017 -4.7745 -1.521 -6.867 -1.521 c -2.196 0 -4.5495 0.504 -7.065 1.521 c -2.52 1.0215 -4.554 1.5525 -6.1065 1.6065 c -2.3625 0.099 -4.716 -0.945 -7.065 -3.1275 c -1.503 -1.305 -3.375 -3.5505 -5.625 -6.7275 c -2.412 -3.3885 -4.392 -7.326 -5.9445 -11.808 c -1.665 -4.8465 -2.4975 -9.54 -2.4975 -14.076 c 0 -5.2065 1.125 -9.693 3.375 -13.455 a 19.8 19.8 90 0 1 7.0695 -7.146 a 19.017 19.017 90 0 1 9.558 -2.7 c 1.8765 0 4.338 0.585 7.3935 1.7235 c 3.0465 1.143 5.004 1.7235 5.8635 1.7235 c 0.6435 0 2.817 -0.675 6.5025 -2.0295 c 3.4875 -1.26 6.426 -1.7775 8.838 -1.575 c 6.5295 0.531 11.439 3.105 14.7015 7.74 c -5.841 3.5415 -8.73 8.5005 -8.676 14.859 c 0.054 4.95 1.854 9.072 5.382 12.348 c 1.602 1.5165 3.3885 2.691 5.3775 3.5235 c -0.432 1.251 -0.8865 2.448 -1.3725 3.6 Z M 59.094 3.24 c 0 3.8835 -1.4175 7.506 -4.248 10.863 c -3.411 3.987 -7.5375 6.291 -12.015 5.931 a 12.087 12.087 90 0 1 -0.09 -1.4715 c 0 -3.7305 1.6245 -7.7175 4.509 -10.98 c 1.44 -1.647 3.267 -3.024 5.4855 -4.1175 c 2.214 -1.08 4.3065 -1.674 6.2775 -1.7775 c 0.054 0.5175 0.081 1.035 0.081 1.5525 Z"
            fill="#1F1F1F"
        />
    </Icon>
)
