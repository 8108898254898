import { Field } from '@codegateinc/react-form-builder-v2'
import { UserType, Store } from 'lib/models'
import { DropdownOption } from 'lib/types'

export enum AddEditUserFields {
    FullName = 'fullName',
    Email = 'email',
    Password = 'password',
    ConfirmPassword = 'confirmPassword',
    SecurityGroup = 'securityGroup',
    Stores = 'stores'
}

export type AddEditUserShape = {
    fullName: Field<string>,
    email: Field<string>,
    password: Field<string>,
    confirmPassword: Field<string>,
    securityGroup: Field<UserType | undefined>,
    stores: Field<Array<DropdownOption<Store>>>
}
