export type Filter = {
    id: string
    title: string
}

export enum SearchItemKey {
    Available = 'available',
    OnPO = 'onPO',
    MTD = 'mtd',
    YTD = 'ytd',
    ISBN = 'isbn',
    Title = 'title',
    Author = 'author',
    Department = 'department',
    Store = 'store',
    Publisher = 'publisher',
    Price = 'price'
}

export enum SearchFilterKey {
    StoreDescription = 'STORE_DESCRIPTION',
    ProductNumber = 'PRODUCT_NUMBER',
    BindingDescription = 'BINDING_DESCRIPTION',
    AuthorShortName = 'AUTHOR_SHORT_NAME',
    AuthorFirstName = 'AUTHOR_FIRST_NAME',
    AuthorLastName = 'AUTHOR_LAST_NAME',
    SKU = 'SKU'
}

export type Input = {
    filter: string,
    value: string
}
