import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'
import { createStyles, conditionalStyle, useStyles } from 'lib/styles'
import { CheckboxProps } from 'lib/types'
import { ErrorMessage } from './ErrorMessage'

export const Checkbox = ({
    form,
    customLabel,
    styles: customStyles = {}
}: CheckboxProps) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...customStyles
            }}
        >
            <View style={styles.content}>
                <Touchable
                    onPress={() => form.onChangeValue(!form.value)}
                    style={{
                        ...styles.checkbox,
                        ...customStyles,
                        ...conditionalStyle(form.value, styles.active)
                    }}
                >
                    {form.value && (
                        <Icons.Checkmark
                            size={22}
                            forceColor={theme.colors.white}
                        />
                    )}
                </Touchable>
                {customLabel && customLabel}
                {form.label && form.label}
            </View>
            {form.errorMessage && (
                <ErrorMessage text={form.errorMessage} />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    checkbox: {
        width: 24,
        height: 24,
        borderRadius: 4,
        backgroundColor: theme.ui.foreground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    active: {
        backgroundColor: theme.colors.pink
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    }
}))
