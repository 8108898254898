import React from 'react'
import { View } from 'react-native'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { BlurModal, Button, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { useDeleteAccount } from '../actions'

export const RemoveAccount: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isActive, setIsActive] = React.useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const { mutate: deleteAccount } = useDeleteAccount()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <BlurModal
                onClose={() => setIsActive(false)}
                isVisible={isActive}
                children={(
                    <View style={styles.modal}>
                        <Typography.SubText style={styles.modalText}>
                            {T.screens.adminSetup.removeAccount.confirm}
                        </Typography.SubText>
                        <View
                            style={{
                                ...styles.buttons,
                                ...conditionalStyle(isMobile, styles.buttonsMobile)
                            }}
                        >
                            <Button
                                text={T.common.cancel}
                                noBackground
                                onPress={() => setIsActive(false)}
                                customStyle={styles.action}
                            />
                            <Button
                                text={T.common.yes}
                                onPress={deleteAccount}
                                customStyle={styles.action}
                            />
                        </View>
                    </View>
                )}
            />
            <Typography.Heading style={styles.text}>
                {T.screens.adminSetup.removeAccount.header}?
            </Typography.Heading>
            <Typography.SubText style={styles.text}>
                {T.screens.adminSetup.removeAccount.subText}
            </Typography.SubText>
            <Button
                noBackground
                text={T.common.delete}
                onPress={() => setIsActive(true)}
                customStyle={styles.button}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.gap * 3
    },
    button: {
        marginTop: theme.gap * 2,
        width: '50%'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    },
    action: {
        flexShrink: 1,
        flexGrow: 1
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap * 3,
        padding: theme.gap * 3,
        minWidth: {
            sm: 500
        }
    },
    modalText: {
        textAlign: 'center',
        color: theme.colors.white
    },
    text: {
        textAlign: 'center'
    },
    buttonsMobile: {
        flexDirection: 'column',
        alignItems: 'center'
    }
}))
