import React from 'react'

export type Dimensions = {
    width?: number,
    height?: number
}

export type ColumnConfig = {
    key: string,
    title?: string,
    size?: number,
    previewElement?: boolean
}

export type Action<T> = {
    key: string,
    label: string,
    tooltipPlacement: TooltipPlacement,
    icon: React.ReactNode,
    havePermissions?: boolean,
    action: (params: T) => void
}

export enum TooltipPlacement {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right'
}

export enum TableAction {
    Edit = 'edit',
    Remove = 'remove',
    Enter = 'enter'
}

export type PaginationState = {
    currentPage: number,
    totalPages: number,
    limit: number,
    offset: number,
    availableLimits: Array<number>
}

export type PaginationActions = {
    setPage: (page: number) => void,
    setLimit: (limit: number) => void,
    clearPaginationState: VoidFunction
}

export type Pagination = {
    state: PaginationState,
    actions: PaginationActions
}

export enum PaginationSide {
    Left = 'left',
    Right = 'right'
}

export enum UrlSearchParams {
    Page = 'page',
    Limit = 'limit'
}

export enum TableItemKey {
    BookCode = 'bookCode',
    Quantity = 'quantity'
}
