import { from, lastValueFrom } from 'rxjs'
import qs from 'qs'
import axios, { AxiosRequestConfig, CustomParamsSerializer } from 'axios'
import { catchError, delay, switchMap } from 'rxjs/operators'
import { Alert } from 'react-native'
import { authTypes, authUtils } from 'features/auth'
import { useTranslations, useCleanApp } from 'lib/hooks'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { ErrorCodes, ErrorResponse, HttpMethod, KeyValuePair, Response } from 'lib/types'
import { setAxiosDefaults } from './config'

setAxiosDefaults()

type FetcherConfig = {
    responseDelay?: number,
    timeout?: number,
    urlParams?: string
}

export const useFetcher = <ResponseType>(method: HttpMethod, url: string) => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { cleanPersonalData } = useCleanApp()

    return (params: KeyValuePair = {}, config?: FetcherConfig) => {
        const requestConfig = {
            url: config?.urlParams
                ? `${url}${config.urlParams}`
                : url,
            method,
            data: method !== HttpMethod.GET
                ? params
                : undefined,
            params: method === HttpMethod.GET
                ? params
                : undefined,
            paramsSerializer: {
                serialize: qs.stringify as CustomParamsSerializer
            }
        } as AxiosRequestConfig

        return lastValueFrom(from(axios.request<ResponseType>(requestConfig))
            .pipe(
                delay(config?.responseDelay || 0),
                catchError((error: Response<ErrorResponse>) => {
                    if (error.response?.status === ErrorCodes.Unauthorized && isAuthorized) {
                        const refreshToken = authUtils.getRefreshToken()

                        return lastValueFrom(from(axios({
                            url: '/auth/refresh-token',
                            method: HttpMethod.POST,
                            data: {
                                refreshToken
                            }
                        })
                            .then((response: Response<authTypes.RefreshTokenResponse>) => {
                                authUtils.saveAccessToken(response.data.accessToken)

                                return response
                            })
                            .catch(refreshTokenError => {
                                cleanPersonalData()

                                throw refreshTokenError.response
                            }))
                            .pipe(switchMap(() => axios.request<ResponseType>(requestConfig)))
                        )
                    }

                    if (error.status === ErrorCodes.TooManyRequests) {
                        Alert.alert(T.alerts.tooManyRequests.title, T.alerts.tooManyRequests.message)
                    }

                    throw error.response
                })
            ))
    }
}
