import { Alert } from 'react-native'
import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useFetcher } from '../api'
import { HttpMethod, Limit } from '../types'
import { useTranslations } from './useTranslations'

type QueryOptions<ItemType> =
    Partial<UseInfiniteQueryOptions<
    AxiosResponse<Array<ItemType>>,
    AxiosError,
    AxiosResponse<Array<ItemType>>,
    AxiosResponse<Array<ItemType>>,
    Array<string>
    >>

type UseGetInfiniteQueryProps<ItemType, QueryParams> = {
    query?: string,
    limit?: Limit,
    url: string,
    queryKeys: Array<string>,
    queryParams?: QueryParams,
    queryOptions?: QueryOptions<ItemType>,
    onSuccess?(items: Array<ItemType>): void
}

export const useGetInfiniteQuery = <ItemType, QueryParams>({
    query = undefined,
    limit = Limit.Default,
    url,
    queryKeys,
    queryParams,
    queryOptions,
    onSuccess = () => {}
}: UseGetInfiniteQueryProps<ItemType, QueryParams>) => {
    const T = useTranslations()
    const fetcher = useFetcher<Array<ItemType>>(HttpMethod.GET, url)
    const fetcherProxy = ({ pageParam = 0 }) => fetcher({
        ...queryParams,
        offset: pageParam,
        limit,
        query
    })

    return useInfiniteQuery([...queryKeys, query || ''], fetcherProxy, {
        retry: 5,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
        onSuccess: ({ pages }) => {
            const items = pages.flatMap(page => page.data)

            onSuccess(items)
        },
        onError: () => Alert.alert(T.common.error, T.common.errorMessage),
        getNextPageParam: (lastPage, allPages) => {
            const itemsCount = allPages.flatMap(page => page.data).length
            const expectedCount = Number(lastPage.config.params?.limit || 0) + Number(lastPage.config.params?.offset || 0)

            return itemsCount < expectedCount
                ? undefined
                : expectedCount
        },
        ...queryOptions
    })
}
