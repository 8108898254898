import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { Measurements, isNative, isSmallPhone, isWeb } from 'lib/common'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles, useStyles, conditionalStyle } from 'lib/styles'
import { Adapter, Grid, Typography, Button, GoBack, Logo } from 'lib/components'
import { UserType } from 'lib/models'
import { ScreenNames } from 'lib/routing'
import { inputProps } from 'lib/utils'
import { SignUpWithEmailFormShape } from '../forms'
import { SignUpFormProps as SignUpFormPropsType } from '../types'
import { AuthMethods } from './AuthMethods'
import { TermsAndConditions } from './TermsAndConditions'

export type SignUpFormProps = {
    isLoading: boolean,
    form: SignUpWithEmailFormShape,
    formProps?: SignUpFormPropsType,
    onSubmit: VoidFunction
}

export const SignUpForm: React.FunctionComponent<SignUpFormProps> = ({
    isLoading,
    formProps,
    form,
    onSubmit
}) => {
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {isWeb && !isMobileView && (
                <GoBack backgroundStyle={styles.goBackWeb}/>
            )}
            <View style={styles.form}>
                {isMobileView && (
                    <GoBack backgroundStyle={styles.goBackMobile} />
                )}
                <Logo size={isSmallPhone ? 60 : 80}/>
                <Grid.Gap gapBottom={2}/>
                <Typography.Heading>
                    {T.screens.signUp.title}
                </Typography.Heading>
                <Grid.Gap gapBottom={3}/>
                <View style={styles.separator}>
                    <Typography.Label style={styles.separatorText}>
                        {T.screens.signUp.message}
                    </Typography.Label>
                </View>
                <Grid.Gap gapBottom={4}/>
                <AuthMethods
                    userType={UserType.Admin}
                    showBiometry={false}
                />
                <Grid.Gap gapBottom={4}/>
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        style={styles.textInput}
                        {...form.fullName}
                        {...formProps?.fullNameProps}
                        inputProps={inputProps.name}
                    />
                    <Adapter.TextInput
                        style={styles.textInput}
                        {...form.email}
                        {...formProps?.emailProps}
                        inputProps={inputProps.email}
                        leftIcon={(
                            <Icons.Envelope size={22}/>
                        )}
                    />
                </View>
                <View style={styles.formRow}>
                    <Adapter.TextInput
                        style={styles.textInput}
                        {...form.password}
                        {...formProps?.passwordProps}
                        leftIcon={(
                            <Icons.Padlock size={20}/>
                        )}
                        isPassword
                        inputProps={inputProps.bypassIosStrongPassword}
                    />
                    <Adapter.TextInput
                        style={styles.textInput}
                        {...form.confirmPassword}
                        {...formProps?.confirmPasswordProps}
                        leftIcon={(
                            <Icons.Padlock size={20}/>
                        )}
                        isPassword
                        inputProps={inputProps.bypassIosStrongPassword}
                    />
                </View>
                <View style={styles.formRow}>
                    <Adapter.Checkbox
                        styles={conditionalStyle(isNative, styles.checkBox)}
                        form={form.termsAndConditions}
                        customLabel={(
                            <TermsAndConditions/>
                        )}
                    />
                </View>
                <Grid.Gap gapBottom={2}/>
                <Button
                    isLoading={isLoading}
                    text={T.screens.signUp.button}
                    onPress={onSubmit}
                    width={Measurements.WindowWidth - 6 * theme.gap}
                    customStyle={styles.signUpButton}
                />
                <Grid.Gap gapBottom={4}/>
                <Typography.SubText style={styles.footerText}>
                    {`${T.screens.signUp.accountExist} `}
                    <Typography.Regular
                        style={styles.signUp}
                        onPress={() => navigation.navigate(ScreenNames.SignIn, {
                            userType: UserType.Admin
                        })}
                    >
                        {T.screens.signUp.signIn}
                    </Typography.Regular>
                </Typography.SubText>
                <Grid.Gap gapBottom={4}/>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            xs: '100%',
            sm: '75%'
        },
        backgroundColor: {
            sm: theme.colors.almostBlack
        },
        padding: {
            sm: 80
        },
        borderRadius: {
            sm: 40
        },
        maxWidth: 1200,
        display: 'flex',
        alignItems: 'center'
    },
    form : {
        width: '100%',
        maxWidth: 676,
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    separator: {
        height: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.components.typography.secondary.color,
        overflow: 'visible'
    },
    separatorText: {
        fontSize: 12,
        height: 14,
        lineHeight: 14,
        textAlign: 'center',
        color: theme.components.typography.secondary.color,
        paddingHorizontal: theme.gap * 1.5,
        backgroundColor: theme.ui.background
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        flexWrap: {
            ':w[0,880]': 'wrap'
        },
        gap: {
            ':w[880]': theme.gap * 2
        }
    },
    signUpButton: {
        maxWidth: '100%'
    },
    footerText: {
        alignSelf: 'center'
    },
    signUp: {
        textDecorationLine: 'underline',
        color: theme.colors.pink
    },
    textInput: {
        flexShrink: 1
    },
    goback: {
        position: 'absolute',
        top: theme.gap * 3,
        left: theme.gap * 3
    },
    goBackMobileView: {
        position: 'absolute',
        left: 0,
        top: -theme.gap * 2
    },
    goBackWeb: {
        position: 'absolute',
        top: theme.gap * 3,
        left: theme.gap * 3
    },
    goBackMobile: {
        position: 'absolute',
        left: 0,
        top: 0
    },
    checkBox: {
        bottom: 6
    }
}))
