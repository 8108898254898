import React from 'react'
import { Icons } from 'assets'
import { Grid, Touchable } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type ScannerIconsProps = {
    isCameraEnabled: boolean,
    onTorchPress: VoidFunction,
    onNumpadPress: VoidFunction
}

export const ScannerIcons: React.FunctionComponent<ScannerIconsProps> = ({
    isCameraEnabled,
    onTorchPress,
    onNumpadPress
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Grid.Gap style={styles.row}>
            {isCameraEnabled && (
                <Grid.Gap gapRight={4}>
                    <Touchable onPress={onTorchPress}>
                        <Icons.Flash size={60}/>
                    </Touchable>
                </Grid.Gap>
            )}
            <Touchable onPress={onNumpadPress}>
                <Icons.Numpad size={60}/>
            </Touchable>
        </Grid.Gap>

    )
}

const stylesheet = createStyles(() => ({
    row: {
        flexDirection: 'row',
        zIndex: 101
    }
}))
