import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { storeActions } from 'features/store'
import { useScanConfigAtom, useUserAtom } from 'lib/atoms'
import { Measurements } from 'lib/common'
import { Button, FormComponents, FullScreenLoader, GoBack, Grid, Touchable, Typography } from 'lib/components'
import { useFocusRefetch, useTranslations } from 'lib/hooks'
import { Store, UserType } from 'lib/models'
import { createStyles, useStyles } from 'lib/styles'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { R } from 'lib/utils'
import { ChooseStoreTile } from '../components'

type ChooseScanStoreScreenProps = {
    navigation: NavigationProps<ScreenNames>
}

export const ChooseScanStoreScreen: React.FunctionComponent<ChooseScanStoreScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const [store, setStore] = useState<Store>()
    const { styles, theme } = useStyles(stylesheet)
    const [scanConfig, setScanConfig] = useScanConfigAtom()
    const [user] = useUserAtom()
    const {
        isFetching,
        isStale,
        refetch: getStores,
        data: response
    } = storeActions.useGetStores()
    const stores = response?.data.stores || []

    useEffect(() => {
        if (stores?.length === 1) {
            setScanConfig({
                isFastScan: scanConfig.isFastScan
            })
        }
    }, [stores])

    useEffect(() => {
        if (isStale) {
            getStores()
                .catch(R.T)
        }
    }, [isStale])

    useFocusRefetch(() => {
        getStores()
    })

    return (
        <Grid.Background
            hasPaddingHorizontal
            style={styles.background}
        >
            <GoBack/>
            <Typography.Heading>
                {T.screens.chooseScanStore.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={4}/>
            {stores.length !== 0 && (
                <Typography.Subheading>
                    {T.screens.chooseScanStore.message}
                </Typography.Subheading>
            )}
            <Grid.Gap gapBottom={5}/>
            <FullScreenLoader
                isActive={isFetching && stores.length === 0}
                color={theme.colors.pink}
            />
            {stores.length > 0 ? (
                <React.Fragment>
                    <FormComponents.Dropdown
                        value={store || null}
                        items={stores}
                        isLoading={isFetching}
                        getItemKey={store => store?.storeUUID || ''}
                        getItemLabel={store => `${store?.storeName}, ${store?.country}, ${store?.city}` || ''}
                        renderCustomItem={(item, index) => (
                            <ChooseStoreTile
                                store={item}
                                isLastItem={index === stores.length - 1}
                            />
                        )}
                        onChangeValue={store => {
                            if (store) {
                                setStore(store)
                            }
                        }}
                        style={styles.dropdown}
                        placeholder={T.screens.chooseScanStore.title}
                    />
                    <Grid.Gap gapBottom={2}/>
                    <View style={styles.row}>
                        <Typography.Title>
                            {T.screens.chooseScanStore.fastScan}
                        </Typography.Title>
                        <Grid.Gap gapLeft={2}/>
                        <Touchable // todo replace with proper icon
                            style={styles.info}
                            onPress={() => navigation.navigate(ScreenNames.CustomModal, {
                                message: T.screens.chooseScanStore.fastScanDescription,
                                filledButtonText: T.common.ok,
                                onFilledPress: navigation.goBack
                            })}
                        >
                            <Typography.Label bold>
                                ?
                            </Typography.Label>
                        </Touchable>
                        <View style={styles.spacer}/>
                        <FormComponents.Switch
                            isToggled={Boolean(scanConfig.isFastScan)}
                            onToggle={value => setScanConfig(prevState => ({
                                ...prevState,
                                isFastScan: !value
                            }))}
                        />
                    </View>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <View style={styles.spacer}/>
                    {!isFetching && (
                        <Typography.Subheading>
                            {user.userType === UserType.Admin
                                ? T.screens.chooseScanStore.noStoresMessage.owner
                                : T.screens.chooseScanStore.noStoresMessage.user
                            }
                        </Typography.Subheading>
                    )}
                    {user.userType === UserType.Admin && !isFetching && (
                        <Grid.Gap gapTop={4}>
                            <Button
                                width={Measurements.WindowWidth - theme.utils.gap(6)}
                                text={T.screens.chooseScanStore.addStoreButton}
                                onPress={() => navigation.navigate(ScreenNames.StoreSetup)}
                            />
                        </Grid.Gap>
                    )}
                </React.Fragment>
            )}
            <View style={styles.spacer}/>
            {stores.length > 0 && (
                <Button
                    disabled={!store}
                    width={Measurements.WindowWidth - theme.utils.gap(6)}
                    text={T.screens.chooseScanStore.button}
                    onPress={() => {
                        if (store) {
                            navigation.navigate(ScreenNames.Scanner, {
                                store
                            })
                        }
                    }}
                />
            )}
            <Grid.Gap gapBottom={8}/>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    dropdown: {
        width: '100%',
        zIndex: 999
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    info: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.components.typography.secondary.color,
        alignItems: 'center',
        justifyContent: 'center'
    },
    spacer: {
        flex: 1
    },
    background: {
        alignItems: 'center'
    }
}))
