import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Google: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M 81.63 41.4225 c 0 -2.8755 -0.2565 -5.634 -0.738 -8.2845 H 42.75 v 15.6645 h 21.798 a 18.63 18.63 90 0 1 -8.082 12.222 v 10.1655 h 13.086 c 7.659 -7.0515 12.078 -17.4375 12.078 -29.7675 Z"
            fill="#4285F4"
        />
        <Path
            d="M 42.75 81 c 10.935 0 20.1015 -3.627 26.802 -9.81 l -13.086 -10.1655 c -3.627 2.43 -8.2665 3.87 -13.716 3.87 c -10.548 0 -19.476 -7.128 -22.662 -16.6995 H 6.5565 v 10.494 A 40.4865 40.4865 90 0 0 42.75 81 Z"
            fill="#34A853"
        />
        <Path
            d="M 20.088 48.195 A 24.345 24.345 90 0 1 18.819 40.5 c 0 -2.6685 0.459 -5.265 1.269 -7.695 V 22.311 H 6.5565 A 40.4865 40.4865 90 0 0 2.25 40.5 c 0 6.534 1.566 12.7215 4.3065 18.189 l 13.5315 -10.494 Z"
            fill="#FBBC05"
        />
        <Path
            d="M 42.75 16.11 c 5.9445 0 11.286 2.043 15.48 6.0525 l 11.619 -11.61 C 62.8335 4.0095 53.667 0 42.75 0 a 40.4865 40.4865 90 0 0 -36.1935 22.311 L 20.088 32.805 C 23.274 23.2335 32.202 16.11 42.75 16.11 Z"
            fill="#EA4335"
        />
    </Icon>
)
