import { useAtom } from 'jotai'
import { Store } from 'lib/models'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const initialValue = {
    totalCount: 0,
    data: [] as Array<Store>
}

const { atom, resetAtom } = createAtom<typeof initialValue>(initialValue, MMKVKey.Stores)

export const useStoresAtom = () => useAtom(atom)
export const useResetStoresAtom = () => useAtom(resetAtom)
