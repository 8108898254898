import { useEffect } from 'react'
import { R } from 'lib/utils'
import { useSignInWithGoogle } from '../actions'
import { initGoogleSDK } from '../utils'
import { GOOGLE_WEB_BUTTON_CONTAINER } from '../constants'

export const useGoogleAuth = () => {
    const { mutate: signIn, isLoading } = useSignInWithGoogle()

    const renderButton = (screenName: string) => {
        const element = document.getElementById(`${GOOGLE_WEB_BUTTON_CONTAINER}-${screenName}`)

        if (element?.innerHTML) {
            return
        }

        if (window.google) {
            window.google.accounts.id.renderButton(document.getElementById(`${GOOGLE_WEB_BUTTON_CONTAINER}-${screenName}`), {
                shape: 'circle',
                type: 'icon'
            })
        }
    }

    const loadGoogleSDK = () => {
        initGoogleSDK('google-sdk', token => signIn({
            // eslint-disable-next-line camelcase
            id_token: token
        }))
            .catch(R.T)
    }

    useEffect(() => {
        loadGoogleSDK()
    }, [])

    return {
        isLoading,
        renderButton
    }
}
