import React from 'react'
import { ViewProps, View, ViewStyle } from 'react-native'
import { Measurements, isIOS, isNative } from 'lib/common'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'

interface BackgroundProps extends ViewProps {
    style?: ViewStyle,
    hasPaddingHorizontal?: boolean,
    hasPaddingVertical?: boolean
}

export const Background: React.FunctionComponent<BackgroundProps> = ({
    children,
    hasPaddingHorizontal = false,
    hasPaddingVertical = false,
    style = {},
    ...props
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View
            {...props}
            children={children}
            style={{
                ...conditionalStyle(isNative, styles.nativePadding),
                ...conditionalStyle(hasPaddingHorizontal, styles.horizontalPadding),
                ...conditionalStyle(hasPaddingVertical, styles.verticalPadding),
                ...styles.container,
                ...style
            }}
        />
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.ui.background
    },
    nativePadding: {
        paddingTop: {
            xs: isIOS
                ? Number(Measurements.StatusBarHeight) + theme.gap * 2
                : Number(Measurements.StatusBarHeight) / 2
        }
    },
    horizontalPadding: {
        paddingHorizontal: {
            xs: theme.contentPaddingMobile,
            sm: theme.contentPaddingWeb
        }
    },
    verticalPadding: {
        paddingVertical: {
            xs: theme.contentPaddingMobile,
            sm: theme.contentPaddingWeb
        }
    }
}))
