import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { StoreItem } from 'lib/models'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { GetItemDetailsRequest, PostScannedItemsRequest, PostScannedItemsResponse } from './types'

export const useGetItemDetails = () => {
    const fetcher = useFetcher<StoreItem>(HttpMethod.GET, '/store-item/search')

    return useMutation<Response<StoreItem>, ErrorResponse, GetItemDetailsRequest>(itemData => fetcher(itemData))
}

export const usePostScannedItems = () => {
    const fetcher = useFetcher<PostScannedItemsResponse>(HttpMethod.POST, '/store-item')

    return useMutation<Response<PostScannedItemsResponse>, ErrorResponse, PostScannedItemsRequest>(itemData => fetcher(itemData))
}
