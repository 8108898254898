import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AdminSetupScreen } from 'features/admin'
import { FilesScreen } from 'features/files'
import { CustomModalScreen, HomeScreen } from 'features/home'
import { ConfirmEmailChangeScreen } from 'features/auth'
import { SearchScreen, SearchDetails, SearchFilters } from 'features/search'
import {
    EditItemModalScreen,
    FinishScanningScreen,
    ItemAddedModalScreen,
    ItemDetailsScreen,
    NewItemScreen,
    ScannedItemsScreen
} from 'features/item'
import {
    ChooseScanStoreScreen,
    QuantityModalScreen,
    ResumeScanModalScreen,
    ScanDetailsScreen,
    ScannerScreen
} from 'features/scan'
import {
    DatabaseItemsScreen,
    ManageStoresScreen,
    StoreFilesScreen,
    StoreSetupScreen,
    ItemSetupScreen
} from 'features/store'
import { AddEditUserScreen, EditSelfScreen, ManageUsersScreen } from 'features/user'
import { isWeb } from 'lib/common'
import { useTranslations } from 'lib/hooks'
import { UserType } from 'lib/models'
import { ScreenNames, StackNavigatorKey } from './screens'
import { AppStackParams } from './stackParams'

const Stack = createNativeStackNavigator<AppStackParams>()

export const NativeStack = (userType: UserType) => {
    const T = useTranslations()
    const isAdmin = userType === UserType.Admin
    const isManager = userType === UserType.Manager
    const initialRoute = isAdmin
        ? ScreenNames.ManageUsers
        : ScreenNames.ManageStores

    return (
        <Stack.Navigator
            key={StackNavigatorKey.NativeStack}
            initialRouteName={isWeb ? initialRoute : ScreenNames.Home}
            screenOptions={{
                headerShown: false,
                freezeOnBlur: false
            }}
        >
            <Stack.Screen
                name={ScreenNames.Home}
                component={HomeScreen}
                options={{
                    title: T.titles.welcome
                }}
            />
            {(isAdmin || isManager) && (
                <Stack.Screen
                    name={ScreenNames.ManageUsers}
                    component={ManageUsersScreen}
                    options={{
                        title: T.titles.manageUsers
                    }}
                />
            )}
            <Stack.Screen
                name={ScreenNames.ManageStores}
                component={ManageStoresScreen}
                options={{
                    title: T.titles.manageStores
                }}
            />
            {(isAdmin || isManager) && (
                <Stack.Screen
                    name={ScreenNames.EditUser}
                    component={AddEditUserScreen}
                    options={{
                        title: T.titles.editUser
                    }}
                />
            )}
            {(isAdmin || isManager) && (
                <Stack.Screen
                    name={ScreenNames.AddUser}
                    component={AddEditUserScreen}
                    options={{
                        title: T.titles.addUser
                    }}
                />
            )}
            {(isAdmin || isManager) && (
                <Stack.Screen
                    name={ScreenNames.StoreSetup}
                    component={StoreSetupScreen}
                    options={{
                        title: T.titles.storeSetup
                    }}
                />
            )}
            <Stack.Screen
                name={ScreenNames.EditSelf}
                component={EditSelfScreen}
                options={{
                    title: T.titles.editSelf
                }}
            />
            <Stack.Screen
                name={ScreenNames.Search}
                component={SearchScreen}
                options={{
                    title: T.titles.search
                }}
            />
            <Stack.Screen
                name={ScreenNames.SearchItemDetails}
                component={SearchDetails}
                options={{
                    title: T.titles.searchItemDetails
                }}
            />
            <Stack.Screen
                name={ScreenNames.StoreItems}
                component={StoreFilesScreen}
                options={{
                    title: T.titles.storeItems
                }}
            />
            <Stack.Screen
                name={ScreenNames.ItemSetup}
                component={ItemSetupScreen}
                options={{
                    title: T.titles.itemSetup
                }}
            />
            <Stack.Screen
                name={ScreenNames.ItemEdit}
                component={ItemSetupScreen}
                options={{
                    title: T.titles.itemSetup
                }}
            />
            <Stack.Screen name={ScreenNames.SearchFilters} component={SearchFilters}/>
            <Stack.Screen name={ScreenNames.AdminSetup} component={AdminSetupScreen}/>
            <Stack.Screen name={ScreenNames.ChooseScanStore} component={ChooseScanStoreScreen}/>
            <Stack.Screen name={ScreenNames.Scanner} component={ScannerScreen}/>
            <Stack.Screen name={ScreenNames.ItemDetails} component={ItemDetailsScreen}/>
            <Stack.Screen name={ScreenNames.NewItem} component={NewItemScreen}/>
            <Stack.Screen name={ScreenNames.ScannedItems} component={ScannedItemsScreen}/>
            <Stack.Screen name={ScreenNames.Files} component={FilesScreen}/>
            <Stack.Screen name={ScreenNames.ScanDetails} component={ScanDetailsScreen}/>
            <Stack.Screen name={ScreenNames.ConfirmEmailChange} component={ConfirmEmailChangeScreen}/>
            <Stack.Screen name={ScreenNames.DatabaseItems} component={DatabaseItemsScreen}/>
            <Stack.Group
                screenOptions={{
                    presentation: 'transparentModal',
                    animation: 'fade'
                }}
            >
                <Stack.Screen name={ScreenNames.ItemAddedModal} component={ItemAddedModalScreen}/>
                <Stack.Screen name={ScreenNames.EditItemModal} component={EditItemModalScreen}/>
                <Stack.Screen name={ScreenNames.FinishScanning} component={FinishScanningScreen}/>
                <Stack.Screen name={ScreenNames.ResumeScan} component={ResumeScanModalScreen}/>
                <Stack.Screen name={ScreenNames.CustomModal} component={CustomModalScreen}/>
                <Stack.Screen name={ScreenNames.QuantityModal} component={QuantityModalScreen}/>
            </Stack.Group>
        </Stack.Navigator>
    )
}
