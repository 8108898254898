import React from 'react'
import { View } from 'react-native'
import { Measurements } from 'lib/common'
import { Typography } from 'lib/components'
import { Store } from 'lib/models'
import { createStyles, useStyles } from 'lib/styles'

type StoreHeaderProps = {
    store: Store
}

export const StoreHeader: React.FunctionComponent<StoreHeaderProps> = ({ store }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <View style={styles.titleWrapper}>
                <Typography.Heading
                    style={styles.title}
                    numberOfLines={1}
                >
                    {store?.storeName}
                </Typography.Heading>
            </View>
            <Typography.SubText style={styles.text}>
                {`${store.streetAddress}, ${store.city}\n${store.state}, ${store.country}`}
            </Typography.SubText>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(3)
    },
    title: {
        textAlign: 'center',
        maxWidth: Measurements.WindowWidth - theme.utils.gap(6) - 80
    },
    titleWrapper: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: Measurements.WindowWidth - theme.utils.gap(6),
        flexDirection: 'row'
    }
}))
