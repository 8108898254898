import React from 'react'
import { TextStyle } from 'react-native'
import { TextChildren, VoidFunction } from 'lib/types'
import { createStyles, useStyles } from 'lib/styles'
import { BaseText } from './BaseText'

type LabelProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    children?: TextChildren,
    bold?: boolean
}

export const Label: React.FunctionComponent<LabelProps> = ({
    children,
    numberOfLines,
    onPress,
    style = {},
    forceColor,
    bold
}) => {
    const { styles } = useStyles(stylesheet)
    const userStyles = bold
        ? {
            ...styles.bold,
            ...style
        }
        : style

    return (
        <BaseText
            onPress={onPress}
            style={{
                ...styles.text,
                ...userStyles
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.typography.label,
        ...theme.components.typography.secondary,
        fontSize: {
            lg: 14,
            xs: 12
        },
        lineHeight: {
            lg: 17,
            xs: 15
        }
    },
    bold: {
        fontWeight: '600'
    }
}))
