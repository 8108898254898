import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { QueryKey, useFetcher } from 'lib/api'
import { Country, Store, Stores } from 'lib/models'
import {
    AddNewStoreRequest,
    AddNewStoreResponse,
    DeleteStoreRequest,
    EditStoreRequest,
    GetStoreItemsRequest,
    DeleteStoreItemRequest,
    AddNewItemRequest,
    GetSingleStoreResponse,
    UseGetStoreItemsResponse
} from './types'

export const useAddNewStore = () => {
    const fetcher = useFetcher<AddNewStoreResponse>(HttpMethod.POST, '/store')

    return useMutation<Response<AddNewStoreResponse>, ErrorResponse, AddNewStoreRequest>(newStore => fetcher(newStore))
}

export const useEditStore = () => {
    const fetcher = useFetcher<Store>(HttpMethod.PATCH, '/store')

    return useMutation<Response<Store>, ErrorResponse, EditStoreRequest>(editedStore => fetcher(editedStore))
}

export const useGetSingleStore = () => {
    const fetcher = useFetcher<GetSingleStoreResponse>(HttpMethod.GET, '/store')

    return useMutation<Response<GetSingleStoreResponse>, ErrorResponse, string>(storeUUID => fetcher({
        storeUUID
    }))
}

export const useGetStores = (query?: string, offset?: number, limit?: number, disabled?: boolean) => {
    const fetcher = useFetcher<Stores>(HttpMethod.GET, '/store')

    return useQuery({
        cacheTime: 0,
        queryKey: [QueryKey.Stores, {
            query,
            offset,
            limit
        }],
        queryFn: () => fetcher({
            query: query || undefined,
            offset: query ? 0 : offset,
            limit: query ? 30 : limit
        }),
        enabled: !disabled
    })
}

export const useGetStoresInfinity = (query?: string, disabled?: boolean) => {
    const limitPerFetch = 15
    const fetcher = useFetcher<Stores>(HttpMethod.GET, '/store')

    return useInfiniteQuery({
        cacheTime: 0,
        queryKey: [QueryKey.InfiniteStores, {
            query
        }],
        queryFn: params => fetcher({
            ...{
                offset: params.pageParam,
                limit: limitPerFetch
            },
            ...query && { query }
        }),
        getNextPageParam: (lastPage, pages) => {
            const pagesCount = pages.flatMap(page => page.data).length
            const fetchedStoresCount = pages.flatMap(page => page.data.stores).length
            const totalStoresCount = lastPage.data.totalCount

            if (totalStoresCount > fetchedStoresCount) {
                return pagesCount * limitPerFetch
            }
        },
        enabled: !disabled
    })
}

export const useDeleteStore = () => {
    const fetcher = useFetcher<void>(HttpMethod.DELETE, '/store')

    return useMutation<Response<void>, ErrorResponse, DeleteStoreRequest>(storeUUID => fetcher(storeUUID))
}

export const useDeleteStoreItem = ({ onSuccess }) => {
    const fetcher = useFetcher<void>(HttpMethod.DELETE, '/store-item')

    return useMutation<Response<void>, ErrorResponse, DeleteStoreItemRequest>(item => fetcher(item), {
        onSuccess
    })
}

export const useGetStoreItems = () => {
    const fetcher = useFetcher<UseGetStoreItemsResponse>(HttpMethod.GET, '/store-item')

    return useMutation<Response<UseGetStoreItemsResponse>, ErrorResponse, GetStoreItemsRequest>(params => fetcher(params))
}

export const useGetStoreItemsInfinity = (storeUUID: string, storeItemUUID?: string, query?: string, disabled?: boolean) => {
    const limitPerFetch = 15
    const fetcher = useFetcher<UseGetStoreItemsResponse>(HttpMethod.GET, '/store-item')

    return useInfiniteQuery({
        cacheTime: 0,
        queryKey: [QueryKey.StoreItems, {
            query
        }],
        queryFn: params => fetcher({
            ...{
                storeUUID,
                offset: params.pageParam,
                limit: limitPerFetch
            },
            ...query && { query },
            ...storeItemUUID && { storeItemUUID }
        }),
        getNextPageParam: (lastPage, pages) => {
            const pagesCount = pages.flatMap(page => page.data).length
            const fetchedStoreItemsCount = pages.flatMap(page => page.data.storeItems).length
            const totalStoreItemsCount = lastPage.data.totalCount

            if (totalStoreItemsCount > fetchedStoreItemsCount) {
                return pagesCount * limitPerFetch
            }
        },
        enabled: !disabled
    })
}

export const useAddNewItem = ({ onSuccess, onError }) => {
    const fetcher = useFetcher<void>(HttpMethod.POST, '/store-item')

    return useMutation<Response<void>, ErrorResponse, AddNewItemRequest>(item => fetcher(item), {
        onSuccess,
        onError
    })
}

export const useGetCountries = () => {
    const fetcher = useFetcher<Array<Country>>(HttpMethod.GET, '/country')

    return useQuery([QueryKey.Country], fetcher, {
        retry: 5,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: false
    })
}
