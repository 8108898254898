import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { SearchItem } from 'lib/models'
import { useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { getSearchItemDescription } from '../utils'

type ItemProps = {
    item: SearchItem
}

export const Item: React.FunctionComponent<ItemProps> = ({ item }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const descriptionValues = getSearchItemDescription(item)
    const navigation = useNavigation()

    return (
        <Touchable
            onPress={() => navigation.navigate(ScreenNames.SearchItemDetails, { item })}
            style={styles.container}
        >
            <View style={styles.leftSide}>
                <View style={styles.image}>
                    <Icons.Books
                        size={100}
                        forceColor={theme.colors.lightGray}
                        topBookColor={theme.ui.foreground}
                    />
                </View>
                <View style={styles.description}>
                    {descriptionValues.map((value, index) => (
                        <Typography.SubText
                            key={index}
                            numberOfLines={1}
                            style={styles.text}
                        >
                            {value}
                        </Typography.SubText>
                    ))}
                </View>
            </View>
            <View style={styles.details}>
                <View style={styles.inStock}>
                    <View style={styles.inStockBorder}>
                        <Typography.SubText
                            style={{
                                ...styles.text,
                                ...styles.detailsText
                            }}
                        >
                            {item.inStock}
                        </Typography.SubText>
                    </View>
                    <Typography.SubText style={styles.text}>
                        {T.screens.search.inStock}
                    </Typography.SubText>
                </View>
                <Typography.SubText
                    style={{
                        ...styles.text,
                        ...styles.detailsText
                    }}
                >
                    ${item.price}
                </Typography.SubText>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: {
            xs: '100%',
            sm: 356
        },
        minWidth: {
            sm: 356
        },
        height: 190,
        backgroundColor: hexToRGBA(theme.ui.foreground, 0.5),
        borderRadius: 12,
        padding: theme.gap * 2
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: 112,
        borderRadius: 6,
        backgroundColor: theme.ui.foreground
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: theme.gap * 2,
        gap: theme.gap,
        flexShrink: 1
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'flex-end'
    },
    text: {
        fontSize: 12,
        fontWeight: '300',
        color: theme.components.typography.secondary.color
    },
    detailsText: {
        color: theme.colors.pink,
        fontSize: 22,
        lineHeight: 22,
        fontWeight: '400'
    },
    inStock: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap,
        alignItems: 'center'
    },
    inStockBorder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: theme.components.button.outline.borderColor,
        paddingVertical: 10,
        paddingHorizontal: theme.gap * 2
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 1
    }
}))
