import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Grid, Touchable, Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type ScannedItemTileProps = {
    disabled?: boolean,
    bookCode: string,
    quantity: number,
    isLast?: boolean,
    paddingRight?: number,
    onEditPress?: VoidFunction,
    onDeletePress?: VoidFunction
}

export const ScannedItemTile: React.FunctionComponent<ScannedItemTileProps> = ({
    disabled,
    bookCode,
    isLast,
    quantity,
    paddingRight,
    onEditPress,
    onDeletePress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const isEditable = onEditPress || onDeletePress

    return (
        <View
            style={{
                ...styles.container,
                ...(isLast ? {} : styles.separator)
            }}
        >
            <Typography.Title numberOfLines={1}>
                {bookCode}
            </Typography.Title>
            <View style={styles.spacer}/>
            <Grid.Gap
                style={{
                    marginRight: isEditable
                        ? theme.utils.gap(3)
                        : paddingRight || theme.utils.gap(8)
                }}
            >
                <Typography.Title numberOfLines={1}>
                    {quantity}
                </Typography.Title>
            </Grid.Gap>
            {isEditable && (
                <React.Fragment>
                    <Touchable
                        style={styles.iconWrapper}
                        disabled={disabled}
                        onPress={onEditPress}
                    >
                        <Icons.Pencil
                            forceColor={theme.components.typography.primary.color}
                            size={22}
                        />
                    </Touchable>
                    <Grid.Gap gapRight={3}/>
                    <Touchable
                        style={styles.iconWrapper}
                        disabled={disabled}
                        onPress={onDeletePress}
                    >
                        <Icons.Bin
                            forceColor={theme.components.typography.primary.color}
                            size={22}
                        />
                    </Touchable>
                </React.Fragment>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: Measurements.WindowWidth - theme.utils.gap(6),
        flexDirection: 'row',
        padding: theme.utils.gap(2),
        paddingLeft: 0
    },
    separator: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.mixTransparent(theme.ui.accent, 0.5)
    },
    iconWrapper: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: theme.ui.foreground,
        justifyContent: 'center',
        alignItems: 'center'
    },
    spacer: {
        flex: 2
    }
}))
