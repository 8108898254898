import { Fragment } from 'react'
import { View, Linking } from 'react-native'
import { isNative } from 'lib/common'
import { useTranslations } from 'lib/hooks'
import { Typography, Touchable } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { getTermsAndConditionsUrl, getPrivacyPolicyUrl } from 'lib/utils'

export const TermsAndConditions = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const FirstLine = () => (
        <Fragment>
            <Typography.SubText>
                {T.forms.common.termsAndConditions.label.firstPart}
            </Typography.SubText>
            <Touchable onPress={() => Linking.openURL(getTermsAndConditionsUrl())}>
                <Typography.SubText style={styles.link}>
                    {T.forms.common.termsAndConditions.label.secondPart}
                </Typography.SubText>
            </Touchable>
            <Typography.SubText>
                {T.forms.common.termsAndConditions.label.thirdPart}
            </Typography.SubText>
        </Fragment>
    )

    if (isNative) {
        return (
            <View style={styles.nativeContainer}>
                <View style={styles.termsAndConditionsText}>
                    <FirstLine />
                </View>
                <Touchable onPress={() => Linking.openURL(getPrivacyPolicyUrl())}>
                    <Typography.SubText style={styles.link}>
                        {T.forms.common.termsAndConditions.label.fourthPart}
                    </Typography.SubText>
                </Touchable>
            </View>
        )
    }

    return (
        <View style={styles.termsAndConditionsText}>
            <FirstLine />
            <Touchable onPress={() => Linking.openURL(getPrivacyPolicyUrl())}>
                <Typography.SubText style={styles.link}>
                    {T.forms.common.termsAndConditions.label.fourthPart}
                </Typography.SubText>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    termsAndConditionsText: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%'
    },
    link: {
        color: theme.colors.pink,
        textDecorationLine: 'underline'
    },
    nativeContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap / 2
    }
}))
