/* eslint-disable @typescript-eslint/no-explicit-any */
import { cond, call, T, empty, always, isNil, compose, not, toPairs, is, isEmpty, assoc, inc, dec } from 'ramda'

const isDefined = (subject: any) => typeof subject !== 'undefined' && subject !== null
export const ifDefined = <T = any>(subject: any, then: (subject: T) => void) => isDefined(subject) && then(subject)
export const hasKeys = (subject: any) => typeof subject === 'object'
    ? Object.keys(subject).length > 0
    : false
const all = (...args: Array<boolean>) => args.every(Boolean)
const any = (...args: Array<boolean>) => args.some(Boolean)
const multiply = (...args: Array<number>) => args.reduce((acc, num) => acc * num, 1)
const notNil = compose(not, isNil)
const isEqual = <T = any>(comparator: any, followingValue?: any) => (value: T) => is(Function, comparator)
    ? comparator(value) === followingValue
    : value === comparator

export {
    T,
    cond,
    call,
    empty,
    isDefined,
    all,
    any,
    always,
    isNil,
    multiply,
    notNil,
    toPairs,
    is,
    isEqual,
    isEmpty,
    assoc,
    inc,
    dec
}
