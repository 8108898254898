import { useEffect } from 'react'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { useAppState } from 'lib/hooks'
import { useGetMe } from '../actions'

export const useGetMeWatcher = () => {
    const [isAuthorized] = useIsAuthorizedAtom()
    const { willBeActive } = useAppState()
    const { refetch: getMe } = useGetMe()

    useEffect(() => {
        if (isAuthorized) {
            getMe()
        }
    }, [isAuthorized])

    useEffect(() => {
        if (willBeActive && isAuthorized) {
            getMe()
        }
    }, [willBeActive])
}
