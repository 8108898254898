import React from 'react'
import { View } from 'react-native'
import { FadeShadow, Typography } from 'lib/components'
import { Store } from 'lib/models'
import { createStyles, useStyles } from 'lib/styles'

type FileStoreTileProps = {
    store: Store
}

export const FileStoreTile: React.FunctionComponent<FileStoreTileProps> = ({ store }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Title numberOfLines={1}>
                {store.storeName}
            </Typography.Title>
            <Typography.Error numberOfLines={1}>
                {`${store.city}${(store.country && store.country) ? ', ' : ''}${store.country || ''}`}
            </Typography.Error>
            <Typography.Label numberOfLines={1}>
                {store.streetAddress}
            </Typography.Label>
            <FadeShadow
                height={theme.utils.gap(4)}
                isInverted
                style={styles.shadow}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        backgroundColor: theme.ui.background,
        justifyContent: 'center',
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    shadow: {
        bottom: -theme.utils.gap(4)
    }
}))
