import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Briefcase: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 61.856 75.4 h 6.5104 c 2.7808 0 5.024 -2.2832 5.024 -5.1088 V 29.9968 c 0 -2.8256 -2.2528 -5.1072 -5.024 -5.1072 H 9.624 C 6.8432 24.888 4.6 27.1712 4.6 29.9968 v 40.2944 C 4.6 73.1168 6.8528 75.4 9.624 75.4 h 37.8688"
                />
                <Path
                    d="M 26.44 24.696 V 17.576 c 0 -1.6384 1.424 -2.976 3.1792 -2.976 h 19.5968 c 1.744 0 3.1808 1.328 3.1808 2.976 v 7.1296"
                />
                <Path
                    d="M 5.6272 40.6064 H 73.4"
                />
            </G>
        </Icon>
    )
}
