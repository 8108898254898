import React, { useEffect, useState } from 'react'
import { Alert } from 'react-native'
import { useFiles } from 'features/files'
import { useScannedItemsAtom } from 'lib/atoms'
import { Measurements } from 'lib/common'
import { Button, FadeShadowWrapper, FullScreenLoader, GoBack, Grid, Typography, TableNative } from 'lib/components'
import { useBackHandler, useTranslations } from 'lib/hooks'
import { SavedScan } from 'lib/models'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { dateHelpers, R } from 'lib/utils'
import { TableItemKey } from 'lib/types'
import { usePostScannedItems } from '../actions'

type FinishScanningScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.FinishScanning>
}
export const FinishScanningScreen: React.FunctionComponent<FinishScanningScreenProps> = ({
    navigation,
    route
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [savedScan, setSavedScan] = useState<SavedScan>()
    const [scannedItems, setScannedItems] = useScannedItemsAtom()
    const { mutate: postScannedItems, isLoading } = usePostScannedItems()
    const {
        saveToCSVFile,
        saveCurrentScan,
        markScanAsShared
    } = useFiles()

    const onClose = () => {
        navigation.reset({
            index: 0,
            routes: [
                {
                    name: ScreenNames.Home
                }
            ]
        })
    }

    const onSaveToFile = () => {
        if (!savedScan) {
            return
        }

        saveToCSVFile(savedScan)
            .catch(() => Alert.alert(T.alerts.oops, T.screens.finishScanning.errorSharing))
    }

    const onSaveToDatabaseScan = () => {
        if (!savedScan) {
            return
        }

        postScannedItems(
            {
                items: savedScan.items.map(scannedItem => ({
                    ...scannedItem,
                    storeItemUUID: scannedItem?.storeItemUUID
                })),
                storeUUID: savedScan.store.storeUUID,
                scanDate: savedScan.createdAt
            },
            {
                onSuccess: () => {
                    markScanAsShared(savedScan.store.storeUUID, savedScan.createdAt)
                        .catch(R.T)
                    navigation.navigate(ScreenNames.CustomModal, {
                        title: T.screens.finishScanning.onSaveSuccess.title,
                        message: T.screens.finishScanning.onSaveSuccess.message,
                        onClose
                    })
                },
                onError: () => Alert.alert(T.alerts.oops, T.screens.finishScanning.errorSaving)
            }
        )
    }

    useEffect(() => {
        const timestamp = dateHelpers.nowUnix()

        setSavedScan({
            items: scannedItems,
            store: route.params.store,
            createdAt: timestamp
        })

        saveCurrentScan({
            items: scannedItems,
            store: route.params.store,
            createdAt: timestamp
        })
            .then(() => setScannedItems([]))
            .catch(R.T)

    }, [])

    useBackHandler(() => isLoading)

    return (
        <Grid.Background
            hasPaddingHorizontal
            style={styles.background}
        >
            <GoBack onPress={onClose}/>
            <Typography.Heading>
                {T.screens.finishScanning.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={5}/>
            <FadeShadowWrapper
                containerStyle={styles.fadeShadowWrapper}
                showTopShadow={false}
                showBottomShadow={(savedScan?.items?.length || scannedItems.length) > 0}
            >
                <TableNative
                    columns={[
                        {
                            key: TableItemKey.BookCode,
                            title: T.components.table.headers.bookCode,
                            size: 170
                        },
                        {
                            key: TableItemKey.Quantity,
                            title: T.components.table.headers.quantity
                        }
                    ]}
                    data={savedScan?.items.map((item => ({
                        bookCode: item.bookCode,
                        quantity: item.quantity
                    }))) || []}
                />
            </FadeShadowWrapper>
            {(savedScan?.items?.length || scannedItems.length) > 0 && (
                <React.Fragment>
                    <Grid.Gap gapBottom={4}/>
                    <Button
                        width={Measurements.WindowWidth - theme.utils.gap(6)}
                        text={T.screens.finishScanning.button.saveToDatabase}
                        onPress={onSaveToDatabaseScan}
                    />
                    <Grid.Gap gapBottom={2}/>
                    <Button
                        width={Measurements.WindowWidth - theme.utils.gap(6)}
                        text={T.screens.finishScanning.button.shareFile}
                        onPress={onSaveToFile}
                    />
                    <Grid.Gap gapBottom={8}/>
                </React.Fragment>
            )}
            <FullScreenLoader isActive={isLoading || !savedScan}/>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingBottom: theme.utils.gap(1.5),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.mixTransparent(theme.ui.accent, 0.5)
    },
    containerFlexed: {
        flex: 1,
        justifyContent: 'center'
    },
    containerPadded: {
        paddingBottom: theme.utils.gap(8)
    },
    text: {
        textAlign: 'center'
    },
    fadeShadowWrapper: {
        width: '100%'
    },
    background: {
        alignItems: 'center'
    }
}))
