import Animated, { useAnimatedReaction, useAnimatedRef, useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { useEffect, useState } from 'react'
import { Keyboard, ScrollView, TextInput, View } from 'react-native'
import { KeyboardAccessoryFieldProps } from './types'
import { measureAndScrollToInput } from './utils'

export const useKeyboardAccessoryCalculations = (
    numberOfInputs: number,
    animatedExtraHeight?: Animated.SharedValue<number>
) => {
    const KEYBOARD_ACCESSORY_HEIGHT = 45
    const activeIndexRef = useSharedValue(-1)
    const keyboardYPosition = useSharedValue(0)
    const animatedScrollY = useSharedValue(0)
    const scrollViewRef = useAnimatedRef<ScrollView>()
    const scrollHandler = useAnimatedScrollHandler({
        onScroll: event => {
            animatedScrollY.value = event.contentOffset.y
        }
    })
    const [activeInputIndex, setActiveInputIndex] = useState<number>(0)
    const inputRefs = Array
        .from(new Array(numberOfInputs))
        .map(() => ({
            inputRef: useAnimatedRef<TextInput>(),
            fieldRef: useAnimatedRef<View>()
        }))
    const childrenProps: Array<KeyboardAccessoryFieldProps> = inputRefs
        .map((refs, index) => ({
            ref: refs.inputRef,
            keyboardAccessory: {
                fieldRef: refs.fieldRef,
                events: {
                    onFocus: () => {
                        setActiveInputIndex(index)
                        activeIndexRef.value = index
                    },
                    onBlur: () => {
                        setActiveInputIndex(-1)
                        activeIndexRef.value = -1
                    }
                }
            }
        }))

    useAnimatedReaction(
        () => ({
            keyboardStartY: keyboardYPosition.value,
            activeIndex: activeIndexRef.value
        }),
        ({ keyboardStartY, activeIndex }) => {
            if (activeIndex === -1 || keyboardStartY === 0) {
                return
            }

            measureAndScrollToInput({
                scrollViewRef,
                animatedScrollY,
                animatedExtraHeight,
                fieldRef: inputRefs[activeIndex].fieldRef,
                keyboardEndYPosition: keyboardStartY
            })
        }
    )

    useEffect(() => {
        const keyboardDidChangeFrameSubscription = Keyboard.addListener('keyboardDidChangeFrame', event => {
            keyboardYPosition.value = event.endCoordinates.screenY - KEYBOARD_ACCESSORY_HEIGHT
        })

        return () => {
            keyboardDidChangeFrameSubscription.remove()
        }
    }, [])

    return {
        scrollHandler,
        activeInputIndex,
        scrollViewRef,
        childrenProps
    }
}
