import NetInfo from '@react-native-community/netinfo'
import { AppStateStatus, Platform } from 'react-native'
import { onlineManager, QueryClient, focusManager } from '@tanstack/react-query'

onlineManager.setEventListener(setOnline => NetInfo.addEventListener(state => {
    setOnline(Boolean(state.isConnected))
}))

export const handleFocusManager = (status: AppStateStatus) => {
    if (Platform.OS !== 'web') {
        focusManager.setFocused(status === 'active')
    }
}

export const queryClient = new QueryClient()
