import React from 'react'
import { TextStyle } from 'react-native'
import { useTranslations } from '../hooks'
import { createStyles, useStyles } from '../styles'
import { Heading } from './typography'

type AppNameProps = {
    style?: TextStyle
}

export const AppName: React.FunctionComponent<AppNameProps> = ({ style = {} }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <Heading
            style={{
                ...styles.appName,
                ...style
            }}
        >
            {T.common.imrchnt}
        </Heading>
    )
}

const stylesheet = createStyles(() => ({
    appName: {
        fontFamily: 'HouschkaRoundedBold',
        fontSize: 32,
        lineHeight: 32
    }
}))
