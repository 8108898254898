import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { Breakpoint, createStyles, useStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { useIsWithinBreakpoints } from 'lib/hooks'
import { FullScreenLoader } from '../FullScreenLoader'

export const IsFetching: React.FunctionComponent = () => {
    const { styles, theme } = useStyles(stylesheet)
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const height = useWindowDimensions().height - theme.topBarHeight
    const isFetching = useIsFetching()
    const isMutating = useIsMutating()
    const isLoading = isFetching || isMutating

    if (!isLoading || isMobileView || isNative) {
        return null
    }

    return (
        <View
            style={{
                ...styles.loading,
                ...{ height }
            }}
        >
            <FullScreenLoader isActive />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    loading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(4px)',
        inset: 0,
        width: '100%',
        top: theme.topBarHeight,
        zIndex: 2
    }
}))
