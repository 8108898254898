import { ReactNode } from 'react'
import { BaseUser, Store } from 'lib/models'

export const getManageUsersTableData = (users: Array<BaseUser>) => users.map(user => {
    const [firstStore] = user.userStores || []
    const userBase = {
        fullName: user.fullName,
        email: user.email,
        userUUID: user.userUUID,
        type: user.type
    }

    if (!firstStore) {
        return {
            ...userBase,
            store: 'Store not assigned'
        }
    }

    const { storeName, country, city } = firstStore

    return {
        ...userBase,
        userStores: user.userStores as ReactNode,
        store: `${storeName}, ${country}, ${city} ${user.userStores.length > 1 ? `+(${user.userStores.length - 1})` : ''}`
    }
})

export const getLocation = (store: Store, otherStores?: string) => {
    if (!store) {
        return undefined
    }

    const { storeName, city, country } = store

    if (storeName && city && country) {
        return `${storeName}, ${city}, ${country}${otherStores}`
    }

    return undefined
}
