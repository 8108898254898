import { ReactNode } from 'react'
import { StoreItem } from 'lib/models'

export type ItemToDelete = {
    createdAt: number,
    storeUUID: string
}

export enum NewItemFormFields {
    BookCode = 'bookCode',
    Title = 'title',
    Author = 'author',
    Category = 'category',
    SKU = 'sku',
    Quantity = 'quantity'
}

export type StoreItemsTableItem = {
    itemUUID?: string,
    bookCode: ReactNode,
    quantity: number,
    storeItemUUID?: string
}

export type DatatbaseItemsTableItem = Omit<StoreItem, 'bookCode'> & {
    bookCode: ReactNode
}
