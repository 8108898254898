import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { isSmallPhone, isWeb, Measurements } from 'lib/common'
import {
    Adapter,
    AppName,
    Button,
    Grid,
    Touchable,
    Typography,
    GoBack,
    Logo
} from 'lib/components'
import { useTranslations, useIsWithinBreakpoints } from 'lib/hooks'
import { UserType } from 'lib/models'
import { ScreenNames } from 'lib/routing'
import { Breakpoint, createStyles, useStyles } from 'lib/styles'
import { inputProps } from 'lib/utils'
import { AuthMethods } from '../components'
import { SignInWithEmailFormShape } from '../forms'
import { SignInFormProps as SignInFormPropsType } from '../types'

type SignInFormProps = {
    form: Record<keyof SignInWithEmailFormShape, Field<string>>,
    isLoading: boolean,
    isAuthorizing: boolean,
    userType: UserType,
    showBiometryButton?: boolean,
    formProps?: SignInFormPropsType,
    onSubmit: VoidFunction,
    setIsAuthorizing: (isAuthorizing: boolean) => void,
    onSignInWithBiometry?: VoidFunction
}

export const SignInForm: React.FunctionComponent<SignInFormProps> = ({
    form,
    userType,
    isLoading,
    formProps,
    isAuthorizing,
    showBiometryButton,
    onSubmit,
    setIsAuthorizing,
    onSignInWithBiometry
}) => {
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()

    return (
        <View style={styles.container}>
            {isWeb && !isMobileView && (
                <GoBack backgroundStyle={styles.goBackWeb}/>
            )}
            <View style={styles.form}>
                {isWeb && isMobileView && (
                    <GoBack backgroundStyle={styles.goBackMobile} />
                )}
                <Grid.Gap gapBottom={1}>
                    <Logo size={isSmallPhone ? 60 : 80}/>
                </Grid.Gap>
                <View style={styles.title}>
                    <Typography.Heading style={styles.titleColor}>
                        {T.screens.signIn.title}
                    </Typography.Heading>
                    <AppName style={styles.appName}/>
                </View>
                <Grid.Gap gapBottom={1}/>
                <Typography.Subheading>
                    {T.screens.signIn.message}
                </Typography.Subheading>
                <Grid.Gap gapBottom={5}/>
                <Adapter.TextInput
                    {...form.email}
                    {...formProps?.emailProps}
                    inputProps={inputProps.email}
                    leftIcon={(
                        <Icons.Envelope size={22}/>
                    )}
                />
                <Grid.Gap gapBottom={1}/>
                <Adapter.TextInput
                    {...form.password}
                    {...formProps?.passwordProps}
                    inputProps={inputProps.password}
                    leftIcon={(
                        <Icons.Padlock size={20}/>
                    )}
                    isPassword
                />
                <Touchable
                    style={styles.forgotPasswordContainer}
                    onPress={() => navigation.navigate(ScreenNames.ForgotPassword, { userType })}
                >
                    <Typography.SubText>
                        {T.screens.signIn.forgotPassword}
                    </Typography.SubText>
                </Touchable>
                <Grid.Gap gapBottom={4}/>
                <Button
                    disabled={isAuthorizing}
                    isLoading={isLoading}
                    width={Measurements.WindowWidth - 6 * theme.gap}
                    customStyle={styles.signinButton}
                    text={T.screens.signIn.button}
                    onPress={onSubmit}
                />
                {userType === UserType.Admin && (
                    <Grid.Gap
                        gapTop={5}
                        style={styles.authorizationMethods}
                    >
                        <View style={styles.separator}>
                            <Typography.Label style={styles.separatorText}>
                                {T.screens.signIn.orUse}
                            </Typography.Label>
                        </View>
                        <Grid.Gap gapBottom={5}/>
                        <AuthMethods
                            userType={userType}
                            onLoading={setIsAuthorizing}
                        />
                        <Grid.Gap gapBottom={3}/>
                        <Typography.SubText style={styles.footerText}>
                            {`${T.screens.signIn.noAccount} `}
                            <Typography.Regular
                                style={styles.signUp}
                                onPress={() => navigation.navigate(ScreenNames.SignUp)}
                            >
                                {T.screens.signIn.signUp}
                            </Typography.Regular>
                        </Typography.SubText>
                    </Grid.Gap>
                )}
                <Grid.Gap gapBottom={3}/>
                {showBiometryButton && (
                    <Button
                        disabled={isLoading || isAuthorizing}
                        text={T.screens.signIn.biometric}
                        isPlain
                        onPress={() => onSignInWithBiometry?.()}
                        renderIcon={() => (
                            <Icons.Biometry size={26}/>
                        )}
                        customStyleText={styles.biometric}
                    />
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            xs: '100%',
            sm: '75%'
        },
        backgroundColor: {
            sm: theme.colors.almostBlack
        },
        padding: {
            sm: 80
        },
        borderRadius: {
            sm: 40
        },
        maxWidth: 1200,
        display: 'flex',
        alignItems: 'center'
    },
    form : {
        width: 368,
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    titleColor: {
        color: theme.components.typography.primary.color
    },
    appName: {
        color: theme.components.typography.primary.color,
        position: 'relative',
        top: 4
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: {
            xs: 0,
            sm: theme.gap
        },
        flexDirection: {
            xs: 'column',
            sm: 'row'
        }
    },
    forgotPasswordContainer: {
        alignSelf: 'flex-start'
    },
    signinButton: {
        width: '100%'
    },
    authorizationMethods: {
        width: '100%'
    },
    separator: {
        height: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: {
            xs: theme.components.typography.secondary.color,
            sm: theme.colors.darkGray
        },
        overflow: 'visible'
    },
    separatorText: {
        fontSize: 12,
        height: 14,
        lineHeight: 14,
        color: theme.components.typography.secondary.color,
        paddingHorizontal: theme.gap * 1.5,
        backgroundColor: {
            xs: theme.ui.background,
            sm: theme.colors.almostBlack
        }
    },
    footerText: {
        alignSelf: 'center'
    },
    signUp: {
        fontSize: 16,
        textDecorationLine: 'underline',
        color: theme.colors.pink
    },
    biometric: {
        fontWeight: '400'
    },
    goBackWeb: {
        position: 'absolute',
        top: theme.gap * 3,
        left: theme.gap * 3
    },
    goBackMobile: {
        position: 'absolute',
        left: 0,
        top: 0
    }
}))
