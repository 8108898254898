import React from 'react'
import { Grid, Typography } from 'lib/components'
import { R } from 'lib/utils'
import { createStyles, useStyles } from 'lib/styles'
import { useTranslations } from '../hooks'
import { Button } from './Button'

type ListErrorComponentProps = {
    message?: string,
    isLoading?: boolean,
    onRefetch: VoidFunction
}

export const ListErrorComponent: React.FunctionComponent<ListErrorComponentProps> = ({
    message,
    onRefetch,
    isLoading
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <Typography.Subheading style={styles.text}>
                {R.isDefined(message)
                    ? message
                    : T.common.errorMessage
                }
            </Typography.Subheading>
            <Grid.Gap gapBottom={4}/>
            <Button
                isLoading={isLoading}
                text={T.common.tryAgain}
                onPress={onRefetch}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(() => ({
    text: {
        textAlign: 'center'
    }
}))
