import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const Mailbox: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 14.844 63.6364 c 1.1232 -0.162 36.2232 -0.054 47.7072 -0.0144 a 3.5856 3.5856 90 0 0 3.5964 -3.6 V 28.3924 c 0 -0.1224 0.0036 -0.2412 0.0108 -0.36 c 0.054 -1.152 0.0108 -7.3728 -7.308 -7.3728 H 20.9928"
                />
                <Path
                    d="M 49.5552 36.7372 V 4.6 h 11.844 l -4.4892 5.2272 l 4.4892 5.4216 H 50.988 M 49.5552 44.956 c 2.34 0 4.2372 -1.7964 4.2372 -4.014 s -1.8972 -4.014 -4.2372 -4.014 s -4.2336 1.8 -4.2336 4.014 c 0 2.2176 1.8936 4.014 4.2336 4.014 Z"
                />
                <Path
                    d="M 38.1864 65.0296 v 12.8"
                />
                <Path
                    d="M 20.9789 20.6601 C 26.5336 20.6601 31.0377 24.9299 31.0377 30.1957 V 60.0283 C 31.0377 62.0166 29.4259 63.6283 27.4377 63.6283 H 14.52 C 12.5317 63.6283 10.92 62.0166 10.92 60.0283 V 30.1957 C 10.92 24.9299 15.4241 20.6601 20.9789 20.6601 Z"
                />
            </G>
        </Icon>
    )
}
