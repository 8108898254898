import React from 'react'
import { View } from 'react-native'
import { Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type StoreColumnProps = {
    name: string,
    state: string,
    city: string,
    street: string
}

export const StoreColumn: React.FunctionComponent<StoreColumnProps> = ({
    name,
    state,
    city,
    street
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Subheading
                numberOfLines={1}
                style={styles.name}
                forceColor={theme.colors.white}
            >
                {name}
            </Typography.Subheading>
            <Typography.Subheading
                numberOfLines={1}
                style={styles.subHeadingText}
                forceColor={theme.colors.pink}
            >
                {`${city}, ${state}`}
            </Typography.Subheading>
            <Typography.Subheading
                numberOfLines={1}
                style={styles.subHeadingText}
            >
                {street}
            </Typography.Subheading>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap / 2
    },
    name: {
        fontSize: 16
    },
    country: {
        fontSize: 14
    },
    subHeadingText: {
        fontWeight: '400'
    }
}))
