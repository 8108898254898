/* eslint-disable functional/immutable-data */
import axios from 'axios'
import { authUtils } from 'features/auth'
import { DeviceInfo, getApiUrl } from 'lib/utils'
import { APP_CONFIG } from '../config'

export const setAxiosDefaults = () => {
    axios.defaults.baseURL = getApiUrl()
    axios.defaults.timeout = APP_CONFIG.API.REQUEST_TIMEOUT
}

axios.interceptors.request.use(request => {
    const authToken = authUtils.getAccessToken()

    request.headers = request.headers || {}

    if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`
    }

    request.headers['Device-id'] = DeviceInfo.get().uniqueId

    return request
})
