import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { GoBack, Grid, Touchable, Typography, TableNative, ConfirmModal, SearchInput } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { Action, TableAction, TableItemKey, TooltipPlacement } from 'lib/types'
import { useDeleteStoreItem, useGetStoreItemsInfinity } from '../actions'
import { DatatbaseItemsTableItem } from '../types'

type DatabaseItemsScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.DatabaseItems>
}
export const DatabaseItemsScreen: React.FunctionComponent<DatabaseItemsScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const [query, setQuery] = useState('')
    const [deleteUUID, setDeleteUUID] = useState('')
    const { styles, theme } = useStyles(stylesheet)
    const {
        data: infinityScrollData,
        fetchNextPage,
        hasNextPage,
        isFetching: isInfinityScrollFetching,
        refetch: refetchInfinityScroll
    } = useGetStoreItemsInfinity(route.params.store.storeUUID, undefined, query)
    const {
        mutate: deleteItem,
        isLoading: isDeleting
    } = useDeleteStoreItem({
        onSuccess: () => setDeleteUUID('')
    })
    const infinityScrollStoreItems = infinityScrollData?.pages.flatMap(page => page.data.storeItems) || []

    const tableActions = [
        {
            key: TableAction.Edit,
            label: T.components.table.actions.edit,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Pencil
                    size={22}
                    forceColor={theme.colors.white}
                />
            ),
            action: row => navigation.navigate(ScreenNames.ItemEdit, {
                storeUUID: route.params.store.storeUUID,
                itemUUID: row.itemUUID || ''
            })
        },
        {
            key: TableAction.Remove,
            label: T.components.table.actions.remove,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Bin
                    size={20}
                    forceColor={theme.colors.white}
                />
            ),
            action: row => setDeleteUUID(row.itemUUID || '')
        }
    ] satisfies Array<Action<DatatbaseItemsTableItem>>

    return (
        <Grid.Background hasPaddingHorizontal>
            <GoBack/>
            <View style={styles.header}>
                <View />
                <Typography.Heading>
                    {T.screens.databaseItems.title}
                </Typography.Heading>
                <Touchable
                    onPress={() => navigation.navigate(ScreenNames.Scanner, {
                        store: route.params.store
                    })}
                >
                    <Icons.Barcode
                        size={32}
                        forceColor={theme.ui.primary}
                    />
                </Touchable>
            </View>
            <Grid.Gap gapBottom={5}/>
            <SearchInput
                placeholder={T.screens.storeItems.searchPlaceholder}
                onDebouncedQuery={setQuery}
                rightIcon={(
                    <Icons.Search size={22}/>
                )}
            />
            <TableNative
                data={infinityScrollStoreItems.map(item => ({
                    itemUUID: item.storeItemUUID || '',
                    bookCode: (
                        <View style={styles.bookCode}>
                            <Typography.Regular numberOfLines={1}>
                                {item.bookCode as string}
                            </Typography.Regular>
                            <Typography.Regular numberOfLines={1}>
                                {T.components.table.headers.sku}: {item.sku ?? ''}
                            </Typography.Regular>
                        </View>
                    ),
                    quantity: item.quantity
                })) || []}
                columns={[
                    {
                        key: TableItemKey.BookCode,
                        title: `${T.components.table.headers.bookCode} & ${T.components.table.headers.sku}`,
                        size: 180
                    },
                    {
                        key: TableItemKey.Quantity,
                        title: T.components.table.headers.quantity
                    }
                ]}
                actions={tableActions}
                onRefetch={refetchInfinityScroll}
                onEndReached={() => {
                    if (hasNextPage && !isInfinityScrollFetching) {
                        fetchNextPage()
                    }
                }}
                isLoading={isInfinityScrollFetching || isDeleting}
            />
            <Grid.Gap gapBottom={4}/>
            <ConfirmModal
                isVisible={Boolean(deleteUUID)}
                message={T.screens.storeItems.itemDeleteLabel}
                onClose={() => setDeleteUUID('')}
                onConfirm={() => deleteItem({
                    itemUUIDs: [deleteUUID],
                    storeUUID: route.params.store.storeUUID,
                    scanDate: dateHelpers.nowUnix()
                })}
                isLoading={isDeleting}
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    shadowWrapper: {
        width: '100%'
    },
    bookCode: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap / 2
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))
