import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Users: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 24.3 48.35 a 12.15 12.15 90 1 0 0 -24.3 a 12.15 12.15 90 0 0 0 24.3 Z M 56.7 40.25 a 9.45 9.45 90 1 0 0 -18.9 a 9.45 9.45 90 0 0 0 18.9 Z M 29.8229 73.9906 H 2.6028 a 1.242 1.242 90 0 1 -1.2488 -1.3271 C 1.8671 66.0363 6.129 51.05 17.55 51.05 c 3.375 2.7 11.0322 2.0493 14.3019 0 c 12.4672 -0.0095 17.5541 13.7606 18.0954 21.6041 a 1.2582 1.2582 90 0 1 -1.2488 1.3459 h -8.717 M 41.175 49.7 c 1.35 -3.375 4.725 -6.075 9.6336 -6.75 C 54 44.975 59.4 44.975 62.9451 42.95 c 10.5773 -0.0081 14.8932 11.3319 15.3522 17.793 a 1.0489 1.0489 90 0 1 -1.0584 1.107 h -25.2855"
                stroke={color}
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
