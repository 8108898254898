import React from 'react'
import { useSearchFilters, useSearchStores } from 'lib/atoms'
import { SearchFilter } from 'lib/types'
import { Grid } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { Store } from 'lib/models'
import { FiltersList } from '../components'

type SearchFiltersProps = {
    route: NavigationParams<ScreenNames.SearchFilters>
}

export const SearchFilters: React.FunctionComponent<SearchFiltersProps> = ({ route }) => {
    const [selectedFilters, setSelectedFilters] = useSearchFilters()
    const [,setSearchStores] = useSearchStores()

    const updateSelectedFilters = (filters: Array<SearchFilter>, stores: Array<Store>) => {
        setSelectedFilters(filters)
        setSearchStores(stores)
    }

    const disableEmptyFilters = () => {
        const filtersWithValue = selectedFilters.filter(filter => Boolean(filter.value))

        setSelectedFilters(filtersWithValue)
    }

    return (
        <Grid.Background>
            <FiltersList
                onBack={disableEmptyFilters}
                onUpdate={updateSelectedFilters}
                stores={route.params.stores || []}
            />
        </Grid.Background>
    )
}
