import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Grid, Touchable, Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'

type SquareTileProps = {
    disabled?: boolean,
    label: string,
    icon: React.ReactElement,
    onPress: VoidFunction,
    customContainerStyle?: ViewStyle
}
export const SquareTile: React.FunctionComponent<SquareTileProps> = ({
    disabled,
    label,
    icon,
    onPress,
    customContainerStyle = {}
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.tileWrapper,
                ...customContainerStyle
            }}
        >
            <Touchable
                disabled={disabled}
                style={styles.tile}
                onPress={onPress}
            >
                {icon}
                <Grid.Gap gapBottom={3}/>
                <Typography.Title>
                    {label}
                </Typography.Title>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    tileWrapper: {
        flex: 1,
        height: 168,
        paddingHorizontal: 12,
        maxWidth: '50%'
    },
    tile: {
        flexGrow: 1,
        flexBasis: 0,
        height: 168,
        borderRadius: theme.components.button.borderRadius,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.ui.foreground
    }
}))
