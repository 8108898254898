import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { authActions } from 'features/auth'
import { QueryKey, useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { User, UserStore } from 'lib/models'
import { useUserAtom } from 'lib/atoms'
import {
    AddNewUserRequest,
    AddNewUserResponse,
    DeleteUserRequest,
    EditSelfRequest,
    EditUserRequest,
    EditUserResponse,
    GetUserStoresRequest,
    GetUserStoresResponse
} from './types'

export const useGetMe = () => {
    const fetcher = useFetcher<User>(HttpMethod.GET, '/user/me')
    const [, setUser] = useUserAtom()

    return useQuery([QueryKey.UserMe], fetcher, {
        retry: 5,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: false,
        onSuccess: ({ data }) => {
            setUser(data)
        }
    })
}

export const useGetUsers = (query?: string, offset?: number, limit?: number, disabled?: boolean) => {
    const fetcher = useFetcher<UserStore>(HttpMethod.GET, '/user/search')
    const body = query ? {
        query,
        offset: query ? 0 : offset,
        limit: query ? 30 : limit
    } : {
        offset: query ? 0 : offset,
        limit: query ? 30 : limit
    }

    return useQuery({
        queryKey: [QueryKey.Users, {
            query,
            offset,
            limit
        }],
        queryFn: () => fetcher(body),
        enabled: !disabled
    })
}

export const useGetUsersInfinity = (query?: string, disabled?: boolean) => {
    const limitPerFetch = 15
    const fetcher = useFetcher<UserStore>(HttpMethod.GET, '/user/search')

    return useInfiniteQuery({
        queryKey: [QueryKey.InfiniteUsers, {
            query
        }],
        queryFn: params => fetcher(query ? {
            offset: params.pageParam,
            query,
            limit: limitPerFetch
        } : {
            offset: params.pageParam,
            limit: limitPerFetch
        }),
        getNextPageParam: (lastPage, pages) => {
            const pagesCount = pages.flatMap(page => page.data).length
            const fetchedUsersCount = pages.flatMap(page => page.data.users).length
            const totalUsersCount = lastPage.data.totalCount

            if (totalUsersCount > fetchedUsersCount) {
                return pagesCount * limitPerFetch
            }
        },
        enabled: !disabled
    })
}

export const useAddNewUser = () => {
    const fetcher = useFetcher<AddNewUserResponse>(HttpMethod.POST, '/user')

    return useMutation<Response<AddNewUserResponse>, ErrorResponse, AddNewUserRequest>(newUser => fetcher(newUser))
}

export const useEditSelf = () => {
    const fetcher = useFetcher<EditUserResponse>(HttpMethod.PATCH, '/user/me')

    return useMutation<Response<EditUserResponse>, ErrorResponse, EditSelfRequest>(editedUser => fetcher(editedUser))
}

export const useEditUser = () => {
    const fetcher = useFetcher<EditUserResponse>(HttpMethod.PATCH, '/user')

    return useMutation<Response<EditUserResponse>, ErrorResponse, EditUserRequest>(editedUser => fetcher(editedUser))
}

export const useDeleteUser = () => {
    const fetcher = useFetcher<void>(HttpMethod.DELETE, '/user')

    return useMutation<Response<void>, ErrorResponse, DeleteUserRequest>(toDeleteUserUUID => fetcher(toDeleteUserUUID))
}

export const useGetUserStores = () => {
    const fetcher = useFetcher<GetUserStoresResponse>(HttpMethod.GET, '/user/search')

    return useMutation<Response<GetUserStoresResponse>, ErrorResponse, GetUserStoresRequest>(userUUID => fetcher(userUUID))
}

export const useDeleteAccount = () => {
    const fetcher = useFetcher<void>(HttpMethod.DELETE, '/user/me')
    const { mutate: logout } = authActions.useLogout()

    return useMutation<Response<void>, ErrorResponse, void>(() => fetcher(), {
        onSuccess: () => logout()
    })
}
