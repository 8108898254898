import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useGoBack, useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { AppLayout, GoBack, Typography } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { Measurements, isNative, isWeb } from 'lib/common'
import { getSearchItemDetails } from '../utils'

type SearchDetailsProps = {
    route: NavigationParams<ScreenNames.SearchItemDetails>
}

export const SearchDetails: React.FunctionComponent<SearchDetailsProps> = ({ route }) => {
    const T = useTranslations()
    const { goBack } = useGoBack(ScreenNames.Search)
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.XS)
    const { styles, theme } = useStyles(stylesheet)
    const item = route.params.item
    const {
        topItems,
        bottomItems
    } = getSearchItemDetails(item)

    useEffect(() => {
        if (!item.SKU) {
            goBack()
        }
    }, [])

    return (
        <AppLayout>
            {isNative && (
                <View style={styles.header}>
                    <GoBack />
                    <Typography.Heading>
                        {T.screens.searchDetails.title}
                    </Typography.Heading>
                </View>
            )}
            <View style={styles.container}>
                <View style={styles.details}>
                    <View style={styles.topDescription}>
                        <View style={styles.image}>
                            <Icons.Books
                                size={isWeb ? 200 : 120}
                                forceColor={theme.colors.lightGray}
                                topBookColor={theme.colors.almostBlack}
                            />
                        </View>
                        {(isNative || isMobileView) && (
                            <View style={styles.topDetailsMobile}>
                                {topItems.reverse().map((item, index) => (
                                    <View
                                        style={styles.tile}
                                        key={index}
                                    >
                                        <View style={styles.countBorder}>
                                            <Typography.SubText
                                                forceColor={theme.ui.primary}
                                                style={styles.countTextMobile}
                                            >
                                                {item.value}
                                            </Typography.SubText>
                                        </View>
                                        <Typography.SubText style={styles.bottomText}>
                                            {T.screens.searchDetails.information[item.key].toUpperCase()}
                                        </Typography.SubText>
                                    </View>
                                ))}
                            </View>
                        )}
                    </View>
                    <View style={styles.bottomDescriptionWeb}>
                        <Typography.SubText
                            numberOfLines={1}
                            style={styles.information}
                        >
                            {T.screens.searchDetails.information.bookInformation}
                        </Typography.SubText>
                        {bottomItems.map((item, index) => (
                            <View
                                key={index}
                                style={styles.informationRow}
                            >
                                <View style={styles.informationTitle}>
                                    <Typography.SubText numberOfLines={2}>
                                        {T.screens.searchDetails.information[item.key]}
                                    </Typography.SubText>
                                </View>
                                <Typography.SubText
                                    numberOfLines={2}
                                    style={styles.information}
                                >
                                    {item.value}
                                </Typography.SubText>
                            </View>
                        ))}
                        {(isWeb && !isMobileView) && (
                            <View style={styles.grid}>
                                <View style={styles.row}>
                                    {topItems.map((item, index) => (
                                        <View
                                            key={index}
                                            style={styles.tile}
                                        >
                                            <View style={styles.countBorder}>
                                                <Typography.SubText
                                                    forceColor={theme.ui.primary}
                                                    style={styles.countText}
                                                >
                                                    {item.value}
                                                </Typography.SubText>
                                            </View>
                                            <Typography.SubText style={styles.bottomText}>
                                                {T.screens.searchDetails.information[item.key].toUpperCase()}
                                            </Typography.SubText>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </View>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: theme.gap * 3,
        padding: {
            xs: theme.contentPaddingMobile,
            sm: theme.contentPaddingWeb
        },
        backgroundColor: theme.ui.background
    },
    details: {
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        gap: theme.gap * 4,
        padding: {
            xs: theme.gap * 2,
            sm: theme.gap * 4
        },
        backgroundColor: hexToRGBA(theme.ui.foreground, 0.5),
        borderRadius: 12,
        width: {
            xs: '100%',
            sm: 756
        },
        overflow: 'scroll'
    },
    topDescription: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.gap * 3
    },
    image: {
        width: {
            sm: 300
        },
        height: {
            xs: 248,
            sm: 400,
            ':w[500, 700]': 400,
            ':w[700, 991]': 500
        },
        flexGrow: 1,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.ui.background
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        gap: {
            xs: theme.gap,
            sm: theme.gap * 3
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: {
            xs: theme.gap,
            sm: theme.gap * 4
        }
    },
    countBorder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        minWidth: 86,
        height: {
            xs: 46,
            sm: 64
        },
        borderWidth: 1,
        borderColor: theme.components.button.outline.borderColor,
        paddingVertical: 10,
        paddingHorizontal: theme.gap * 2
    },
    countText: {
        fontSize: 32,
        lineHeight: 32
    },
    countTextMobile: {
        fontSize: 16,
        lineHeight: 16
    },
    bottomText: {
        color: theme.components.typography.primary.color,
        fontSize: 14
    },
    tile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.gap
    },
    price: {
        fontSize: 32,
        lineHeight: 32
    },
    bottomDescriptionWeb: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap * 3,
        flexShrink: {
            sm: 1
        }
    },
    informationRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.gap * 2
    },
    informationTitle: {
        width: 100
    },
    information: {
        color: theme.components.typography.primary.color,
        fontWeight: '400',
        flexShrink: 1
    },
    header: {
        paddingTop: Measurements.StatusBarHeight + theme.gap * 2,
        backgroundColor: theme.ui.background,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        paddingBottom: theme.gap
    },
    topDetailsMobile: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.gap
    }
}))
