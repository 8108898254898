import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Biometry: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 44.26 11.452 c 7.768 -0.18 12.936 9.76 12.784 17.856 c -0.032 1.72 0.328 3.432 1.144 4.932 l 4.172 7.64 c 0.648 1.22 0.12 2.504 -1.056 2.588 l -2.84 0.068 l 0.16 10.152 c 0.04 2.668 -2.012 4.852 -4.56 4.852 H 45.2 l -0.24 10.824 M 22.348 17.48 c 3.672 -4.044 8.892 -6.04 14.224 -6.028 M 20.16 21.24 C 17.776 25.9 17.38 31.88 17.776 36 M 18.24 40.376 c 1.132 8.696 5.448 17.336 17.88 19.008 M 22.188 26.576 c 1.136 -5.028 3.592 -9.12 9.784 -10.68"
                />
                <Path
                    d="M 34.58 54.82 c -1.7 -0.048 -4.24 -1.404 -5.468 -2.16 M 26.192 50.16 c -3.684 -2.82 -5.664 -13.752 -4.816 -18.44 M 28.32 33 c -0.136 -5.904 2.072 -10.656 9.784 -11.048 M 28.544 37.44 c -0.144 2.76 3.436 8.184 7.576 9.34 M 37.2 30.296 s -3.652 -1.724 -4.788 1.888 c -0.52 1.668 -0.3 5.48 1.144 7.216 c 1.936 2.332 4.008 3.004 4.008 3.004"
                />
                <Path
                    d="M 36.572 18.4 s -8.036 -0.36 -10.38 6.808 c -2.124 6.488 -1.456 12.652 -0.552 15.736 c 1.444 4.9 3.972 9.1 10.932 10.256"
                />
                <Path
                    d="M 38.864 26.576 s -1.064 -1.652 -3.016 -1 M 5 12.764 V 4 h 8.376 M 77 12.764 V 4 h -8.376 M 77 67.236 V 76 h -8.376 M 5 67.236 V 76 h 8.376"
                />
            </G>
        </Icon>
    )
}
