import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { useIconColor } from './useIconColor'
import { Icon } from './Icon'

export const HouseBorder: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="m 7 28.552 l 32.912 -24.428 a 0.62 0.62 90 0 1 0.744 0 L 59.24 18.2 V 11.896 c 0 -0.352 0.28 -0.64 0.632 -0.632 l 6.32 0.06 c 0.34 0 0.62 0.292 0.62 0.628 v 12.36 l 6.7 5.416 M 11.656 36.348 v 35.612 c 0 1.816 1.48 3.292 3.308 3.292 h 51.12 c 1.824 0 3.308 -1.476 3.308 -3.292 V 36.348"
                stroke={color}
                strokeWidth={5}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </Icon>
    )
}
