import React, { Ref, useEffect, useRef } from 'react'
import { TextInput } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Adapter, BlurModal, Button, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { isAndroid } from 'lib/common'
import { createStyles, useStyles } from 'lib/styles'
import { inputProps } from 'lib/utils'
import { useAddBookCodeForm } from '../forms'

type ManualInputModalProps = {
    isVisible: boolean,
    onClose: VoidFunction,
    onSuccess(bookCode: string): void
}

export const ManualInputModal: React.FunctionComponent<ManualInputModalProps> = ({
    isVisible,
    onClose,
    onSuccess
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const inputRef = useRef<TextInput>()
    const { form, submit, resetForm } = useForm(useAddBookCodeForm(), {
        onSuccess: formValues => {
            onSuccess(formValues.bookCode)
            resetForm()
        }
    })

    useEffect(() => {
        if (!isVisible) {
            return inputRef.current?.clear()
        }

        const timeout = setTimeout(() => inputRef.current?.focus(), 100)

        return () => clearTimeout(timeout)
    }, [isVisible])

    return (
        <BlurModal
            isVisible={isVisible}
            onClose={onClose}
            enableKeyboardAvoiding={!isAndroid}
        >
            <Grid.Gap gapTop={4}/>
            <Typography.Title style={styles.title}>
                {T.screens.scanner.modalTitle}
            </Typography.Title>
            <Grid.Gap
                gapTop={2}
                gapBottom={2}
            >
                <Adapter.TextInput
                    ref={inputRef as Ref<TextInput> | undefined}
                    {...form.bookCode}
                    inputProps={{
                        style: styles.input,
                        ...inputProps.bookCode
                    }}
                />
            </Grid.Gap>
            <Typography.Subheading forceColor={theme.components.typography.secondary.color}>
                {T.screens.scanner.bookCodeExample}
            </Typography.Subheading>
            <Grid.Gap gapBottom={10}/>
            <Button
                disabled={!form.bookCode.value}
                text={T.common.confirm}
                onPress={submit}
            />
        </BlurModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalBackground: {
        flex: 1,
        justifyContent: 'center'
    },
    container: {
        paddingHorizontal: theme.utils.gap(3),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.ui.background,
        paddingBottom: 80,
        borderRadius: theme.components.button.borderRadius
    },
    title: {
        fontWeight: '300'
    },
    input: {
        backgroundColor: theme.colors.transparent,
        borderBottomWidth: 1,
        borderBottomColor: theme.components.typography.secondary.color,
        textAlign: 'center',
        width: 240,
        height: isAndroid ? undefined : 46,
        paddingTop: isAndroid ? 12 : 20,
        fontSize: 16
    },
    blur: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
}))
