import React from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Icons } from 'assets'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { isWeb } from 'lib/common'
import { regexes } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { useCurrentStoreAtom } from 'lib/atoms'

type TitleProps = {
    onBurgerMenuPress: VoidFunction
}

export const Title: React.FunctionComponent<TitleProps> = ({ onBurgerMenuPress }) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const route = useRoute()
    const { styles, theme } = useStyles(stylesheets)
    const disabledScreens = [ScreenNames.EditSelf]
    const shouldShowEditIcon = !disabledScreens.includes(route.name as ScreenNames)
    const [currentStore] = useCurrentStoreAtom()
    const storeItemsTitle = route.name === ScreenNames.StoreItems && currentStore?.storeName

    return (
        <View style={styles.container}>
            {isWeb && (
                <Touchable onPress={onBurgerMenuPress}>
                    <Icons.BurgerMenu
                        forceColor={theme.colors.white}
                        size={24}
                    />
                </Touchable>
            )}
            <View style={styles.header}>
                <Typography.Heading>
                    {storeItemsTitle
                        ? storeItemsTitle
                        : T.titles[regexes.firstLetterLowercase(route.name)]
                    }
                </Typography.Heading>
            </View>
            {shouldShowEditIcon ? (
                <Touchable onPress={() => navigation.navigate(ScreenNames.EditSelf)}>
                    <Icons.User
                        size={24}
                        forceColor={theme.ui.primary}
                    />
                </Touchable>
            ) : (
                // NOTE: Empty view to keep the layout
                <View />
            )}
        </View>
    )
}

const stylesheets = createStyles(theme => ({
    container: {
        backgroundColor: theme.ui.background,
        display: {
            xs: 'flex',
            sm: 'none'
        },
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingHorizontal: theme.gap * 3,
        alignItems: 'center',
        position: 'relative',
        paddingTop: theme.contentPaddingMobile
    },
    header: {
        display: {
            xs: 'flex',
            sm: 'none'
        }
    }
}))
