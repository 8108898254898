import React, { useState } from 'react'
import { Alert } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { isNative, isWeb, Measurements } from 'lib/common'
import {
    Grid,
    Touchable,
    Typography,
    CustomKeyboardAccessory,
    GoBack
} from 'lib/components'
import { useBackHandler, useIsWithinBreakpoints, useToast, useTranslations } from 'lib/hooks'
import { UserType } from 'lib/models'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { Breakpoint, conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { useSignInWithEmail } from '../actions'
import { SignInForm, BackgroundImage } from '../components'
import { useSignInWithEmailForm } from '../forms'
import { useBiometry } from '../hooks'

type SignInScreenProps = {
    route: NavigationParams<ScreenNames.SignIn>
}

export const SignInScreen: React.FunctionComponent<SignInScreenProps> = ({ route }) => {
    const isMobileView = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)
    const T = useTranslations()
    const { actions: { showErrorToast } } = useToast()
    const { styles, theme } = useStyles(stylesheet)
    const [isAuthorizing, setIsAuthorizing] = useState(false)
    const onSignInWithEmailSuccess = (email: string, password: string) => {
        if (isSupportingBiometry && biometryInfo?.email !== email) {
            showSaveCredentialsAlert({
                username: email,
                password
            })
        }
    }
    const { mutate: signInWithEmail, isLoading } = useSignInWithEmail(onSignInWithEmailSuccess)
    const {
        showSaveCredentialsAlert,
        isSupportingBiometry,
        loadCredentials,
        biometryInfo
    } = useBiometry(route.params.userType)
    const showBiometryButton = route.params.userType === UserType.Manager && biometryInfo?.userType === UserType.Manager
    const { form, submit } = useForm(useSignInWithEmailForm(), {
        onSuccess: formData => signInWithEmail(
            {
                ...formData,
                userType: route.params.userType
            },
            {
                onError: error => showErrorToast(error.data?.message || T.alerts.oops)
            }
        )
    })

    const onSignInWithBiometry = () => {
        loadCredentials({
            onSuccess: credentials => {
                form.email.onChangeValue(credentials.username)
                form.password.onChangeValue(credentials.password)
                signInWithEmail(
                    {
                        userType: route.params.userType,
                        password: credentials.password,
                        email: credentials.username
                    },
                    {
                        onError: () => Alert.alert(T.alerts.oops, T.components.authMethods.errorBiometry)
                    }
                )
            }
        }).catch(R.T)
    }

    useBackHandler(() => isLoading)

    return (
        <Grid.Background
            style={{
                ...styles.background,
                ...conditionalStyle((isWeb && isMobileView), styles.mobileWebBackground)
            }}
        >
            {isWeb && (
                <BackgroundImage />
            )}
            {isNative ? (
                <CustomKeyboardAccessory
                    numberOfInputs={2}
                    contentContainerStyle={styles.keyboardAccessory}
                    centerComponent={isSupportingBiometry && biometryInfo.email
                        ? (
                            <Touchable onPress={onSignInWithBiometry}>
                                <Typography.Regular forceColor={theme.ui.primary}>
                                    {T.screens.signIn.biometric}
                                </Typography.Regular>
                            </Touchable>
                        )
                        : undefined
                    }
                >
                    {keyboardProps => {
                        const [emailProps, passwordProps] = keyboardProps

                        return (
                            <React.Fragment>
                                <GoBack />
                                <SignInForm
                                    formProps={{
                                        emailProps,
                                        passwordProps
                                    }}
                                    isLoading={isLoading}
                                    form={form}
                                    isAuthorizing={isAuthorizing}
                                    userType={route.params.userType}
                                    onSubmit={submit}
                                    showBiometryButton={showBiometryButton}
                                    onSignInWithBiometry={onSignInWithBiometry}
                                    setIsAuthorizing={setIsAuthorizing}
                                />
                            </React.Fragment>
                        )
                    }}
                </CustomKeyboardAccessory>
            ) : (
                <SignInForm
                    isLoading={isLoading}
                    form={form}
                    isAuthorizing={isAuthorizing}
                    userType={route.params.userType}
                    onSubmit={submit}
                    setIsAuthorizing={setIsAuthorizing}
                />
            )}
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    background: {
        paddingTop: theme.gap * 4,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mobileWebBackground: {
        justifyContent: 'flex-start'
    },
    keyboardAccessory: {
        width: Measurements.WindowWidth,
        paddingHorizontal: theme.gap * 3,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Number(Measurements.StatusBarHeight) + theme.gap * 3
    }
}))
