import { ParamListBase, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { isNative } from 'lib/common'
import { AppStackParams } from 'lib/routing'

export const useGoBack = <T extends keyof AppStackParams>(screenName: T, params?: AppStackParams[T]) => {
    const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>()

    const goBack = () => {
        if (navigation.canGoBack() || isNative) {
            return navigation.goBack()
        }

        navigation.replace(screenName, params)
    }

    return {
        goBack
    }
}
