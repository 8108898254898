import React from 'react'
import { Modal, Keyboard, View } from 'react-native'
import { BlurView } from 'expo-blur'
import Animated, {
    runOnJS,
    useSharedValue,
    withDelay,
    withTiming,
    cancelAnimation,
    useAnimatedStyle
} from 'react-native-reanimated'
import { Gesture  } from 'react-native-gesture-handler'
import { createStyles, useStyles } from 'lib/styles'
import { Children, VoidFunction } from 'lib/types'
import { Measurements, isAndroid } from 'lib/common'
import { ModalContent } from './ModalContent'
import { Touchable } from '../Touchable'

type ChildrenProps = {
    onModalClose(didClose?: VoidFunction): void
}

type AnimatedModalProps = {
    isVisible: boolean,
    onClose: VoidFunction,
    draggable: boolean,
    enableKeyboardAwareForAndroid?: boolean,
    hasCloseButton?: boolean,
    keyboardShouldPersistTaps?: boolean,
    children(props: ChildrenProps): Children
}

export const AnimatedModal: React.FunctionComponent<AnimatedModalProps> = ({
    isVisible,
    onClose,
    draggable,
    keyboardShouldPersistTaps = false,
    children
}) => {
    const { styles } = useStyles(stylesheet)
    const MODAL_HIDE_THRESHOLD = Measurements.WindowHeight / 5
    const animatedBackgroundOpacity = useSharedValue(0)
    const animatedBackgroundTranslateY = useSharedValue<number>(Measurements.WindowHeight)
    const animatedModalBodyStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: animatedBackgroundTranslateY.value
            }
        ]
    }))
    const onModalClose = () => {
        animatedBackgroundOpacity.value = withTiming(0)
        animatedBackgroundTranslateY.value = withTiming(Measurements.WindowHeight, undefined, isFinished => {
            if (isFinished) {
                runOnJS(onClose)()
            }
        })
    }
    const dragDown = Gesture.Pan()
        .onBegin(() => {
            cancelAnimation(animatedBackgroundTranslateY)
        })
        .onChange(({ translationY }) => {
            animatedBackgroundTranslateY.value = animatedBackgroundTranslateY.value + translationY < 0
                ? animatedBackgroundTranslateY.value
                : translationY
        })
        .onFinalize(({ translationY }) => {
            if (translationY >= MODAL_HIDE_THRESHOLD) {
                return onModalClose()
            }

            animatedBackgroundTranslateY.value = withTiming(0)
        })
        .runOnJS(true)
        .enabled(draggable)

    return (
        <Modal
            transparent
            visible={isVisible}
            onRequestClose={() => onModalClose()}
            animationType="none"
            statusBarTranslucent // required for android not to trigger external keyboard avoiding
            onShow={() => {
                animatedBackgroundTranslateY.value = withDelay(250, withTiming(0))
                animatedBackgroundOpacity.value = withDelay(250, withTiming(1))
            }}
        >
            {isAndroid && (
                <View style={styles.blur} />
            )}
            {!isAndroid && (
                <BlurView
                    style={styles.blur}
                    tint="dark"
                    intensity={2}
                // todo
                // reducedTransparencyFallbackColor={theme.colors.mixTransparent(theme.colors.black, 0.5)}
                />
            )}
            <Animated.View
                style={[
                    animatedModalBodyStyles,
                    styles.container
                ]}
            >
                <ModalContent
                    dragDown={dragDown}
                    draggable={draggable}
                    onBackgroundPress={() => onModalClose()}
                >
                    <Touchable
                        activeOpacity={1}
                        onPress={keyboardShouldPersistTaps ? undefined : Keyboard.dismiss}
                    >
                        {children({ onModalClose })}
                    </Touchable>
                </ModalContent>
            </Animated.View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    blur: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.colors.mixTransparent(theme.colors.black, 0.5)
    }
}))
