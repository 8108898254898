import React from 'react'
import { TextStyle } from 'react-native'
import { TextChildren, VoidFunction } from 'lib/types'
import { createStyles, useStyles } from 'lib/styles'
import { BaseText } from './BaseText'

type RegularProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    children?: TextChildren,
    bold?: boolean
}

export const SubText: React.FunctionComponent<RegularProps> = ({
    children,
    numberOfLines,
    onPress,
    style = {},
    forceColor,
    bold
}) => {
    const { styles } = useStyles(stylesheet)
    const userStyles = bold
        ? {
            ...styles.bold,
            ...style
        }
        : style

    return (
        <BaseText
            onPress={onPress}
            style={{
                ...styles.text,
                ...userStyles
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.typography.subText,
        fontSize: 16,
        color: {
            xs: theme.colors.lighterGray,
            sm: theme.colors.lightGray
        },
        lineHeight: 16
    },
    bold: {
        fontWeight: '400'
    }
}))
