import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Typography } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type ItemDetailTileProps = {
    label: string,
    value: string,
    style?: ViewStyle
}

export const ItemDetailTile: React.FunctionComponent<ItemDetailTileProps> = ({
    label,
    value,
    style
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            <Typography.SubText>
                {label}:
            </Typography.SubText>
            <Typography.SubText forceColor={theme.components.typography.primary.color}>
                {value}
            </Typography.SubText>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.mixTransparent(theme.ui.accent, 0.5),
        padding: theme.utils.gap(2),
        paddingTop: 0,
        marginBottom: theme.utils.gap(2)
    }
}))
