import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Storefront: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
            >
                <Path
                    d="M 11.274 2.28 h 60.572 c 0.95 0 1.786 0.608 2.052 1.558 l 3.838 12.806 c 0.114 0.342 0.152 0.722 0.152 1.064 v 5.548 c -0.38 6.08 -9.348 6.08 -9.918 0 c -0.152 5.852 -10.146 6.118 -10.526 0 c 0.266 6.346 -10.26 6.308 -10.412 0 c -0.114 6.498 -10.374 6.346 -10.716 0 c 0.304 6.422 -10.564 6.498 -10.374 0 c -0.684 6.726 -10.602 5.928 -10.45 0 c -0.76 6.118 -9.69 6.498 -10.45 0 V 17.86 c 0 -0.38 0.038 -0.722 0.152 -1.102 L 9.184 3.8 c 0.266 -0.912 1.102 -1.52 2.052 -1.52 h 0.038 Z"
                />
                <Path
                    d="M 10.78 28.006 v 44.878 c 0 2.926 2.356 5.282 5.282 5.282 h 51.262 c 2.926 0 5.282 -2.356 5.282 -5.282 V 28.006 M 21.8 41.8 h 30.476 M 21.8 64.6 h 30.476 M 59.116 41.8 h 5.016 M 59.116 64.6 h 5.016 M 10.4 53.2 h 61.788 M 18.988 2.28 l -3.458 20.976 M 28.298 2.28 L 25.98 23.256 M 36.316 23.75 L 37 2.28 M 47.07 23.75 L 45.854 2.28 M 57.558 23.94 L 54.632 2.28 M 68.198 24.206 L 63.638 2.28 M 5.27 18.126 l 72.428 -0.608"
                />
            </G>
        </Icon>
    )
}
