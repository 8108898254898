import React from 'react'
import { View, ViewProps, ViewStyle } from 'react-native'
import { createStyles, useStyles } from 'lib/styles'

interface ForegroundProps extends ViewProps {
    style?: ViewStyle
}

export const Foreground: React.FunctionComponent<ForegroundProps> = ({
    style = {},
    children,
    ...props
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View
            {...props}
            children={children}
            style={{
                ...styles.container,
                ...style
            }}
        />
    )
}
const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white
    }
}))
