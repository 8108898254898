import React from 'react'
import { BlurModal, Button, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'

type ItemAddedModalScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ItemAddedModal>
}

export const ItemAddedModalScreen: React.FunctionComponent<ItemAddedModalScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <BlurModal
            isVisible
            onClose={navigation.goBack}
        >
            <Grid.Gap
                gapTop={1}
                gapBottom={5}
            >
                <Typography.Subheading style={styles.text}>
                    {T.screens.itemAdded.message}
                </Typography.Subheading>
            </Grid.Gap>
            <Button
                text={T.screens.itemAdded.button}
                onPress={navigation.goBack}
            />
        </BlurModal>
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(3)
    }
}))
