import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Logo: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M60.4857 80.0002H19.5143C1.20476 80.0002 0 75.2627 0 60.508V19.5876C0.842857 10.593 -2.94286 -1.15561 19.5143 0.0905962H60.4857C80 -0.275656 80 5.93161 80 19.5828V60.508C80 80.9563 74.2762 79.32 60.4857 80.0002Z"
            fill="#E77C76"
        />
        <Path
            d="M21.7 26.8411C25.1189 26.8411 27.8905 24.0726 27.8905 20.6576C27.8905 17.2426 25.1189 14.4741 21.7 14.4741C18.2811 14.4741 15.5095 17.2426 15.5095 20.6576C15.5095 24.0726 18.2811 26.8411 21.7 26.8411Z"
            fill="#fff"
        />
        <Path
            d="M16.2905 38.1569V58.5576C16.2905 60.3936 17.1715 62.1678 18.7429 63.1143C20.3143 64.0609 22.4429 64.0942 24.1143 63.0953C25.7381 62.1202 26.6572 60.2937 26.6572 58.4054V44.6352C26.8096 40.0499 34.6953 39.6837 34.6953 44.6352V59.0428C34.6953 60.0416 34.9667 61.0405 35.562 61.8443C35.962 62.3818 36.4572 62.8194 37.0048 63.1571C40.5239 65.3214 45.062 62.5864 45.062 58.4529V44.7827C45.2143 40.0452 53.1762 40.0452 53.3286 44.7827V58.3483C53.3286 60.2557 54.2429 62.0917 55.8715 63.0858C57.6143 64.1465 59.7477 64.0704 61.4524 62.8575C62.9048 61.8206 63.6953 60.0844 63.6953 58.3007V43.6792C63.6953 40.1594 62.0477 36.7822 59.1334 34.794C57.5905 33.738 55.1572 32.9675 52.5381 32.9152C44.7858 32.7677 42.4524 36.5682 42.4524 36.5682C39.3715 32.216 29.9667 31.921 26.1334 35.2982C25.262 33.6239 23.262 32.7011 21.2905 32.7725C18.462 32.8819 16.281 35.322 16.281 38.1521L16.2905 38.1569Z"
            fill="#fff"
        />
    </Icon>
)
