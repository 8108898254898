import { ENV } from '../types'

export const getEnv = () => window?.env?.EXPO_PUBLIC_NODE_ENV || process.env.NODE_ENV || ENV.DEVELOPMENT
export const isProduction = getEnv() === ENV.PRODUCTION
export const getGoogleWebClientId = () => window?.env?.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID || process.env.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID || ''
export const getGoogleIosClientId = () => window?.env?.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID || process.env.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID || ''
export const getGoogleAndroidClientId = () => window?.env?.EXPO_PUBLIC_GOOGLE_ANDROID_CLIENT_ID || process.env.EXPO_PUBLIC_OOGLE_ANDROID_CLIENT_ID || ''
export const getAzureClientId = () => window?.env?.EXPO_PUBLIC_AZURE_CLIENT_ID || process.env.EXPO_PUBLIC_AZURE_CLIENT_ID || ''
export const getAppleAppBundleIdentifier = () => window?.env?.EXPO_PUBLIC_APPLE_BUNDLE_IDENTIFIER || process.env.EXPO_PUBLIC_APPLE_BUNDLE_IDENTIFIER || ''
export const getApiUrl = () => window?.env?.EXPO_PUBLIC_API_URL || process.env.EXPO_PUBLIC_API_URL || ''
export const getTermsAndConditionsUrl = () => window?.env?.EXPO_PUBLIC_TERMS_AND_CONDITIONS_URL || process.env.EXPO_PUBLIC_TERMS_AND_CONDITIONS_URL || ''
export const getPrivacyPolicyUrl = () => window?.env?.EXPO_PUBLIC_PRIVACY_POLICY_URL || process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL || ''
