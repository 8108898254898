import React, { useEffect } from 'react'
import * as WebBrowser from 'expo-web-browser'
import { ActivityIndicator, Alert, View } from 'react-native'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { Icons } from 'assets'
import { isAndroid, isNative, isWeb } from 'lib/common'
import { Grid, Touchable } from 'lib/components'
import { useBackHandler, useTranslations } from 'lib/hooks'
import { UserType } from 'lib/models'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { useSignInWithEmail } from '../actions'
import { useAppleAuth, useGoogleAuth, useMicrosoftAuth } from '../authProviders'
import { useBiometry } from '../hooks'
import { GOOGLE_WEB_BUTTON_CONTAINER } from '../constants'

type AuthMethodsProps = {
    userType: UserType,
    showBiometry?: boolean,
    onLoading?(isLoading: boolean): void
}

WebBrowser.maybeCompleteAuthSession()

// TODO Need proper Apple and Microsoft accounts setup and redirect URLs
export const AuthMethods: React.FunctionComponent<AuthMethodsProps> = ({
    userType,
    showBiometry = true,
    onLoading = () => {}
}) => {
    const T = useTranslations()
    const route = useRoute()
    const { styles } = useStyles(stylesheet)
    const { authorizeWithGoogle, isLoading: isGoogleLoading, renderButton } = useGoogleAuth()
    const { authorizeWithApple, isLoading: isAppleLoading } = useAppleAuth()
    const { authorizeWithMicrosoft, isLoading: isMicrosoftLoading } = useMicrosoftAuth()
    const { loadCredentials, biometryInfo } = useBiometry(userType)
    const { mutate: signInWithEmail, isLoading: isBiometryLoading } = useSignInWithEmail()
    const isLoading = isBiometryLoading || isGoogleLoading || isAppleLoading || isMicrosoftLoading
    const hideMicrosoft = true // microsoft auth method should be hidden for now

    useEffect(() => {
        onLoading(isLoading)
    }, [isLoading])

    useBackHandler(() => isLoading)

    useFocusEffect(() => {
        if (isWeb) {
            renderButton(route.name)
        }
    })

    return (
        <View style={styles.container}>
            {biometryInfo?.userType === userType && showBiometry && (
                <React.Fragment>
                    <Touchable
                        disabled={isLoading}
                        style={styles.icon}
                        onPress={() => loadCredentials({
                            onSuccess: credentials => signInWithEmail(
                                {
                                    userType,
                                    password: credentials.password,
                                    email: credentials.username
                                },
                                {
                                    onError: () => Alert.alert(T.alerts.oops, T.components.authMethods.errorBiometry)
                                }
                            ),
                            onError: () => Alert.alert(T.alerts.oops, T.components.authMethods.errorBiometry)
                        })}
                    >
                        {isBiometryLoading ? (
                            <ActivityIndicator/>
                        ) : (
                            <Icons.Biometry size={24}/>
                        )}
                    </Touchable>
                    <Grid.Gap gapRight={3}/>
                </React.Fragment>
            )}
            <View style={styles.googleButtonContainer}>
                {isWeb && (
                    <View
                        nativeID={`${GOOGLE_WEB_BUTTON_CONTAINER}-${route.name}`}
                        style={styles.renderedGoogleWebButton}
                    />
                )}
                <Touchable
                    disabled={isLoading}
                    style={{
                        ...styles.icon,
                        ...conditionalStyle(isWeb, styles.googleWebButtonWrapper)
                    }}
                    onPress={isNative ? authorizeWithGoogle : undefined}
                >
                    {isGoogleLoading ? (
                        <ActivityIndicator/>
                    ) : (
                        <Icons.Google size={18}/>
                    )}
                </Touchable>
            </View>
            {!isAndroid && (
                <Grid.Gap gapLeft={3}>
                    <Touchable
                        disabled={isLoading}
                        style={styles.icon}
                        onPress={() => authorizeWithApple()
                            .catch(R.T)
                        }
                    >
                        {isAppleLoading ? (
                            <ActivityIndicator/>
                        ) : (
                            <Icons.Apple size={18}/>
                        )}
                    </Touchable>
                </Grid.Gap>
            )}
            {!hideMicrosoft && (
                <Grid.Gap gapLeft={3}>
                    <Touchable
                        disabled={isLoading}
                        style={styles.icon}
                        onPress={authorizeWithMicrosoft}
                    >
                        {isMicrosoftLoading ? (
                            <ActivityIndicator/>
                        ) : (
                            <Icons.Microsoft size={18}/>
                        )}
                    </Touchable>
                </Grid.Gap>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        backgroundColor: theme.ui.foreground,
        width: 50,
        height: 50,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center'
    },
    renderedGoogleWebButton: {
        position: 'absolute',
        width: 44,
        top: 5,
        left: 5,
        height: 44,
        overflow: 'hidden',
        borderRadius: 22
    },
    googleWebButtonWrapper: {
        pointerEvents: 'none',
        zIndex: 999
    },
    googleButtonContainer: {
        position: 'relative'
    }
}))
