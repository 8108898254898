import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { createStyles, useStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { useSearchFilters, useSearchStores } from 'lib/atoms'
import { isNative, isWeb } from 'lib/common'
import { Store } from 'lib/models'
import { FiltersListWeb } from './FiltersListWeb'

type FilterProps = {
    onReset: VoidFunction,
    stores: Array<Store>,
    isFetching?: boolean
}

export const Filter: React.FunctionComponent<FilterProps> = ({
    onReset,
    stores,
    isFetching
}) => {
    const [shouldRenderWebFilters, setShouldRenderWebFilters] = useState(false)
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const [searchFilters, setSearchFilters] = useSearchFilters()
    const [,setSearchStores] = useSearchStores()

    const navigateToFilters = () => {
        onReset()
        navigation.navigate(ScreenNames.SearchFilters, {
            stores
        })
    }

    return (
        <View style={styles.container}>
            {isWeb && shouldRenderWebFilters && (
                <FiltersListWeb
                    stores={stores}
                    onSave={(filters, stores) => {
                        setShouldRenderWebFilters(false)
                        setSearchFilters(filters)
                        setSearchStores(stores)
                    }}
                    onClose={() => setShouldRenderWebFilters(false)}
                />
            )}
            <Touchable
                style={styles.button}
                onPress={() => !isFetching && isNative
                    ? navigateToFilters()
                    : setShouldRenderWebFilters(prevValue => !prevValue)
                }
            >
                <Icons.Filter
                    size={32}
                    forceColor={theme.components.icon.primary}
                />
                {searchFilters.length > 0 && (
                    <View style={styles.filterBadge}>
                        <Typography.SubText
                            bold
                            forceColor={theme.colors.white}
                            style={styles.text}
                        >
                            {searchFilters.length}
                        </Typography.SubText>
                    </View>
                )}
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'relative',
        cursor: 'pointer',
        bottom: 6
    },
    button: {
        position: 'relative',
        top: -2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: 50,
        backgroundColor: theme.ui.foreground
    },
    filterBadge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 28,
        right: -6,
        width: 20,
        height: 20,
        borderRadius: 50,
        backgroundColor: theme.ui.primary
    },
    text: {
        fontSize: 12
    }
}))
