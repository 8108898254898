import { Field } from '@codegateinc/react-form-builder-v2'

export enum SignInWithEmailFormFields {
    Email = 'email',
    Password = 'password'
}

export enum SignUpWithEmailFormFields {
    FullName = 'fullName',
    Email = 'email',
    Password = 'password',
    ConfirmPassword = 'confirmPassword',
    TermsAndConditions = 'termsAndConditions'
}

export enum ResetPasswordFormFields {
    Password = 'password',
    ConfirmPassword = 'confirmPassword'
}

export enum ForgotPasswordFormFields {
    Email = 'email'
}

export type SignInWithEmailFormShape = {
    [SignInWithEmailFormFields.Email]: string,
    [SignInWithEmailFormFields.Password]: string
}

export type SignUpWithEmailFormShape = {
    [SignUpWithEmailFormFields.FullName]: Field<string>,
    [SignUpWithEmailFormFields.Email]: Field<string>,
    [SignUpWithEmailFormFields.Password]: Field<string>,
    [SignUpWithEmailFormFields.ConfirmPassword]: Field<string>,
    [SignUpWithEmailFormFields.TermsAndConditions]: Field<boolean>
}

export type ResetPasswordFormShape = {
    [SignUpWithEmailFormFields.Password]: string
    [SignUpWithEmailFormFields.ConfirmPassword]: string
}

export type ForgotPasswordFormShape = {
    [ForgotPasswordFormFields.Email]: string
}
