import React from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { isNative, isWeb, Measurements } from 'lib/common'
import { Grid, CustomKeyboardAccessory } from 'lib/components'
import { useBackHandler, useToast } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { ScreenNames, StackProps } from 'lib/routing'
import { useRegister } from '../actions'
import { BackgroundImage, SignUpForm } from '../components'
import { useSignUpWithEmailForm } from '../forms'

type SignUpScreenProps = {
    navigation: StackProps<ScreenNames>
}

export const SignUpScreen: React.FunctionComponent<SignUpScreenProps> = ({ navigation }) => {
    const { actions: { showErrorToast } } = useToast()
    const { styles } = useStyles(stylesheet)
    const { mutate: signUp, isLoading } = useRegister()
    const { form, submit } = useForm(useSignUpWithEmailForm(), {
        onSuccess: formData => signUp(formData, {
            onSuccess: () => navigation.navigate(ScreenNames.SignUpConfirm),
            onError: response => showErrorToast(response.data?.message || '')
        })
    })

    useBackHandler(() => isLoading)

    return (
        <Grid.Background
            hasPaddingHorizontal={isWeb}
            style={styles.background}
        >
            {isWeb && (
                <BackgroundImage />
            )}
            {isNative ? (
                <CustomKeyboardAccessory
                    numberOfInputs={4}
                    contentContainerStyle={styles.keyboardAccessory}
                >
                    {keyboardProps => {
                        const [
                            fullNameProps,
                            emailProps,
                            passwordProps,
                            confirmPasswordProps
                        ] = keyboardProps

                        return (
                            <SignUpForm
                                form={form}
                                formProps={{
                                    fullNameProps,
                                    emailProps,
                                    passwordProps,
                                    confirmPasswordProps
                                }}
                                isLoading={isLoading}
                                onSubmit={submit}
                            />
                        )
                    }}
                </CustomKeyboardAccessory>
            ) : (
                <SignUpForm
                    form={form}
                    isLoading={isLoading}
                    onSubmit={submit}
                />
            )}
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    separator: {
        height: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: Measurements.WindowWidth - theme.gap * 6,
        backgroundColor: theme.components.typography.secondary.color,
        overflow: 'visible'
    },
    separatorText: {
        fontSize: 12,
        height: 14,
        lineHeight: 14,
        color: theme.components.typography.secondary.color,
        paddingHorizontal: theme.gap * 1.5,
        backgroundColor: theme.ui.background
    },
    background: {
        paddingTop: 0,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    keyboardAccessory: {
        width: Measurements.WindowWidth,
        paddingHorizontal: theme.gap * 3,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Number(Measurements.StatusBarHeight) + theme.gap * 3
    }
}))
