import { ScrollView, View } from 'react-native'
import Animated, { AnimatedRef, measure, scrollTo } from 'react-native-reanimated'

type Config = {
    fieldRef: AnimatedRef<View>,
    scrollViewRef: AnimatedRef<ScrollView>,
    keyboardEndYPosition: number,
    animatedScrollY: Animated.SharedValue<number>,
    animatedExtraHeight?: Animated.SharedValue<number>
}

export const measureAndScrollToInput = (config: Config) => {
    'worklet'

    const {
        fieldRef,
        scrollViewRef,
        animatedScrollY,
        animatedExtraHeight,
        keyboardEndYPosition
    } = config
    const measured = measure(fieldRef)
    const inputYEndYPosition = (measured?.height as number ?? 0) +
        (measured?.pageY as number ?? 0) +
        animatedScrollY.value +
        (animatedExtraHeight?.value || 0)
    const scrollToY = inputYEndYPosition <= keyboardEndYPosition
        ? 0
        : inputYEndYPosition - keyboardEndYPosition

    scrollTo(
        scrollViewRef,
        0,
        scrollToY,
        true
    )
}
