import { ColumnConfig } from 'lib/types'

export const manageUsersTableHeaders = (isSmallerView?: boolean) => [
    {
        key: 'fullName',
        size: isSmallerView ? 120 : 240,
        title: 'User'
    },
    {
        key: 'email',
        size: isSmallerView ? 120 : 240,
        title: 'Mail'
    },
    {
        key: 'store',
        size: isSmallerView ? 240 : 340,
        title: 'Store'
    }
] satisfies Array<ColumnConfig>

export const manageStoresTableHeaders = [
    {
        key: 'store',
        title: 'Store'
    }
] satisfies Array<ColumnConfig>
