import React from 'react'
import { Alert } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Adapter, Button, GoBack, Grid, ReKeyboardAccessory, Typography } from 'lib/components'
import { useBackHandler, useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { inputProps } from 'lib/utils'
import { useResetPassword } from '../actions'
import { useResetPasswordForm } from '../forms'

type SignUpScreenProps = {
    navigation: NavigationProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ResetPassword>,
}

export const ResetPasswordScreen: React.FunctionComponent<SignUpScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { mutate: resetPassword, isLoading } = useResetPassword()
    const { form, submit } = useForm(useResetPasswordForm(), {
        onSuccess: ({ password }) => resetPassword({
            token: route.params.token,
            password
        }, {
            onSuccess: navigation.goBack,
            onError: () => Alert.alert(T.common.error, T.common.errorMessage)
        })
    })

    useBackHandler(() => isLoading)

    return (
        <Grid.Background style={styles.background}>
            <ReKeyboardAccessory
                numberOfInputs={2}
                contentContainerStyle={styles.form}
            >
                {keyboardProps => {
                    const [passwordProps, confirmPasswordProps] = keyboardProps

                    return (
                        <React.Fragment>
                            <GoBack/>
                            <Icons.Logo size={72}/>
                            <Grid.Gap gapBottom={2}/>
                            <Typography.Heading>
                                {T.screens.resetPassword.title}
                            </Typography.Heading>
                            <Grid.Gap gapBottom={2}/>
                            <Typography.Subheading style={styles.message}>
                                {T.screens.resetPassword.message}
                            </Typography.Subheading>
                            <Grid.Gap gapBottom={5}/>
                            <Adapter.TextInput
                                {...form.password}
                                {...passwordProps}
                                inputProps={inputProps.newPassword}
                                leftIcon={(
                                    <Icons.Padlock size={20}/>
                                )}
                                isPassword
                            />
                            <Grid.Gap gapBottom={1}/>
                            <Adapter.TextInput
                                {...form.confirmPassword}
                                {...confirmPasswordProps}
                                inputProps={inputProps.newPassword}
                                leftIcon={(
                                    <Icons.Padlock size={20}/>
                                )}
                                isPassword
                            />
                            <Grid.Gap gapBottom={6}/>
                            <Button
                                isLoading={isLoading}
                                text={T.common.save}
                                onPress={submit}
                            />
                            <Grid.Gap gapBottom={3}/>
                            <Button
                                noBackground
                                text={T.common.cancel}
                                onPress={navigation.goBack}
                            />
                        </React.Fragment>
                    )
                }}
            </ReKeyboardAccessory>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    message: {
        textAlign: 'center',
        paddingHorizontal: theme.gap * 2
    },
    background: {
        paddingTop: 0
    },
    form: {
        width: Measurements.WindowWidth,
        paddingHorizontal: theme.gap * 3,
        paddingBottom: 200,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Number(Measurements.StatusBarHeight) + theme.gap * 3
    }
}))
