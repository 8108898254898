import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Bin: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                d="M 73 21 c -11.84 -2.64 -23.752 -4 -35.628 -4 c -7.04 0 -14.08 0.8 -21.12 2.4 L 9 21 M 29 17 l 0.756 -5.292 c 0.548 -3.84 0.96 -6.708 6.752 -6.708 h 8.984 c 5.792 0 6.24 3.032 6.752 6.748 L 53 17 M 65 29 l -2.28 37.588 C 62.34 72.448 62.028 77 52.252 77 h -22.496 c -9.776 0 -10.088 -4.552 -10.476 -10.412 L 17 29 M 37 57 h 8 M 33 41 h 16"
                stroke={color}
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    )
}
