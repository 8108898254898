import { useAtom } from 'jotai'
import { UserStore } from 'lib/models'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const initialValue = {
    totalCount: 0,
    users: []
}

const { atom, resetAtom } = createAtom<UserStore>(initialValue, MMKVKey.Users)

export const useUsersAtom = () => useAtom(atom)
export const useResetUsersAtom = () => useAtom(resetAtom)
