export enum QueryKey {
    Users = 'Users',
    UserMe = 'UserMe',
    InfiniteUsers = 'InfiniteUsers',
    Stores = 'Stores',
    InfiniteStores = 'InfiniteStores',
    Item = 'Item',
    StoreItems = 'StoreItems',
    Country = 'country'
}
