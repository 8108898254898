import { useEffect } from 'react'
import { Alert } from 'react-native'
import { useAuthRequest, useAutoDiscovery } from 'expo-auth-session'
import { APP_CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'
import { getAzureClientId, R } from 'lib/utils'
import { useSignInWithMicrosoft } from '../actions'
import { AuthResult } from '../types'

export const useMicrosoftAuth = () => {
    const T = useTranslations()
    const discovery = useAutoDiscovery(APP_CONFIG.URLS.MICROSOFT_AUTH)
    const { mutate: signInWithMicrosoft, isLoading } = useSignInWithMicrosoft()
    const [
        request,
        response,
        promptAsync
    ] = useAuthRequest(
        {
            clientId: getAzureClientId(),
            scopes: [
                'openid',
                'profile',
                'email',
                'offline_access'
            ],
            redirectUri: APP_CONFIG.URLS.MICROSOFT_REDIRECT_URL
        },
        discovery
    )

    useEffect(() => {
        if (response && response.type === AuthResult.Success) {
            signInWithMicrosoft(
                {
                    // eslint-disable-next-line camelcase
                    access_token: response.params.code,
                    email: ''
                },
                {
                    onError: () => Alert.alert(T.alerts.oops, T.components.authMethods.errorMicrosoft)
                }
            )
        }
    }, [response])

    return {
        isLoading,
        authorizeWithMicrosoft: () => {
            if (request) {
                promptAsync()
                    .catch(R.T)
            }
        }
    }
}
