import React from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withTiming, withSequence } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'

const INITIAL_SCALE = 1
const MAX_SCALE = 1.2

type LogoProps = {
    size?: number
}

export const Logo: React.FunctionComponent<LogoProps> = ({ size = 80 }) => {
    const scale = useSharedValue(INITIAL_SCALE)
    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ scale: scale.value }]
    }))

    const startAnimation = () => {
        if (scale.value === INITIAL_SCALE) {
            scale.value = withSequence(
                withTiming(MAX_SCALE, { duration: 150 }),
                withTiming(INITIAL_SCALE, { duration: 150 })
            )
        }
    }

    return (
        <Touchable onPress={startAnimation}>
            <Animated.View style={animatedStyle}>
                <Icons.Logo size={size}/>
            </Animated.View>
        </Touchable>
    )
}
